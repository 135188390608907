// JavaScript source code
import { useState, useEffect, useCallback } from 'react';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../utils/Communicator';
import { useSelector, useDispatch } from 'react-redux';
import { URL, LANGUAGES, mailroom_id } from '../constants/global-constants';
import { onAdminChange, setSenderList, onParcelCountChange, onManifestChange, onCarrierPrefixChange, onPortOfLodingChange, emptyParcelList, deleteSender, deleteCarrierPrefix, deleteManifest, onUsersChange, onParcelListChange, setImageScanData, onWarehouseChange, onDiscriptionsChange, deleteDiscription, deleteCountryOfImport, deletePortOfLoding, onAirTerminalChange, onAgentCodeChange, onCountryOfImportChange } from './admin-reducers/AdminSlicer';
import { onQsChange, onEmptyQsChange } from './QS/qsSlice';
import { onCrmChange, onEmptyCrm, setCrmListItems } from './crm/crmSlice';
import { onCountryChange, onAirPortChange, onPortChange, setAgentNetwork, setFclExportImportAFs, setLclExportImportAFs, setAirExportAFs, setLclImportExport, setFclImportExport, setAirImportExport, setListOfServiceType, setCrmListByFilter, setFunctionTypes, setAirLines, setRejectReasions, setAirPorts, setPorts, setCountryAndOrigins, setMeasurementUnits, setFLCTypes20, setFLCTypes40, setChargesTypes, setListOfShipmentTypes, setListOfProductCategories, setListOfProductCharges, setListOfKgFactor, setListOfcurrency, setListOfExchangeRates, setQsTypeList, setQsStatusList, setListOfIncoterms } from './parameter/parameterSlice';
import { snackbarToggle } from './snackbarSlicer';
import { setAdmin, setAdminUser, setIsLoading } from './session/sessionSlice';


var bDownLoad = false; 
var downLoadId = 0;
export const getLoginDetails = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getLoginDetails" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            var users = response.misc.users;
            var admins = response.misc.admins;
            var LIST_QS = response.misc.LIST_QS;
            var FUNCTION_LIST = response.misc.FUNCTION_LIST;
            var QUOTATION_TYPE_LIST = response.misc.QUOTATION_TYPE_LIST;
            var QUOTATION_STATUS_LIST = response.misc.QUOTATION_STATUS_LIST;
            var LIST_COUNTRY_ORIGIN = response.misc.LIST_COUNTRY_ORIGIN;
            var LIST_AIR_LINE = response.misc.LIST_AIR_LINE;
            var rejectReasions = response.misc.rejectReasions;
            var LIST_AIR_PORT = response.misc.LIST_AIR_PORT;
            var LIST_PORT = response.misc.LIST_PORT;
            var LIST_INCOTERMS = response.misc.LIST_INCOTERMS;
            var LIST_SERVICE_TYPE = response.misc.LIST_SERVICE_TYPE;
            var LIST_EXCHANGE_RATE = response.misc.LIST_EXCHANGE_RATE;
            var CURRIENCIES = response.misc.CURRIENCIES;
            var LIST_KG_FACTOR = response.misc.LIST_KG_FACTOR;
            var LIST_PRODUCT_CHARGES = response.misc.LIST_PRODUCT_CHARGES;
            var PRODUCT_CATEGORIES = response.misc.PRODUCT_CATEGORIES;
            var SHIPMENT_TYPES = response.misc.SHIPMENT_TYPES;
            var CHARGES_TYPES = response.misc.CHARGES_TYPES;
            var MEASUREMENT_UNITS = response.misc.MEASUREMENT_UNITS;
            var LIST_FLC_TYPE_20 = response.misc.LIST_FLC_TYPE_20;
            var LIST_FLC_TYPE_40 = response.misc.LIST_FLC_TYPE_40;
            var AIR_EXPORT_IMPORT_LIST = response.misc.AIR_EXPORT_IMPORT_LIST;
            var LCL_EXPORT_IMPORT_LIST = response.misc.LCL_EXPORT_IMPORT_LIST;
            var FCL_EXPORT_IMPORT_LIST = response.misc.FCL_EXPORT_IMPORT_LIST;
            var LIST_AIR_EXPORT_AF = response.misc.LIST_AIR_EXPORT_AF;
            var NETWORK_LIST = response.misc.NETWORK_LIST;
            var LIST_LCL_EXPORT_AF = response.misc.LIST_LCL_EXPORT_AF;

            for (var i = 0; i < users.length; i++) {
                dispatch(onUsersChange(users[i]));
            }
            for (var i = 0; i < admins.length; i++) {
                dispatch(onAdminChange(admins[i]));
            }
            dispatch(onEmptyQsChange(LIST_QS));
            dispatch(setFunctionTypes(FUNCTION_LIST));
            dispatch(setQsTypeList(QUOTATION_TYPE_LIST));
            dispatch(setQsStatusList(QUOTATION_STATUS_LIST));
            dispatch(setCountryAndOrigins(LIST_COUNTRY_ORIGIN));
            dispatch(setAirLines(LIST_AIR_LINE));
            dispatch(setRejectReasions(rejectReasions));
            dispatch(setAirPorts(LIST_AIR_PORT));
            dispatch(setPorts(LIST_PORT));
            dispatch(setListOfIncoterms(LIST_INCOTERMS));
            dispatch(setListOfServiceType(LIST_SERVICE_TYPE));
            dispatch(setListOfExchangeRates(LIST_EXCHANGE_RATE));
            dispatch(setListOfcurrency(CURRIENCIES));
            dispatch(setListOfKgFactor(LIST_KG_FACTOR));
            dispatch(setListOfProductCharges(LIST_PRODUCT_CHARGES));
            dispatch(setListOfProductCategories(PRODUCT_CATEGORIES));
            dispatch(setListOfShipmentTypes(SHIPMENT_TYPES));
            dispatch(setChargesTypes(CHARGES_TYPES));
            dispatch(setMeasurementUnits(MEASUREMENT_UNITS));
            dispatch(setFLCTypes20(LIST_FLC_TYPE_20));
            dispatch(setFLCTypes40(LIST_FLC_TYPE_40));
            dispatch(setAirImportExport(AIR_EXPORT_IMPORT_LIST));
            dispatch(setLclImportExport(LCL_EXPORT_IMPORT_LIST));
            dispatch(setFclImportExport(FCL_EXPORT_IMPORT_LIST));
            dispatch(setAirExportAFs(LIST_AIR_EXPORT_AF));
            dispatch(setAgentNetwork(NETWORK_LIST));
            dispatch(setLclExportImportAFs(LIST_LCL_EXPORT_AF));
            dispatch(setIsLoading(false));
        },
        failedCallback: error => {

        }
    });
}
export const getUsers = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getUsers" }
    sendRequest(URL.USER_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {

            for (var i = 0; i < response.length; i++) {
                dispatch(onUsersChange(response[i]));
            }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getCRMs = (dispatch, admin) => {
    dispatch(setIsLoading(true));
    let loginObj = { "sessionId": admin.data.session, opcode: "getCRMs" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            
            dispatch(onEmptyCrm());
            for (var i = 0; i < response.data.length; i++) {
                dispatch(onCrmChange(response.data[i]));
            }
           
            dispatch(setIsLoading(false));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getCRMList = (dispatch, admin,callback) => {
   // dispatch(setIsLoading(true));
    let loginObj = { "sessionId": admin.data.session, opcode: "getCRMs" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            
            dispatch(setCrmListItems(response.data));
            if (callback) {
                callback();
            }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getCRMList1 = (dispatch, admin, callback) => {
    // dispatch(setIsLoading(true));
    //dispatch(setCrmListItems([]));
    let loginObj = { "sessionId": admin.data.session, opcode: "getCRMs" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {


            //dispatch(setCrmListItems(response.data));
            for (var i = 0; i < response.data.length; i++) {
                dispatch(onCrmChange(response.data[i]));
            }
            callback(response.data)
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getCRMList2 = (dispatch, admin, callback) => {
    // dispatch(setIsLoading(true));
    //dispatch(setCrmListItems([]));
    let loginObj = { "sessionId": admin.data.session, opcode: "getCRMList" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {


            //dispatch(setCrmListItems(response.data));
            /*for (var i = 0; i < response.data.length; i++) {
                dispatch(onCrmChange(response.data[i]));
            }*/
            callback(response.data)
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}

export const getCRMListByIds = (dispatch, admin, ids, callback) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getCRMListByIds", ids: ids }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            for (var i = 0; i < response.length; i++) {
               // dispatch(onCrmChange(response[i]));
            }
            //dispatch(onCrmChange(response));
           
        },
        failedCallback: error => {

        }
    });
}
export const updateCrm = (dispatch, response) => {
    dispatch(onCrmChange(response));
}
export const getCrmById_Updated = (dispatch, admin, id, callback) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getCrmById_updated", id: id }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {

            dispatch(onCrmChange(response));
            callback(response)
        },
        failedCallback: error => {

        }
    });
}
export const sendBulkMail = (dispatch, admin, email, message, subject,callBack) => {
   // dispatch(setIsLoading(true));
    let loginObj = { "sessionId": admin.data.session, opcode: "sendBulkMail", message: message, subject: subject, email: email }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
          callBack(response)
        },
        failedCallback: error => {

        }
    });
}
export const sendmailToMultiple = (dispatch, admin, toEmail, bccEmail, message, subject, header, footer,callBack) => {
   // dispatch(setIsLoading(true));
    let loginObj = {
        "sessionId": admin.data.session, opcode: "sendmailToMultiple", message: message, subject: subject, toEmail: toEmail, bccEmail: bccEmail, header: header, footer: footer, }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
          callBack(response)
        },
        failedCallback: error => {

        }
    });
}
export const sendMail = (dispatch, admin, email, message, subject,callBack) => {
   // dispatch(setIsLoading(true));
    let loginObj = { "sessionId": admin.data.session, opcode: "sendMail", message: message, subject: subject, email: email }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
          callBack(response)
        },
        failedCallback: error => {

        }
    });
}
export const getCRMsForMail = (dispatch, admin,callBack) => {
   // dispatch(setIsLoading(true));
    let loginObj = { "sessionId": admin.data.session, opcode: "getCRMList" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            callBack(response.data)
        },
        failedCallback: error => {

        }
    });
}
export const getAirLines = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getAirLines" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setAirLines(response.data));
            //
          ///  for (var i = 0; i < response.data.length; i++) {
           //     dispatch(onAirPortChange(response.data[i]));
           // }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getRejectReasions = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getRejectReasions" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setRejectReasions(response.data));
            //
          ///  for (var i = 0; i < response.data.length; i++) {
           //     dispatch(onAirPortChange(response.data[i]));
           // }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}


export const getBannerEmails = (dispatch, data, callback) => {
    data.opcode = 'getBannerEmails'
    //let loginObj = { "sessionId": admin.data.session, opcode: "getBannerEmails" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            callback(response)
        },
        failedCallback: error => {

        }
    });
}
export const getAirPorts = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getAirPorts" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setAirPorts(response.data));
            //
          ///  for (var i = 0; i < response.data.length; i++) {
           //     dispatch(onAirPortChange(response.data[i]));
           // }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getPorts = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getPorts" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(setPorts(response.data));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getExchangeRates = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getExchangeRates" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(setListOfExchangeRates(response.data));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getMeasurementUnits = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getMeasurementUnits" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(setMeasurementUnits(response));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getFLCTypes20 = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getFLCTypes20" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(setFLCTypes20(response.data));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getFLCTypes40 = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getFLCTypes40" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(setFLCTypes40(response.data));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getChargesTypes = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getChargesTypes" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(setChargesTypes(response));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getAgentNetwork = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getAgentNetwork" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(setAgentNetwork(response));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getFunctionTypes = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getFunctionTypes" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(setFunctionTypes(response));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getShipmentTypes = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getShipmentTypes" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(setListOfShipmentTypes(response));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getProductCategories = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getProductCategories" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(setListOfProductCategories(response));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getProductCharges = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getProductCharges" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(setListOfProductCharges(response.data));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getProductCharges1 = (dispatch, admin,callback) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getProductCharges" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
            
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getCurrencyConstants = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getCurrencyConstants" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(setListOfcurrency(response));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getCountryAndOrigins = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getCountryAndOrigins" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            
            dispatch(setCountryAndOrigins(response.data));
           // for (var i = 0; i < response.data.length; i++) {
             //   dispatch(onCountryChange(response.data[i]));
            //}
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getAdminList = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getAdminList" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            for (var i = 0; i < response.length; i++) {
                dispatch(onAdminChange(response[i]));
            }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getQuotationStatus = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getQuotationStatus" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setQsStatusList(response));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getIncoterms = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getIncoterms" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setListOfIncoterms(response.data));
        },
        failedCallback: error => {

        }
    });
}
export const getAirImportExport = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getAirImportExport" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setAirImportExport(response));
        },
        failedCallback: error => {

        }
    });
}
export const getLclExportImportAFs = (dispatch, admin, callback) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getLclExportImportAFs" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setLclExportImportAFs(response.data));
            dispatch(setIsLoading(false));
            if (callback) {
                callback()
            }
        },
        failedCallback: error => {

        }
    });
}
export const getFclExportImportAFs = (dispatch, admin, callback) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getFclExportImportAFs" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setFclExportImportAFs(response.data));
            dispatch(setIsLoading(false));
            if (callback) {
                callback()
            }
        },
        failedCallback: error => {

        }
    });
}
export const getCrmById = (dispatch, admin,id,callBack) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getCrmById" ,id:id}
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setAirExportAFs(response.data));
            if (callBack) {
                callBack()
            }
        },
        failedCallback: error => {

        }
    });
}
export const getCrmByName = (dispatch, admin,name,callBack) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getCrmByName", name: name }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setAirExportAFs(response.data));
            if (callBack) {
                callBack()
            }
        },
        failedCallback: error => {

        }
    });
}
export const getAirExportAFs = (dispatch, admin,callBack) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getAirExportAFs" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setAirExportAFs(response.data));
            if (callBack) {
                callBack()
            }
        },
        failedCallback: error => {

        }
    });
}
export const getLclImportExport = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getLclImportExport" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setLclImportExport(response));
        },
        failedCallback: error => {

        }
    });
}
export const getFclImportExport = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getFclImportExport" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setFclImportExport(response));
        },
        failedCallback: error => {

        }
    });
}
export const getKgFactors = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getKgFactors" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setListOfKgFactor(response.data));
        },
        failedCallback: error => {

        }
    });
}
export const getKgFactorsforSave = (dispatch, admin, callback) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getKgFactors" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response);
        },
        failedCallback: error => {

        }
    });
}
export const getCRMsPaginationFilter = (dispatch, admin, data,callback) => {
    let loginObj = {
        "sessionId": admin.data.session,
        numItems: data.numItems,
        pageNum: data.pageNum,
        CompanyName: data.CompanyName,
        FreeText: data.FreeText,
        Address: data.Address,
        CountryId: data.CountryId,
        FunctionId: data.FunctionId,
        opcode: "getCRMsPaginationFilter"
    }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
           // (callBack(response.data)); //
            dispatch(onParcelCountChange(response.data.count));
            //dispatch(setCrmListByFilter(response.data.list));
            callback(response.data.list)
        },
        failedCallback: error => {

        }
    });
}
export const getProductChargesByProdShipCharge = (dispatch, admin, productTypeId, shipmentTypeId, chargesTypeId,callback) => {
    
    var data = {
        sessionId: admin.data.session,
        "productTypeId": productTypeId,
        "shipmentTypeId": shipmentTypeId,
        "chargesTypeId": chargesTypeId,
        "opcode": "getProductChargesByProdShipCharge",
    }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
           
            callback(response)
        },
        failedCallback: error => {

        }
    });
}
export const getLclExportImportAFsPaginationFilter = (dispatch, admin, data,callback) => {
    data.opcode = "getLclExportImportAFsPaginationFilter"
    data.sessionId = admin.data.session
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
           // (callBack(response.data)); //
            dispatch(onParcelCountChange(response.data.count));
            //dispatch(setCrmListByFilter(response.data.list));
            callback(response.data.list)
        },
        failedCallback: error => {

        }
    });
}
export const getFclExportImportAFsPagination = (dispatch, admin, data,callback) => {
    data.opcode = "getFclExportImportAFsPaginationFilter"
    //data.opcode = "getFclExportImportAFsPagination"
    data.sessionId = admin.data.session
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
           // (callBack(response.data)); //
            dispatch(onParcelCountChange(response.data.count));
            //dispatch(setCrmListByFilter(response.data.list));
            callback(response.data.list)
        },
        failedCallback: error => {

        }
    });
}
export const deleteFclExportImportAF = (dispatch, admin, data,callback) => {
    data.opcode = "deleteFclExportImportAF"
    data.sessionId = admin.data.session
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
           
            callback()
        },
        failedCallback: error => {

        }
    });
}
export const getAirExportAFsPaginationFilter = (dispatch, admin, data,callback) => {
    data.opcode = "getAirExportAFsPaginationFilter"
    data.sessionId = admin.data.session
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
           // (callBack(response.data)); //
            dispatch(onParcelCountChange(response.data.count));
            //dispatch(setCrmListByFilter(response.data.list));
            if (response.data.list)
            response.data.list.sort(function (a, b) {
                return (Number(b.updateTime) - Number(a.updateTime));
            })
            callback(response.data.list)
        },
        failedCallback: error => {

        }
    });
}
export const updateLocalCharges = (dispatch, admin, data, callback) => {
    if (data.id) {
        data.opcode = "updateLocalCharges"
    } else {
        data.opcode = "addLocalCharges"
    }
    data.sessionId = admin.data.session
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
           // (callBack(response.data)); //
           // dispatch(onParcelCountChange(response.data.count));
            //dispatch(setCrmListByFilter(response.data.list));
            callback()
        },
        failedCallback: error => {

        }
    });
}
export const getLocalChargesByShipProd = (dispatch, admin, data,callback) => {
    data.opcode = "getLocalChargesByShipProd"
    data.sessionId = admin.data.session
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
           // (callBack(response.data)); //
           // dispatch(onParcelCountChange(response.data.count));
            //dispatch(setCrmListByFilter(response.data.list));
            //callback([])
            callback(response.data)
        },
        failedCallback: error => {

        }
    });
}
export const updateExchangeRate = (dispatch, snackbarToggle, value, admin) => {
    if (!value.id) {
        value.opcode = "";
    } else {
        value.incotermId = value.id;
        value.opcode = "updateExchangeRate"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'ExchangeRate added successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'ExchangeRate updated successfully' }));
            }
            (getExchangeRates(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const addCrm = (dispatch, snackbarToggle, value, admin, callback) => {
    if (!value.id) {
        value.opcode = "addCrm";
    } else {
        value.incotermId = value.id;
        value.opcode = "updateCRM"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            /*if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'Crm added successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'Crm updated successfully' }));
            }*/
            window.setTimeout(function () {
                getCRMList(dispatch, admin, callback(value.id));
                //window.location.reload(true);
            }, 500)
        },
        failedCallback: error => {

        }
    });
}
export const deleteCRM = (dispatch, snackbarToggle, value, admin,callback) => {
    value.opcode = "deleteCRM"
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'Crm deleted successfully' }));
            window.setTimeout(function () { getCRMList(dispatch, admin); }, 500)
            callback()
        },
        failedCallback: error => {

        }
    });
}
export const deleteQS = (dispatch, snackbarToggle, value, admin,callback) => {
    value.opcode = "deleteQS"
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'QS deleted successfully' }));
            //window.setTimeout(function () { getCRMList(dispatch, admin); }, 500)
            callback()
        },
        failedCallback: error => {

        }
    });
}
export const copyQs = (dispatch, snackbarToggle, value, admin,callback) => {
    value.opcode = "copyQs"
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'QS copy created successfully' }));
            //window.setTimeout(function () { getCRMList(dispatch, admin); }, 500)
            callback(response)
        },
        failedCallback: error => {

        }
    });
}
export const updateAccountsCRM = (dispatch, snackbarToggle, value, admin,callback) => {
    value.opcode = "updateAccountsCRM"
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            callback(response)
        },
        failedCallback: error => {

        }
    });
}
export const updateQuoteStatus = (dispatch, snackbarToggle, value, admin , callBack) => {
    value.opcode = "updateQuoteStatus";
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            callBack(dispatch, admin)
            if (response.misc.CODE == 1) {
                dispatch(snackbarToggle({ type: 'success', message: 'Status updated successfully' }));
            }
            
        },
        failedCallback: error => {

        }
    });
}
export const addIncoterm = (dispatch, snackbarToggle, value, admin) => {
    if (!value.id) {
        value.opcode = "addIncoterm";
    } else {
        value.incotermId = value.id;
        value.opcode = "updateIncoterm"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'Incoterm added successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'Incoterm updated successfully' }));
            }
            (getIncoterms(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const addUpdateProductCharges = (dispatch, snackbarToggle, value, admin) => {
    if (!value.id) {
        value.opcode = "addProductCharges";
    } else {
        value.opcode = "updateProductCharges"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'ProductCharges added successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'ProductCharges updated successfully' }));
            }
            (getProductCharges(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const addPort = (dispatch, snackbarToggle, value, admin) => {
    if (!value.id) {
        value.opcode = "addPort";
    } else {
        value.incotermId = value.id;
        value.opcode = "updatePort"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'Port added successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'Port updated successfully' }));
            }
            (getPorts(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const addAirPort = (dispatch, snackbarToggle, value, admin) => {
    if (!value.id) {
        value.opcode = "addAirPort";
    } else {
        value.incotermId = value.id;
        value.opcode = "updateAirPort"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'AirPort added successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'AirPort updated successfully' }));
            }
            (getAirPorts(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const addAirLine = (dispatch, snackbarToggle, value, admin) => {
    if (!value.id) {
        value.opcode = "addAirLine";
    } else {
        value.incotermId = value.id;
        value.opcode = "updateAirLine"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'AirLine added successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'AirLine updated successfully' }));
            }
            (getAirLines(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const addRejectReasions = (dispatch, snackbarToggle, value, admin) => {
    if (!value.id) {
        value.opcode = "addRejectReasions";
    } else {
        value.incotermId = value.id;
        value.opcode = "updateRejectReasions"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'RejectReasions added successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'RejectReasions updated successfully' }));
            }
            (getRejectReasions(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const addKgFactor = (dispatch, snackbarToggle, value, admin) => {
    if (!value.id) {
        value.opcode = "addKgFactor";
    } else {
        value.kgFactorId = value.id;
        value.opcode = "updateKgFactor"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'KgFactor added successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'KgFactor updated successfully' }));
            }
            (getKgFactors(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const addServiceType = (dispatch, snackbarToggle, value, admin) => {
    if (!value.id) {
        value.opcode = "addServiceType";
    } else {
        value.serviceTypeId = value.id;
        value.opcode = "updateServiceType"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'ServiceType added successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'ServiceType updated successfully' }));
            }
            (getServiceTypes(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const addFLCType20 = (dispatch, snackbarToggle, value, admin) => {
    if (!value.id) {
        value.opcode = "addFLCType20";
    } else {
        value.flcType20Id = value.id;
        value.opcode = "updateFLCType20"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'FLCType20 added successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'FLCType20 updated successfully' }));
            }
            (getFLCTypes20(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const addFLCType40 = (dispatch, snackbarToggle, value, admin) => {
    if (!value.id) {
        value.opcode = "addFLCType40";
    } else {
        value.flcType40Id = value.id;
        value.opcode = "updateFLCType40"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'FLCType40 added successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'FLCType40 updated successfully' }));
            }
            (getFLCTypes40(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const addCountryAndOrigin = (dispatch, snackbarToggle, value, admin) => {
    if (!value.id) {
        value.opcode = "addCountryAndOrigin";
    } else {
        value.opcode = "updateCountryAndOrigin"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: (response) => {
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'CountryAndOrigin added successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'CountryAndOrigin updated successfully' }));
            }
            (getCountryAndOrigins(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const deleteCountryAndOrigin = (dispatch, snackbarToggle,data, admin) => {
    let loginObj = { "sessionId": admin.data.session, id: data.id, opcode: "deleteCountryAndOrigin" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'CountryAndOrigin deleted successfully' }));
            (getCountryAndOrigins(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}

export const deleteRejectReasions = (dispatch, snackbarToggle, data, admin) => {
    let loginObj = { "sessionId": admin.data.session, id: data.id, opcode: "deleteRejectReasions" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'Reject Reasions deleted successfully' }));
            (getRejectReasions(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const addBannerEmail = (dispatch, snackbarToggle, data, admin,callback) => {
    data.sessionId = admin.data.session;
    if (!data.id) {
        data.opcode = "addBannerEmail";
    } else {
        data.opcode = "updateBannerEmail";
    }
    //let loginObj = { "sessionId": admin.data.session, base64: data.id, opcode: "addBannerEmail" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'Banner updated successfully' }));
            //(getBannerEmails(dispatch, data, callback));
            callback(response.data)
        },
        failedCallback: error => {

        }
    });
}

export const deleteAirLine = (dispatch, snackbarToggle,data, admin) => {
    let loginObj = { "sessionId": admin.data.session, id: data.id, opcode: "deleteAirLine" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'Air Line deleted successfully' }));
            (getAirLines(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const deleteProductCharges = (dispatch, snackbarToggle,data, admin) => {
    let loginObj = { "sessionId": admin.data.session, id: data.id, opcode: "deleteProductCharges" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'Product Charges deleted successfully' }));
            (getProductCharges(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const deleteAirPort = (dispatch, snackbarToggle,data, admin) => {
    let loginObj = { "sessionId": admin.data.session, id: data.id, opcode: "deleteAirPort" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'Air Port deleted successfully' }));
            (getAirPorts(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const deletePort = (dispatch, snackbarToggle,data, admin) => {
    let loginObj = { "sessionId": admin.data.session, id: data.id, opcode: "deletePort" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'Port deleted successfully' }));
            (getPorts(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const deleteFLCType20 = (dispatch, snackbarToggle,data, admin) => {
    let loginObj = { "sessionId": admin.data.session, flcType20Id: data.id, opcode: "deleteFLCType20" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'FLC deleted successfully' }));
            (getFLCTypes20(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const deleteFLCType40 = (dispatch, snackbarToggle,data, admin) => {
    let loginObj = { "sessionId": admin.data.session, flcType40Id: data.id, opcode: "deleteFLCType40" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'FLC deleted successfully' }));
            (getFLCTypes40(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const deleteServiceType = (dispatch, snackbarToggle,data, admin) => {
    let loginObj = { "sessionId": admin.data.session, serviceTypeId: data.id, opcode: "deleteServiceType" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'ServiceType deleted successfully' }));
            (getServiceTypes(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const deleteIncoterm = (dispatch, snackbarToggle,data, admin) => {
    let loginObj = { "sessionId": admin.data.session, incotermId:data.id, opcode: "deleteIncoterm" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'Incoterm deleted successfully' }));
            (getIncoterms(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const deleteKgFactor = (dispatch, snackbarToggle,data, admin) => {
    let loginObj = { "sessionId": admin.data.session, kgFactorId: data.id, opcode: "deleteKgFactor" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(snackbarToggle({ type: 'success', message: 'KgFactor deleted successfully' }));
            (getKgFactors(dispatch, admin));
        },
        failedCallback: error => {

        }
    });
}
export const getServiceTypes = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getServiceTypes" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setListOfServiceType(response.data));
        },
        failedCallback: error => {

        }
    });
}
export const getQuoatationType = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getQuoatationType" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setQsTypeList(response));
        },
        failedCallback: error => {

        }
    });
}
export const getQSById = (dispatch, admin,id,callback) => {
    let loginObj = { "sessionId": admin.data.session, id: id, opcode: "getQSById" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            callback(response)
        },
        failedCallback: error => {

        }
    });
}
export const getQsList = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getQSList" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            /*for (var i = 0; i < response.data.length; i++) {
                dispatch(onQsChange(response.data[i]));
            }*/
            //setIsLoaded(true); 
            dispatch(onEmptyQsChange(response.data));
        },
        failedCallback: error => {

        }
    });
}
export const getQSListBeforeDate = (dispatch, admin,date,callback) => {
    let loginObj = { "sessionId": admin.data.session,date:date, opcode: "getQSListBeforeDate" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            /*for (var i = 0; i < response.data.length; i++) {
                dispatch(onQsChange(response.data[i]));
            }*/
            //setIsLoaded(true); 
            (callback(response.data));
        },
        failedCallback: error => {

        }
    });
}
export const getAllBetweenDate = (dispatch, admin,start,end,callback) => {
    let loginObj = { "sessionId": admin.data.session, start: start, end: end, opcode: "getAllBetweenDate" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            /*for (var i = 0; i < response.data.length; i++) {
                dispatch(onQsChange(response.data[i]));
            }*/
            //setIsLoaded(true); 
            (callback(response.data));
        },
        failedCallback: error => {

        }
    });
}
export const getQsByQuoteTypeAndStatus = (dispatch, admin, quotes, statuses,callback) => {
    let loginObj = { "sessionId": admin.data.session, quotes: quotes, statuses: statuses, opcode: "getQsByQuoteTypeAndStatus" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            /*for (var i = 0; i < response.data.length; i++) {
                dispatch(onQsChange(response.data[i]));
            }*/
            //setIsLoaded(true); 
            (callback(response.data));
        },
        failedCallback: error => {

        }
    });
}
export const getQSPaginationFilter = (dispatch, admin, data, callBack) => {
    data.sessionId = admin.data.session;
    data.opcode = "getQSPaginationFilter";
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, data, {
        successCallback: (response) => {
            var tF = []
            callBack(tF)
            response.data.list.forEach(element => {
                var tObj = JSON.parse(JSON.stringify(element));
                tF.push(tObj);
            });
            callBack(tF)
            dispatch(onParcelCountChange(response.data.count));
            //dispatch(setCrmListByFilter(response.data.list));
           // callback(response.data.list)
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const quoatationReport = function (data, url) {
    var fileName = data.fileName
    fetch(url, {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
    })
        .then(response => response.blob())
        .then(response => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
        })
}
export const getCarrierPrefixs = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getCarrierPrefixs" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            for (var i = 0; i < response.data.length; i++) {
                dispatch(onCarrierPrefixChange(response.data[i]));
            }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getManifestList = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getManifestList" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            for (var i = 0; i < response.data.length; i++) {
                
                dispatch(onManifestChange(response.data[i]));
            }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getPortOfLodings = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getPortOfLoading" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            for (var i = 0; i < response.data.length; i++) {
                dispatch(onPortOfLodingChange(response.data[i]));
            }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getWarehouseList = (dispatch, admin) => {
    /*let loginObj = { "sessionId": admin.data.session, opcode: "listWarehouse" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            for (var i = 0; i < response.data.length; i++) {
                dispatch(onWarehouseChange(response.data[i]));
            }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });*/
}
export const getAirTerminalList = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getAirTerminal" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            for (var i = 0; i < response.data.length; i++) {
                dispatch(onAirTerminalChange(response.data[i]));
            }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getCountryOfImportList = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getCountryOfImport" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            for (var i = 0; i < response.data.length; i++) {
                dispatch(onCountryOfImportChange(response.data[i]));
            }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getDiscriptionsList = (dispatch, admin) => {
    /*let loginObj = { "sessionId": admin.data.session, opcode: "getDesc" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            for (var i = 0; i < response.data.length; i++) {
                dispatch(onDiscriptionsChange(response.data[i]));
            }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });*/
}
export const getSenderList = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getSenderList" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            dispatch(setSenderList(response.data));
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getAgentCodeList = (dispatch, admin) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getAgentCodeList" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            for (var i = 0; i < response.data.length; i++) {
                dispatch(onAgentCodeChange(response.data[i]));
            }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const getUserInfoByLockerId = (dispatch, lockerId, admin,callBack) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "getUserInfoByLockerId", lockerId: lockerId }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            callBack(response.data)
        },
        failedCallback: error => {

        }
    });
}
export const getParcelsList = (dispatch,data, admin) => {
    let loginObj = { "sessionId": admin.data.session, warehouseId: data.warehouseId, start: data.start, end: data.end, token: data.token, pageNo: data.pageNo, pageSize: data.pageSize, status: data.status, sortBy: data.sortBy, opcode: "getShippingRequestByDateStatusToken" }
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(emptyParcelList());
            dispatch(onParcelCountChange(response.data.count));
            for (var i = 0; i < response.data.list.length; i++) {
                //console.log(response.data.list[i].warehouseId);
                dispatch(onParcelListChange(response.data.list[i]));
            }
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
function send_data_get(url, fileName) {
    fetch(url)
        .then(response =>
            response.blob()
        )
        .then(response => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
        })
}
export const recieptWithBarCode = (dispatch, hawb, admin) => {
    var url = "/shipping/recieptWithBarCode/" + admin.data.session + "/" + hawb;
    send_data_get(url, "CWB-" + hawb);
    
}
export const scanImageForData = (dispatch, image, admin,callBack) => {
    let loginObj = { "sessionId": admin.data.session, opcode: "scanImageForData", mailroom_id: mailroom_id, call_type: "scan", image: image}
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {
            
            dispatch(setImageScanData(JSON.parse(response.data)));
            callBack();
            //setIsLoaded(true);
        },
        failedCallback: error => {

        }
    });
}
export const updateUser = (dispatch, snackbarToggle, value, admin) => {
    
    value.opcode = "updateUser"
    value.userId = value.id
    value.sessionId = admin.data.session;
    sendRequest(URL.USER_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            //dispatch(addUser(response));
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'User added successfully' }));
            } else {
                dispatch(deleteCountryOfImport(value));
                dispatch(snackbarToggle({ type: 'success', message: 'User updated successfully' }));
            }
            getUsers(dispatch, admin);
        },
        failedCallback: error => {

        },
    });

}
export const addQs = (dispatch, snackbarToggle, value, admin) => {
    
    value.opcode = "addQS"
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            try {
                //dispatch(addUser(response));
                if (response.misc.CODE ===1) {
                    dispatch(snackbarToggle({ type: 'success', message: 'Qs created successfully' }));
                } else {
                    dispatch(snackbarToggle({ type: 'error', message: 'Qs creation Failed.' }));
                }
               // bDownLoad = true;
                //downLoadId = response.data.id;
                getQsList(dispatch, admin);
                //window.setTimeout(function () { window.location.reload(true); },10)
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });

}
export const updateQS = (dispatch, snackbarToggle, value, admin) => {
    //console.log("Request data:");
    //console.log(value);
    value.opcode = "updateQS"
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {

            try {
                //dispatch(addUser(response));
                if (response.misc.CODE === 1) {
                    dispatch(snackbarToggle({ type: 'success', message: 'Qs created successfully' }));
                } else {
                    dispatch(snackbarToggle({ type: 'error', message: 'Qs creation Failed.' }));
                }
                // bDownLoad = true;
                //downLoadId = response.data.id;
                getQsList(dispatch, admin);
                //window.setTimeout(function () { window.location.reload(true); },10)
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });
}
export const updateQS1 = (dispatch, snackbarToggle, value, admin, callback) => {
    //console.log("Request data:");
    //console.log(value);
    value.opcode = "updateQS"
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {

            try {
                callback();
                //window.setTimeout(function () { window.location.reload(true); },10)
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });
}
export const addFclExportImportAF = (dispatch, snackbarToggle, value, admin ,callback) => {
    if (!value.id) {
        value.opcode = "addFclExportImportAF"
    } else {
        value.opcode = "updateFclExportImportAF"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            try {
                //dispatch(addUser(response));
                if (response.misc.CODE === 1) {
                    if (!value.id)
                        dispatch(snackbarToggle({ type: 'success', message: 'Cost created successfully' }));
                    else
                        dispatch(snackbarToggle({ type: 'success', message: 'Cost updated successfully' }));
                } else {
                    dispatch(snackbarToggle({ type: 'error', message: 'cost creation Failed.' }));
                }
                callback()
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });

}
export const addLclExportImportAF = (dispatch, snackbarToggle, value, admin,callBack) => {
    if (!value.id) {
        value.opcode = "addLclExportImportAF"
    } else {
        value.opcode = "updateLclExportImportAF"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            try {
                //dispatch(addUser(response));
                if (response.misc.CODE === 1) {
                    if (!value.id)
                        dispatch(snackbarToggle({ type: 'success', message: 'Cost created successfully' }));
                    else
                        dispatch(snackbarToggle({ type: 'success', message: 'Cost updated successfully' }));
                } else {
                    dispatch(snackbarToggle({ type: 'error', message: 'cost creation Failed.' }));
                }
               callBack()
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });

}
export const addAirExportAF = (dispatch, snackbarToggle, value, admin,callBack) => {
    if (!value.id) {
        value.opcode = "addAirExportAF"
    } else {
        value.opcode = "updateAirExportAF"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            if (callBack) {
                callBack()
            }
            
            try {
                //dispatch(addUser(response));
                if (response.misc.CODE === 1) {
                    if (!value.id)
                        dispatch(snackbarToggle({ type: 'success', message: 'Cost created successfully' }));
                    else
                        dispatch(snackbarToggle({ type: 'success', message: 'Cost updated successfully' }));
                } else {
                    dispatch(snackbarToggle({ type: 'error', message: 'cost creation Failed.' }));
                }
               
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });

}
export const getAirExportAf = ( value, admin,callBack) => {
    if (!value.id) {
        value.opcode = "getAirExportAf"
    } else {
        value.opcode = "getAirExportAf"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
           
            try {
                //dispatch(addUser(response));
                if (response.misc.CODE === 1) {
                    callBack(response);
                } else {
                   // dispatch(snackbarToggle({ type: 'error', message: 'cost creation Failed.' }));
                }
               
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });

}
export const getLclExportImportAF = ( value, admin,callBack) => {
    if (!value.id) {
        value.opcode = "getLclExportImportAF"
    } else {
        value.opcode = "getLclExportImportAF"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
           
            try {
                //dispatch(addUser(response));
                if (response.misc.CODE === 1) {
                    callBack(response);
                } else {
                   // dispatch(snackbarToggle({ type: 'error', message: 'cost creation Failed.' }));
                }
               
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });

}
export const getFclExportImportAF = ( value, admin,callBack) => {
    if (!value.id) {
        value.opcode = "getFclExportImportAF"
    } else {
        value.opcode = "getFclExportImportAF"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
           
            try {
                //dispatch(addUser(response));
                if (response.misc.CODE === 1) {
                    callBack(response);
                } else {
                   // dispatch(snackbarToggle({ type: 'error', message: 'cost creation Failed.' }));
                }
               
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });

}
export const deleteAirExportAF = (dispatch, snackbarToggle, value, admin,callBack) => {
    if (!value.id) {
        value.opcode = "deleteAirExportAF"
    } else {
        value.opcode = "deleteAirExportAF"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            callBack();
            try {
                //dispatch(addUser(response));
                if (response.misc.CODE === 1) {
                    if (!value.id)
                        dispatch(snackbarToggle({ type: 'success', message: 'Cost deleted successfully' }));
                    else
                        dispatch(snackbarToggle({ type: 'success', message: 'Cost deleted successfully' }));
                } else {
                    dispatch(snackbarToggle({ type: 'error', message: 'Cost Deletion Failed.' }));
                }
               
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });

}
export const deleteLclExportImportAF = (dispatch, snackbarToggle, value, admin,callBack) => {
    if (!value.id) {
        value.opcode = "deleteLclExportImportAF"
    } else {
        value.opcode = "deleteLclExportImportAF"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            callBack();
            try {
                //dispatch(addUser(response));
                if (response.misc.CODE === 1) {
                    if (!value.id)
                        dispatch(snackbarToggle({ type: 'success', message: 'Cost deleted successfully' }));
                    else
                        dispatch(snackbarToggle({ type: 'success', message: 'Cost deleted successfully' }));
                } else {
                    dispatch(snackbarToggle({ type: 'error', message: 'Cost Deletion Failed.' }));
                }
               
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });

}
export const updateAirExportAFRate = (dispatch, snackbarToggle, value, admin,callBack) => {
    if (!value.id) {
        value.opcode = "addAirExportAF"
    } else {
        value.opcode = "updateAirExportAFRate"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            try {
                //dispatch(addUser(response));
                if (response.misc.CODE === 1) {
                    if (!value.id) {
                        //dispatch(snackbarToggle({ type: 'success', message: 'Cost created successfully' }));
                    } else {
                        
                        dispatch(snackbarToggle({ type: 'success', message: 'Rate updated successfully' }));
                    }
                } else {
                   // dispatch(snackbarToggle({ type: 'error', message: 'cost creation Failed.' }));
                }
               callBack();
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });

}
export const updateLCLExportAFRate = (dispatch, snackbarToggle, value, admin,callBack) => {
    if (!value.id) {
        value.opcode = "updateLCLExportAFRate"
    } else {
        value.opcode = "updateLCLExportAFRate"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            try {
                //dispatch(addUser(response));
                if (response.misc.CODE === 1) {
                    if (!value.id) {
                        //dispatch(snackbarToggle({ type: 'success', message: 'Cost created successfully' }));
                    } else {
                        
                        dispatch(snackbarToggle({ type: 'success', message: 'Rate updated successfully' }));
                    }
                } else {
                   // dispatch(snackbarToggle({ type: 'error', message: 'cost creation Failed.' }));
                }
               callBack();
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });

}
export const addManifest = (dispatch, snackbarToggle, value, admin) => {
    
    value.opcode = "addManifest"
    value.sessionId = admin.data.session;
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            try {
                //dispatch(addUser(response));
                if (response.misc.CODE ===1) {
                    dispatch(snackbarToggle({ type: 'success', message: 'Manifest created successfully' }));
                } else {
                    dispatch(snackbarToggle({ type: 'error', message: 'Manifest Failed.' }));
                }
               // bDownLoad = true;
                //downLoadId = response.data.id;
                getManifestList(dispatch, admin);
                var name = "Manifest.csv"
                exportToCsv(response.data, name);
            } catch (err) {
                //console.log(err)
            }
        },
        failedCallback: error => {

        },
    });

}

function convertToCSV1(items, header1) {
    const replacer = (key, value) => value ===null ? '' : value // specify how you want to handle null values here
    const header = header1
    const csv = [
        header.join(','), // header row first
        ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n')
    return csv;
}

function downLoadPdf(data, fileName) {
    //var blob = new Blob([data], { type: 'text/pdf;charset=utf-8;' });
    var blob = new Blob([data]);
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(data, fileName);
    } else {
        var link = document.createElement("a");
        if (link.download !==undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = window.URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
export const exportToCsv = function (data, name) {
    var manifestItems = data.manifestItems;
    var recieptList = data.params.recieptList;
    for (var i = 0; i < recieptList.length; i++) {
        downLoadPdf(recieptList[i].reciept, recieptList[i].name)
    }
    //var headers = Object.keys(manifestItems[0]) 
    var headers = ["Sender",
        "SenderAddress",
        "SenderCountry",
        "ReceiverName",
        "ReceiverStreetAddress",
        "ReceiverZipcode",
        "ReceiverCity",
        "ReceiverCountry",
        "ReceiverPhone",
        "ReceiverEmail",
        "ReceiverReceiverId",
        "ConsignmentNumber",
        "Reference1",
        "Reference2",
        "GoodsDesc",
        "Cubic",
        "Weight ",
        "ChargeWeight",
        "Quantity",
        "DeliveryInstructions",
        "InvoiceValue",
        "InvoiceCurrency",
        "InvoiceLineQty",
        "InvoiceLineAmount",
        "OriginPort",
        "GatewayPort",
        "AgentCode",
        "CarrierPrefix",
        "MAWB",
        "HAWB",
        "FlightDate",
        "FlightTime",
        "FlightNumber",
        "ETADate",
        "SendeETATimer",
        "TotalMasterQuantity",
        "TotalMasterWeightKgm",
        "InvoiceLineOriginCountry",
        "IsAutonomy",
        "CourierAWBDate",
        "ReceiverPassportId",
        "ReceiverPassportCountry",
        "FreightTotalAmount",
        "FreightCurrency",
        "DeliveryMethod",
        "TotalPackageInMawb",
        "TotalShipment",
        "CrateNumber",
        "StorageSiteCode"]
    exportCSVFile(headers, manifestItems, name);
}
export function exportCSVFile(headers, items, fileTitle) {

    var csv = convertToCSV1(items, headers);

    var exportedFilenmae = fileTitle;

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !==undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            try {
            var url = window.URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
                document.body.removeChild(link);
            } catch (err) {
                if (err.message) {
                    //console.log(err.message)
                }
                //console.log(err)
            }

        }
    }
}
export const addCountryOfImport = (dispatch, snackbarToggle, value, admin) => {
    value.like = value.description;
    if (!value.id) {
        value.opcode = "addCountryOfImport";
    } else {
        value.opcode = "deleteCountryOfImport"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            //dispatch(addUser(response));
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'CountryOfImport Created successfully' }));
            } else {
                dispatch(deleteCountryOfImport(value));
                dispatch(snackbarToggle({ type: 'success', message: 'CountryOfImport deleted successfully' }));
            }
            getCountryOfImportList(dispatch, admin);
        },
        failedCallback: error => {

        },
    });

}
export const ftpExport = (dispatch, snackbarToggle, value, admin) => {

    value.opcode = "ftpExport";
    value.sessionId = admin.data.session;
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            if (response.misc.CODE ===1) {
                dispatch(snackbarToggle({ type: 'success', message: 'Exported succesfully.' }));
            }
        },
        failedCallback: error => {

        },
    });

}
export const deleteManifestItem = (dispatch, snackbarToggle, value, admin) => {

    value.opcode = "deleteManifest";
    value.sessionId = admin.data.session;
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            dispatch(deleteManifest(value));
            dispatch(snackbarToggle({ type: 'success', message: 'Manifest deleted successfully' }));
            getManifestList(dispatch, admin);
        },
        failedCallback: error => {

        },
    });

}
export const addCarrierPrefix = (dispatch, snackbarToggle, value, admin) => {
    value.like = value.description;
    if (!value.id) {
        value.opcode = "addCarrierPrefix";
    } else {
        value.opcode = "deleteCarrierPrefix"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            //dispatch(addUser(response));
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'CarrierPrefix Created successfully' }));
            } else {
                dispatch(deleteCarrierPrefix(value));
                dispatch(snackbarToggle({ type: 'success', message: 'CarrierPrefix deleted successfully' }));
            }
            getCarrierPrefixs(dispatch, admin);
        },
        failedCallback: error => {

        },
    });

}
export const addPortOfLoding = (dispatch, snackbarToggle, value, admin) => {
    value.like = value.description;
    if (!value.id) {
        value.opcode = "addPortOfLoading";
        value.id = 0;
    } else {
        value.opcode = "deletePortOfLoading"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            //dispatch(addUser(response));
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'PortOfLoding added successfully' }));
            } else {
                dispatch(deletePortOfLoding(value));
                dispatch(snackbarToggle({ type: 'success', message: 'PortOfLoding deleted successfully' }));
            }
            getPortOfLodings(dispatch, admin);
        },
        failedCallback: error => {

        },
    });

}
export const addAirTerminal = (dispatch, snackbarToggle, value, admin) => {
    value.like = value.description;
    if (!value.id) {
        value.opcode = "addAirTerminal";
    } else {
        value.opcode = "deleteAirTerminal"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            //dispatch(addUser(response));
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'AirTerminal Created successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'AirTerminal deleted successfully' }));
            }
            getAirTerminalList(dispatch, admin);
        },
        failedCallback: error => {

        },
    });

}
export const createShippingRequest = (dispatch, snackbarToggle, value, admin,callBack) => {
    if (!value.id) {
        value.opcode = "createShippingRequest";
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            //dispatch(addUser(response));
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'Parcel added successfully' }));
            } 
            callBack();
        },
        failedCallback: error => {

        },
    });

}
export const addAgentCode = (dispatch, snackbarToggle, value, admin) => {
    value.like = value.description;
    if (!value.id) {
        value.opcode = "addAgentCode";
    } else {
        value.opcode = "updateAgentCode"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            //dispatch(addUser(response));
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'AgentCode Created successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'AgentCode Updated successfully' }));
            }
            getAgentCodeList(dispatch, admin);
        },
        failedCallback: error => {

        },
    });

}
export const addDesc = (dispatch, snackbarToggle, value, admin) => {
    
    if (!value.id) {
        value.like = value.description;
        value.opcode = "addDesc";
    } else {
        value.descId = value.id;
        value.opcode = "deleteDesc"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            //dispatch(addUser(response));
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'Discription Created successfully' }));
            } else {
                dispatch(deleteDiscription(value));
                dispatch(snackbarToggle({ type: 'success', message: 'Discription Deleted successfully' }));
            }
            getDiscriptionsList(dispatch, admin);
        },
        failedCallback: error => {

        },
    });

}
export const addSender = (dispatch, snackbarToggle, value, admin) => {
    
    if (!value.id) {
        value.opcode = "addSender";
        value.wareHouseId = value.warehouseId;
    } else {
        value.descId = value.id;
        value.opcode = "deleteSender"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            //dispatch(addUser(response));
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'Sender Created successfully' }));
            } else {
                dispatch(deleteSender(value));
                dispatch(snackbarToggle({ type: 'success', message: 'Sender Deleted successfully' }));
            }
            getSenderList(dispatch, admin);
        },
        failedCallback: error => {

        },
    });

}
export const createWarehouse = (dispatch, snackbarToggle, value, admin) => {
    if (!value.id) {
        value.opcode = "createWarehouse";
    } else {
        value.adminId = value.id;
        value.opcode = "updateWarehouse"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.SHIPPING_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            //dispatch(addUser(response));
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'Warehouse Created successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'Warehouse Updated successfully' }));
            }
            getWarehouseList(dispatch, admin);
        },
        failedCallback: error => {

        },
    });

}
export const addAdmin = (dispatch, snackbarToggle, value, admin) => {
    if (value.type ===2) {
        if (value.permissions.warehouseId) {

        } else {
            dispatch(snackbarToggle({ type: 'error', message: 'Please Select Warehouse.' }));
            return;
        }
    }
    if (!value.id) {
        value.opcode = "addAdmin";
    } else {
        value.adminId = value.id;
        value.opcode = "updateAdmin"
    }
    value.sessionId = admin.data.session;
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, value, {
        successCallback: response => {
            
            //dispatch(addUser(response));
            if (!value.id) {
                dispatch(snackbarToggle({ type: 'success', message: 'User Created successfully' }));
            } else {
                dispatch(snackbarToggle({ type: 'success', message: 'User Updated successfully' }));
            }
            getAdminList(dispatch, admin);
        },
        failedCallback: error => {

        },
    });

}
export const login = (dispatch, userName, password, setHasError) => {
    setHasError(false);
    let loginObj = { "adminName": userName, "password": password, opcode: "login" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {

            if (response.misc.CODE ===1) {
                setCookie("NBO_SESSION_ID", response.session, 30);
                dispatch(setAdmin(response))
                pingCall(dispatch, response.session, setHasError);
                //  ping();
            } else {
                setHasError(true);
            }
        },
        failedCallback: error => {
            setHasError(true);
        }
    });
}
export const logOutSystem = (dispatch, session, setHasError) => {
    setHasError(false);
    let loginObj = { "sessionId": session, opcode: "logout" }
    sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
        successCallback: (response) => {

            if (response.misc.CODE ===1) {
                setCookie("NBO_SESSION_ID", "", 30);
                setHasError(false);
            } else {
                setHasError(true);
            }
        },
        failedCallback: error => {
            setHasError(true);
        }
    });
}
export const pingCall = (dispatch, session, setHasError) => {
    setHasError(false);
    let loginObj = { "sessionId": session, opcode: "ping" }
    if (session) {
        sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
            successCallback: (response) => {                
                dispatch(setAdminUser(response))
            },
            failedCallback: error => {
                setHasError(true);
            }
        });
    }
}

export const validateEmail = function (m) {
    var mReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (mReg.test(m)) {
        return (true)
    }
    return (false)
}

export function downloadBase64File(base64Data, fileName) {
    const linkSource = base64Data;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}