import { useState, useEffect, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDraggable } from "react-use-draggable-scroll";
import { Typography, TableHead, TableRow, TableCell, Button, Table, Grid, TableBody, TableContainer, Paper, TablePagination } from '@mui/material';
import { addBannerEmail,getBannerEmails } from '../../reducers/requestHandler';
import { getAdminList, getWarehouseList } from '../Admins/AdminHandler'
import { REQUEST_ACTIONS, sendRequest } from '../../utils/Communicator';
import { useSelector, useDispatch } from 'react-redux';
import { onAdminChange, onWarehouseChange } from '../../reducers/admin-reducers/AdminSlicer';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { Dialog, Snackbar } from '../../style-guide';
import { setHeaderAirImport, setFooterAirImport, setFooterAirExport, setHeaderAirExport, setHeaderLclImport, setFooterLclImport, setFooterLclExport, setHeaderLclExport, setFooterFclExport, setFooterFclImport, setHeaderFclImport, setHeaderFclExport } from '../../reducers/parameter/parameterSlice';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { useDropzone } from 'react-dropzone'
import { FaAngleDown, FaWindowClose, FaCloudUploadAlt } from "react-icons/fa";
import { BannerRow } from './BannerRow'
export const Banners = () => {
    const ref = useRef();
    const { events } = useDraggable(ref);
    const dispatch = useDispatch();
    const { adminList, warehouseList, discriptionsList } = useSelector(state => state.admin);
    const { admin } = useSelector(state => state.session);
    const { listOfProductCharges, headerAirImport, footerAirImport, headerAirExport, footerAirExport, headerLclExport, headerLclImport, footerLclImport, footerLclExport, headerFclExport, headerFclImport, footerFclImport, footerFclExport } = useSelector(state => state.parameter);
    const { snackbar } = useSelector(state => state.snackbar);
    const [isLoaded, setIsLoaded] = useState(false);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => {
        setOpenOrderModal(true);
        getAdminList(dispatch, admin);
        getWarehouseList(dispatch, admin);
    };
    const onHandleCancelModal = () => setOpenOrderModal(false);

    // pagination
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const onPageHandleChange = (_, newPage) => {
        setPage(newPage);
    };
    const onRowsPerPageHandleChange = event => {
        setSize(+event.target.value);
        setPage(0);
    };

    const [isUp, setIsUp] = useState(false);
    const onDropHeaderAirImport = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                //setHeader(reader.result)
                //setIsUp(true)
                addBannerEmail(dispatch, snackbarToggle, { ...headerAirImport, importExportType: 2, shipmentType: 1, type: 0, base64: e.target.result }, admin, function (data) {
                    //dispatch(setHeaderAirImport(data?.data[0]));
                    getHeaderBannerAirImport()
                })
            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])
    const { getRootProps: getRootHeaderAirImport, getInputProps: getInputHeaderAirImport } = useDropzone({
        accept: 'image/png',
        onDrop: onDropHeaderAirImport,
    });

    const onDropFooterAirImport = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                //setFooter(reader.result)
                //setIsUpFooter(true)
                addBannerEmail(dispatch, snackbarToggle, { ...footerAirImport, importExportType: 2, shipmentType: 1, type: 1, base64: e.target.result }, admin, function (data) {
                    //dispatch(setFooterAirImport(data?.data[0]));
                    getFooterBannerAirImport()
                })
            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])


    const { getRootProps: getRootFooterAirImport, getInputProps: getInputFooterAirImport } = useDropzone({
        accept: 'image/png',
        onDrop: onDropFooterAirImport,
    });

    const onDropHeaderAirExport = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                //setHeader(reader.result)
                //setIsUp(true)
                addBannerEmail(dispatch, snackbarToggle, { ...headerAirExport, importExportType: 1, shipmentType: 1, type: 0, base64: e.target.result }, admin, function (data) {
                    //dispatch(setHeaderAirExport(data?.data[0]));
                    getHeaderBannerAirExport()
                })
            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])
    const { getRootProps: getRootHeaderAirExport, getInputProps: getInputHeaderAirExport } = useDropzone({
        accept: 'image/png',
        onDrop: onDropHeaderAirExport,
    });

    const onDropFooterAirExport = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                //setFooter(reader.result)
                //setIsUpFooter(true)
                addBannerEmail(dispatch, snackbarToggle, { ...footerAirExport, importExportType: 1, shipmentType: 1, type: 1, base64: e.target.result }, admin, function (data) {
                    //dispatch(setFooterAirExport(data?.data[0]));
                    getBannerEmails(dispatch, { importExportType: 1, shipmentType: 1, type: 1, sessionId: admin.data.session }
                        , function (data) {
                            console.log(data.data[0])
                            if (data?.data?.length) {

                                dispatch(setFooterAirExport(data?.data[0]));
                            }
                        })
                })
            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])


    const { getRootProps: getRootFooterAirExport, getInputProps: getInputFooterAirExport } = useDropzone({
        accept: 'image/png',
        onDrop: onDropFooterAirExport,
    });



    const onDropHeaderLclImport = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                //setHeader(reader.result)
                //setIsUp(true)
                addBannerEmail(dispatch, snackbarToggle, { ...headerLclImport, importExportType: 2, shipmentType: 2, type: 0, base64: e.target.result }, admin, function (data) {
                    //dispatch(setHeaderLclImport(data?.data[0]));
                    getHeaderBannerLclImport()
                })
            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])
    const { getRootProps: getRootHeaderLclImport, getInputProps: getInputHeaderLclImport } = useDropzone({
        accept: 'image/png',
        onDrop: onDropHeaderLclImport,
    });

    const onDropFooterLclImport = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                //setFooter(reader.result)
                //setIsUpFooter(true)
                addBannerEmail(dispatch, snackbarToggle, { ...footerLclImport, importExportType: 2, shipmentType: 2, type: 1, base64: e.target.result }, admin, function (data) {
                    //dispatch(setFooterLclImport(data?.data[0]));
                    getBannerEmails(dispatch, { importExportType: 2, shipmentType: 2, type: 1, sessionId: admin.data.session }
                        , function (data) {
                            console.log(data.data[0])
                            if (data?.data?.length) {

                                dispatch(setFooterLclImport(data?.data[0]));
                            }
                        })
                })
            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])


    const { getRootProps: getRootFooterLclImport, getInputProps: getInputFooterLclImport } = useDropzone({
        accept: 'image/png',
        onDrop: onDropFooterLclImport,
    });

    const onDropHeaderLclExport = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                //setHeader(reader.result)
                //setIsUp(true)
                addBannerEmail(dispatch, snackbarToggle, { ...headerLclExport, importExportType: 1, shipmentType: 2, type: 0, base64: e.target.result }, admin, function (data) {
                    //dispatch(setHeaderLclExport(data?.data[0]));
                    getHeaderBannerLclExport()
                })
            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])
    const { getRootProps: getRootHeaderLclExport, getInputProps: getInputHeaderLclExport } = useDropzone({
        accept: 'image/png',
        onDrop: onDropHeaderLclExport,
    });

    const onDropFooterLclExport = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                //setFooter(reader.result)
                //setIsUpFooter(true)
                addBannerEmail(dispatch, snackbarToggle, { ...footerLclExport, importExportType: 1, shipmentType: 2, type: 1, base64: e.target.result }, admin, function (data) {
                    //dispatch(setFooterLclExport(data?.data[0]));
                    getBannerEmails(dispatch, { importExportType: 1, shipmentType:2, type: 1, sessionId: admin.data.session }
                        , function (data) {
                            console.log(data.data[0])
                            if (data?.data?.length) {

                                dispatch(setFooterLclExport(data?.data[0]));
                            }
                        })
                })
            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])


    const { getRootProps: getRootFooterLclExport, getInputProps: getInputFooterLclExport } = useDropzone({
        accept: 'image/png',
        onDrop: onDropFooterLclExport,
    });




    const onDropHeaderFclImport = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                //setHeader(reader.result)
                //setIsUp(true)
                addBannerEmail(dispatch, snackbarToggle, { ...headerFclImport, importExportType: 2, shipmentType: 3, type: 0, base64: e.target.result }, admin, function (data) {
                    //dispatch(setHeaderFclImport(data?.data[0]));
                    getHeaderBannerFclImport()
                })
            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])
    const { getRootProps: getRootHeaderFclImport, getInputProps: getInputHeaderFclImport } = useDropzone({
        accept: 'image/png',
        onDrop: onDropHeaderFclImport,
    });

    const onDropFooterFclImport = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                //setFooter(reader.result)
                //setIsUpFooter(true)
                addBannerEmail(dispatch, snackbarToggle, { ...footerFclImport, importExportType: 2, shipmentType: 3, type: 1, base64: e.target.result }, admin, function (data) {
                    //dispatch(setFooterFclImport(data?.data[0]));
                    getBannerEmails(dispatch, { importExportType: 2, shipmentType: 3, type: 1, sessionId: admin.data.session }
                        , function (data) {
                            console.log(data.data[0])
                            if (data?.data?.length) {

                                dispatch(setFooterFclImport(data?.data[0]));
                            }
                        })
                })
            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])


    const { getRootProps: getRootFooterFclImport, getInputProps: getInputFooterFclImport } = useDropzone({
        accept: 'image/png',
        onDrop: onDropFooterFclImport,
    });

    const onDropHeaderFclExport = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                //setHeader(reader.result)
                //setIsUp(true)
                addBannerEmail(dispatch, snackbarToggle, { ...headerFclExport, importExportType: 1, shipmentType: 3, type: 0, base64: e.target.result }, admin, function (data) {
                    //dispatch(setHeaderFclExport(data?.data[0]));
                    getHeaderBannerFclExport()
                })
            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])
    const { getRootProps: getRootHeaderFclExport, getInputProps: getInputHeaderFclExport } = useDropzone({
        accept: 'image/png',
        onDrop: onDropHeaderFclExport,
    });

    const onDropFooterFclExport = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                //setFooter(reader.result)
                //setIsUpFooter(true)
                addBannerEmail(dispatch, snackbarToggle, { ...footerFclExport, importExportType: 1, shipmentType: 3, type: 1, base64: e.target.result }, admin, function (data) {
                    //dispatch(setFooterFclExport(data?.data[0]));
                    getBannerEmails(dispatch, { importExportType: 1, shipmentType: 3, type: 1, sessionId: admin.data.session }
                        , function (data) {
                            console.log(data.data[0])
                            if (data?.data?.length) {

                                dispatch(setFooterFclExport(data?.data[0]));
                            }
                        })
                })
            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])


    const { getRootProps: getRootFooterFclExport, getInputProps: getInputFooterFclExport } = useDropzone({
        accept: 'image/png',
        onDrop: onDropFooterFclExport,
    });

    const getHeaderBannerAirImport = function () {
        getBannerEmails(dispatch, { importExportType: 2, shipmentType: 1, type: 0, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setHeaderAirImport(data?.data[0]));
                }
            })

    }
    const getFooterBannerAirImport = function () {
        getBannerEmails(dispatch, { importExportType: 2, shipmentType: 1, type: 1, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setFooterAirImport(data?.data[0]));
                }
            })

    }
    const getHeaderBannerAirExport = function () {
        getBannerEmails(dispatch, { importExportType: 1, shipmentType: 1, type: 0, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setHeaderAirExport(data?.data[0]));
                }
            })

    }
    const getFooterBannerAirExport = function () {
        getBannerEmails(dispatch, { importExportType: 1, shipmentType: 1, type: 1, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setFooterAirExport(data?.data[0]));
                }
            })

    }

    const getHeaderBannerLclImport = function () {
        getBannerEmails(dispatch, { importExportType: 2, shipmentType: 2, type: 0, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setHeaderLclImport(data?.data[0]));
                }
            })

    }
    const getFooterBannerLclImport = function () {
        getBannerEmails(dispatch, { importExportType: 2, shipmentType: 2, type: 1, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setFooterLclImport(data?.data[0]));
                }
            })

    }
    const getHeaderBannerLclExport = function () {
        getBannerEmails(dispatch, { importExportType: 1, shipmentType: 2, type: 0, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setHeaderLclExport(data?.data[0]));
                }
            })

    }
    const getFooterBannerLclExport = function () {
        getBannerEmails(dispatch, { importExportType: 1, shipmentType: 2, type: 1, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setFooterLclExport(data?.data[0]));
                }
            })

    }


    const getHeaderBannerFclImport = function () {
        getBannerEmails(dispatch, { importExportType: 2, shipmentType: 3, type: 0, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setHeaderFclImport(data?.data[0]));
                }
            })

    }
    const getFooterBannerFclImport = function () {
        getBannerEmails(dispatch, { importExportType: 2, shipmentType: 3, type: 1, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setFooterFclImport(data?.data[0]));
                }
            })

    }
    const getHeaderBannerFclExport = function () {
        getBannerEmails(dispatch, { importExportType: 1, shipmentType: 3, type: 0, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setHeaderFclExport(data?.data[0]));
                }
            })

    }
    const getFooterBannerFclExport = function () {
        getBannerEmails(dispatch, { importExportType: 1, shipmentType: 3, type: 1, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setFooterFclExport(data?.data[0]));
                }
            })

    }

    useEffect(() => {
    }, [dispatch, headerAirExport, headerAirImport, footerAirImport, footerAirExport, headerLclExport, headerLclImport, footerLclImport, footerLclExport, headerFclExport, headerFclImport, footerFclImport, footerFclExport]);
    useEffect(() => {
        getHeaderBannerAirImport()        
        getFooterBannerAirImport();
        getHeaderBannerAirExport()
        getFooterBannerAirExport()

        getHeaderBannerLclImport()
        getFooterBannerLclImport();
        getHeaderBannerLclExport()
        getFooterBannerLclExport()

        getHeaderBannerFclImport()
        getFooterBannerFclImport();
        getHeaderBannerFclExport()
        getFooterBannerFclExport()


    }, [dispatch]);
    return (

        <Paper sx={{ width: '100%' }} >
            <Paper sx={{ width: '100%', overflow: 'auto', height: 'calc(100vh - 180px)' }}  {...events} ref={ref}>
                <Grid item container style={{ justifyContent: "center", margin: "25px", fontSize: "16px", fontWeight: "bold" }}>Please upload PNG files only and with width less than 600px.</Grid>
                <BannerRow afterHeaderUpload={getHeaderBannerAirImport} afterFooterUpload={getFooterBannerAirImport} headerHeading={'Header'} footerHeading={'Footer'} heading={'Air Import'} getInputFooter={getInputFooterAirImport} getRootFooter={getRootFooterAirImport} getRootHeader={getRootHeaderAirImport} rowDataHeader={headerAirImport} isUp={headerAirImport?.params?.base64} rowDataFooter={footerAirImport} isUpFooter={footerAirImport?.params?.base64} getInputHeader={getInputHeaderAirImport} />
                <BannerRow afterHeaderUpload={getHeaderBannerAirExport} afterFooterUpload={getFooterBannerAirExport} headerHeading={'Header'} footerHeading={'Footer'} heading={'Air Export'} getInputFooter={getInputFooterAirExport} getRootFooter={getRootFooterAirExport} getRootHeader={getRootHeaderAirExport} rowDataHeader={headerAirExport} isUp={headerAirExport?.params?.base64} rowDataFooter={footerAirExport} isUpFooter={footerAirExport?.params?.base64} getInputHeader={getInputHeaderAirExport} />

                <BannerRow afterHeaderUpload={getHeaderBannerLclImport} afterFooterUpload={getFooterBannerLclImport} headerHeading={'Header'} footerHeading={'Footer'} heading={'Lcl Import'} getInputFooter={getInputFooterLclImport} getRootFooter={getRootFooterLclImport} getRootHeader={getRootHeaderLclImport} rowDataHeader={headerLclImport} isUp={headerLclImport?.params?.base64} rowDataFooter={footerLclImport} isUpFooter={footerLclImport?.params?.base64} getInputHeader={getInputHeaderLclImport} />
                <BannerRow afterHeaderUpload={getHeaderBannerLclExport} afterFooterUpload={getFooterBannerLclExport} headerHeading={'Header'} footerHeading={'Footer'} heading={'Lcl Export'} getInputFooter={getInputFooterLclExport} getRootFooter={getRootFooterLclExport} getRootHeader={getRootHeaderLclExport} rowDataHeader={headerLclExport} isUp={headerLclExport?.params?.base64} rowDataFooter={footerLclExport} isUpFooter={footerLclExport?.params?.base64} getInputHeader={getInputHeaderLclExport} />


                <BannerRow afterHeaderUpload={getHeaderBannerFclImport} afterFooterUpload={getFooterBannerFclImport} headerHeading={'Header'} footerHeading={'Footer'} heading={'Fcl Import'} getInputFooter={getInputFooterFclImport} getRootFooter={getRootFooterFclImport} getRootHeader={getRootHeaderFclImport} rowDataHeader={headerFclImport} isUp={headerFclImport?.params?.base64} rowDataFooter={footerFclImport} isUpFooter={footerFclImport?.params?.base64} getInputHeader={getInputHeaderFclImport} />
                <BannerRow afterHeaderUpload={getHeaderBannerFclExport} afterFooterUpload={getFooterBannerFclExport} headerHeading={'Header'} footerHeading={'Footer'} heading={'Fcl Export'} getInputFooter={getInputFooterFclExport} getRootFooter={getRootFooterFclExport} getRootHeader={getRootHeaderFclExport} rowDataHeader={headerFclExport} isUp={headerFclExport?.params?.base64} rowDataFooter={footerFclExport} isUpFooter={footerFclExport?.params?.base64} getInputHeader={getInputHeaderFclExport} />


                {snackbar && (
                    <Snackbar
                        open={!!snackbar}
                        message={snackbar.message}
                        type={snackbar.type}
                        onClose={onSnackbarHandleClose}
                    />
                )}
            </Paper>

        </Paper>



    );
};
export default Banners;
