import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import './login.scss';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from '../../reducers/session/sessionSlice';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { pingCall, logOutSystem, login } from '../Admins/AdminHandler'
import { setHeaderAirImport, setFooterAirImport, setFooterAirExport, setHeaderAirExport, setHeaderLclImport, setFooterLclImport, setFooterLclExport, setHeaderLclExport, setHeaderFclImport, setFooterFclImport, setFooterFclExport, setHeaderFclExport } from '../../reducers/parameter/parameterSlice';
import { getLoginDetails, getAdminList, getQsList, getCRMs, getAgentNetwork, getAirExportAFs, getLclExportImportAFs, getExchangeRates, getAirImportExport, getLclImportExport, getFclImportExport, getCurrencyConstants, getFLCTypes40, getCRMsPaginationFilter, getFunctionTypes, getRejectReasions, getAirLines, getFLCTypes20, getChargesTypes, getMeasurementUnits, getShipmentTypes, getCountryAndOrigins, getProductCategories, getBannerEmails, getProductCharges, getAirPorts, getServiceTypes, getPorts, getQuoatationType, getQuotationStatus, getIncoterms, getKgFactors} from '../../reducers/requestHandler';


export const LoginForm = (hiddenData) => {
    const { admin } = useSelector(state => state.session);
    const [userName, setUserName] = new useState("");
    const [password, setPassword] = new useState("");
    const [hasError, setHasError] = new useState(false);
    const [session, setSession] = new useState(getCookie("NBO_SESSION_ID"));
	const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    useEffect(() => {
        if (!admin.data.session) {
            ping();
           // getPackages();
        }
        if (admin.data.session) {
            setIsLoading(true)
            getLoginDetails(dispatch, admin);
            /*getAdminList(dispatch, admin);
            getQsList(dispatch, admin);
            getFunctionTypes(dispatch, admin);
            getQuoatationType(dispatch, admin);
            getQuotationStatus(dispatch, admin);
            getCountryAndOrigins(dispatch, admin);
            getAirLines(dispatch, admin);
            getRejectReasions(dispatch, admin);
            getAirPorts(dispatch, admin);
            getPorts(dispatch, admin);
            getIncoterms(dispatch, admin);
            getServiceTypes(dispatch, admin);
            getExchangeRates(dispatch, admin);
            getCurrencyConstants(dispatch, admin);
            getKgFactors(dispatch, admin);
            getProductCharges(dispatch, admin);
            getProductCategories(dispatch, admin);
            getShipmentTypes(dispatch, admin);
            getChargesTypes(dispatch, admin);
            getMeasurementUnits(dispatch, admin);
            getFLCTypes20(dispatch, admin);
            getFLCTypes40(dispatch, admin);
            getAirImportExport(dispatch, admin);
            getLclImportExport(dispatch, admin);
            getFclImportExport(dispatch, admin);
            getAirExportAFs(dispatch, admin);
            getAgentNetwork(dispatch, admin);
            getLclExportImportAFs(dispatch, admin);*/

            /*getBannerEmails(dispatch, { importExportType: 2, shipmentType: 1, type: 0, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setHeaderAirImport(data?.data[0]));
                }
            })
            getBannerEmails(dispatch, { importExportType: 2, shipmentType: 1, type: 1, sessionId: admin.data.session }
            , function (data) {
                console.log(data.data[0])
                if (data?.data?.length) {

                    dispatch(setFooterAirImport(data?.data[0]));
                }
            })

            getBannerEmails(dispatch, { importExportType: 1, shipmentType: 1, type: 0, sessionId: admin.data.session }
                , function (data) {
                    console.log(data.data[0])
                    if (data?.data?.length) {

                        dispatch(setHeaderAirExport(data?.data[0]));
                    }
                })
            getBannerEmails(dispatch, { importExportType: 1, shipmentType: 1, type: 1, sessionId: admin.data.session }
                , function (data) {
                    console.log(data.data[0])
                    if (data?.data?.length) {

                        dispatch(setFooterAirExport(data?.data[0]));
                    }
                })


            getBannerEmails(dispatch, { importExportType: 2, shipmentType: 2, type: 0, sessionId: admin.data.session }
                , function (data) {
                    console.log(data.data[0])
                    if (data?.data?.length) {

                        dispatch(setHeaderLclImport(data?.data[0]));
                    }
                })
            getBannerEmails(dispatch, { importExportType: 2, shipmentType: 2, type: 1, sessionId: admin.data.session }
                , function (data) {
                    console.log(data.data[0])
                    if (data?.data?.length) {

                        dispatch(setFooterLclImport(data?.data[0]));
                    }
                })

            getBannerEmails(dispatch, { importExportType: 1, shipmentType: 2, type: 0, sessionId: admin.data.session }
                , function (data) {
                    console.log(data.data[0])
                    if (data?.data?.length) {

                        dispatch(setHeaderLclExport(data?.data[0]));
                    }
                })
            getBannerEmails(dispatch, { importExportType: 1, shipmentType: 2, type: 1, sessionId: admin.data.session }
                , function (data) {
                    console.log(data.data[0])
                    if (data?.data?.length) {

                        dispatch(setFooterLclExport(data?.data[0]));
                    }
                })


            getBannerEmails(dispatch, { importExportType: 2, shipmentType: 3, type: 0, sessionId: admin.data.session }
                , function (data) {
                    console.log(data.data[0])
                    if (data?.data?.length) {

                        dispatch(setHeaderFclImport(data?.data[0]));
                    }
                })
            getBannerEmails(dispatch, { importExportType: 2, shipmentType: 3, type: 1, sessionId: admin.data.session }
                , function (data) {
                    console.log(data.data[0])
                    if (data?.data?.length) {

                        dispatch(setFooterFclImport(data?.data[0]));
                    }
                })

            getBannerEmails(dispatch, { importExportType: 1, shipmentType: 3, type: 0, sessionId: admin.data.session }
                , function (data) {
                    console.log(data.data[0])
                    if (data?.data?.length) {

                        dispatch(setHeaderFclExport(data?.data[0]));
                    }
                })
            getBannerEmails(dispatch, { importExportType: 1, shipmentType: 3, type: 1, sessionId: admin.data.session }
                , function (data) {
                    console.log(data.data[0])
                    if (data?.data?.length) {

                        dispatch(setFooterFclExport(data?.data[0]));
                    }
                })
*/
            getBannerEmails(dispatch, { importExportType: 1, shipmentType: 3, type: 1, sessionId: admin.data.session }
                , function (data) {
                    console.log(data.data[0])
                    if (data?.data?.length) {
                        dispatch(setHeaderAirImport(data?.misc.bannerHeaderAirImport));
                        dispatch(setFooterAirImport(data?.misc.bannerFooterAirImport));

                        dispatch(setHeaderAirExport(data?.misc.bannerHeaderAirExport));
                        dispatch(setFooterAirExport(data?.misc.bannerFooterAirExport));

                        dispatch(setHeaderLclImport(data?.misc.bannerHeaderLclImport));
                        dispatch(setFooterLclImport(data?.misc.bannerFooterLclImport));

                        dispatch(setHeaderLclExport(data?.misc.bannerHeaderLclExport));
                        dispatch(setFooterLclExport(data?.misc.bannerFooterLclExport));
                        
                        dispatch(setHeaderFclImport(data?.misc.bannerHeaderFclImport));
                        dispatch(setFooterFclImport(data?.misc.bannerFooterFclImport));

                        dispatch(setHeaderFclExport(data?.misc.bannerHeaderFclExport));
                        dispatch(setFooterFclExport(data?.misc.bannerFooterFclExport));
                    }
                })

            //setIsLoading(false)
            //getCRMs(dispatch, admin);
           
        }
    }, [dispatch, failedCallback, admin]);
    const doLogin = () => {
        login(dispatch, userName, password, setHasError);
    }
    const logOut = () => {
        setHasError(false);
        setIsLoading(true)
        logOutSystem(dispatch, session, setHasError)        
    }
    const ping = () => {
        if (session) {
            pingCall(dispatch, session, setHasError);
            

        }
    }

    const thidden = hiddenData.hidden;

    const keyPressed = (e) => {
      if(e.keyCode ===13){
        doLogin();
      }
    }
    return (
        <div>
            {!thidden&&<div className="login-main-container" hidden={thidden}>
                <div className="login-container">
                    <div className="login-title">
                        <div className="login-title-strong"></div>
                        <div className="login-title-sub">Welcome To Tclog.</div>
                    </div>
                    <div className="login_form"  >
                        <div className="login-label">Username</div>
                        <input type="text" className="login-input" onChange={e => setUserName(e.target.value)} />
                        <div className="login-label password" >Password</div>
                        <input type="password" className="login-input" onKeyDown={e => keyPressed(e)} onChange={e => setPassword(e.target.value)} />
                        <div>
                            <button type="button" value="Login" className="login-button" onClick={doLogin}>
                                <span className="btn-label">Login</span>
                                {true &&
                                    <em className="btn-loading" />
                                }
                            </button>
                        </div>
                    </div>
                    <div className="login-error">{hasError && "Invalid login!"}</div>
                </div>
            </div>}
        </div>
    );
}

export default LoginForm; 