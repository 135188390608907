import { memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { FormControlLabel, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, TableCell, TableHead, TableRow, Typography, Button } from '@mui/material';


import { TextField, SelectField } from '../../../style-guide';
import { listOfCostLandWeightLcl} from '../../../constants/global-constants';

const LclCostHeader = (props) => {
    const [listOfCostLandWeightLcl1, setListOfCostLandWeightLcl] = useState(listOfCostLandWeightLcl);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    if (props.id == 2 || props.id == 1) {
        return (
            <TableHead style={{ padding: '2px', lineHeight: '0 !important' }}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                    <TableCell style={{ width: '2vw', padding: '8px' }}>
                        <Typography variant='h7'>

                            #
                        </Typography>
                    </TableCell>
                    <TableCell style={{ width: '21vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            
                            <TextField
                                lg={12}
                                value={props.lclCostData.crmId}
                                onChange={event => props.setLclCostData(prev => ({ ...prev, crmId: event.target.value }))}
                                label='Co-loader'
                            />
                        </Typography>
                    </TableCell>
                    <TableCell style={{ width: '11vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            
                            <TextField
                                lg={12}
                                value={props.lclCostData.countryId}
                                onChange={event => props.setLclCostData(prev => ({ ...prev, countryId: event.target.value }))}
                                label='Country'
                            />
                        </Typography>
                    </TableCell>
                    
                    {(props.id == 2) && <TableCell style={{ width: '11vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.lclCostData.portLid}
                                onChange={event => props.setLclCostData(prev => ({ ...prev, portLid: event.target.value }))}
                                label='POL'
                            />
                        </Typography>
                    </TableCell>
                    }
                    { <TableCell style={{ width: '11vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.lclCostData.portAid}
                                onChange={event => props.setLclCostData(prev => ({ ...prev, portAid: event.target.value }))}
                                label={props.id==2?'POA':'Port'}
                                />
                        </Typography>
                    </TableCell>
                    }
                    <TableCell style={{ width: '6vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.lclCostData.rate}
                                onChange={event => props.setLclCostData(prev => ({ ...prev, rate: event.target.value }))}
                                label='Rate'
                            />
                        </Typography>
                    </TableCell>
                    <TableCell style={{ width: '21vw',padding:'8px' }}>
                        <Typography variant='h7'>
                           
                            <TextField
                                lg={12}
                                value={props.lclCostData.clAgent}
                                onChange={event => props.setLclCostData(prev => ({ ...prev, clAgent: event.target.value }))}
                                label='Cl Agent'
                            />
                        </Typography>
                    </TableCell>
                    {(props.id == 1 || props.id == 2) && <TableCell style={{ width: '6vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.lclCostData.tt}
                                onChange={event => props.setLclCostData(prev => ({ ...prev, tt: event.target.value }))}
                                label='TT'
                            />
                        </Typography>
                    </TableCell>
                    }
                    <TableCell style={{ width: '6vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            Valid Date
                        </Typography>
                    </TableCell>
                    <TableCell style={{ width: '6vw',padding:'8px' }}>
                        <Typography variant='h7'>
                           Actions
                        </Typography>
                    </TableCell>
                   


                </TableRow>
            </TableHead>
        );
    } else if (props.id == 3) {
        return (
             <TableHead style={{ padding: '2px', lineHeight: '0' }}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0', margin: 0, padding: '2px' } }}>
                    <TableCell style={{ width: '2vw', padding: '8px' }}>
                        <Typography variant='h7'>

                            #
                        </Typography>
                    </TableCell>
                    <TableCell style={{ width: '8.3vw', padding: '8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.truckingCompany}
                                onChange={event => props.setTruckingCompany(event.target.value)}
                                label={"Trucking company"}
                            />
                        </Typography>
                    </TableCell>
                    {<TableCell style={{ width: '11vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.lclCostData.countryId}
                                onChange={event => props.setLclCostData(prev => ({ ...prev, countryId: event.target.value }))}
                                label='Country'
                            />
                        </Typography>
                    </TableCell>
                    }
                    <TableCell style={{ width: '11vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.lclCostData.portAid}
                                onChange={event => props.setLclCostData(prev => ({ ...prev, portAid: event.target.value }))}
                                label='Port'
                            />
                        </Typography>
                    </TableCell>
                    <TableCell style={{ width: '11vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.lclCostData.zip}
                                onChange={event => props.setLclCostData(prev => ({ ...prev, zip: event.target.value }))}
                                label='Zip'
                            />
                        </Typography>
                    </TableCell>
                    {<TableCell style={{ width: '11vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.lclCostData.min}
                                onChange={event => props.setLclCostData(prev => ({ ...prev, min: event.target.value }))}
                                label='Min'
                            />
                        </Typography>
                    </TableCell>
                    }
                    {listOfCostLandWeightLcl1.map((crm, index)  => (
                        <TableCell key={crm?.weight || uuidv4()} >
                            <Typography variant='h7'>
                                <TextField
                                    lg={12}
                                    value={crm?.rate}
                                    onChange={function (event) {
                                        var t = [...listOfCostLandWeightLcl1]; var tObj = JSON.parse(JSON.stringify(t[index])); tObj.rate = event.target.value; t[index] = tObj; setListOfCostLandWeightLcl(t);

                                        props.setListOfCostLandWeightLcl(t)
                                        props.setLclCostData(prev => ({ ...prev, rateList: t }))
                                    }}
                                    label={crm?.weight}
                                />
                            </Typography>
                        </TableCell>
                    ))
                    }
                    


                </TableRow>
            </TableHead>
        );
    } else if (props.id == 4 || props.id == 5) {
        return (
             <TableHead style={{ padding: '2px', lineHeight: '0' }}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0', margin: 0, padding: '2px' } }}>
                   
                    {<TableCell style={{ width: '11vw', padding: '15px', fontWeight: 'bold' }}>
                        <Typography variant='h7'>
                            Product
                        </Typography>
                    </TableCell>
                    }
                    <TableCell style={{ width: '11vw', padding: '8px', fontWeight: 'bold' }}>
                        <Typography variant='h7'>
                            Unit Of Measure
                        </Typography>
                    </TableCell>
                    <TableCell style={{ width: '11vw', padding: '8px', fontWeight: 'bold'}}>
                        <Typography variant='h7'>
                            Currency
                        </Typography>
                    </TableCell>
                    {<TableCell style={{ width: '11vw', padding: '8px', fontWeight: 'bold' }}>
                        <Typography variant='h7'>
                            Rate
                        </Typography>
                    </TableCell>
                    }
                    {<TableCell style={{ width: '11vw', padding: '8px', fontWeight: 'bold'}}>
                        <Typography variant='h7'>
                            Min
                        </Typography>
                    </TableCell>
                    }
                    {<TableCell style={{ width: '11vw', padding: '8px', fontWeight: 'bold' }}>
                        <Typography variant='h7'>
                            Remark
                        </Typography>
                    </TableCell>
                    }


                </TableRow>
            </TableHead>
        );
    } 
};

export default memo(LclCostHeader);
