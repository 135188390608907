import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TableCell, Grid, TableRow, Typography, Box, Button, Checkbox, Radio, FormControlLabel } from '@mui/material';
import { Dialog } from '../../../style-guide';
import { useSelector, useDispatch } from 'react-redux';
import { addDesc, recieptWithBarCode } from '../../Admins/AdminHandler'
import { onAirCostChange, onEmptyAirCost } from '../../../reducers/crm/crmSlice';
import { snackbarToggle } from '../../../reducers/snackbarSlicer';
import { listOfCostLandWeightAir, parcelSortBy, SHIPPING_STATUS_LIST } from '../../../constants/global-constants';
import { getQuoatationType, getQuotationStatus, getCRMList, deleteAirExportAF } from '../../../reducers/requestHandler';
import { getAdminList, getQsList } from '../../../reducers/requestHandler';
import { setCurrentPage } from '../../../reducers/session/sessionSlice';
import { FaTrash, FaPen } from "react-icons/fa";
const AirCostRow = props => {
    const dispatch = useDispatch();
    const { crmList } = useSelector(state => state.crm);
    const { countryList, listOfAirPort, listOfAirLine, listOfMeasurementUnits, listOfcurrency, qsStatusList, listOfIncoterms, listOfServiceType } = useSelector(state => state.parameter);
    const [openOrderModalDelete, setOpenOrderModalDelete] = useState(false);
    const onHandleOpenModalDelete = () => {
        setOpenOrderModalDelete(true);
    };
    const onHandleCancelModalDelete = () => setOpenOrderModalDelete(false);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                crmId: props.data.crmId,
                fromId: props.data.fromId,
                airportId: props.data.airportId,
                countryId: props.data.countryId,
                toId: props.data.toId,
                airLineId: props.data.airLineId,
                weightList: props.data.weightList,
                rateList: props.data.rateList,
                min: props.data.min,
                zip: props.data.zip,
                updateTime: props.data.updateTime,
                rate: props.data.rate,
                product: props.data.product,
                unitOfMeasure: props.data.unitOfMeasure,
                currency: props.data.currency,
                remark: props.data.remark,
                bSelected: props.data.bSelected,
                TO_NAME: props.data.TO_NAME,
                FROM_NAME: props.data.FROM_NAME,
                CRM_NAME: props.data.CRM_NAME,
                CMP_NAME: props.data.CMP_NAME,
                COUNTRY: props.data.COUNTRY,
                AL: props.data.AL,
            }
            : {
                id: null,
                crmId: null,
                fromId: null,
                countryId: null,
                airportId: null,
                toId: null,
                updateTime: 0,
                airLineId: null,
                weightList: null,
                rateList: null,
                min: null,
                zip: null,
                product: null,
                unitOfMeasure: null,
                currency: null,
                rate: null,
                remark: null,
                bSelected: null,
            },
    );
    const { roles, adminList, users } = useSelector(state => state.admin);
    const { currentPage,admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const deleteDescription = () => {
        var temp = {
            id: value.id,
            descId: value.id
        }
        addDesc(dispatch, snackbarToggle, temp, admin);

    };
    const [isLoaded, setIsLoaded] = useState(false);
    if (!isLoaded && admin.data.session && crmList && crmList.length == 0) {
        //getCRMList(dispatch, admin);
        setIsLoaded(true);
    }
    function formatDate(newDate) {        
        const d = newDate
        const year = d.getFullYear()
        const date = d.getDate()
        const monthName = d.getMonth()+1
        const formatted =  `${date}/${monthName}/${year}`
        return formatted.toString()
    }
    useEffect(() => {
       
    }, [dispatch]);
    var t2 = [];
    const handleParams = (event) => {
    }
    if (props.id == 2 || props.id == 1) {
        if (props.id == 1) {
            if (currentPage != 'Air Export A/F') {
                dispatch(setCurrentPage("Air Export A/F"));
            }
        } else {
            if (currentPage != 'Air Import A/F') {
                dispatch(setCurrentPage("Air Import A/F"));
            }
        }
        //console.log(value)
        
        return (
            <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        <FormControlLabel control={<Checkbox
                            checked={value.bSelected} onChange={(event) => {
                                setValue(prev => ({ ...prev, bSelected: event.target.checked }))
                                props.setSelectedAirCost(value.id, event.target.checked) 
                            }} />} label="" />
                    </Typography>
                </TableCell>
                {props.id == 2 && value.crmId != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.CRM_NAME}
                        {/*{crmList.find(u => u.id === value.crmId)?.companyName || '-'}*/}
                    </Typography>
                </TableCell>
                }
                {value.fromId != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.FROM_NAME}
                        {/*{listOfAirPort.find(u => u.id === value.fromId)?.name || '-'}*/}
                    </Typography>
                </TableCell>
                }
                {value.toId != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.TO_NAME}
                        {/*{listOfAirPort.find(u => u.id === value.toId)?.name || '-'}*/}
                    </Typography>
                </TableCell>
                }
                {value.airLineId != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.CMP_NAME}
                        {/*{listOfAirLine.find(u => u.id === value.airLineId)?.name || '-'}*/}
                    </Typography>
                </TableCell>
                }
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    {value?.weightList!=null && value?.weightList?.map(w => (
                        <Typography sx={{ mt: 0, mr: 0, lineHeight: '0.75 !important' }}  key={uuidv4()} variant='h7' >
                            {!w?.weight ? w : w?.weight}
                        </Typography>
                    ))}
                </TableCell>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    {value?.rateList!=null &&value?.rateList?.map(r => (
                        <Typography sx={{ mt: 0, mr: 0, lineHeight: '0.75 !important' }}  key={ uuidv4()} variant='h7' >
                            {r}
                        </Typography>
                    ))}
                </TableCell>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.min}
                    </Typography>
                </TableCell>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Button variant='contained' sx={{ mt: 0, ml: 2, lineHeight: '0.75 !important', background:"#66b1ff" }} onClick={props.openDialog}>
                        <FaPen/>
                    </Button>
                    <Button variant='contained' sx={{ mt: 0, ml: 2, lineHeight: '0.75 !important', background: "#F56C6C" }} onClick={onHandleOpenModalDelete}>
                        <FaTrash />
                    </Button>

                    {openOrderModalDelete && (
                        <Dialog maxWidth='md' open={openOrderModalDelete} title={'Delete'} onHandleCancel={onHandleCancelModalDelete}>
                            <Grid container item>  Are you sure, you want to delete?</Grid>
                            <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {

                                deleteAirExportAF(dispatch, snackbarToggle, { id: value.id }, admin, props.getAirCostData)
                                onHandleCancelModalDelete()
                            }}>
                                Delete
                            </Button>
                        </Dialog>
                    )}
                </TableCell>
            </TableRow >

        );
    } else if (props.id == 3) {
        if (currentPage != 'Air Land Transport') {
            dispatch(setCurrentPage("Air Land Transport"));
        }

        return (
            <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        <FormControlLabel control={<Checkbox
                            checked={value.bSelected} onChange={(event) => {
                                setValue(prev => ({ ...prev, bSelected: event.target.checked }))
                                props.setSelectedAirCost(value.id, event.target.checked)
                            }} />} label="" />
                    </Typography>
                </TableCell>
                {<TableCell >
                    <Typography variant='h7' >
                        {/*{value.countryId} */}
                        {props.data?.params?.TRUCKER_NAME}
                    </Typography>
                </TableCell>
                }
                {value.countryId != 0 ? <TableCell >
                    <Typography variant='h7' >
                        {value.COUNTRY}
                        {/*{countryList.find(u => u.id === value.countryId)?.name || '-'}*/}
                    </Typography>
                </TableCell> : <TableCell >
                    <Typography variant='h7' >
                        -
                        {/*{countryList.find(u => u.id === value.countryId)?.name || '-'}*/}
                    </Typography>
                </TableCell>
                }
                {!value.countryId && <TableCell >
                    <Typography variant='h7' >
                        -
                    </Typography>
                </TableCell>
                }
                {value.airportId != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.AL}
                        {/*{listOfAirPort.find(u => u.id === value.airportId)?.name || '-'}*/}
                    </Typography>
                </TableCell>
                }
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.zip}
                    </Typography>
                </TableCell>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.min}
                    </Typography>
                </TableCell>
                {listOfCostLandWeightAir.map((r,index) => (
                    <TableCell key={uuidv4()} style={{ margin: 0, padding: '8px' }}>

                        <Typography sx={{ mt: 0, mr: 0, lineHeight: '0.75 !important' }}  key={index || uuidv4()} variant='h7' >
                            {(value.rateList && value.rateList.length > 0 && value.rateList[index]) ? value.rateList[index] : '-'}
                        </Typography>
                    </TableCell>
                ))}
                <TableCell style={{ margin: 0, padding: '8px',minWidth:"10vw" }}>
                    <Button variant='contained' sx={{ mt: 0, ml: 2, lineHeight: '0.75 !important', background: "#66b1ff" }} onClick={props.openDialog}>
                        <FaPen />
                    </Button>
                    <Button variant='contained' sx={{ mt: 0, ml: 2, lineHeight: '0.75 !important', background: "#F56C6C" }} onClick={onHandleOpenModalDelete}>
                        <FaTrash />
                    </Button>

                    {openOrderModalDelete && (
                        <Dialog maxWidth='md' open={openOrderModalDelete} title={'Delete'} onHandleCancel={onHandleCancelModalDelete}>
                            <Grid container item>  Are you sure, you want to delete?</Grid>
                            <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {

                                deleteAirExportAF(dispatch, snackbarToggle, { id: value.id }, admin, props.getAirCostData)
                                onHandleCancelModalDelete()
                            }}>
                                Delete
                            </Button>
                        </Dialog>
                    )}
                </TableCell>
            </TableRow >

        );
    } else if (props.id == 4 || props.id == 5) {
        if (props.id == 4) {
            if (currentPage != 'Air Export - Locals') {
                dispatch(setCurrentPage("Air Export - Locals"));
            }
        }
       
        if (props.id == 5) {
            if (currentPage != 'Air Import - Locals') {
                dispatch(setCurrentPage("Air Import - Locals"));
            }
        }
        return (
            <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.product }
                    </Typography>
                </TableCell>
                {value.unitOfMeasure != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {listOfMeasurementUnits.find(u => u.id === value.unitOfMeasure)?.lable || '-'}
                    </Typography>
                </TableCell>
                }
                {value.currency != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {listOfcurrency.find(u => u.id === value.currency)?.lable || '-'}
                    </Typography>
                </TableCell>
                }
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.rate}
                    </Typography>
                </TableCell>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.min}
                    </Typography>
                </TableCell>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.remark}
                    </Typography>
                </TableCell>
            </TableRow >

        );
    }
};

export default AirCostRow;
