import { useState } from 'react';
import { TableCell, TableRow, Typography, Box, Button } from '@mui/material';
import AddAdmin from './AddAdmin';
import { Dialog } from '../../style-guide';
import { useSelector } from 'react-redux';
import { FaTrash, FaPen } from "react-icons/fa";

const AdminRow = ({ data }) => {
    const { roles } = useSelector(state => state.admin);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);

    return (
        <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
            <TableCell style={{ margin: 0, padding: '8px' }} />
            <TableCell style={{ margin: 0, padding: '8px' }} >
                <Typography variant='h7' >
                    {data.adminName || '-'}
                </Typography>
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }} >
                <Typography variant='h7' >
                    {data.email || '-'}
                </Typography>
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }} >
                <Typography variant='h7' >
                    {roles.find(role => role.id === data.type)?.label || '-'}
                </Typography>
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }} >
                <Typography variant='h7' >
                    {data.phone || '-'}
                </Typography>
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }} >
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button sx={{ mr: 1 }} onClick={onHandleOpenModal} variant='outlined'>
                        <FaPen />
                    </Button>
                    {openOrderModal && (
                        <Dialog open={openOrderModal} title={'User'} onHandleCancel={onHandleCancelModal}>
                            <AddAdmin data={data} onHandleCancel={onHandleCancelModal} id={null} />
                        </Dialog>
                    )}
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default AdminRow;
