import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Grid, Button, Typography, TableHead, TableCell, TableRow, Table, TableBody, TableContainer, Paper, TablePagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { setSelectedAdmin } from '../../reducers/QS/qsSlice';
import { setCommonFilterData, setQsStatusList } from '../../reducers/parameter/parameterSlice';
import { onAdminChange } from '../../reducers/admin-reducers/AdminSlicer';
import { Dialog, Snackbar, DateTimePicker, SelectField } from '../../style-guide';

import { snackbar1Toggle } from '../../reducers/snackbar1Slice';
import { setCurrentPage } from '../../reducers/session/sessionSlice';
import { getAdminList, getQsList, getAllBetweenDate, getCRMList} from '../../reducers/requestHandler';
import DonutChart from "react-donut-chart";
import CompairTable from "./CompairTable"
export const CompareQs = () => {
    const dispatch = useDispatch();
    const { crmList } = useSelector(state => state.crm);
    const { qsList1 } = useSelector(state => state.QS);
    const { commonFilterData, countryList, listOfcurrency, listOfAirPort, listOfPort, qsTypeList, qsStatusList, listOfIncoterms, listOfServiceType } = useSelector(state => state.parameter);

    const [qsListStart, setQsListStart] = new useState([]);
    const [qsListEnd, setQsListEnd] = new useState([]);
    const [reactDonutChartdata, setDonutChartdata] = new useState([]);
    const { adminList } = useSelector(state => state.admin);
    const [filteredAdminList, setFilteredAdminList] = useState(adminList);
    const { currentPage, admin } = useSelector(state => state.session);
    const { selectedAdmin } = useSelector(state => state.QS);
    function getMargin(value) {
        if (value.shipmentType != 3) {
            var total = Number(value.total) ? Number(value.total) : 0;
            var price = value.params?.originalPrice ? Number(value.params?.originalPrice) : total;
            /*var profit = total - price;
            var gMargin = profit / total;*/
            var gMargin = ((total / price) - 1) * 100
            if (gMargin) {
                return Number(gMargin.toFixed(2))
            }
        } 

        return 0
    } 
    function getMargin20(value) {
        if (value.shipmentType != 3) {
           
        } else {
            var total20 = Number(value.total20) ? Number(value.total20) : 0;
            var price20 = value.params?.originalPrice20 ? Number(value.params?.originalPrice20) : total20;
            var gMargin20 = ((total20 / price20) - 1) * 100
            if (gMargin20) {
                return Number(gMargin20.toFixed(2))
            }
        }

        return 0
    } 
    function getMargin40(value) {
        if (value.shipmentType != 3) {           
        } else {
            var total40 = Number(value.total40) ? Number(value.total40) : 0;
            var price40 = value.params?.originalPrice40 ? Number(value.params?.originalPrice40) : total40;
            var gMargin40 = ((total40 / price40) - 1) * 100
            if (gMargin40) {
                return Number(gMargin40.toFixed(2))
            }
        }

        return 0
    } 
    const getTotalAvgByUserLcl = function (usetId,list) {
        var margin = 0;
        var count = 0;
        list.forEach(element => {
            if (element.userId == usetId) {
                console.log(element.shipmentType)                
                if (element.shipmentType == 2) {
                    margin = margin + Number(getMargin(element))
                    count = count + 1;
                }
            }
        });
        var avg = margin / count;
        if (!avg) {
            avg = 0;
        }
        return avg;
    }
    const getTotalAvgByUserAir = function (usetId, qsList) {
        var margin = 0;
        var count = 0;
        qsList.forEach(element => {
            if (element.userId == usetId) {
                console.log(element.shipmentType)                
                if (element.shipmentType == 1) {
                    margin = margin + Number(getMargin(element))
                    count = count + 1;
                }
            }
        });
        var avg = margin / count;
        if (!avg) {
            avg = 0;
        }
        return avg;
    }
    const getTotalAvgByUserFcl = function (usetId, qsList) {
        var margin = 0;
        var count = 0;
        qsList.forEach(element => {
            if (element.userId == usetId) {
                console.log(element.shipmentType)                
                if (element.shipmentType == 3) {
                    var m1 = Number(getMargin20(element))
                    var m2 = Number(getMargin40(element))
                    margin = margin + ((m1 + m2) / 2)
                    count = count + 1;
                }
            }
        });
        var avg = margin / count;
        if (!avg) {
            avg = 0;
        }
        return avg;
    }
    const getTotalAvgByUser = function (usetId, qsList) {
        var margin = 0;
        qsList.forEach(element => {
            if (element.userId == usetId) {
                console.log(element.shipmentType)                
                if (element.shipmentType == 3) {
                    var m1 = Number(getMargin20(element))
                    var m2 = Number(getMargin40(element))
                    margin = margin + ((m1 + m2) / 2)
                } else {
                    margin = margin + Number(getMargin(element))
                }
            }
        });
        return margin;
    }
    const getStatusCountByUser = function (usetId, status, qsList) {
        var count = 0;
        qsList?.forEach(element => {
            if (element.userId == usetId && element.status == status) {
                count++;
                //temp.push(element)
            }
        });


        return count;
    }
    if (currentPage !== 'Dashboard') {

        dispatch(setCurrentPage("Dashboard"));
    }
    const onHandleHideAllItems = () => {

    }

    const navigate = useNavigate();

    const onHandleUserFilter = (userId, data) => {
        var t = {}
        if (!data)
            t = { ...commonFilterData }
        else
            t = { ...data }
        t.userId = [];
        var t1 = { ...adminList.find(u => u.id === userId) }
        t1.bSelected = true;
        t.userId.push(t1)
        dispatch(setCommonFilterData(t));

        var p = []
        var selected = [...adminList]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            if (tObj.id == userId) {
                tObj.bSelected = true
            }
            dispatch(onAdminChange(tObj));
        }
        navigate("/menu/qs")

    }
    const onHandleStatusFilter = (status) => {
        var t = { ...commonFilterData }
        t.status = [];
        var t1 = { ...qsStatusList.find(u => u.id === status) }
        t1.bSelected = true;
        t.status.push(t1)

        dispatch(setCommonFilterData(t));
        var p = []
        var selected = [...qsStatusList]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            if (tObj.id == status) {
                tObj.bSelected = true
            }
            p.push(tObj)
        }
        dispatch(setQsStatusList(p));
        //navigate("/menu/qs")
        onHandleUserFilter(selectedAdmin.id, t)

    }
    const setSelectedUser = (user) => {
        console.log(user);
        dispatch(setSelectedAdmin(user));

        var data = []

        data.push({ label: "Prepared", value: getStatusCountByUser(selectedAdmin.id, 1), color: "#eaae39" });
        data.push({ label: "Pending", value: getStatusCountByUser(selectedAdmin.id, 4), color: "#2d9cdb" });
        data.push({ label: "Approved", value: getStatusCountByUser(selectedAdmin.id, 2), color: "#6fcf97" });
        data.push({ label: "Declined", value: getStatusCountByUser(selectedAdmin.id, 3), color: "#f45858" });
        setDonutChartdata(data);
    }
    const reactDonutChartBackgroundColor = [
        "#eaae39",
        "#2d9cdb",
        "#6fcf97",
        "#f45858",
    ];
    const [isLoaded, setIsLoaded] = useState(false);
    if (!isLoaded) {
        if (admin.data.session) {
            getCRMList(dispatch, admin);
            getAdminList(dispatch, admin);
            getQsList(dispatch, admin);
            setSelectedUser(admin.data.user)
            window.setTimeout(function () {
                setSelectedUser(admin.data.user)
                if (document.getElementById('admin_' + admin.data.user.id))
                    document.getElementById('admin_' + admin.data.user.id).onmouseover = function () { setSelectedUser(admin.data.user) };
            }, 1000);

            setIsLoaded(true);
        }
    }

    
    const getTotal = (userId,list) => {

        var n = getStatusCountByUser(userId, 1, list) + getStatusCountByUser(userId, 2, list) + getStatusCountByUser(userId, 3, list);// + getStatusCountByUser(userId, 4)
        return n;
    }


    const reactDonutChartInnerRadius = 0.5;
    const reactDonutChartSelectedOffset = 0.04;
    const reactDonutChartHandleClick = (item, toggled) => {
        if (toggled) {
            console.log(item);
        }
    };
    let reactDonutChartStrokeColor = "#FFFFFF";
    const reactDonutChartOnMouseEnter = (item) => {
        let color = reactDonutChartdata.find((q) => q.label === item.label).color;
        reactDonutChartStrokeColor = color;
    };
    const st = new Date(new Date().getTime());
    const st1 = new Date(new Date().getTime());
    const twoDayBefor = st.setDate(st.getDate() - 30);
    const twoDayafter = st1.setDate(st1.getDate() + 0);

    const [startDate, setStartDate] = useState((st));
    const [startDate1, setStartDate1] = useState((st1));

    const isFiltered = (user, qsList) => {
        for (var i = 0; i < qsList?.length; i++) {
            var qs = qsList[i];
            if (qsList[i].userId === user.id) {
                
                //if (bQuouteType && bService && bInco && bPort && bCountry && bCustomer && bDate) {
                    return true;
               // }
            }
        }
        return false;
    }
    const [endDateLoader, setEndDateLoader] = useState((false));
    const [startDateLoader, setStartDateLoader] = useState((false));
    const [endDate, setEndDate] = useState((st));
    const [endDate1, setEndDate1] = useState((st1));
    useEffect(() => {
        setStartDateLoader(true)
        getAllBetweenDate(dispatch, admin, startDate.getTime(), startDate1.getTime(), function (data) {
            setQsListStart(data)
            setStartDateLoader(false)
        })
    }, [startDate, startDate1]);
    useEffect(() => {
        setEndDateLoader(true)
        getAllBetweenDate(dispatch, admin, endDate.getTime(), endDate1.getTime(), function (data) {
            setQsListEnd(data)
            setEndDateLoader(false)
        })
    }, [endDate, endDate1]);

    const onStartDateChange = data => {
        setStartDate(data);
        
    };
    const onStartDateChange1 = data => {
        setStartDate1(data);
        
    };

    
    const onEndDateChange = data => {
        setEndDate(data);
        
    };
    const onEndDateChange1 = data => {
        setEndDate1(data);
        
    };
    const crmListOpt = crmList.map(sender => ({
        id: sender.id,
        name: sender.companyName
    }));
    const crmListOpt1 = [{
        id: -1,
        name: 'All'
    }].concat(crmListOpt)
    const [crmId, setCrmId] = useState(0);
    const countryList1 = [{
        id: -1,
        name: 'All'
    }].concat(countryList)
    const [countryId, setCountryId] = useState(0);
    var t = [{
        id: -2,
        name: 'Air Port'
    }, {
        id: -1,
        name: 'All'
    }].concat(listOfAirPort)
    const [portOfOrigin, setPortOfOrigin] = useState("");
    const listOfPortOpt = listOfPort.map(sender => ({
        id: t.length + 1 + sender.id,
        name: sender.name
    }));
    var t1 = [{
        id: -3,
        name: 'Port'
    }].concat(listOfPortOpt)
    const airPortAndPorts = t.concat(t1)

    const listOfIncotermsOpt = listOfIncoterms.map(sender => ({
        id: sender.id,
        name: sender.incoterm
    }));
    const listOfIncotermsOpt1 = [{
        id: -1,
        name: 'All'
    }].concat(listOfIncotermsOpt)


    const [listOfIncotermsOpt2, setListOfIncotermsOpt2] = useState(listOfIncotermsOpt1);
    const [incoId, setIncoId] = useState(0);
    const listOfServiceTypeOpt = listOfServiceType.map(sender => ({
        id: sender.id,
        name: sender.serviceType
    }));
    const listOfServiceTypeOpt1 = [{
        id: -1,
        name: 'All'
    }].concat(listOfServiceTypeOpt)


    const [listOfServiceTypeOpt2, setListOfServiceTypeOpt2] = useState(listOfServiceTypeOpt1);

    const [serviceId, setServiceId] = useState(0);
    const qsTypeListOpt = qsTypeList.map(sender => ({
        id: sender.id,
        name: sender.lable
    }));
    const qsTypeListOpt1 = [{
        id: -1,
        name: 'All'
    }].concat(qsTypeListOpt)

    const [qsTypeListOpt2, setQsTypeListOpt2] = useState(qsTypeListOpt1);
    const [quouteTypeId, setQuouteTypeId] = useState(0);


    return (
        <Grid container sx={{mt:2}}>
            
            <Grid container item xs={12} justifyContent='center' spacing={3} sx={{ml:0}}>
                <Grid container item xs={6} justifyContent='flex-start' spacing={2}>
                    <DateTimePicker
                        sm={null}
                        md={null}
                        xs={6}
                        label={<Typography fontSize="12px" fontWeight='bold'>Start</Typography>}
                        value={startDate}
                        onChange={date => onStartDateChange(date)}
                        style={{ 'top': '15px' }}
                        />
                    <DateTimePicker
                        sm={null}
                        md={null}
                        xs={5}
                        label={<Typography fontSize="12px" fontWeight='bold'>End</Typography>}
                        value={startDate1}
                        onChange={date => onStartDateChange1(date)}
                        style={{ 'top': '15px' }}
                        />
                </Grid>
                <Grid container item xs={6} justifyContent='flex-start' spacing={2}>
                <DateTimePicker
                    sm={null}
                    md={null}
                    xs={6}
                    label={<Typography fontSize="12px" fontWeight='bold'>Start</Typography>}
                    value={endDate}
                    onChange={date => onEndDateChange(date)}
                    style={{ 'top': '15px', fontSize: '0.7em' }}

                />
                <DateTimePicker
                    sm={null}
                    md={null}
                    xs={5}
                    label={<Typography fontSize="12px" fontWeight='bold'>End</Typography>}
                    value={endDate1}
                    onChange={date => onEndDateChange1(date)}
                    style={{ 'top': '15px', fontSize: '0.7em' }}

                />
                </Grid>
                </Grid>
            <Grid container item xs={12} justifyContent='start' sx={{ ml: 1 }}>
                <Grid container item xs={6} justifyContent='start'>
                    <CompairTable bLoading={startDateLoader} filteredAdminList={filteredAdminList} qsListStart={qsListStart} isFiltered={isFiltered} getTotalAvgByUserFcl={getTotalAvgByUserFcl} getTotalAvgByUserLcl={getTotalAvgByUserLcl} getTotalAvgByUserAir={getTotalAvgByUserAir} getTotal={getTotal} getTotalAvgByUser={getTotalAvgByUser} getStatusCountByUser={getStatusCountByUser} onHandleUserFilter={onHandleUserFilter} setSelectedUser={setSelectedUser} />
                </Grid>
                <Grid container item xs={6} justifyContent='start'>
                    <CompairTable bLoading={endDateLoader} filteredAdminList={filteredAdminList} qsListStart={qsListEnd} isFiltered={isFiltered} getTotalAvgByUserFcl={getTotalAvgByUserFcl} getTotalAvgByUserLcl={getTotalAvgByUserLcl} getTotalAvgByUserAir={getTotalAvgByUserAir} getTotal={getTotal} getTotalAvgByUser={getTotalAvgByUser} getStatusCountByUser={getStatusCountByUser} onHandleUserFilter={onHandleUserFilter} setSelectedUser={setSelectedUser} />
                </Grid>

                
            </Grid>
        </Grid>
    );
};

export default CompareQs;
