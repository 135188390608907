import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { Grid, Button, Typography, Paper } from '@mui/material';
import { BrowserRouter as Router, Link, Route,  Routes, useNavigate, useLocation } from 'react-router-dom';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TextButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LoginForm } from './LoginForm/LoginForm';
import { snackbarToggle } from '../reducers/snackbarSlicer';
import { URL, LANGUAGES } from '../constants/global-constants';
import { resetAdminState, setMenu } from '../reducers/admin-reducers/AdminSlicer';
import { setCurrentPage, setIsLoading } from '../reducers/session/sessionSlice';
import { setCommonFilterData } from '../reducers/parameter/parameterSlice';
import './MainPage.scss';
import { Dialog, Snackbar } from '../style-guide';
import { LoadingSpinner } from './LoadingSpinner';
import { ParameterMenu } from './Menu/ParameterMenu';
import { QSMenu } from './Menu/QSMenu';
import { CrmMenu } from './Menu/CrmMenu';
import AddCrm from './CrmList/AddCrm';
import { CostMenu } from './Menu/CostMenu';
import { DashBoard } from './Menu/DashBoardMenu';
import { CompareQs } from './Menu/CompareQs';
import { Report } from './Menu/Report';
import ShipmentDetails from './Shipment/ShipmentDetails';
import { ManifestMenu } from './Menu/ManifestMenu';
import { IFrame } from './Iframe';
import AddQS from './QSList/AddQS';
import { addAirExportAF, updateLocalCharges, getCRMList } from '../reducers/requestHandler';
export const LandingPage = (hidden) => {
    const { admin, currentPage, isLoading } = useSelector(state => state.session);
    const {bMenu } = useSelector(state => state.admin);
    const dispatch = useDispatch();
    const location = useLocation();

    const { selectedAdmin, selectedQs } = useSelector(state => state.QS);
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (admin.data.session) {
            setIsActive(true);
        }
    //}, [dispatch, failedCallback, admin]);
    }, [admin]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [bLogOut, setBlogOut] = useState(false);
    const open = Boolean(anchorEl);
    const defaultFilterData = {
        id: 0,
        cutomerCrmId: "",
        countryId: "",
        from: "",
        to: "",
        flcType20: "",
        flctype40: "",
        total: "",
        createdDate: 0,
        quoteType1: -1,
        quoteType: [],
        incoId1: -1,
        incoId: [],
        status1: -1,
        status: [],
        userId1: -1,
        userId: [],
        serviceType1: -1,
        serviceType: [],
        currency1: -1,
        currency: [],
    }
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        
        dispatch(setMenu(true));
    };
    const toggleLogOut = event => {
        if (bLogOut) {
            setBlogOut(false);
        } else {
            setBlogOut(true);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
        dispatch(setMenu(false));
    };

    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const onHandleOpenModal = () => {
        setOpenOrderModal(true);
    };
    const StyledMenu = styled(props => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            minWidth: 180,
            color: theme.palette.mode ==='light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                },
            },
        },
    }));
    const IlTime = new Date().toLocaleString("en-EN", { timeZone: 'Israel' }).split(",");
    const NlTime = new Date().toLocaleString("en-EN", { timeZone: 'Europe/Amsterdam' }).split(",");

    const Il_Time = new Date().toLocaleString("en-EN", { timeZone: 'Israel' });
    const Nl_Time = new Date().toLocaleString("en-EN", { timeZone: 'Europe/Amsterdam' });

    const IL_Time = new Date(Il_Time);
    const NL_Time = new Date(Nl_Time);

    const toolbarStyle = {
        minHeight: 50,
        color: 'black',
        width: 'calc(100vw - 300px)',
        justifyContent: 'start',
        float: 'left'
    };
    const [bAdd, setBadd] = useState(false);
    
    const onHandleSetBadd = () => {
        setBadd(!bAdd);
        navigate("/menu/qs")
    };
    const [hasError, setHasError] = new useState(false);
    const logOut = () => {
        setHasError(false);
        dispatch(setIsLoading(true));
        setIsActive(false);
        let loginObj = { "sessionId": admin.data.session, opcode: "logout" }
        if (getCookie("NBO_SESSION_ID") ==="") {
            setIsActive(false);
            return;
        }
        sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
            successCallback: (response) => {
                setCookie("NBO_SESSION_ID", "", 30);
                setHasError(false);
                setIsActive(false);
                // dispatch(resetAdminState(response))
                // dispatch(setAdmin(response))
            },
            failedCallback: error => {
                setHasError(true);
            }
        });
    }
    const iframe = '<iframe src="/swag/swagger-ui/index.html" title="OpenAPI" style="width:100%;height:90vh;"></iframe>';
    return (
        <React.Fragment>
            {openOrderModal && (
                <Dialog open={openOrderModal} title={'Crm'} onHandleCancel={onHandleCancelModal}>
                    <AddCrm callback={() => { } } onHandleCancel={() => setOpenOrderModal(false)} id={null} />
                </Dialog>
            )}
            <LoginForm hidden={isActive} />
            <div hidden={!isActive} >
                {currentPage != 'addQs' && <div className="main-title-strong" />}
                {currentPage!='addQs' &&<AppBar
                    position='static'
                    elevation={0}
                    className='appbar'
                    style={{
                        marginBottom: 0,
                        backgroundColor: 'white',
                        alignItems: 'center',
                        flexDirection: 'start',
                        width: 'calc(100vw - 300px)',
                    }}>

                    {isActive ? (
                        <Toolbar style={toolbarStyle}>
                            <Grid container item lg={6} justifyContent="flex-end">
                                <TextButton sx={{ ml: 1 }} onClick={function (event) { navigate("/menu/dashboard") }} style={{ fontSize: "12px !important", color: 'black', fontWeight: location.pathname === '/menu/dashboard'?'bold':'' }}>
                                Dashboard
                            </TextButton>
                                <TextButton sx={{ ml: 1 }} onClick={function (event) { navigate("/menu/qs") }} style={{ fontSize: "12px !important", color: 'black', fontWeight: location.pathname === '/menu/qs' ? 'bold' : '' }}>
                                QS
                            </TextButton>
                                {admin?.data?.user?.type == 1 && < TextButton sx={{ ml: 1 }} onClick={function (event) { navigate("/menu/cost") }} style={{ fontSize: "12px !important", color: 'black', fontWeight: location.pathname === '/menu/cost' ? 'bold' : '' }}>
                                COST
                            </TextButton>
                            }
                                <TextButton sx={{ ml: 1 }} onClick={function (event) { navigate("/menu/Crm") }} style={{ fontSize: "12px !important", color: 'black', fontWeight: location.pathname === '/menu/Crm' ? 'bold' : '' }}>
                                CRM
                            </TextButton>
                                {admin?.data?.user?.type == 1 && <TextButton sx={{ ml: 1, mr: 2 }} onClick={function (event) { navigate("/menu/parameters") }} style={{ fontSize: "12px !important", color: 'black', fontWeight: location.pathname === '/menu/parameters' ? 'bold' : '' }}>
                                Parameters
                                </TextButton>}
                            </Grid>
                            <Grid container item lg={6} justifyContent="flex-start">
                                {/*<Button variant='contained' sx={{ mr: 2 }} onClick={function () { navigate("/menu/calcAir") }} style={{ borderRadius: '20px', height: "44px", fontSize: "13px !important", textTransform: "none", background: "#00aeef" }}>
                                Calc. Air
                            </Button>
                                <Button variant='contained' sx={{ mr: 2 }} onClick={function () { navigate("/menu/calcLcl") }} style={{ borderRadius: '20px', height: "44px", fontSize: "13px !important", textTransform: "none", background: "#00aeef" }}>
                                Calc. Sea
                                </Button>*/}
                                <Button variant='contained' sx={{ mr: 2 }} onClick={onHandleOpenModal} style={{ borderRadius: '20px', height: "44px", fontSize: "13px !important", textTransform: "none", background: "#00aeef" }}>
                                    CRM ADD
                                </Button>
                                <Button variant='contained' sx={{ mr: 2 }} onClick={function () {
                                    dispatch(setCurrentPage("qs")); navigate("/menu/qs"); window.setTimeout(function () {
                                        //getCRMList(dispatch, admin);

                                        navigate("/addQs")
                                    }, 1)
                                }} style={{ borderRadius: '20px', height: "44px", fontSize: "13px !important", textTransform: "none", background: "#00aeef" }}>
                                + Create New Quote
                                </Button>
                            </Grid>
                        </Toolbar>
                    ) : <Toolbar style={toolbarStyle} />}
                    <div className='logged-in-user-container' >
                        <div className='dropdown'>
                            <button onClick={toggleLogOut} className='dropbtn'>{admin.data.user.adminName}</button>
                            {<div className='dropdown-content'>
                                <span onClick={logOut}>Logout</span>
                            </div>}

                        </div>
                    </div>
                </AppBar>
                }
                <StyledMenu
                    id='demo-customized-menu'
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClick={handleClose}>


                    {admin.data.user.type ===1 && <Link to='/menu/dashboard' onClick={event => dispatch(setCurrentPage("User list"))} style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                        <MenuItem onClick={handleClose}>Dashboard </MenuItem>
                    </Link>}
                    <Link to='/menu/qs' onClick={onHandleSetBadd} style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                        <MenuItem onClick={handleClose}>QS</MenuItem>
                    </Link>
                    <Link to='/addQs'  style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                       
                        <MenuItem onClick={handleClose}>Create Quotation </MenuItem>
                    </Link>
                    
                    <Link to='/menu/cost' style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                        <MenuItem onClick={handleClose}>Cost</MenuItem>
                    </Link>
                    <Link to='/menu/Crm' style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                        <MenuItem onClick={handleClose}>Crm</MenuItem>
                    </Link>
                    <Link to='/menu/parameters' style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                        <MenuItem onClick={handleClose}>Parameters</MenuItem>
                    </Link>
                    {<Link to='/menu/calcAir' style={{ textDecoration: 'none', color: 'inherit', fontSize: 17, background:"#00aeef" }}>
                        <MenuItem onClick={handleClose}>Calc Air</MenuItem>
                    </Link>
                    }
                    {<Link to='/menu/calcLcl' style={{ textDecoration: 'none', color: 'inherit', fontSize: 17, background: "#00aeef" }}>
                        <MenuItem onClick={handleClose}>Calc Sea</MenuItem>
                    </Link>
                    }
                </StyledMenu>
                {isLoading && <LoadingSpinner />}
                {!isLoading&&
                    <Routes>

                        {admin.data.user.type === 1 && <Route exact path='/*' element={<DashBoard />} />}
                        {admin.data.user.type === 2 && <Route exact path='/*' element={<DashBoard />} />}
                        <Route exact path='/menu/parameters' element={<ParameterMenu />} />
                        <Route exact path='/menu/dashboard' element={<DashBoard />} />
                        <Route exact path='/compareQs' element={<CompareQs />} />
                        <Route exact path='/report' element={<Report />} />
                        <Route exact path='/menu/qs' element={<QSMenu />} />
                        <Route exact path='/addQs' element={<AddQS  view={''} onHandleCancel={() => onHandleSetBadd(false)} bOpen={true} />} />
                        <Route exact path='/menu/Crm' element={<CrmMenu />} />
                        <Route exact path='/menu/cost' element={<CostMenu />} />
                        <Route exact path='/menu/calcAir' element={<ShipmentDetails setCostShipmentDetails={function () { }} shipmentDetails={[]} shipmentType={1}/>} />
                        <Route exact path='/menu/calcLcl' element={<ShipmentDetails setCostShipmentDetails={function () { }} shipmentDetails={[]} shipmentType={2}/>} />
                    </Routes>
                }
                
                
            </div>
        </React.Fragment>
    );
}

export default LandingPage;