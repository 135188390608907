import { useState, useCallback, useEffect,useRef  } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table, TableHead, TableContainer, TableBody, TableCell, TableRow, Typography, Grid, Button, Checkbox, FormControlLabel } from '@mui/material';
import { SelectField, Snackbar, TextField, MultilineField } from '../../style-guide';
import { sendRequest, REQUEST_ACTIONS } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';

import ChargesRow from './ChargesRow';
import { FaPlus, FaMinus } from "react-icons/fa";
const Charges = props => {
    const dispatch = useDispatch();
    const { snackbar } = useSelector(state => state.snackbar);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const addForeignCharge = function () {
        
    }
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    function generateString(length) {
        let result = ' ';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }
    useEffect(() => {

    }, [dispatch, failedCallback]);
    return (

            <Grid container item>
                <Grid container item justifyContent='flex-start' lg={3}>
                <Button variant='contained' onClick={props.addChargesRec} style={{ margin: '9px', padding: '5px', minWidth: '0px', height: '25px',marginLeft:12 }} className="charges_details_btn plus_btn">
                    <FaPlus />
                </Button>
                <Button variant='text' sx={{ mt: 2, mb: 2, ml: 1 }} style={{ marginLeft: -8, marginTop: 10, background: "white" }} className="charges_label font_16">
                    { props.heading}
                    </Button>
                </Grid>
                <Grid container item justifyContent='flex-start' lg={12} style={{  marginTop: 0, marginLeft: 0, borderRadius: 5 }}>
                    
                <Grid container item justifyContent='flex-start' lg={12} sx={{ mt: 0 }}>
                   
                    {<TableContainer>
                        <Table style={{ borderCollapse: "separate", borderSpacing: "0 7px" }}>
                            
                                <TableBody>
                                    {
                                    props.productChargesItem.map((detail, index) => (
                                        <ChargesRow productCharges={props.productCharges} heading={props.heading} original={props.original} originalItem={'originalItem'} isFourty={props.isFourty} isTwenty={props.isTwenty} key={props.heading + (detail.index ? detail.index : index)} calculation={props.calculation} shipmentType={props.shipmentType} productChargesItem={props.productChargesItem} data={detail} removeCharge={props.removeCharge} index={detail.index ? detail.index : index} setChargesItem={props.setChargesItem} />
                                        ))

                                    }
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>}

                    </Grid>
                    
                </Grid>
            {snackbar && (
                <Snackbar open={!!snackbar} message={snackbar.message} type={snackbar.type} onClose={onSnackbarHandleClose} />
            )}
            </Grid>
           
    );
};

export default Charges;
