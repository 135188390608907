import { useState } from 'react';
import { TableCell, TableRow, Typography, Box, Button } from '@mui/material';
import AddCountryOfImport from './AddCountryOfImport';
import { Dialog } from '../../style-guide';
import { useSelector, useDispatch } from 'react-redux';
import { addCountryOfImport } from '../Admins/AdminHandler'
import { snackbarToggle } from '../../reducers/snackbarSlicer';

const CountryOfImportRow = ({ data }) => {
    const dispatch = useDispatch();
    const { roles, admin } = useSelector(state => state.admin);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const deleteCountryOfImport = () => {

        var temp = {
            id: data.id,
            descId: data.id
        }
        addCountryOfImport(dispatch, snackbarToggle, temp, admin);

    };
    return (
        <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
            <TableCell />

            <TableCell>
                <Typography variant='h7' >
                    {data.name || '-'}
                </Typography>
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button onClick={deleteCountryOfImport} variant='outlined'>
                        Delete
                    </Button>
                    {openOrderModal && (
                        <Dialog open={openOrderModal} title={'Country Of Import'} onHandleCancel={onHandleCancelModal}>
                            <AddCountryOfImport data={data} onHandleCancel={onHandleCancelModal} id={null} />
                        </Dialog>
                    )}
                </Box>
            </TableCell>
            <TableCell /> 
        </TableRow>
    );
};

export default CountryOfImportRow;
