import { useState, useCallback, useEffect } from 'react';
import { Grid, Button, Checkbox, FormControlLabel} from '@mui/material';
import { SelectField, TextField, MultilineField} from '../../style-guide';
import { sendRequest, REQUEST_ACTIONS } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { setAdmin, onWarehouseChange, onPageChange, onSizeChange } from '../../reducers/admin-reducers/AdminSlicer';
import { getCRMList } from '../../reducers/requestHandler';
import { Snackbar } from '../../style-guide';
import imgHide from './hide.png'
import imgShow from './show.png'
import { URL, LANGUAGES, parcelSortBy, parcelStatusList, paginationArr } from '../../constants/global-constants';
import { addDesc, getUserInfoByLockerId, getDiscriptionsList, createShippingRequest, getParcelsList} from '../Admins/AdminHandler'

const AddCost = props => {
    const dispatch = useDispatch();
    const { crmList } = useSelector(state => state.crm);
    const { roles, paginationData, warehouseList, senderList, discriptionsList} = useSelector(state => state.admin);
    const { admin} = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const [userData, setUserData] = useState(null);
    const [bNextPage, setNextPage] = useState(false);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                importExportType: props.data.importExportType,
                crmId: props.data.crmId,
                countryId: props.data.countryId,
                portAid: props.data.portAid,
                portLid: props.data.portLid,
                rateList: props.data.rateList,
                weightList: props.data.weightList,
                tt: props.data.tt,
                clAgent: props.data.clAgent,
                min: props.data.min,
                zip: props.data.zip,
                validDate: props.data.validDate,
                createTime: props.data.createTime,
                updateTime: props.data.updateTime,
                fromId: props.data.fromId,
                toId: props.data.toId,
                airLineId: props.data.airLineId,
                airportId: props.data.airportId,
                zip: props.data.zip,
                weightList: props.data.weightList,
                countryId: props.data.countryId,
                agentCrmId: props.data.agentCrmId,
                shippingCrmId: props.data.shippingCrmId,
                countryId: props.data.countryId,
                ofList: props.data.ofList,
                pssList: props.data.pssList,
                conList: props.data.conList,
                bafList: props.data.bafList,
                gpsList: props.data.gpsList,
                cnlList: props.data.cnlList,
                enList: props.data.enList,
                isList: props.data.isList,
                lrList: props.data.lrList,
                wrsList: props.data.wrsList,
                ieiList: props.data.ieiList,
                ebsList: props.data.ebsList,
                scsList: props.data.scsList,
                obsList: props.data.obsList,
                eisList: props.data.eisList,
                esList: props.data.esList,
                vispsList: props.data.vispsList,
                lsaList: props.data.lsaList,
                rateList: props.data.rateList,
                rateList: props.data.rateList,
            }
            : {
                id: null,
                importExportType: null,
                crmId: null,
                countryId: null,
                portAid: null,
                portLid: null,
                rateList: null,
                weightList: null,
                tt: null,
                clAgent: null,
                min: null,
                zip: null,
                validDate: null,
                createTime: null,
                updateTime: null,
                fromId: null,
                toId: null,
                airLineId: null,
                airportId: null,
                zip: null,
                weightList: null,
                countryId: null,
                agentCrmId: null,
                shippingCrmId: null,
                countryId: null,
                ofList: null,
                pssList: null,
                conList: null,
                bafList: null,
                gpsList: null,
                cnlList: null,
                enList: null,
                isList: null,
                lrList: null,
                wrsList: null,
                ieiList: null,
                ebsList: null,
                scsList: null,
                obsList: null,
                eisList: null,
                esList: null,
                vispsList: null,
                lsaList: null,
                rateList: null,
                rateList: null,
            },
    );
    const save = () => {
       

    };
    const [isLoaded, setIsLoaded] = useState(false);
    
    useEffect(() => {
        if (!isLoaded && admin.data.session && crmList && crmList.length == 0) {
            //getCRMList(dispatch, admin);
            setIsLoaded(true);
        }
    }, [dispatch, failedCallback, warehouseList]);

    const getUserInfoByToken = (e) => {
       
    }
    const keyPressed = (e) => {
        
    }

    const senderNameOptions = senderList.map(sender => ({
        id: sender.id,
        name: sender.senderName + ", " + sender.senderAddr + ", " + sender.senderCountry
    }));
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const getCRMsByFunctionTypes = function (functionId) {
        var temp = []
        for (var i = 0; i < crmList.length; i++) {
            if (crmList[i].functionId == functionId) {
                temp.push(crmList[i])
            }
        }
        return temp;
    }
    return (
        <div>
            <Grid container item spacing={2}>
                <Grid container item spacing={2}>
                    {false && <TextField
                        required
                        lg={11}
                        value={value.token}
                        onChange={event => setValue(prev => ({ ...prev, token: event.target.value }))}
                        label='Locker Id'
                        readOnly={userData?true:false}
                        onKeyDown={e => keyPressed(e)}
                    />}
                    <h1> Cost form will be here.</h1>
                    
                    
                </Grid>
            </Grid>
            {snackbar && (
                <Snackbar open={!!snackbar} message={snackbar.message} type={snackbar.type} onClose={onSnackbarHandleClose} />
            )}
        </div>
    );
};

export default AddCost;
