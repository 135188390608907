import { createSlice } from '@reduxjs/toolkit';

export const parameterSlice = createSlice({
    name: 'parameter',
    initialState: {
        crmList: [],
        listOfAirExportAFs: [],
        listOfLclExportImportAFs: [],
        listOfFclExportImportAFs: [],
        qsTypeList: [],
        qsStatusList: [],
        listOfServiceType: [],
        listOfAirLine: [],
        listOfRejectReasions: [],
        listOfShipmentTypes: [],
        listOfIncoterms: [],
        listOfChargesTypes: [],
        listOfProductCharges: [],
        headerAirImport: [],
        footerAirImport: [],
        headerAirExport: [],
        footerAirExport: [],
        headerLclImport: [],
        footerLclImport: [],
        headerLclExport: [],
        footerLclExport: [],
        headerFclImport: [],
        footerFclImport: [],
        headerFclExport: [],
        footerFclExport: [],
        listOfMeasurementUnits: [],
        countryList: [],
        listOfFunctionTypes: [],
        listOfAgentNetwork: [],
        portList: [],
        crmListFilter: [],
        listOfFLCTypes20: [],
        listOfCountryAndOrigins: [],
        listOfFLCTypes40: [],
        listOfAirPort: [],
        listOfKgFactor: [],
        listOfExchangeRates: [],
        listOfProductCategories: [],
        listOfPort: [],
        adminList: [],
        listOfcurrency: [],
        listOfAirImportExport: [],
        listOfLclImportExport: [],
        listOfFclImportExport: [],
        productForeignChargesItem: [],
        productForeignChargesItemNew: [],
        selectedCrm: {},
        commonFilterData:
        {
            id: 0,
            cutomerCrmId: "",
            countryId: "-1",
            from: "All",
            to: "All",
            margin: "",
            originalPrice: "",
            flcType20: "",
            flctype40: "",
            total: "",
            createdDate: 0,
            quoteType1: -1,
            quoteType: [],
            incoId1: -1,
            incoId: [],
            status1: -1,
            status: [],
            userId1: -1,
            userId: [],
            serviceType1: -1,
            serviceType: [],
            currency1: -1,
            currency: [],
        }
        ,
    },
    reducers: {
        setSelectedCrm: (state, { payload }) => {
            state.selectedCrm = payload
        },
        setCommonFilterData: (state, { payload }) => {
            state.commonFilterData = payload
        },
        setCrmListByFilter: (state, { payload }) => {
            state.crmListFilter = []
            state.crmListFilter = payload;
        },
        onCrmChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.crmList.length; i++) {
                if (payload.id === state.crmList[i].id) {
                    bUpdated = true;
                    state.crmList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.crmList.push(payload);
            }
        },
        onCountryChange: (state, { payload }) => {

            var bUpdated = false;
            for (var i = 0; i < state.countryList.length; i++) {
                if (payload.id === state.countryList[i].id) {
                    bUpdated = true;
                    state.countryList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.countryList.push(payload);
            }
            state.countryList.sort(function (a, b) {
                if (a.name?.toLowerCase() < b.name?.toLowerCase()) { return -1; }
                if (a.name?.toLowerCase() > b.name?.toLowerCase()) { return 1; }
                return 0;
            })
        },
        onAirPortChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.listOfAirPort.length; i++) {
                if (payload.id === state.listOfAirPort[i].id) {
                    bUpdated = true;
                    state.listOfAirPort[i] = payload;
                }
            }
            if (!bUpdated) {
                state.listOfAirPort.push(payload);
            }

            state.listOfAirPort.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            })/**/

        },
        onPortChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.listOfPort.length; i++) {
                if (payload.id === state.listOfPort[i].id) {
                    bUpdated = true;
                    state.listOfPort[i] = payload;
                }
            }
            if (!bUpdated) {
                state.listOfPort.push(payload);
            }
        },
        onAdminChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.adminList.length; i++) {
                if (payload.id === state.adminList[i].id) {
                    bUpdated = true;
                    state.adminList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.adminList.push(payload);
            }
        },
        setQsTypeList: (state, { payload }) => {
            state.qsTypeList = payload
        },
        setQsStatusList: (state, { payload }) => {
            state.qsStatusList = payload
        },
        setListOfIncoterms: (state, { payload }) => {
            state.listOfIncoterms = payload
        },
        setAirImportExport: (state, { payload }) => {
            state.listOfAirImportExport = payload
        },
        setAirExportAFs: (state, { payload }) => {
            state.listOfAirExportAFs = payload
        },
        setLclExportImportAFs: (state, { payload }) => {
            state.listOfLclExportImportAFs = payload
        },
        setFclExportImportAFs: (state, { payload }) => {
            state.listOfFclExportImportAFs = payload
        },
        setFclImportExport: (state, { payload }) => {
            state.listOfFclImportExport = payload
        },
        setLclImportExport: (state, { payload }) => {
            state.listOfLclImportExport = payload
        },
        setListOfServiceType: (state, { payload }) => {
            state.listOfServiceType = payload
        },
        setListOfExchangeRates: (state, { payload }) => {
            state.listOfExchangeRates = payload
        },
        setListOfcurrency: (state, { payload }) => {
            state.listOfcurrency = payload
        },
        setListOfKgFactor: (state, { payload }) => {
            state.listOfKgFactor = payload
        },
        setListOfProductCharges: (state, { payload }) => {
            state.listOfProductCharges = payload
        },
        setHeaderAirImport: (state, { payload }) => {
            state.headerAirImport = payload
        },
        setFooterAirImport: (state, { payload }) => {
            state.footerAirImport = payload
        },
        setHeaderAirExport: (state, { payload }) => {
            state.headerAirExport = payload
        },
        setFooterAirExport: (state, { payload }) => {
            state.footerAirExport = payload
        },
        setHeaderLclImport: (state, { payload }) => {
            state.headerLclImport = payload
        },
        setFooterLclImport: (state, { payload }) => {
            state.footerLclImport = payload
        },
        setHeaderLclExport: (state, { payload }) => {
            state.headerLclExport = payload
        },
        setFooterLclExport: (state, { payload }) => {
            state.footerLclExport = payload
        },
        setHeaderFclImport: (state, { payload }) => {
            state.headerFclImport = payload
        },
        setFooterFclImport: (state, { payload }) => {
            state.footerFclImport = payload
        },
        setHeaderFclExport: (state, { payload }) => {
            state.headerFclExport = payload
        },
        setFooterFclExport: (state, { payload }) => {
            state.footerFclExport = payload
        },
        setListOfProductCategories: (state, { payload }) => {
            state.listOfProductCategories = payload
        },
        setListOfShipmentTypes: (state, { payload }) => {
            state.listOfShipmentTypes = payload
        },
        setAgentNetwork: (state, { payload }) => {
            state.listOfAgentNetwork = payload
        },
        setProductForeignChargesItem: (state, { payload }) => {
            state.productForeignChargesItem = payload
        },
        setProductForeignChargesItemNew: (state, { payload }) => {
            var bAdd = false;
            for (var i = 0; i < state.productForeignChargesItemNew.length; i++) {
                if (state.productForeignChargesItemNew[i]?.selectedItem?.id === payload.selectedItem?.id) {
                    bAdd = true;
                    state.productForeignChargesItemNew[i] = payload;
                    break;
                }
            }
            if (!bAdd) {
                state.productForeignChargesItemNew.push(payload)
            }


           // state.productForeignChargesItemNew = payload
        },
       emptyProductForeignChargesItemNew: (state, { payload }) => {
            state.productForeignChargesItemNew = []
        },
        setFunctionTypes: (state, { payload }) => {
            state.listOfFunctionTypes = payload
        },
        setChargesTypes: (state, { payload }) => {
            state.listOfChargesTypes = payload
        },
        setMeasurementUnits: (state, { payload }) => {
            state.listOfMeasurementUnits = payload
        },
        setFLCTypes20: (state, { payload }) => {
            state.listOfFLCTypes20 = payload
        },
        setFLCTypes40: (state, { payload }) => {
            state.listOfFLCTypes40 = payload
        },
        setCountryAndOrigins: (state, { payload }) => {
            state.countryList = payload;
            state.countryList.sort(function (a, b) {
                if (a.name?.toLowerCase() < b.name?.toLowerCase()) { return -1; }
                if (a.name?.toLowerCase() > b.name?.toLowerCase()) { return 1; }
                return 0;
            })
        },
        setPorts: (state, { payload }) => {
            state.listOfPort = payload
            state.listOfPort.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            })
        },
        setAirPorts: (state, { payload }) => {
            state.listOfAirPort = payload;
            state.listOfAirPort.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            })
        },
        setAirLines: (state, { payload }) => {
            state.listOfAirLine = payload
            state.listOfAirLine.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            })
        },
        setRejectReasions: (state, { payload }) => {
            state.listOfRejectReasions = payload
        },

    },
});
// Action creators are generated for each case reducer function
export const { setSelectedCrm, onCountryChange, setCommonFilterData, emptyProductForeignChargesItemNew, onAirPortChange, setProductForeignChargesItemNew, setProductForeignChargesItem, setFclExportImportAFs, setLclExportImportAFs, setAirExportAFs, setLclImportExport, setFclImportExport, setAirImportExport, setCrmListByFilter, setAirPorts, setAgentNetwork, setFunctionTypes, setAirLines, setRejectReasions, setPorts, setFLCTypes40, setFLCTypes20, onPortChange, setCountryAndOrigins, setMeasurementUnits, setListOfShipmentTypes, setChargesTypes, setFooterAirExport, setHeaderAirExport, setFooterAirImport, setHeaderAirImport, setFooterLclExport, setHeaderLclExport, setFooterLclImport, setHeaderLclImport, setFooterFclExport, setHeaderFclExport, setFooterFclImport, setHeaderFclImport, setListOfProductCharges, setListOfProductCategories, setListOfKgFactor, setListOfServiceType, setListOfcurrency, setQsTypeList, setListOfExchangeRates, setQsStatusList, setListOfIncoterms } = parameterSlice.actions;

export default parameterSlice.reducer;
