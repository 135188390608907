import { useState, useCallback, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { SelectField, TextField, MultilineField } from '../../style-guide';
import { sendRequest, REQUEST_ACTIONS } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { setAdmin, onWarehouseChange } from '../../reducers/admin-reducers/AdminSlicer';
import { Snackbar } from '../../style-guide';
import imgHide from './hide.png'
import imgShow from './show.png'
import { URL, LANGUAGES, sites } from '../../constants/global-constants';
import { addAdmin } from './AdminHandler'
import { FaEye, FaEyeSlash } from "react-icons/fa";
const AddAdmin = props => {
    const dispatch = useDispatch();
    const { roles, warehouseList } = useSelector(state => state.admin);
    const { admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                sessionId: admin.data.session,
                adminName: props.data.adminName,
                password: props.data.password,
                email: props.data.email,
                firstName: props.data.firstName,
                lastName: props.data.lastName,
                phone: props.data.phone,
                type: props.data.type,
                permissions: props.data.permissions,
                site: props.data.permissions.site
            }
            : {
                id: null,
                sessionId: null,
                adminName: null,
                password: null,
                email: null,
                firstName: null,
                lastName: null,
                phone: null,
                type: null,
                changePassword: false,
                permissions: {

                },
            },
    );

    const save = () => {
        var t = JSON.parse(JSON.stringify(value));
        t.permissions.site = value.site;
        t.permissions.pass = value.password;
        addAdmin(dispatch, snackbarToggle, t, admin);
        props.onHandleCancel();

    };

    const seePassword = () => {
        setValue(prev => ({ ...prev, seePass: !value.seePass }))
    }

    const update = () => {
        var t = JSON.parse(JSON.stringify(value));
        t.permissions.site = value.site;
        t.permissions.pass = value.password;
        addAdmin(dispatch, snackbarToggle, t, admin);
        props.onHandleCancel();
    };
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        if (!isLoaded) {
            //getWarehouseList();
            // getPackages();
        }
    }, [dispatch, failedCallback, warehouseList]);

    const setPermissionsData = (event) => {
        var data = {
            warehouseId: event.target.value
        }
        setValue(prev => ({ ...prev, permissions: data }));
    }

    return (
        <div>
            <Grid container item spacing={2}>
                <Grid container item spacing={2}>
                    <TextField
                        required
                        lg={11}
                        readOnly={value.id ? true : false}
                        value={value.adminName}
                        onChange={event => setValue(prev => ({ ...prev, adminName: event.target.value }))}
                        label='Name'
                    />

                    <TextField
                        required
                        lg={5.5}
                        label='First Name'
                        value={value.firstName}
                        onChange={event => setValue(prev => ({ ...prev, firstName: event.target.value }))}
                    />
                    <TextField
                        required
                        lg={5.5}
                        label='Last Name'
                        value={value.lastName}
                        onChange={event => setValue(prev => ({ ...prev, lastName: event.target.value }))}
                    />
                    <TextField
                        required
                        lg={5.5}
                        label='Email'
                        value={value.email}
                        onChange={event => setValue(prev => ({ ...prev, email: event.target.value }))}
                    />
                    <TextField
                        required
                        lg={5.5}
                        label='Phone'
                        value={value.phone}
                        onChange={event => setValue(prev => ({ ...prev, phone: event.target.value }))}
                    />
                    {(!value.id || value.changePassword) && <Grid container item lg={ 5.5}> <TextField
                        required
                        lg={10.5}
                        value={value.password}
                        label='Password'
                        type={value.bShow?"text":"password"}
                        onChange={event => setValue(prev => ({ ...prev, password: event.target.value }))}
                    />
                    <Button variant='text' onClick={() => {

                        setValue(prev => ({ ...prev, bShow: !value.bShow }))
                        }}>
                            {value.bShow ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                    </Grid>}
                    {value.id && !value.changePassword && <TextField
                        required
                        lg={5.5}
                        value={value.permissions?.pass }
                        label='Password'
                        readOnly={admin?.data?.user?.type==1 ? false : true}
                        onChange={event => {
                            setValue(prev => ({ ...prev, permissions: { ...value.permissions, pass: event.target.value } }))
                            setValue(prev => ({ ...prev, password: event.target.value }))
                        }}
                    />}
                    <SelectField
                        required
                        value={value.type}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, type: event.target.value }))}
                        label='Type'
                        options={roles}
                    />
                    <SelectField
                        required
                        value={value.site}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, site: event.target.value }))}
                        label='Site'
                        options={sites}
                    />
                    {value.type ===2 && false&& <SelectField
                        required
                        value={value.permissions.warehouseId}
                        lg={5.5}
                        onChange={event => setPermissionsData(event)}
                        label='Warehouse'
                        options={warehouseList}
                    />}
                </Grid>
                <Grid container item justifyContent='flex-end' lg={11}>
                    {value.type==1 && false&& <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={() => {

                        setValue(prev => ({ ...prev, password: value.permissions?.pass }))
                        setValue(prev => ({ ...prev, changePassword: !value.changePassword }))
                    }}>
                        {!value.changePassword ? 'Change' : 'Un-Change'} Password
                    </Button>}
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={props.data?.id ? update : save}>
                        Save
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={props.onHandleCancel}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            {snackbar && (
                <Snackbar open={!!snackbar} message={snackbar.message} type={snackbar.type} onClose={onSnackbarHandleClose} />
            )}
        </div>
    );
};

export default AddAdmin;
