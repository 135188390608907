import { createSlice } from '@reduxjs/toolkit';

export const qsSlice = createSlice({
    name: 'QS',
    initialState: {
        selectedAdmin: {},
        selectedQs: {},
        qsList: [],
        currentPage: "",
    },
    reducers: {  
        setSelectedQs: (state, { payload }) => {
            state.selectedQs = payload
        },  
        setSelectedAdmin: (state, { payload }) => {
            state.selectedAdmin = payload
        },
        onEmptyQsChange: (state, { payload }) => {
            state.qsList = payload
        },
        onQsChange: (state, { payload }) => {
            var bUpdated = false;            
            for (var i = 0; i < state.qsList.length; i++) {
              // state.qsList[i].checked = false;
                if (payload.id === state.qsList[i].id) {
                    bUpdated = true;
                    
                    state.qsList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.qsList.push(payload);
            }
        },

    },
});
// Action creators are generated for each case reducer function
export const { setSelectedAdmin, setSelectedQs, onQsChange, onEmptyQsChange} = qsSlice.actions;

export default qsSlice.reducer;
