import { useState, useEffect, useRef } from 'react';
import { SelectField, TextField, MultilineField } from '../../style-guide';

import {  FaMinus } from "react-icons/fa";
import { FormGroup, TableCell, TableRow, Typography, Box, Button, Checkbox, Radio, FormControlLabel } from '@mui/material';
export const ShipmentDetailsRow = props => {
    const ref_input2 = useRef();
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                pieces: props.data.pieces,
                length: props.data.length,
                width: props.data.width,
                height: props.data.height,
                weight: props.data.weight,
            }
            : {
                id: null,
                pieces: null,
                length: null,
                width: null,
                height: null,
                weight: null,
            },
    );
    const handleParams = (event) => {
        setValue(prev => ({ ...prev, pieces: event.target.value }))
        //window.setTimeout(function () { ref_input2.current.focus() },1)
        // setValue(prev => ({ ...prev, params: t }))
       // setDetailsPieces({ ...value, pieces: event.target.value })
    }
    const setDetailsPieces = (value) => {
        props.setDetails(props.index, value)
    }
    const setDetails = (value,id) => {
        props.setDetails(props.index, value)
        /*window.setTimeout(function () {
            document.getElementById(id)?.focus();
        }, 1000)*/
    }
    const setDetails1 = (weight) => {
        if (value.pieces && value.length && value.width && value.height && weight) {
            var w = { ...value, weight: weight }
            props.setDetails(props.index, w)
        }
            
    }
    return (
        <TableRow sx={{ '& > *': { borderBottom: 'unset',padding: '4px' } }} >
            <TableCell>
                <Typography variant='h6'>

                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='h6'>
                    {props.index > 0 ? <Button disabled={props.index == 0} variant='contained' onClick={function () { props.removeShipmentRec(props.index) }} style={{ margin: '9px', padding: '5px', minWidth: 'none', height: '25px', marginLeft: -2.5 }} className="charges_details_btn minus_btn">
                        <FaMinus />
                    </Button> : <Button  variant='text' onClick={function () {  }} style={{ margin: '9px', padding: '5px', minWidth: '0px', height: '25px', }}>
                        
                    </Button>
                    }
                </Typography>
            </TableCell>
            <TableCell>
                <TextField
                    required
                    lg={12}
                    md={12}
                    sm={12}
                    value={value.pieces}
                    onChange={(event) => {
                        handleParams(event)
                        
                    }}
                    onBlur={(event) => {
                        setDetails(value, "L(cm)_" + props.index)
                    }}
                    label='Pieces'
                />
            </TableCell>
            <TableCell>
                <TextField
                    required={!props.manualWt}
                    lg={12}
                    md={12}
                    sm={12}
                    value={value.length}
                    onChange={event => {
                        setValue(prev => ({ ...prev, length: event.target.value }));
                        //setDetails({ ...value, length: event.target.value })
                    }}
                    onBlur={(event) => {
                        setDetails(value, "W(cm)_" + props.index)
                    }}
                    label='L(cm)'
                    id={"L(cm)_" + props.index}
                />
            </TableCell>
            <TableCell>
                <TextField
                    required={!props.manualWt}
                    lg={12}
                    md={12}
                    sm={12}
                    value={value.width}
                    onChange={event => {
                        setValue(prev => ({ ...prev, width: event.target.value }))
                      //  setDetails({ ...value, width: event.target.value })
                    }}
                    onBlur={(event) => {
                        setDetails(value, "H(cm)_" + props.index)
                    }}
                   // onKeyUp={e => props.setDetails(props.index, value)}
                    label='W(cm)'
                    id={"W(cm)_" + props.index}
                />
            </TableCell>
            <TableCell>
                <TextField
                    required={!props.manualWt}
                    lg={12}
                    md={12}
                    sm={12}
                    value={value.height}
                    onChange={event => {
                        setValue(prev => ({ ...prev, height: event.target.value }))
                        //setDetails({ ...value, height: event.target.value })
                    }}
                    onBlur={(event) => {
                        setDetails(value, "WPP_" + props.index)
                    }}
                    label='H(cm)'
                    id={"H(cm)_" + props.index}
                />
            </TableCell>
            <TableCell>

                <TextField
                    required={!props.manualWt}
                    lg={12}
                    md={12}
                    sm={12}
                    value={value.weight}
                    onChange={event => {
                        setValue(prev => ({ ...prev, weight: event.target.value }))
                       // setDetails({ ...value, weight: event.target.value })
                       // setDetails1(event.target.value)
                        //setDetails()
                    }}
                    onBlur={(event) => {
                        setDetails(value, "0" + props.index)
                    }}
                    label='Weight per piece (kg)'
                    id={"WPP_" + props.index}
                />
            </TableCell>
            <TableCell style={{ border: 'none' }}>
                <TextField
                    lg={12}
                    md={12}
                    sm={12}
                    value={(value.weight * value.pieces) ? value.weight * value.pieces:0}
                    disabled={true}
                    label='Total Weight (kg)'
                />
            </TableCell>
            <TableCell>
                <TextField
                    lg={12}
                    md={12}
                    sm={12}
                    value={(value.pieces && value.length && value.width && value.height) ? Number(Number(value.pieces * (value.length * value.width * value.height) / 1000000).toFixed(2)) : 0}
                    disabled={true}
                    label='Total Volume (m3)'
                />
            </TableCell>

            </TableRow>
    );
};

export default ShipmentDetailsRow;
