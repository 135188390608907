import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, FormControl, InputLabel, MenuItem, FormGroup, Grid , TableCell, TableRow, Typography, Box, Button, Checkbox, Radio, FormControlLabel } from '@mui/material';

import { SelectField, TextField, MultilineField, SearchSelectField } from '../../style-guide';
const AirImport = props => {
    const ref_input2 = useRef();
    const { listOfFunctionTypes, listOfAgentNetwork, listOfProductCategories, listOfShipmentTypes, listOfMeasurementUnits, listOfcurrency, listOfKgFactor, listOfAirLine, listOfLclExportImportAFs, listOfAirExportAFs, countryList, qsStatusList, listOfServiceType, listOfAirPort, listOfPort, listOfIncoterms, listOfFLCTypes40, listOfFLCTypes20 } = useSelector(state => state.parameter);

    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                name: props.data.name,
                units: props.data.units,
                unitOfMeasure: props.data.unitOfMeasure,
                currency: props.data.currency,
                currencies: props.data.currencies,
                rate: props.data.rate,
                rate20: props.data.rate20,
                rate40: props.data.rate40,
                min: props.data.min,
                remarks: props.data.remarks,
            }
            : {
                id: null,
                units: null,
                name: null,
                unitOfMeasure: null,
                currency: null,
                currencies: null,
                min: null,
                rate: null,
                remarks: null,
            },
    );
    const handleParams = (event) => {
        setValue(prev => ({ ...prev, pieces: event.target.value }))
        window.setTimeout(function () { ref_input2.current.focus() }, 1)
        // setValue(prev => ({ ...prev, params: t }))
    }
    const setDetails = (event) => {
        if (value.pieces && value.length && value.width && value.height && value.weight)
            props.setDetails(props.index, value)
    }
    useEffect(() => {
        //props.setDetails(props.index,value)
    }, [value]);
    return (
        <TableRow  sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell >
                <Typography variant='h7'>
                    { value.name}
                </Typography>
            </TableCell>
            <TableCell >
                <Grid item lg={12}>
                    <FormControl fullWidth>
                        <InputLabel sx={{ top: -6, maxWidth: 'calc(100% - 40px)' }}>
                            {''}
                        </InputLabel>
                        <Select
                            value={value.unitOfMeasure || ''}
                            label={''}
                           // disabled={true}
                            onChange={function (event) {
                                setValue(prev => ({ ...prev, unitOfMeasure: event.target.value }))
                                var t = [...props.crmAirImports]; t[props.index].unitOfMeasure = event.target.value; props.setCrmAirImports(t)
                            }}
                            size='small'
                        >
                            {value.units?.map(option => (
                                <MenuItem key={option} value={option}>
                                    {listOfMeasurementUnits.find(u => u.id === option)?.lable || '-'}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </TableCell>
            <TableCell >
                <Grid item lg={12}>
                    <FormControl fullWidth>
                        <InputLabel sx={{ top: -6, maxWidth: 'calc(100% - 40px)' }}>
                            {''}
                        </InputLabel>
                        <Select
                            value={value.currency || ''}
                            label={''}
                            onChange={function (event) {
                                setValue(prev => ({ ...prev, currency: event.target.value }))
                                var t = [...props.crmAirImports]; t[props.index].currency = event.target.value; props.setCrmAirImports(t)
                            }}
                            size='small'
                        >
                            {value.currencies?.map(option => (
                                <MenuItem key={option} value={option}>
                                    {listOfcurrency.find(u => u.id === option)?.lable || '-'}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </TableCell>
            {!props.fcl && <TableCell >
                <TextField
                    lg={12}
                    md={12}
                    sm={12}
                    value={value.rate}
                    label=''
                    onChange={function (event) { setValue(prev => ({ ...prev, rate: event.target.value })) }}
                    onBlur={function (event) { var t = [...props.crmAirImports]; t[props.index].rate = event.target.value; props.setCrmAirImports(t) }}
                    type="number" onwheel="return false"
                />
            </TableCell>}
            {props.fcl && <TableCell >
                <TextField
                    lg={12}
                    md={12}
                    sm={12}
                    value={value.rate20}
                    label=''
                    onChange={function (event) { setValue(prev => ({ ...prev, rate20: event.target.value })) }}
                    onBlur={function (event) { var t = [...props.crmAirImports]; t[props.index].rate20 = event.target.value; props.setCrmAirImports(t) }}
                    type="number" onwheel="return false"
                />
            </TableCell>}
            {props.fcl && <TableCell >
                <TextField
                    lg={12}
                    md={12}
                    sm={12}
                    value={value.rate40}
                    label=''
                    onChange={function (event) { setValue(prev => ({ ...prev, rate40: event.target.value })) }}
                    onBlur={function (event) { var t = [...props.crmAirImports]; t[props.index].rate40 = event.target.value; props.setCrmAirImports(t) }}
                    type="number" onwheel="return false"
                />
            </TableCell>}
            {!props.fcl && <TableCell >
                <TextField
                    lg={12}
                    md={12}
                    sm={12}
                    value={value.min}
                    label=''
                    type="number" onwheel="return false"
                    onChange={function (event) { setValue(prev => ({ ...prev, min: event.target.value })) }}
                    onBlur={function (event) { var t = [...props.crmAirImports]; t[props.index].min = event.target.value; props.setCrmAirImports(t) }}
                />
            </TableCell>}
            <TableCell>
                <TextField
                    lg={12}
                    md={12}
                    sm={12}
                    value={value.remarks}
                    label=''
                    onChange={function (event) { setValue(prev => ({ ...prev, remarks: event.target.value })) }}
                    onBlur={function (event) { var t = [...props.crmAirImports]; t[props.index].remarks = event.target.value; props.setCrmAirImports(t) }}
                    />
            </TableCell>
        </TableRow>
    );
};

export default AirImport;
