import { createSlice } from '@reduxjs/toolkit';
export const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        admin: {
            data: {
                session: "",
                user: {
                    adminName: "",
                    email: "",
                    firstName: "",
                    id: 0,
                    lastName: "",
                    nickName: "",
                    password: "",
                    permissions: {},
                    phone: "",
                    status: 1,
                    type: 1
                }
            }
        },
        currentPage:"",
        isLoading:true,
    },
    reducers: {
        

        setCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
        },
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setAdmin: (state, { payload }) => {
            state.admin.data = payload;
        },
        setAdminUser: (state, { payload }) => {
            state.admin.data.user = payload.adminData;
            state.admin.data.session = payload.sessionId;
        }
        
    },
});
// Action creators are generated for each case reducer function
export const { setAdmin, setCurrentPage, setAdminUser, setIsLoading } =
    sessionSlice.actions;

export default sessionSlice.reducer;
