import { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table, TableBody, TableContainer, TableCell, TableRow, Typography, Select, Grid, FormControl, InputLabel, Box, MenuItem, Button, Checkbox, FormControlLabel } from '@mui/material';
import { SelectField, Snackbar, TextField, MultilineField, DateTimePicker } from '../../../style-guide';
import { sendRequest, REQUEST_ACTIONS } from '../../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../../reducers/snackbarSlicer';
import { setAdmin, onWarehouseChange, onPageChange, onSizeChange } from '../../../reducers/admin-reducers/AdminSlicer';
import imgHide from '../hide.png'
import imgShow from '../show.png'
import { addAirExportAF, addLclExportImportAF, updateLocalCharges, getCRMList } from '../../../reducers/requestHandler';
import { lclCostFrieghtExportCharges, lclCostFrieghtImportCharges, listOfCostWeight, listOfCostLandWeightLcl, lclCostLocalExportCharges, lclCostLocalImportCharges } from '../../../constants/global-constants';
import { addDesc, getUserInfoByLockerId, getDiscriptionsList, createShippingRequest, getParcelsList } from '../../Admins/AdminHandler'

import RowSelectOption from './RowSelectOption';
const AddLclCost = props => {
    const dispatch = useDispatch();
    const { crmList } = useSelector(state => state.crm);
    const { countryList, listOfAirPort, listOfMeasurementUnits, listOfAirLine, listOfcurrency, listOfPort, qsTypeList, qsStatusList, listOfIncoterms, listOfServiceType } = useSelector(state => state.parameter);
    const { roles, paginationData, warehouseList, senderList, discriptionsList } = useSelector(state => state.admin);
    const { admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const [userData, setUserData] = useState(null);
    const [bNextPage, setNextPage] = useState(false);
    const [listOfWeight, setListOfWeight] = useState(JSON.parse(JSON.stringify(listOfCostLandWeightLcl)));
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const [bFrieghtCharges, setBFrieghtCharges] = useState(true);
    const [bLocalCharges, setBLocalCharges] = useState(false);
    const [selectFreightId, setselectFreightId] = useState(1);
    const [selectLocalId, setselectLocalId] = useState(1);
    const [frieghtChargesTemp, setFrieghtChargesTemp] = useState([]);
    const [chargesTemp, setChargesTemp] = useState([]);
    const resetTempFrieght = function (t) {
        var t1 = [];
        for (var i = 0; i < t.length; i++) {
            if (!Number(t[i].rate)) {
                var tObj = JSON.parse(JSON.stringify(t[i]))
                tObj.id = i + 1;
                tObj.currency = listOfcurrency.find(u => u.id === tObj.currency)?.id
                t1.push(tObj)
            }
        }
        setFrieghtChargesTemp(t1)
        if (t1.length)
        setselectFreightId(t1[0].id)
    }
    const resetTempLocal = function (t) {
        var t1 = [];
        for (var i = 0; i < t.length; i++) {
            if (!Number(t[i].rate)) {
                var tObj = JSON.parse(JSON.stringify(t[i]))
                console.log(tObj)
                tObj.id = i + 1;
                tObj.currency = listOfcurrency.find(u => u.id === tObj.currency)?.id
                t1.push(tObj)
            }
        }

        console.log(t1)
        setChargesTemp(t1)
        if (t1.length)
            setselectLocalId(t1[0].id)
    }
    const hideAllCharges = function () {
        setBLocalCharges(false)
        setBFrieghtCharges(false)
    }
    const [value, setValue] = useState(
        props?.data
            ? {
                id: props?.data?.id,
                clAgent: props?.data?.clAgent,
                countryId: props?.data?.countryId,
                createTime: props?.data?.createTime,
                crmId: props?.isAddFromQsCostTable ? null : props?.data?.crmId,
                importExportType: props?.data?.importExportType,
                min: props?.isAddFromQsCostTable ? null : props?.data?.min,
                portAid: props?.data?.portAid,
                portLid: props?.data?.portLid,
                rateList: props?.data?.rateList,
                rate: props?.isAddFromQsCostTable ? null : props?.data?.rateList[0],
                tt: props?.data?.tt,
                updateTime: props?.data?.updateTime,
                validDate: props?.data?.validDate,
                validfrom: props?.data?.validfrom,
                weightList: props?.data?.weightList,
                weight: null,
                zip: props?.data?.zip,
                product: props?.data?.product,
                unitOfMeasure: props?.data?.unitOfMeasure,
                currency: props?.data?.currency,
                remark: props?.data?.remark,
                params: props?.data?.params,
            }
            : {
                id: null,
                clAgent: "",
                countryId: 0,
                createTime: "",
                crmId:0,
                importExportType: "",
                min: 0,
                portAid: 0,
                portLid: 0,
                rateList: [],
                tt: "",
                updateTime: "",
                validDate: "",
                validfrom: "",
                weightList: [],
                zip: "",
                product: null,
                unitOfMeasure: null,
                currency: null,
                rate: 0,
                remark: null,
            },
    );

    const isCreateShipmentDiasabled = () => {
        return (!value.token || !value.descId ||
            !value.serialNum || !value.weight || !value.length || !value.width ||
            !value.height || !value.packages);
    }
    const [checked, setChecked] = useState(false);
    if (props?.typeData.id == 3 && !checked && value.id) {
        setChecked(true)
        var t = [...listOfWeight]
        for (var i = 0; i < value.weightList.length; i++) {
            t[i].rate = value.rateList[i];
        }
        setListOfWeight(t)
    }
    const save = () => {
        
        var data = {}
        var weight = [];
        var rate = [];

        if (props?.typeData.id == 3) {

            for (var i = 0; i < listOfWeight.length; i++) {
                weight.push(listOfWeight[i].weight);
                rate.push(listOfWeight[i].rate);
            }
        } else {
            rate.push(value.rate)
        }
        data = {
            id: value.id,
            importExportType: props?.typeData.id,
            crmId: value.crmId,
            countryId: value.countryId,
            portAid: value.portAid,
            portLid: value.portLid,
            weight: weight,
            rate: rate,
            clAgent: value.clAgent,
            tt: value.tt,
            zip: value.zip,
            min: value.min,
            validDate: value.validDate,
            validfrom: value.validfrom,
            params: {
                FRIEGHT_CAHRGES: frieghtCharges,
                LOCAL_CHARGES: charges,
                TRUCKER_NAME: crmList.find(u => u.id === value.crmId)?.companyName,
            }

        }
        addLclExportImportAF(dispatch, snackbarToggle, data, admin, props?.callBack)
        props?.onHandleCancel();

    };
    const update = () => {
        props?.onHandleCancel();
    };
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        
        if (!isLoaded) {
            getDiscriptionsList(dispatch, admin);
            window.setTimeout(function () {
                resetTempFrieght(frieghtCharges)
            }, 1000)
            /*var t = []
            for (var i = 0; i < frieghtCharges.length; i++) {
                if (!frieghtCharges[i].rate) {
                    var tObj = JSON.parse(JSON.stringify(frieghtCharges[i]))
                    tObj.id = i + 1;
                    tObj.currency = listOfcurrency.find(u => u.id === tObj.currency)?.id
                    tObj.unitOfMeasure = listOfMeasurementUnits.find(u => u.id === tObj.unitOfMeasure)?.id
                    t.push(tObj)
                }
            }

            setFrieghtChargesTemp(t)
            if (t.length) {
                setselectFreightId(t[0].id)
            }*/
            // getPackages();
        }
        if (!isLoaded && admin.data?.session && crmList && crmList.length == 0) {
            //getCRMList(dispatch, admin);
            setIsLoaded(true);
        }
    }, [dispatch, failedCallback, warehouseList]);

    const getUserInfoByToken = (e) => {
        if (value.token && value.token.length < 4) {
            dispatch(snackbarToggle({ type: 'Error', message: 'Locker ID is too short, please check again.' }));
            return;
        }
        getUserInfoByLockerId(dispatch, value.token, admin, setUserData);
        console.log(userData)
    }
    const keyPressed = (e) => {
        if (e.keyCode === 13) {
            getUserInfoByToken();

        }
    }

    const senderNameOptions = senderList.map(sender => ({
        id: sender.id,
        name: sender.senderName + ", " + sender.senderAddr + ", " + sender.senderCountry
    }));
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [uploadStarted, setUploadStarted] = useState(false);

    const changeHandler = (event) => {

    };
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const getCRMsByFunctionTypes = function (functionId) {
        var temp = []
        for (var i = 0; i < crmList.length; i++) {
            if (crmList[i].functionId == functionId) {
                temp.push(crmList[i])
            }
        }
        return temp;
    }
   const agentNameOption = getCRMsByFunctionTypes(5).map(sender => ({
  // const agentNameOption = crmList.map(sender => ({
        id: sender.id,
        name: sender.companyName //+ " " + sender.id
   })).sort(function (a, b) {
       if (a.name?.toLowerCase() < b.name?.toLowerCase()) { return -1; }
       if (a.name?.toLowerCase() > b.name?.toLowerCase()) { return 1; }
       return 0;
   });
    const truckerNameOption = getCRMsByFunctionTypes(6).map(sender => ({
        id: sender.id,
        name: sender.companyName
    })).sort(function (a, b) {
        if (a.name?.toLowerCase() < b.name?.toLowerCase()) { return -1; }
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) { return 1; }
        return 0;
    });
    const msx = { maxHeight: '400px' }
    const onStartDateChange = data => {
        //setStartDate(data);
        setValue(prev => ({ ...prev, validDate: data?.getTime() }))
    };
    const onStartDateChangeFrom = data => {
        //setStartDate(data);
        setValue(prev => ({ ...prev, validfrom: data?.getTime() }))
    };

    const [frieghtCharges, setFrieghtCharges] = useState(props?.data?.importExportType == 1 ? (props?.data?.params?.FRIEGHT_CAHRGES ? props?.data?.params?.FRIEGHT_CAHRGES : [...lclCostFrieghtExportCharges]) : (props?.data?.params?.FRIEGHT_CAHRGES ? props?.data?.params?.FRIEGHT_CAHRGES : [...lclCostFrieghtImportCharges]));
    const [charges, setCharges] = useState(props.data ? props?.data?.importExportType == 1 ? (props?.data?.params?.LOCAL_CHARGES ? props?.data?.params?.LOCAL_CHARGES : [...lclCostLocalExportCharges]) : (props?.data?.params?.LOCAL_CHARGES ? props?.data?.params?.LOCAL_CHARGES : [...lclCostLocalImportCharges]) : props.charges?.length > 0 ? props.charges : [...lclCostLocalImportCharges] );
    const setChargesitem = function (event, key, index) {
        var t = []
        var selected = [...charges]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            if (index == i)
                tObj[key] = event.target.value

            t.push(tObj)
        }
        setCharges(t)
        resetTempLocal(t)
    }
    const setFrieghtChargesitem = function (event, key, index) {
        var t = []
        var selected = [...frieghtCharges]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            if (index == i)
                tObj[key] = event.target.value

            t.push(tObj)
        }
        setFrieghtCharges(t)
        resetTempFrieght(t)
    }
    const saveCharges = () => {
        var t = [];

        for (var i = 0; i < charges.length; i++) {
            var tObj = JSON.parse(JSON.stringify(charges[i]));
            charges[i].product = charges[i].product ? charges[i].product:charges[i].name;
            charges[i].importExportType = props.typeData.id;
            charges[i].shipmentId = 2;
            charges[i].remark = charges[i].remark ? charges[i].remark : "";
            charges[i].rate = charges[i].rate ? charges[i].rate : "";
            charges[i].min = charges[i].min ? charges[i].min : "";
            updateLocalCharges(dispatch, admin, charges[i], props?.callBack);
            t.push(tObj)
        }
        props?.setAirImportExport([]);
        props?.onHandleCancel();
        window.setTimeout(function () { props?.setAirImportExport(t); }, 500)
    }
    return (
        <div>
            
            {(props?.typeData?.id == 1 || props?.typeData?.id == 2 || props?.typeData?.id == 3) && <Grid container item spacing={2}>
                {(props?.typeData.id == 2 || props?.typeData.id == 1) && <Grid container item spacing={2}>
                   
                    {<SelectField
                        required
                        value={value.crmId}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, crmId: event.target.value }))}
                        label='Co-loader'
                        options={agentNameOption}
                    />}
                    <SelectField
                        value={value.countryId}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, countryId: event.target.value }))}
                        label='Country'
                        options={countryList}
                    />
                    {(props?.typeData.id == 2) && <SelectField
                        required
                        value={value.portLid}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, portLid: event.target.value }))}
                        label='POL'
                        options={listOfPort}
                    />}
                    {<SelectField
                        required
                        value={value.portAid}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, portAid: event.target.value }))}
                        label='POA'
                        options={listOfPort}
                    />}
                    <TextField
                        lg={5.5}
                        value={value.clAgent}
                        onChange={event => setValue(prev => ({ ...prev, clAgent: event.target.value }))}
                        label='Cl-Agent'
                    />
                    <TextField
                        lg={5.5}
                        value={value.tt}
                        onChange={event => setValue(prev => ({ ...prev, tt: event.target.value }))}
                        label='TT'
                    />
                    <DateTimePicker
                        sm={null}
                        md={null}
                        xs={5.5}
                        label={<Typography fontSize="0.8rem" fontWeight='bold'>Valid Start Date</Typography>}
                        value={value.validfrom}
                        onChange={date => onStartDateChangeFrom(date)}
                        style={{ 'top': '15px', left: '35px' }}
                    />
                    <DateTimePicker
                        sm={null}
                        md={null}
                        xs={5.5}
                        label={<Typography fontSize="0.8rem" fontWeight='bold'>Valid End Date</Typography>}
                        value={value.validDate}
                        onChange={date => onStartDateChange(date)}
                        style={{ 'top': '15px', left: '35px' }}
                    />

                   

                    {/*<Grid container item lg={5.5} sx={{ pt: 0, mb: 2 }} style={{ paddingTop: 0 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Button style={{ maxWidth: '20px' }} variant='contained' sx={{ mr: 2, ml: 20, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, min: Number(value.min) - 1 })) }}>
                                -
                            </Button>
                            <Grid style={{ marginTop: '15px' }}>
                                <TextField
                                    lg={12}
                                    value={value.min}
                                    onChange={event => setValue(prev => ({ ...prev, min: event.target.value }))}
                                    label='Min'
                                    type='number'
                                />
                            </Grid>
                            <Button style={{ maxWidth: '20px !important' }} variant='contained' sx={{ mr: 2, ml: 2, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, min: Number(value.min) + 1 })) }}>
                                +
                            </Button>
                        </Box>
                    </Grid>*/}
                    <Grid container item lg={5.5} sx={{ pt: 0, mb: 2 }} style={{ paddingTop:0 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button style={{ maxWidth: '20px' }} variant='contained' sx={{ mr: 2, ml: 2, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, rate: Number(value.rate) - 1 })) }}>
                            -
                        </Button>
                        <Grid style={{ marginTop: '15px' }}>
                            <TextField
                                lg={12}
                                value={value.rate}
                                onChange={event => setValue(prev => ({ ...prev, rate: event.target.value }))}
                                    label='O/F rate'
                                type='number'
                            />
                        </Grid>
                        <Button style={{ maxWidth: '20px !important' }} variant='contained' sx={{ mr: 2, ml: 2, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, rate: Number(value.rate) + 1 })) }}>
                            +
                        </Button>



                        </Box>
                        
                    </Grid>
                    <Grid container item lg={5.5} sx={{ pt: 0, mb: 2 }} style={{ paddingTop: 0 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Button style={{ maxWidth: '20px' }} variant='contained' sx={{ mr: 2, ml: 2, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, min: Number(value.min) - 1 })) }}>
                                -
                            </Button>
                            <Grid style={{ marginTop: '15px' }}>
                                <TextField
                                    lg={12}
                                    value={value.min}
                                    onChange={event => setValue(prev => ({ ...prev, min: event.target.value }))}
                                    label='Min'
                                    type='number'
                                />
                            </Grid>
                            <Button style={{ maxWidth: '20px !important' }} variant='contained' sx={{ mr: 2, ml: 2, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, min: Number(value.min) + 1 })) }}>
                                +
                            </Button>



                        </Box>

                    </Grid>
                    <Grid container item >
                        <Button variant={bFrieghtCharges ? 'contained' : 'outlined'} sx={{ mr: 1, ml: 0, mt: 2, mb: 3 }} onClick={function () {
                            hideAllCharges();
                            setBFrieghtCharges(true)
                            
                            resetTempFrieght(frieghtCharges)
                        }}>
                            Frieght charges
                        </Button>
                        <Button variant={bLocalCharges ? 'contained' : 'outlined'} sx={{ mr: 1, ml: 0, mt: 2, mb: 3 }} onClick={function () {
                            hideAllCharges();
                            setBLocalCharges(true)
                            var t = JSON.parse(JSON.stringify(charges))
                            
                            if (props?.data?.importExportType == 1) {
                                console.log(charges)
                                console.log(lclCostLocalExportCharges)
                               // [...lclCostLocalImportCharges]
                                for (var i = 0; i < lclCostLocalExportCharges.length; i++) {
                                    var it = charges.find(u => u.name === lclCostLocalExportCharges[i].name)
                                    if (!it) {
                                        console.log("no")
                                        console.log(lclCostLocalExportCharges[i])
                                        t.push(lclCostLocalExportCharges[i])
                                    }
                                }

                            }
                            /*if (props?.data?.importExportType == 2) {
                                console.log(charges)
                                console.log(lclCostLocalImportCharges)
                                // [...lclCostLocalImportCharges]
                                for (var i = 0; i < lclCostLocalImportCharges.length; i++) {
                                    var it = charges.find(u => u.name === lclCostLocalImportCharges[i].name)
                                    if (!it) {
                                        console.log("no")
                                        console.log(lclCostLocalImportCharges[i])
                                        t.push(lclCostLocalImportCharges[i])
                                    }
                                }

                            }*/
                            console.log(t)
                            for (var i = 0; i < t?.length; i++) {
                                if (!t[i].name && t[i].product) {
                                    t[i].name = t[i].product
                                }
                                
                            }
                            setCharges(t)
                            resetTempLocal(t)
                        }}>
                            Local charges
                        </Button>
                    </Grid>
                    {bFrieghtCharges && <TableContainer style={{ width:"100vw" }}>
                        <Table>

                            <TableBody>
                                {frieghtChargesTemp?.length > 0 && <RowSelectOption setselectFreightId={setselectFreightId} frieghtChargesTemp={frieghtChargesTemp} setFrieghtChargesTemp={setFrieghtChargesTemp} selectFreightId={selectFreightId} setFrieghtCharges={setFrieghtCharges} frieghtCharges={frieghtCharges} listOfcurrency={listOfcurrency} listOfMeasurementUnits={listOfMeasurementUnits} ></RowSelectOption>}
                                
                                {frieghtCharges.length > 0 &&
                                    frieghtCharges.map((chargesItem, index) => (
                                        <> {(Number(chargesItem.rate) || Number(chargesItem.min) || chargesItem.remark) &&<TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.name}
                                                    disabled={true}
                                                    label='Product'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'MeasurementUnit'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'MeasurementUnit'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.unitOfMeasure}
                                                                label={'MeasurementUnit'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setFrieghtChargesitem(event, 'unitOfMeasure', index)
                                                                }}
                                                               // disabled={true}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfMeasurementUnits?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'currency'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'currency'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.currency}
                                                                label={'currency'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setFrieghtChargesitem(event, 'currency', index)
                                                                }}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfcurrency?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.rate}
                                                    onChange={function (event) {
                                                        setFrieghtChargesitem(event, 'rate', index)
                                                    }}
                                                    label='Rate'
                                                    type='number'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.min}
                                                    onChange={function (event) {
                                                        setFrieghtChargesitem(event, 'min', index)
                                                    }}
                                                    label='Min'
                                                    type='number'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.remark}
                                                    onChange={function (event) {
                                                        setFrieghtChargesitem(event, 'remark', index)
                                                    }}
                                                    label='Remark'
                                                />
                                            </TableCell>
                                        </TableRow>}</>))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>}
                    {bLocalCharges &&<TableContainer>
                        <Table>

                            <TableBody>

                                {chargesTemp?.length > 0 && <RowSelectOption setselectFreightId={setselectLocalId} frieghtChargesTemp={chargesTemp} setFrieghtChargesTemp={setChargesTemp} selectFreightId={selectLocalId} setFrieghtCharges={setCharges} frieghtCharges={charges} listOfcurrency={listOfcurrency} listOfMeasurementUnits={listOfMeasurementUnits} ></RowSelectOption>}


                                {charges.length > 0 &&
                                    charges.map((chargesItem, index) => (
                                        <> {(Number(chargesItem.rate) || Number(chargesItem.min) || chargesItem.remark) && <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.name ? chargesItem.name : chargesItem.product}
                                                    disabled={true}
                                                    label='Product'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'MeasurementUnit'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'MeasurementUnit'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.unitOfMeasure}
                                                                label={'MeasurementUnit'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setChargesitem(event, 'unitOfMeasure', index)
                                                                }}
                                                               // disabled={true}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfMeasurementUnits?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'currency'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'currency'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.currency}
                                                                label={'currency'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setChargesitem(event, 'currency', index)
                                                                }}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfcurrency?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.rate}
                                                    onChange={function (event) {
                                                        setChargesitem(event, 'rate', index)
                                                    }}
                                                    label='Rate'
                                                    type='number'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.min}
                                                    onChange={function (event) {
                                                        setChargesitem(event, 'min', index)
                                                    }}
                                                    label='Min'
                                                    type='number'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.remark}
                                                    onChange={function (event) {
                                                        setChargesitem(event, 'remark', index)
                                                    }}
                                                    label='Remark'
                                                />
                                            </TableCell>
                                        </TableRow>}</>))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Grid>
                }
                {(props?.typeData.id == 3) && <Grid container item spacing={2} justifyContent='flex-end'>
                    <Grid container item spacing={2} justifyContent='flex-end' style={{ marginRight: '15px' }}>

                        <SelectField
                            value={value.crmId}
                            lg={6}
                            onChange={event => setValue(prev => ({ ...prev, crmId: event.target.value }))}
                            label='Trucker'
                            options={truckerNameOption}
                        />
                        <SelectField
                            value={value.countryId}
                            lg={6}
                            onChange={event => setValue(prev => ({ ...prev, countryId: event.target.value }))}
                            label='Country'
                            options={countryList}
                        />

                        <SelectField
                            value={value.portAid}
                            lg={6}
                            onChange={event => setValue(prev => ({ ...prev, portAid: event.target.value }))}
                            label='port'
                            options={listOfPort}
                        />
                        <TextField
                            lg={6}
                            value={value.zip}
                            onChange={event => setValue(prev => ({ ...prev, zip: event.target.value }))}
                            label='zip'
                        />
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }} lg={12}>
                        <Button style={{ maxWidth: '20px' }} variant='contained' sx={{ mr: 2, ml: 20, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, min: Number(value.min) - 1 })) }}>
                            -
                        </Button>
                        <Grid style={{ marginTop: '15px' }}>
                            <TextField
                                lg={12}
                                value={value.min}
                                onChange={event => setValue(prev => ({ ...prev, min: event.target.value }))}
                                label='Min'
                                type='number'
                            />
                        </Grid>
                        <Button style={{ maxWidth: '20px !important' }} variant='contained' sx={{ mr: 2, ml: 2, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, min: Number(value.min) + 1 })) }}>
                            +
                        </Button>
                    </Box>

                    <Grid container item justifyContent='flex-end' lg={22}>

                        {
                            listOfWeight.map((rateWeight, index) => (


                                <Box key={uuidv4()} sx={{ display: 'flex', justifyContent: 'flex-start' }}>

                                    <Button style={{ maxWidth: '20px' }} variant='contained' sx={{ mr: 10, ml: 0, mt: 2 }} color='secondary' onClick={function () { }}>
                                        {rateWeight.weight}
                                    </Button>
                                    <Button style={{ maxWidth: '20px' }} variant='contained' sx={{ mr: 2, ml: 0, mt: 2 }} color='secondary' onClick={function () {
                                        var t = [...listOfWeight]
                                        t[index].rate = Number(t[index].rate) - 1
                                        setListOfWeight(t)

                                    }}>
                                        -
                                    </Button>
                                    <Grid style={{ marginTop: '15px' }}>
                                        <TextField
                                            lg={12}
                                            value={rateWeight.rate}
                                            onChange={function (event) {
                                                var t = [...listOfWeight]
                                                t[index].rate = event.target.value
                                                setListOfWeight(t)
                                            }}
                                            label='Rate'
                                            type='number'

                                        />
                                    </Grid>
                                    <Button style={{ maxWidth: '20px !important' }} variant='contained' sx={{ mr: 2, ml: 2, mt: 2 }} color='secondary' onClick={function () {
                                        var t = [...listOfWeight]
                                        t[index].rate = Number(t[index].rate) + 1
                                        setListOfWeight(t)

                                    }}>
                                        +
                                    </Button>
                                </Box>
                            ))
                        }
                    </Grid>

                </Grid>
                }
                {<Grid container item justifyContent='flex-end' lg={22}>
                    <Button disabled={!value.crmId || !value.portAid} variant='contained' sx={{ mt: 2, mr: 2 }} onClick={save}>
                        Save
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={props?.onHandleCancel}>
                        Cancel
                    </Button>
                </Grid>}
            </Grid>}
            {(props?.typeData?.id == 4 || props?.typeData?.id == 5) && <Grid container justifyContent='flex-start' lg={12} >
                <TableContainer>
                    <Table>

                        <TableBody>

                            {charges.length > 0 &&
                                charges.map((chargesItem, index) => (
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                                        <TableCell style={{ padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.product ? chargesItem.product : chargesItem.name}
                                                disabled={true}
                                                label='Product'
                                            />
                                        </TableCell>
                                        <TableCell style={{ padding: '8px' }}>
                                            <Typography variant='h7'>
                                                <Grid item xs={6} sm={6} md={4} lg={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id={'MeasurementUnit'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                            {'MeasurementUnit'}
                                                        </InputLabel>
                                                        <Select
                                                            value={chargesItem.unitOfMeasure}
                                                            label={'MeasurementUnit'}
                                                            inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                            onChange={function (event) {
                                                                setChargesitem(event, 'unitOfMeasure', index)
                                                            }}
                                                            size='small'
                                                           // disabled={true}
                                                            MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                            {listOfMeasurementUnits?.map((option, index) => (
                                                                <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                    {
                                                                        (option.lable)
                                                                    }
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: '8px' }}>
                                            <Typography variant='h7'>
                                                <Grid item xs={6} sm={6} md={4} lg={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id={'currency'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                            {'currency'}
                                                        </InputLabel>
                                                        <Select
                                                            value={chargesItem.currency}
                                                            label={'currency'}
                                                           // disabled={true}
                                                            inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                            onChange={function (event) {
                                                                setChargesitem(event, 'currency', index)
                                                            }}
                                                            size='small'
                                                            MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                            {listOfcurrency?.map((option, index) => (
                                                                <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                    {
                                                                        (option.lable)
                                                                    }
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.rate}
                                                onChange={function (event) {
                                                    setChargesitem(event, 'rate', index)
                                                }}
                                                label='Rate'
                                                type='number'
                                            />
                                        </TableCell>
                                        <TableCell style={{ padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.min}
                                                onChange={function (event) {
                                                    setChargesitem(event, 'min', index)
                                                }}
                                                label='Min'
                                                type='number'
                                            />
                                        </TableCell>
                                        <TableCell style={{ padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.remark}
                                                onChange={function (event) {
                                                    setChargesitem(event, 'remark', index)
                                                }}
                                                label='Remark'
                                            />
                                        </TableCell>
                                    </TableRow>))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {<Grid container item justifyContent='flex-end' lg={22}>
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={saveCharges}>
                        Save
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={props?.onHandleCancel}>
                        Cancel
                    </Button>
                </Grid>}
            </Grid>}
            {snackbar && (
                <Snackbar open={!!snackbar} message={snackbar.message} type={snackbar.type} onClose={onSnackbarHandleClose} />
            )}
        </div>
    );
};

export default AddLclCost;
