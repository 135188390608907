import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectField, TextField } from '../../style-guide';
import { FaPlus, FaMinus } from "react-icons/fa";
import { setProductForeignChargesItemNew } from '../../reducers/parameter/parameterSlice';
import { Table, TableHead, TableContainer, TableBody,Select, Grid, FormControl, InputLabel, MenuItem, FormGroup, TableCell, TableRow, Typography, Box, Button, Checkbox, Radio, FormControlLabel } from '@mui/material';
const ChargesRow = props => {
    const dispatch = useDispatch();
    const ref_input2 = useRef();
    const { listOfProductCategories, listOfExchangeRates, listOfShipmentTypes, listOfMeasurementUnits, listOfcurrency, listOfKgFactor, listOfAirLine, listOfLclExportImportAFs, listOfAirExportAFs, countryList, qsStatusList, listOfServiceType, listOfAirPort, listOfPort, listOfIncoterms, listOfFLCTypes40, listOfFLCTypes20 } = useSelector(state => state.parameter);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                items: [...props.data.items],
                selectedCurrency: props.data.selectedCurrency,
                selectedItem: props.data.selectedItem,
                selectedUnit: props.data.selectedUnit,
                rate: props.data.rate ? props.data.rate : props.data.selectedItem.rate,
                min: props.data.min ? props.data.min : props.data.selectedItem.min,
                remark: props.data.remark,
                rate40: props.data.rate40 ? props.data.rate40 : props.data.selectedItem.rate40,
                rate20: props.data.rate20 ? props.data.rate20 : props.data.selectedItem.rate20,
                orgValue: props.data.orgValue,
                orgValue20: props.data.orgValue20,
                orgValue40: props.data.orgValue40,
                bOrgValue: props.data.bOrgValue,
                index: props.index,
            }
            : {
                id: null,
                items: null,
                selectedCurrency: null,
                selectedItem: null,
                selectedUnit: null,
                rate: null,
                remark: null,
                rate40: null,
                rate20: null,
                min: null,
                org: null,
                index: null,
            },
    );


    useEffect(() => {
        if (props.shipmentType != 3) {
            var orgValue = null;
            if (props.data.orgValue) {
                orgValue = props.data.orgValue;
            }
            if (value.selectedItem.name == 'Collection Fee') {
                console.log(props.original)
                console.log(orgValue)
            }
            //  setValue(prev => ({ ...prev, org: orgValue }))
            var t = {
                items: value.items,
                selectedItem: value.selectedItem,
                selectedUnit: value.selectedUnit,
                selectedCurrency: value.selectedCurrency,
                rate: value.rate ? value.rate : value.selectedItem.rate,
                rate20: value.rate20 ? value.rate20 : value.selectedItem.rate20,
                rate40: value.rate40 ? value.rate40 : value.selectedItem.rate40,
                bOrgValue: value.bOrgValue,
                min: value.min,
                remark: value.remark,
                orgValue: orgValue,
                index: value.index,
            }

            setValue(t)
            props.setChargesItem(value.index, t)
            //dispatch(setProductForeignChargesItemNew(t));

        } else {

            var orgValue20 = null;
            if (props.data.orgValue20) {
                orgValue20 = props.data.orgValue20;
            }
            if (value.selectedItem.name == 'Collection Fee') {
                console.log(props.original)
                console.log(orgValue20)
            }


            var orgValue40 = null;
            if (props.data.orgValue40) {
                orgValue40 = props.data.orgValue40;
            }
            var t = {
                items: value.items,
                selectedItem: value.selectedItem,
                selectedUnit: value.selectedUnit,
                selectedCurrency: value.selectedCurrency,
                rate20: value.rate20 ? value.rate20 : value.selectedItem.rate20,
                rate40: value.rate40 ? value.rate40 : value.selectedItem.rate40,
                min: value.min,
                remark: value.remark,
                orgValue20: orgValue20,
                orgValue40: orgValue40,
                bOrgValue: value.bOrgValue,
                index: value.index,
            }
            setValue(t)
            props.setChargesItem(value.index, t)
            //dispatch(setProductForeignChargesItemNew(t));
        }
    }, []);


    const handleParams = (event) => {
        setValue(prev => ({ ...prev, pieces: event.target.value }))
        window.setTimeout(function () { ref_input2.current.focus() },1)
        // setValue(prev => ({ ...prev, params: t }))
    }
    const setChargesItem = (event) => {
        var items = value.items;
       /* items.forEach(item => {
            item.rate = 0;
            item.min = 0;
        })*/
        var selectedItem = items.find(u => u.id === event.target.value.id)
        selectedItem.selectedUnit = selectedItem.units.find(u => u.id === selectedItem.selectedUnit.id)
        selectedItem.selectedCurrency = selectedItem.currencies.find(u => u.id === selectedItem.selectedCurrency.id)
        selectedItem.rate = 0;
        selectedItem.min = 0;
        var t = {
            items: items,
            selectedItem: selectedItem,
            selectedUnit: selectedItem.selectedUnit,
            selectedCurrency: selectedItem.selectedCurrency,
            index: value.index,
            rate:0,
            min: 0,
            orgValue: value.orgValue,
            orgValue20: value.orgValue20,
            orgValue40: value.orgValue40,
            remark: value.remark,
            bOrgValue: value.bOrgValue,
        }       
        setValue(t)
        props.setChargesItem(value.index, t)
        //dispatch(setProductForeignChargesItemNew(t));
        //setValue(prev => ({ ...prev, selectedItem: event.target.value }))
       // setValue(prev => ({ ...prev, selectedUnit: event.target.value.selectedUnit }))
       // setValue(prev => ({ ...prev, selectedCurrency: event.target.value.selectedCurrency }))
       
    }
    const setUnitChangesItem = (event) => {
       // value.selectedItem.rate = event.target.value
        var t = {
            items: value.items,
            selectedItem: value.selectedItem,
            selectedUnit: event.target.value,
            selectedCurrency: value.selectedCurrency,
            index: value.index,
            rate: value.rate,
            min: value.min,
            orgValue: value.orgValue,
            orgValue20: value.orgValue20,
            orgValue40: value.orgValue40,
            remark: value.remark,
            bOrgValue: value.bOrgValue,
        }
        if (props.shipmentType == 2) {
            if (t?.selectedUnit?.id == 3) {
                t.selectedItem.min = 1;
            }
        }
        setValue(t)
        props.setChargesItem(value.index, t)
        //dispatch(setProductForeignChargesItemNew(t));
    }
    const setCurrencyChangesItem = (event) => {
       // value.selectedItem.rate = event.target.value
        var t = {
            items: value.items,
            selectedItem: value.selectedItem,
            selectedUnit: value.selectedUnit,
            selectedCurrency: event.target.value,
            index: value.index,
            rate: value.rate,
            min: value.min,
            orgValue: value.orgValue,
            orgValue20: value.orgValue20,
            orgValue40: value.orgValue40,
            remark: value.remark,
            bOrgValue: value.bOrgValue,
        }
        
        setValue(t)
        props.setChargesItem(value.index, t)
        //dispatch(setProductForeignChargesItemNew(t));
    }
    const setRateChargesItem = (event,id) => {
        if (value.selectedItem.rate !== event.target.value) {
            value.selectedItem.rate = event.target.value
            var t = {
                items: value.items,
                selectedItem: value.selectedItem,
                selectedUnit: value.selectedUnit,
                selectedCurrency: value.selectedCurrency,
                index: value.index,
                rate: event.target.value,
                min: value.min,
                orgValue: value.orgValue,
                orgValue20: value.orgValue20,
                orgValue40: value.orgValue40,
                remark: value.remark,
                bOrgValue: value.bOrgValue,
            }

            setValue(t)
            props.setChargesItem(value.index, t)
            //dispatch(setProductForeignChargesItemNew(t));
            /*window.setTimeout(function () {
                document.getElementById(id)?.focus();
            }, 1000)*/
        }
    }
    const setRate20ChargesItem = (event,id) => {
        if (value.selectedItem.rate20 !== event.target.value && event.target.value?.length) {
            value.selectedItem.rate20 = event.target.value
            var t = {
                items: value.items,
                selectedItem: value.selectedItem,
                selectedUnit: value.selectedUnit,
                selectedCurrency: value.selectedCurrency,
                index: value.index,
                rate20: event.target.value,
                rate40: value.rate40,
                min: value.min,
                orgValue20: value.orgValue20,
                orgValue40: value.orgValue40,
                orgValue: value.orgValue,
                remark: value.remark,
                bOrgValue: value.bOrgValue,
            }

            setValue(t)
            props.setChargesItem(value.index, t)
            //dispatch(setProductForeignChargesItemNew(t));
            /*window.setTimeout(function () {
                document.getElementById(id)?.focus();
            }, 1000)*/
        }
    }
    const setRate40ChargesItem = (event,id) => {
        if (value.selectedItem.rate40 !== event.target.value && event.target.value?.length) {
            value.selectedItem.rate40 = event.target.value
            var t = {
                items: value.items,
                selectedItem: value.selectedItem,
                selectedUnit: value.selectedUnit,
                selectedCurrency: value.selectedCurrency,
                index: value.index,
                rate20: value.rate20,
                rate40: event.target.value,
                min: value.min,
                orgValue20: value.orgValue20,
                orgValue40: value.orgValue40,
                orgValue: value.orgValue,
                remark: value.remark,
                bOrgValue: value.bOrgValue,
            }

            setValue(t)
            props.setChargesItem(value.index, t)
            //dispatch(setProductForeignChargesItemNew(t));
            /*window.setTimeout(function () {
                document.getElementById(id)?.focus();
            }, 1000)*/
        }
    }
    const getJSONString = (event) => {
        return JSON.stringify(event)
    }
    const setMinChargesItem = (event,id) => {
        if (value.selectedItem.min !== event.target.value) {
            value.selectedItem.min = event.target.value
            var t = {
                items: value.items,
                selectedItem: value.selectedItem,
                selectedUnit: value.selectedUnit,
                selectedCurrency: value.selectedCurrency,
                index: value.index,
                rate: value.rate,
                rate20: value.rate20,
                rate40: value.rate40,
                min: event.target.value,
                orgValue: value.orgValue,
                orgValue20: value.orgValue20,
                orgValue40: value.orgValue40,
                remark: value.remark,
                bOrgValue: value.bOrgValue,
            }

            setValue(t)
            props.setChargesItem(value.index, t)
            //dispatch(setProductForeignChargesItemNew(t));
            /*window.setTimeout(function () {
                document.getElementById(id)?.focus();
            }, 1000)*/
        }
    }
    const setRemarksChargesItem = (event,id) => {
        //if (value.selectedItem.remark !== event.target.value) {
            value.selectedItem.remark = event.target.value
            var t = {
                items: value.items,
                selectedItem: value.selectedItem,
                selectedUnit: value.selectedUnit,
                selectedCurrency: value.selectedCurrency,
                index: value.index,
                rate: value.rate,
                rate20: value.rate20,
                rate40: value.rate40,
                min: value.min,
                remark: event.target.value,
                orgValue: value.orgValue,
                orgValue20: value.orgValue20,
                orgValue40: value.orgValue40,
                bOrgValue: value.bOrgValue,
            }

            setValue(t)
            props.setChargesItem(value.index, t)
            //dispatch(setProductForeignChargesItemNew(t));
            /*window.setTimeout(function () {
                document.getElementById(id)?.focus();
            }, 1000)*/
           
       // }
    }
    const setOrgChargesItem = (event,id) => {
       // if (value.selectedItem.remark !== event.target.value && event.target.value?.length) {
        value.orgValue = event.target.value
            var t = {
                items: value.items,
                selectedItem: value.selectedItem,
                selectedUnit: value.selectedUnit,
                selectedCurrency: value.selectedCurrency,
                index: value.index,
                rate: value.rate,
                min: value.min,
                remark: value.remark,
                orgValue: event.target.value == "" ? null : event.target.value,
                orgValue20: value.orgValue20,
                orgValue40: value.orgValue40,
                bOrgValue: value.bOrgValue,
            }

            setValue(t)
        props.setChargesItem(value.index, t)
        //dispatch(setProductForeignChargesItemNew(t));
           /* window.setTimeout(function () {
                document.getElementById(id)?.focus();
            }, 1000)*/
           
       // }
    }
    
    const setOrg20ChargesItem = (event,id) => {
       // if (value.selectedItem.remark !== event.target.value && event.target.value?.length) {
       // value.orgValue = event.target.value
            var t = {
                items: value.items,
                selectedItem: value.selectedItem,
                selectedUnit: value.selectedUnit,
                selectedCurrency: value.selectedCurrency,
                index: value.index,
                rate20: value.rate20,
                rate40: value.rate40,
                min: value.min,
                remark: value.remark,
                orgValue20: event.target.value == "" ? null : event.target.value,
                orgValue40: value.orgValue40,
                orgValue: value.orgValue,
            }

            setValue(t)
        props.setChargesItem(value.index, t)
        //dispatch(setProductForeignChargesItemNew(t));
           /* window.setTimeout(function () {
                document.getElementById(id)?.focus();
            }, 1000)*/
           
       // }
    }
    
    const setOrg40ChargesItem = (event,id) => {
       // if (value.selectedItem.remark !== event.target.value && event.target.value?.length) {
        value.orgValue = event.target.value
            var t = {
                items: value.items,
                selectedItem: value.selectedItem,
                selectedUnit: value.selectedUnit,
                selectedCurrency: value.selectedCurrency,
                index: value.index,
                rate20: value.rate20,
                rate40: value.rate40,
                min: value.min,
                remark: value.remark,
                orgValue40: event.target.value == "" ? null : event.target.value,
                orgValue20: value.orgValue20,
                orgValue: value.orgValue,
                bOrgValue: value.bOrgValue,
            }

            setValue(t)
        props.setChargesItem(value.index, t)
        //dispatch(setProductForeignChargesItemNew(t));
           /* window.setTimeout(function () {
                document.getElementById(id)?.focus();
            }, 1000)*/
           
       // }
    }

    return (
        <TableRow sx={{ '& > *': { borderBottom: 'unset', margin: 0, padding: '5px' } }} >
            <TableCell>
                <Typography variant='h6'>
                   
                </Typography>
            </TableCell>
            <TableCell style={{ width: '50px' }}>
                <Typography variant='h6'>
                    <Button variant='contained' onClick={function () {
                        var t = {
                            items: value.items,
                            selectedItem: value.selectedItem,
                            selectedUnit: value.selectedUnit,
                            selectedCurrency: value.selectedCurrency,
                            index: value.index,
                            rate: value.rate ? 0 : null,
                            rate20: value.rate20 ? 0 : null,
                            rate40: value.rate40 ? 0 : null,
                            bOrgValue: false,
                            min: 0,
                            remark: value.remark,
                        };

                        setValue(t);
                        props.setChargesItem(value.index, t);
                        
                        window.setTimeout(function () {
                            props.removeCharge(value.index);
                        }, 200)
                    }} style={{ margin: '9px', padding: '5px', minWidth: '0px', height: '25px', marginLeft: -2.5 }} className="charges_details_btn minus_btn">
                        <FaMinus />  
                    </Button>
                </Typography>
            </TableCell>
            <TableCell style={{ width: '15vw' }}>
               
                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel sx={{ top: 0, maxWidth: 'calc(100% - 40px)' }}>
                            {'Item'}
                        </InputLabel>
                        <Select
                            value={value.selectedItem || 'name'}
                            label={'Item'}
                            onChange={(event) => {
                                setChargesItem(event)
                                window.setTimeout(function () {
                                    document.getElementById("Unit_" + value.index + props.heading)?.focus();
                                }, 1000)
                            }}
                            size='small'
                           >
                            {value.items?.map(option => (
                                <MenuItem key={option.id} value={option}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </TableCell>
            <TableCell style={{ width: '8vw' }}>
                
                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel sx={{ top: 0, maxWidth: 'calc(100% - 40px)' }}>
                            {'Unit'} 
                        </InputLabel>
                        <Select
                            value={value.selectedUnit || ''}
                            label={'Unit'}
                            onChange={event => {
                                setValue(prev => ({ ...prev, selectedUnit: event.target.value }))
                                setUnitChangesItem(event)
                                window.setTimeout(function () {
                                    document.getElementById("Currency_" + value.index + props.heading)?.focus();
                                }, 1000)
                            }}
                            size='small'
                            id={"Unit_" + value.index + props.heading}
                        >
                            {value.selectedItem.units?.map(option => (
                                <MenuItem key={option.id} value={option}>
                                    {option.lable}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </TableCell>
            <TableCell style={{ width: '8vw' }}>
               
                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel sx={{ top:0, maxWidth: 'calc(100% - 40px)' }}>
                            {'Currency'}
                        </InputLabel>
                        <Select
                            value={value.selectedCurrency || ''}
                            label={'Currency'}
                            onChange={event => {
                                setValue(prev => ({ ...prev, selectedCurrency: event.target.value }));
                                setCurrencyChangesItem(event)
                                window.setTimeout(function () {
                                    document.getElementById("Rate_" + value.index + props.heading)?.focus();
                                }, 1000)
                            }}
                            size='small'
                            id={"Currency_" + value.index + props.heading}
                        >
                            {value.selectedItem.currencies?.map(option => (
                                <MenuItem key={option.id} value={option}>
                                    {option.lable}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </TableCell>
            {props.shipmentType != 3 && <TableCell style={{ width: '8vw' }}>
                <TextField
                    lg={12}
                    md={12}
                    sm={12}
                    value={value.rate}
                    onChange={event => setValue(prev => ({ ...prev, rate: event.target.value }))}
                    onBlur={function (event) {
                        setRateChargesItem(event, "MIN_" + value.index + props.heading)
                        //setUnitChangesItem
                    }}
                    label='Rate'
                    fullWidth
                    size='small'
                    type="number" onwheel="return false"
                    id={"Rate_" + value.index + props.heading}
                />
            </TableCell>
            }
            {props.shipmentType == 3 && <TableCell style={{ width: '8vw' }}>
                <Grid item  >
                <TextField
                        lg={12}
                        md={12}
                        sm={12}
                        value={props?.isTwenty? value.rate20:0}
                        onChange={event => setValue(prev => ({ ...prev, rate20: event.target.value }))}
                        onBlur={function (event) { setRate20ChargesItem(event) }}
                        label='Rate 20'
                        fullWidth
                        size='small'
                       // type="number" onwheel="return false"
                    />
                </Grid>
            </TableCell>
            }
            {props.shipmentType == 3 && <TableCell style={{ width: '8vw' }}>
                <Grid item  >
                <TextField
                        lg={12}
                        md={12}
                        sm={12}
                        value={props?.isFourty? value.rate40:0}
                        onChange={event => setValue(prev => ({ ...prev, rate40: event.target.value }))}
                        onBlur={function (event) { setRate40ChargesItem(event) }}
                        label='Rate 40'
                        fullWidth
                        size='small'
                        //type="number" onwheel="return false"
                    />
                </Grid>
            </TableCell>
            }
            {props.shipmentType != 3 &&<TableCell style={{ width: '8vw' }}>
                <Grid item >
                    <TextField
                        lg={12}
                        md={12}
                        sm={12}
                        value={value.min}
                        onChange={event => {
                            setValue(prev => ({ ...prev, min: event.target.value }));
                        }}
                        onBlur={(event) => {

                            setMinChargesItem(event, "REM_" + value.index + props.heading)
                        }}
                        label='min'
                        fullWidth
                        size='small'
                        id={"MIN_" + value.index + props.heading}
                    /></Grid>
            </TableCell>
            }
            {<TableCell style={{ width: '22vw' }}>
                <Grid item >
                    <TextField
                        lg={12}
                        md={12}
                        sm={12}
                        value={value.remark}
                        onChange={event => {
                            setValue(prev => ({ ...prev, remark: event.target.value }));
                        }}
                        onBlur={(event) => {
                            setRemarksChargesItem(event, "ORG_" + value.index + props.heading)
                        }}
                        label='remark'
                        fullWidth
                        size='small'
                        id={"REM_" + value.index + props.heading}
                    /></Grid>
            </TableCell>
            }
            {props.shipmentType != 3 &&<>
            {
                    
                    <TableCell style={{ width: '10vw', border: "1px solid", borderRight: "none" }}>
                        <TextField
                            lg={12}
                            md={12}
                            sm={12}
                            value={value.orgValue ? value.orgValue :0}
                            onChange={event => setValue(prev => ({ ...prev, orgValue: event.target.value }))}
                            onBlur={(event) => {
                                setOrgChargesItem(event, "ORG_" + value.index + props.heading)
                            }}
                            readOnly={props.data.bOrgValue ? true : false}
                            label='Original price'
                            className={props.data.bOrgValue ? 'text_disabled' : ''}
                            fullWidth
                            size='small'
                            id={"ORG_" + value.index + props.heading}
                            type="number" onwheel="return false"
                        />
                    </TableCell>

                }
            </>}
            
            {props.shipmentType != 3 &&<TableCell style={{ width: '10vw', border:"1px solid",borderRight:"none" }}>
                    <TextField
                    lg={12}
                    md={12}
                    sm={12}
                    value={(value.selectedItem.name == 'IATA Fee' ? Number(props.calculation(5, value.min, value.selectedUnit?.id))?.toFixed(2) : Number(props.calculation(value.rate, value.min, value.selectedUnit?.id))?.toFixed(2))}
                    label='Calculation'
                    fullWidth
                    size='small'
                    className='text_disabled'
                    readonly
                    style={{ background: "#80808026" }}
                    />
            </TableCell>
            }
            {props.shipmentType != 3 &&!isNaN((props.calculation(value?.rate, value?.min, value?.selectedUnit?.id) / listOfExchangeRates.find(u => u.id === value?.selectedCurrency?.id)?.oneUsd)?.toFixed(2)) && <TableCell style={{ width: '10vw', border: "1px solid", borderLeft: "none" }}>
                <TextField
                    lg={12}
                    md={12}
                    sm={12}
                    value={((value.selectedItem.name == 'IATA Fee' ? props.calculation(5, value.min, value.selectedUnit?.id) : props.calculation(value.rate, value.min, value.selectedUnit?.id)) / listOfExchangeRates.find(u => u.id === value.selectedCurrency?.id)?.oneUsd)?.toFixed(2)}
                    label={'USD'}
                    fullWidth
                    size='small'
                    className='text_disabled'
                    readonly
                    style={{ background: "#80808026" }}
                    />
            </TableCell>
            }
            {props.shipmentType != 3 &&isNaN(((value.selectedItem.name == 'IATA Fee' ? props.calculation(5, value.min, value.selectedUnit?.id) : props.calculation(value.rate, value.min, value.selectedUnit?.id)) / listOfExchangeRates.find(u => u.id === value.selectedCurrency?.id)?.oneUsd)?.toFixed(2)) && <TableCell style={{ width: '10vw', border: "1px solid", borderLeft: "none" }}>
                    <TextField
                    lg={12}
                    md={12}
                    sm={12}
                        value={0}
                        label='USD'
                        fullWidth
                    size='small'
                    className='text_disabled'
                    readonly
                    style={{ background: "#80808026" }}
                    />
            </TableCell>
            }

            {props.shipmentType == 3 && <TableCell style={{width:"25vw"}}>
                <Grid item style={{ border: '1px solid' }}>
                <TableContainer>
                    <Table>

                        <TableBody>
                                {<TableRow sx={{ '& > *': { borderBottom: 'unset', margin: 0, padding: '5px' } }}>

                                    <TableCell style={{ width: '10vw', border: "1px solid", borderRight: "none" }}>
                                        <TextField
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            value={value.orgValue20}
                                            onChange={event => setValue(prev => ({ ...prev, orgValue20: event.target.value }))}
                                            onBlur={(event) => {
                                                setOrg20ChargesItem(event, "ORG_" + value.index + props.heading)
                                            }}
                                            readOnly={props.data.bOrgValue ? true : false}
                                            label='Original20 price'
                                            className={props.data.bOrgValue ? 'text_disabled' : 'text_normal'}
                                            fullWidth
                                            size='small'
                                            id={"ORG_" + value.index + props.heading}
                                            type={ 'number'}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Calculation 20
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {props?.isTwenty ? ((value.selectedUnit && value.selectedCurrency) ? props.calculation(value?.rate20, value?.min, value.selectedUnit?.id, true) : 0) : ""}
                                        </Typography>
                                    </TableCell>


                                    <TableCell>
                                        <Typography variant='h7'>
                                            USD {props?.isTwenty ? ((value.selectedUnit && value.selectedCurrency) ?(props.calculation(value.rate20, value?.min, value.selectedUnit?.id, true) / listOfExchangeRates.find(u => u.id === value.selectedCurrency?.id)?.oneUsd).toFixed(2):0) : ""}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                }
                                {false&& <TableRow sx={{ '& > *': { borderBottom: 'unset', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            USD
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {(value.selectedUnit && value.selectedCurrency) ?(props.calculation(value.rate20, value.min, value.selectedUnit?.id, true) / listOfExchangeRates.find(u => u.id === value.selectedCurrency?.id)?.oneUsd).toFixed(2):0}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                }
                                { <TableRow sx={{ '& > *': { borderBottom: 'unset', margin: 0, padding: '5px' } }}>
                                    

                                    <TableCell className="lable_style" style={{ width: '10vw', border: "1px solid", borderRight: "none", padding: "10px" }}>
                                        <TextField
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            value={value.orgValue40}
                                            onChange={event => setValue(prev => ({ ...prev, orgValue40: event.target.value }))}
                                            onBlur={(event) => {
                                                setOrg40ChargesItem(event, "ORG_" + value.index + props.heading)
                                            }}
                                            readOnly={props.data.bOrgValue ? true : false}
                                            label='Original 40 price'
                                            className={props.data.bOrgValue ? 'text_disabled' : 'text_normal'}
                                            fullWidth
                                            size='small'
                                            id={"ORG_" + value.index + props.heading}
                                            type={'number'}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Calculation 40
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {props?.isFourty ? ((value.selectedUnit && value.selectedCurrency) ? props.calculation(value?.rate40, value?.min, value.selectedUnit?.id, false):0):""}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            USD  {props?.isFourty ? ((value.selectedUnit && value.selectedCurrency) ? (props.calculation(value?.rate40, value?.min, value.selectedUnit?.id, false) / listOfExchangeRates.find(u => u.id === value?.selectedCurrency?.id)?.oneUsd).toFixed(2):0):""}
                                        </Typography>
                                    </TableCell>
                                </TableRow>}
                                {false&& <TableRow sx={{ '& > *': { borderBottom: 'unset', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            USD 
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {(value.selectedUnit && value.selectedCurrency) ?(props.calculation(value.rate40, value.min, value.selectedUnit?.id, false) / listOfExchangeRates.find(u => u.id === value?.selectedCurrency?.id)?.oneUsd).toFixed(2):0}
                                        </Typography>
                                    </TableCell>
                                </TableRow>}
                            
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Grid>
            </TableCell>
            }
                 
            </TableRow>
    );
};

export default ChargesRow;
