import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, MultilineField, TextField } from '../../style-guide';
import AddCrm from '../CrmList/AddCrm';
import { useDropzone } from 'react-dropzone'
import { LoadingSpinner } from '../LoadingSpinner';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { getBannerEmails, sendmailToMultiple, validateEmail,updateAccountsCRM, sendBulkMail, getCRMsForMail, getProductChargesByProdShipCharge, getLocalChargesByShipProd, addQs, getCRMList, getAirExportAFs, getLclExportImportAFs } from '../../reducers/requestHandler';
import { Grid, TableCell, TableRow, Typography, Box, Button, Checkbox, Radio, FormControlLabel } from '@mui/material';


import { FaAngleDown, FaWindowClose, FaCloudUploadAlt } from "react-icons/fa";
const MailTemplate = props => {
    const { id, airOrigin, airDest, pol, pod, flcType20, flc20, flcType40, flc40, manualVolume, manualWeight, getTotalCostChargeable, getTotalCostSum, incoterm, address, to, from, shipmentDetails, shipmentType, productType, openOrderModalMail, onHandleCancelModalMail, listOfLclQuoatationCost, listOfFclQuotations, listOfQuoatationCost, listOfAirLine } = props;
    const ref_input2 = useRef();
    const { admin } = useSelector(state => state.session);
    const dispatch = useDispatch();
    const { listOfFLCTypes40, headerAirImport, footerAirImport, headerAirExport, footerAirExport, headerLclExport, headerLclImport, footerLclImport, footerLclExport, headerFclExport, headerFclImport, footerFclImport, footerFclExport } = useSelector(state => state.parameter);
    const [isLoading, setIsLoading] = useState(false);
    const [allSelectedMail1, setallSelectedMail1] = useState([]);
    const [allSelectedMail, setallSelectedMail] = useState([]);
    const [crmsForMail, setcrmsForMail] = useState([]);
    const [mailContent, setMailContent] = useState("");
    const [failedMailList, setfailedMailList] = useState("");
    const [succesMailList, setsuccesMailList] = useState("");
    const [selectedMails, setSelectedMails] = useState("");
    const [toMails, setToMails] = useState();
    const [message, setMessage] = useState("");
    const [header, setHeader] = useState("");
    const [footer, setFooter] = useState("");
    const [isUp, setIsUp] = useState(false);
    const [isUpFooter, setIsUpFooter] = useState(false);
    const [costShipmentDetails, setCostShipmentDetails] = useState(props.shipmentDetails ? props.shipmentDetails : []);
    const [openOrderModalSentMail, setOpenOrderModalSentMail] = useState(false);
    const onHandleOpenModalSentMail = () => {
        setOpenOrderModalSentMail(true);
    };
    const onHandleCancelModalSentMail = () => setOpenOrderModalSentMail(false);
    const areValidateEmail = () => {
        var toMArr = toMails?.split(",")
        var bV = true;
        for (var i = 0; i < toMArr?.length; i++) {
            if (toMArr[i]) {
                bV = validateEmail(toMArr[i])
            }
        }
        return bV;
    };
    const getValidateEmail = () => {
        var toMArr = toMails?.split(",")
        var bV = true;
        var t = [];
        for (var i = 0; i < toMArr?.length; i++) {
            if (toMArr[i]) {
                bV = validateEmail(toMArr[i])
                if (bV) {
                    t.push(toMArr[i])
                }
            }
        }
        return t;
    };

    const onDropHeader = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                setHeader(reader.result)
                setIsUp(true)

            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])
    const { getRootProps: getRootHeader, getInputProps: getInputHeader } = useDropzone({
        accept: 'image/*',
        onDrop: onDropHeader,
    });
    const onDropFooter = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            // var file = acceptedFiles[0];
            var extension = "";
            var type = "";
            var name = "";
            type = file.type;
            const reader = new FileReader()

            const rABS = !!reader.readAsBinaryString;
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (e) => {
                // var base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                //setValue(prev => ({ ...prev, PriceList: { "file": e.target.result, type: type, ext: extension } }));
                setFooter(reader.result)
                setIsUpFooter(true)

            }
            reader.readAsDataURL(file)
            extension = file.name.split('.').pop();
        })


    }, [])
    const { getRootProps: getRootFooter, getInputProps: getInputFooter } = useDropzone({
        accept: 'image/*',
        onDrop: onDropFooter,
    });
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                pieces: props.data.pieces,
                length: props.data.length,
                width: props.data.width,
                height: props.data.height,
                weight: props.data.weight,
            }
            : {
                id: null,
                pieces: null,
                length: null,
                width: null,
                height: null,
                weight: null,
            },
    );
    const handleParams = (event) => {
        setValue(prev => ({ ...prev, pieces: event.target.value }))
        window.setTimeout(function () { ref_input2.current.focus() },1)
        // setValue(prev => ({ ...prev, params: t }))
    }
    const setDetails = (event) => {
        if (value.pieces && value.length && value.width && value.height && value.weight)
            props.setDetails(props.index, value)
    }
    useEffect(() => {
        //props.setDetails(props.index,value)
        getCRMsForMail(dispatch, admin, function (data) {
            setcrmsForMail(data)
            setMailCOntent(data)
        });
        if (productType == 2 && shipmentType == 1) {
            setHeader(headerAirImport?.params?.base64)
            setFooter(footerAirImport?.params?.base64)
        }
        if (productType == 1 && shipmentType == 1) {
            setHeader(headerAirExport?.params?.base64)
            setFooter(footerAirExport?.params?.base64)
        }
        if (productType == 2 && shipmentType == 2) {
            setHeader(headerLclImport?.params?.base64)
            setFooter(footerLclImport?.params?.base64)
        }
        if (productType == 1 && shipmentType == 2) {
            setHeader(headerLclExport?.params?.base64)
            setFooter(footerLclExport?.params?.base64)
        }
        if (productType == 2 && shipmentType == 3) {
            setHeader(headerFclImport?.params?.base64)
            setFooter(footerFclImport?.params?.base64)
        }
        if (productType == 1 && shipmentType == 3) {
            setHeader(headerFclExport?.params?.base64)
            setFooter(footerFclExport?.params?.base64)
        }
    }, []);

    const [mType1, setMType] = useState("");
    const [greeting, setGreeting] = useState("");
    const setMailCOntent = function (crmsForMail) {
        var fMailList = [];
        var sMailList = [];
        var allMails = [];
        var listOfQuoatationCost1 = [];
        var mType = "";
        var text = "";
        var name = "";
        if (shipmentType != 3) {
            for (var i = 0; i < listOfLclQuoatationCost.length; i++) {
                if (listOfLclQuoatationCost[i].selected) {
                    // var crm = listOfLclQuoatationCost[i]
                    var crm = crmsForMail.find(u => u.id === listOfLclQuoatationCost[i].id)
                    name = crm?.companyName;
                    crm.isLclAgent = true;
                    crm.selected = true
                    var bAdd = false;
                    for (var j = 0; j < listOfQuoatationCost1.length; j++) {
                        if (listOfQuoatationCost1[j].name.trim() == name.trim()) {
                            bAdd = true;
                        }
                    }
                    if (!bAdd) {
                        listOfQuoatationCost1.push({ ...crm, name: name })
                    }


                }
            }
            for (var i = 0; i < listOfQuoatationCost.length; i++) {
                if (listOfQuoatationCost[i].selected) {
                    var crm = {}
                    if (shipmentType == 1) {
                        if (listOfQuoatationCost[i].importExportType == 1) {
                            mType = "AirLine"

                            var airName = listOfAirLine.find(u => u.id === listOfQuoatationCost[i].airLineId)?.name;
                            name = airName;
                            crm = crmsForMail.find(u => u.companyName.trim() === airName.trim())
                        } else if (listOfQuoatationCost[i].importExportType == 2) {
                            mType = "Agent"
                            crm = crmsForMail.find(u => u.id === listOfQuoatationCost[i].crmId)
                            name = crm?.companyName;
                        }
                    } else {
                        mType = "Co-loader"
                        crm = crmsForMail.find(u => u.id === listOfQuoatationCost[i].crmId)
                        name = crm?.companyName;
                    }
                    var bAdd = false;
                    for (var j = 0; j < listOfQuoatationCost1.length; j++) {
                        if (listOfQuoatationCost1[j].name.trim() == name.trim()) {
                            bAdd = true;
                        }
                    }
                    if (!bAdd) {
                        listOfQuoatationCost1.push({ ...listOfQuoatationCost[i], name: name })
                    }


                }
            }
        } else {

            for (var i = 0; i < listOfFclQuotations.length; i++) {
                if (listOfFclQuotations[i].selected) {
                    // var crm = listOfLclQuoatationCost[i]
                    var crmIdTemp1 = listOfFclQuotations[i].agentCrmId ? listOfFclQuotations[i].agentCrmId : listOfFclQuotations[i].shippingCrmId;
                    var crm = crmsForMail.find(u => u.id === crmIdTemp1)
                    name = crm?.companyName;
                    crm.crmId = crm?.id;
                    crm.selected = true
                    var bAdd = false;
                    for (var j = 0; j < listOfQuoatationCost1.length; j++) {
                        if (listOfQuoatationCost1[j].name.trim() == name.trim()) {
                            bAdd = true;
                        }
                    }
                    if (!bAdd) {
                        listOfQuoatationCost1.push({ ...crm, name: name })
                    }


                }
            }
            for (var i = 0; i < listOfFclQuotations.length; i++) {
                if (listOfFclQuotations[i].selected) {
                    // var crm = listOfLclQuoatationCost[i]
                    var crm = crmsForMail.find(u => u.id === listOfFclQuotations[i].shippingCrmId)
                    name = crm?.companyName;
                    crm.crmId = crm?.id;
                    crm.selected = true
                    var bAdd = false;
                    for (var j = 0; j < listOfQuoatationCost1.length; j++) {
                        if (listOfQuoatationCost1[j].name.trim() == name.trim()) {
                            bAdd = true;
                        }
                    }
                    if (!bAdd) {
                        listOfQuoatationCost1.push({ ...crm, name: name })
                    }


                }
            }

        }

        for (var i = 0; i < listOfQuoatationCost1.length; i++) {
            if (listOfQuoatationCost1[i].selected) {
                var crm = {}
                if (!listOfQuoatationCost1[i].isLclAgent) {



                    if (shipmentType == 1) {
                        if (listOfQuoatationCost1[i].importExportType == 1) {
                            mType = "AirLine"

                            var airName = listOfAirLine.find(u => u.id === listOfQuoatationCost1[i].airLineId)?.name;
                            name = airName;
                            crm = crmsForMail.find(u => u.companyName.trim() === airName.trim())
                        } else if (listOfQuoatationCost1[i].importExportType == 2) {
                            mType = "Agent"
                            crm = crmsForMail.find(u => u.id === listOfQuoatationCost1[i].crmId)
                            name = crm?.companyName;
                        }
                    } else {
                        mType = "Co-loader"
                        crm = crmsForMail.find(u => u.id === listOfQuoatationCost1[i].crmId)
                        name = crm?.companyName;
                    }
                } else {
                    mType = "Agent"
                    crm = listOfQuoatationCost1[i]
                    name = crm?.companyName;
                }
                setMType(mType)
                var accounts = crm?.accounts;
                var email = "";
                var cName = "";
                var ind = "";
                var indexes = [0, 1]
                for (var k = 0; k < indexes.length; k++) {
                    // need to add logic based on importExport
                    ind = k;
                    if (accounts?.length) {
                        if (productType == 2) {
                            //import contact 1
                            email = accounts[k]?.mail
                            cName = accounts[k]?.name
                        } else {
                            //export contact 1
                            email = accounts[k + 2]?.mail
                            cName = accounts[k + 2]?.name
                        }
                    }
                    /*for (var j = 0; j < accounts?.length; j++) {
                        if (accounts[j].mail) {
                            email = accounts[j].mail;
                            cName = accounts[j].name;
                            break;
                        }
                    }*/
                    if (name) {
                        var t = {}
                        if (email) {
                            sMailList.push({
                                email: email,
                                name: name,
                                cName: cName,
                                id: crm?.id,
                                ind: ind,
                                disabled: crm ? false : true,
                            })
                            t = {
                                email: email,
                                name: name,
                                id: crm?.id,
                                ind: ind,
                                disabled: crm ? false : true,
                            }
                        } else {
                            fMailList.push({
                                email: "",
                                name: name,
                                id: crm?.id,
                                ind: ind,
                                disabled: crm ? false : true,
                            })
                            t = {
                                email: "",
                                name: name,
                                id: crm?.id,
                                ind: ind,
                                disabled: crm ? false : true,
                            }
                        }

                        console.log(t)
                        allMails.push(t)
                    }

                }
                //MailAirExportAF(dispatch, snackbarToggle, { id: airImportExport[i].id }, admin, getAirCostData)
            }
        }

        function uniqueByKey(array, key) {
            return [...new Map(array.map((x) => [x[key], x])).values()];
        }
        fMailList = uniqueByKey(fMailList, 'email')
        //sMailList = uniqueByKey(sMailList, 'email')
        //allMails = uniqueByKey(allMails, 'email')
        var allMails1 = uniqueByKey(allMails, 'name')
        setallSelectedMail(allMails)
        setallSelectedMail1(allMails1)
        setfailedMailList(fMailList)
        setsuccesMailList(sMailList)
        
        //onHandleOpenModalSentMail();
    }
   /* const getTotalCostSum = function () {
        var tot = 0;
        for (var i = 0; i < costShipmentDetails.length; i++) {
            tot = tot + costShipmentDetails[i].weight * costShipmentDetails[i].pieces;
        }

        return tot;
    }*/
    const [accounts, setAccounts] = useState(value.accounts && value.accounts.length ? value.accounts : [{ type: "Contact", name: "", position: "", mail: "", mobile: "", landline: "" }, { type: "Contact", name: "", position: "", mail: "", mobile: "", landline: "" }, { type: "Contact", name: "", position: "", mail: "", mobile: "", landline: "" }, { type: "Contact", name: "", position: "", mail: "", mobile: "", landline: "" }, { type: "Accounts", name: "", position: "", mail: "", mobile: "", landline: "" }, { type: "Manager level", name: "", position: "", mail: "", mobile: "", landline: "" }]);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const [selectedCrm, setSelectedrm] = useState({});
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);



    return (
        <Dialog maxWidth='md' open={openOrderModalMail} title={" Details"} onHandleCancel={onHandleCancelModalMail}>
            {isLoading && < div className="mailLoader"> <LoadingSpinner /></div>}

            {openOrderModal && (
                <Dialog open={openOrderModal} title={'Crm- ' + selectedCrm.name} onHandleCancel={onHandleCancelModal}>
                   
                    <Grid container item sx={{ minWidth: "45vw" }} spacing={2} justifyContent={'flex-end'}>
                        < TextField
                            required
                            lg={4}
                            value={selectedCrm.accountItem?.type}
                            label='Type'
                            readOnly={true}
                        />
                        <TextField
                            required
                            lg={4}
                            value={selectedCrm.accountItem?.name}
                            label='Name'
                            onChange={event => {
                                //event.target.value
                                var tCrm = JSON.parse(JSON.stringify(selectedCrm))
                                tCrm.accountItem.name = event.target.value
                                setSelectedrm(tCrm)
                            }}
                        />
                        <TextField
                            required
                            lg={4}
                            value={selectedCrm.accountItem?.position}
                            label='Position'
                            onChange={event => {
                                //event.target.value
                                var tCrm = JSON.parse(JSON.stringify(selectedCrm))
                                tCrm.accountItem.position = event.target.value
                                setSelectedrm(tCrm)
                            }}
                        />
                        <TextField
                            required
                            lg={4}
                            value={selectedCrm.accountItem?.mail}
                            label='Mail'
                            onChange={event => {
                                //event.target.value
                                var tCrm = JSON.parse(JSON.stringify(selectedCrm))
                                tCrm.accountItem.mail = event.target.value
                                setSelectedrm(tCrm)
                            }}
                        />

                        <TextField
                            required
                            lg={4}
                            value={selectedCrm.accountItem?.mobile}
                            label='Mobile'
                            onChange={event => {
                                //event.target.value
                                var tCrm = JSON.parse(JSON.stringify(selectedCrm))
                                tCrm.accountItem.mobile = event.target.value
                                setSelectedrm(tCrm)
                            }}
                        />

                        <TextField
                            
                            lg={4}
                            value={selectedCrm.accountItem?.landline}
                            label='Landline'
                            onChange={event => {
                                //event.target.value
                                var tCrm = JSON.parse(JSON.stringify(selectedCrm))
                                tCrm.accountItem.landline = event.target.value
                                setSelectedrm(tCrm)
                            }}
                        />
                        <Button variant='contained' sx={{ height: "4.7vh", mt: 2, ml: 2, background: '#F56C6C !important' }} onClick={() => {
                            console.log(accounts[0])
                            var crm = crmsForMail.find(u => u.id === selectedCrm.id)

                            var tAccounts = JSON.parse(JSON.stringify(crm?.accounts))
                           // console.log(tAccounts[0])
                            if (!tAccounts) {
                                tAccounts = accounts
                            }
                            if (tAccounts) {
                                if (selectedCrm.accountItem?.type == 'Import Contact 1') {
                                    tAccounts[0] = selectedCrm.accountItem;
                                } else if (selectedCrm.accountItem?.type == 'Import Contact 2') {
                                    tAccounts[1] = selectedCrm.accountItem;
                                } else if (selectedCrm.accountItem?.type == 'Export Contact 1') {
                                    tAccounts[2] = selectedCrm.accountItem;
                                } else if (selectedCrm.accountItem?.type == 'Export Contact 2') {
                                    tAccounts[3] = selectedCrm.accountItem;
                                }
                                //console.log(tAccounts[0]?.mail)
                                console.log(tAccounts)

                                var temp = {
                                    id: selectedCrm.id,
                                    accounts: tAccounts,
                                }
                                setIsLoading(true)
                                updateAccountsCRM(dispatch, snackbarToggle, temp, admin, function (resp) {
                                    setIsLoading(false)
                                    if (resp.misc.CODE == 1) {
                                        dispatch(snackbarToggle({ type: 'success', message: 'Mail updated successfully' }));
                                        getCRMsForMail(dispatch, admin, function (data) {
                                            setcrmsForMail(data)
                                            setMailCOntent(data)
                                        });
                                        onHandleCancelModal()
                                    }

                                });
                            }
                        }}>
                            Add contact to {selectedCrm.name}
                        </Button>
                    </Grid>
                </Dialog>
            )}



            {/* {greeting}*/}
            {/*<div style={{ width: "600px" }} > <img src={`${header}`} /></div>*/}
            Hi,<br/>
            Please send your rate for below shipment:<br /><br />
            <span className="font_15" style={{ fontWeight: "bold" }}>From:</span><br />
            <span>{from}</span><br /><br />
            <span className="font_15" style={{ fontWeight: "bold", fontSize: "15px !important" }}>To:</span><br />
            <span>{to}</span><br /><br />

            {productType == 2 &&<>
                {incoterm &&<><span className="font_15" style={{ fontWeight: "bold", fontSize: "15px !important" }}>Shipment incoterms:</span><br /></>}
                <span>{incoterm ? incoterm : ""}</span><br /><br />

                {incoterm == 'EXW' && <> <span className="font_15" style={{ fontWeight: "bold", fontSize: "15px !important" }}>Pickup address:</span><br />
                    <span>{address ? address :""}</span><br /><br /></>}

            </>
            }
            <span className="font_15" style={{ fontWeight: "bold", fontSize: "15px !important" }}>Remark:</span><br /><br />
            <Grid container item style={{ minWidth: "45vw" }}> <MultilineField
                lg={12}
                md={12}
                rows={2}
                label='Your Remarks'
                value={message}
                onChange={event => { setMessage(event.target.value) }}
            /></Grid>
            <br/>
            {<> <span className="font_15" style={{ fontWeight: "bold", fontSize: "15px !important" }}>Shipment Details:</span><br /><br /></>}{/*{getTotalCostSum()}<br /><br />*/}
            {shipmentType == 3 && <>
                <span style={{ fontWeight: "bold", fontSize: "15px !important" }}>FLC Types 40: </span>{ flcType40} <br /><span style={{ fontWeight: "bold", fontSize: "15px !important" }}>Quantity 40:</span> {flc40} <br />
                <span style={{ fontWeight: "bold", fontSize: "15px !important" }}>FLC Types 20: </span>{flcType20}<br /> <span style={{ fontWeight: "bold", fontSize: "15px !important" }}>Quantity 20:</span> {flc20} <br />
                

            </>}
            {shipmentType != 3 && <>
                <>{
                    !manualWeight? <table className="shipMentDetails">
                        <tr><th style={{ textAlign: "center", borderBottom: "1px solid black" }}>Pieces</th><th style={{ textAlign: "center", borderBottom: "1px solid black" }}>L(cm)</th><th style={{ textAlign: "center", borderBottom: "1px solid black" }}>W(cm)</th><th style={{ textAlign: "center", borderBottom: "1px solid black" }}>H(cm)</th><th style={{ textAlign: "center", borderBottom: "1px solid black" }}>Weight per piece (kg)</th></tr>

                        {costShipmentDetails.length > 0 &&
                            costShipmentDetails.map((cost, index) => (
                                <tr key={index}>
                                    <td style={{ textAlign: "center", borderBottom: "1px solid black" }}>{cost.pieces}</td>
                                    <td style={{ textAlign: "center", borderBottom: "1px solid black" }}>{cost.length}</td>
                                    <td style={{ textAlign: "center", borderBottom: "1px solid black" }}>{cost.width}</td>
                                    <td style={{ textAlign: "center", borderBottom: "1px solid black" }}>{cost.height}</td>
                                    <td style={{ textAlign: "center", borderBottom: "1px solid black" }}>{cost.weight}</td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ textAlign: "center", fontWeight: "bold" }}>Sum - Total Weight</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ textAlign: "center", borderBottom: "1px solid black", fontWeight: "bold" }}>{getTotalCostSum()}</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ textAlign: "center", fontWeight: "bold" }}>Chargeable Weight</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ fontWeight: "bold" }}></td>
                            <td style={{ textAlign: "center", borderBottom: "1px solid black", fontWeight: "bold" }}>{getTotalCostChargeable()}</td>
                        </tr>
                    </table> : <>Manual weight: {getTotalCostSum()}<br />
                            Manual volume: {manualVolume}
                    </>}
                </>
                <br /></>}
            

            <Grid container item style={{ marginTop: '15px' }}>
                <TextField
                    lg={12}
                    value={toMails}
                    onChange={event => setToMails(prev => (event.target.value))}
                    label='To'
                />
                {!areValidateEmail(toMails) && <Grid style={{color:"red",marginTop:"5px"}} item>
                    Please Enter Valid Comma Saperated Mails.
                </Grid>}
            </Grid>
            <br />
            {/*<div style={{ width: "600px" }} > <img src={`${footer}`} /></div>*/}
            {false&&<Grid lg={12} container item>
                <Grid lg={5} container item justifyContent="center"   {...getRootHeader()}
                    style={{ background: isUp ? 'green' : '#1565c0', cursor: isUp ? 'pointer' : 'pointer', borderRadius: "5px", paddingBottom: "2%", marginLeft: "2%", marginTop: "1.5%" }}>
                    <FaCloudUploadAlt style={{ fill: "white", marginTop: "5%", marginRight: "3%" }} /><div style={{ color: "white", marginTop: "5%" }}> Upload Header </div>
                    {<input {...getInputHeader()} />}
                </Grid>
                <Grid lg={5} container item justifyContent="center"   {...getRootFooter()}
                    style={{ background: isUpFooter ? 'green' : '#1565c0', cursor: isUpFooter ? 'pointer' : 'pointer', borderRadius: "5px", paddingBottom: "2%", marginLeft: "2%", marginTop: "1.5%" }}>
                    <FaCloudUploadAlt style={{ fill: "white", marginTop: "5%", marginRight: "3%" }} /><div style={{ color: "white", marginTop: "5%" }}> Upload Footer </div>
                    {<input {...getInputFooter()} />}
                </Grid>
            </Grid>
            }

            <br/>
            <br />

            <Grid container item style={{ minWidth: "45vw" }} justifyContent="flex-end">
                <Button variant='contained' sx={{ mt: 1, height: "4.7vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {
                    
                    //var type = (shipmentType == 1 ? "AIR " : (shipmentType == 2 ? "LCL " : "FCL ")) + (productType == 2 ? "Import " : "Export ") + (shipmentType != 3 ? mType1:"") ;
                    var type = (shipmentType == 1 ? "AIR " : (shipmentType == 2 ? "LCL " : "FCL ")) + (productType == 2 ? "Import" : "Export");
                    var subject = props.id ? props.id + " : " : "";
                    subject = subject + type + " : " + (shipmentType == 1 ? airOrigin : pol) + " to " + (shipmentType == 1 ? airDest : pod);
                    //subject = subject + " : " + (shipmentType == 1 ? "Weight: " + getTotalCostSum() + "Kg" : (shipmentType == 2 ? "Volume: " + getTotalCostSum() + "m3" : "Containers: " + + (Number(flc20 ? flc20 : 0) + Number(flc40 ? flc40 : 0))))
                    subject = subject + " : " + (shipmentType == 1 ? "Weight: " + getTotalCostChargeable() + "Kg" : (shipmentType == 2 ? "Volume: " + getTotalCostSum() + "m3" : "Containers: " + + (Number(flc20 ? flc20 : 0) + Number(flc40 ? flc40 : 0))))
                    console.log(shipmentDetails)
                    var details = "<table><tr><th style='text-align: center;border-bottom: 1px solid black;'>Pieces</th><th style='text-align: center;border-bottom: 1px solid black;'>L(cm)</th><th style='text-align: center;border-bottom: 1px solid black;'>W(cm)</th><th style='text-align: center;border-bottom: 1px solid black;'>H(cm)</th><th style='text-align: center;border-bottom: 1px solid black;'>Weight per piece (kg)</th></tr>"

                    for (var selectedInd = 0; selectedInd < costShipmentDetails.length; selectedInd++) {
                        var s =
                            "<tr>" +
                            "<td style='text-align: center;border-bottom: 1px solid black;'>" + costShipmentDetails[selectedInd].pieces + "</td>" +
                            "<td style='text-align: center;border-bottom: 1px solid black;'>" + costShipmentDetails[selectedInd].length + "</td>" +
                            "<td style='text-align: center;border-bottom: 1px solid black;'>" + costShipmentDetails[selectedInd].width + "</td>" +
                            "<td style='text-align: center;border-bottom: 1px solid black;'>" + costShipmentDetails[selectedInd].height + "</td>" +
                            "<td style='text-align: center;border-bottom: 1px solid black;'>" + costShipmentDetails[selectedInd].weight + "</td>" +
                            "</tr>"
                        details = details + s;
                        //tot = tot + costShipmentDetails[selectedInd].weight * costShipmentDetails[selectedInd].pieces;
                    }

                    var total = "<tr>" +
                        "<td >" + "" + "</td>" +
                        "<td >" + "" + "</td>" +
                        "<td >" + "" + "</td>" +
                        "<td >" + "" + "</td>" +
                        "<td style='text-align: center;font-weight:bold;'>" + "Sum - Total Weight" + "</td>" +
                        "</tr>" +
                        "<tr>" +
                        "<td >" + "" + "</td>" +
                        "<td >" + "" + "</td>" +
                        "<td >" + "" + "</td>" +
                        "<td >" + "" + "</td>" +
                        "<td style='text-align: center;border-bottom: 1px solid black;font-weight:bold;'>" + getTotalCostSum() + "</td>" +
                        "</tr>"
                        +
                        "<tr>" +
                        "<td >" + "" + "</td>" +
                        "<td >" + "" + "</td>" +
                        "<td >" + "" + "</td>" +
                        "<td >" + "" + "</td>" +
                        "<td style='text-align: center;font-weight:bold;'>" + "Chargeable Weight" + "</td>" +
                        "</tr>" +
                        "<tr>" +
                        "<td >" + "" + "</td>" +
                        "<td >" + "" + "</td>" +
                        "<td >" + "" + "</td>" +
                        "<td >" + "" + "</td>" +
                        "<td style='text-align: center;border-bottom: 1px solid black;font-weight:bold;'>" + getTotalCostChargeable() + "</td>" +
                        "</tr>"

                    details = details + total + "</table>"
                    // details = "" + getTotalCostSum();
                    if (manualWeight) {
                        details = "Manual Weight: " + manualWeight + "<br/>ManualVolume: " + manualVolume;
                    }
                    if (shipmentType == 3) {
                        details = "<b>FCL Types 40: </b>" + flcType40 + "<br/>  <b>Quantity 40:</b> " + flc40 + " <br/> "
                        details = details + "<b>FLC Types 20: </b>" + flcType20 + "<br/>  <b>Quantity 20:</b> " + flc20 + " <br/>  "
                    }
                    //  var from =from;
                    var add = "";
                    var exw = incoterm ? incoterm:"";
                    if (exw == 'EXW') {
                        add = "<h3>Pickup address:</h3>" + (address ? address : "") + "<br/>";
                    }
                    var shipmentInco = (exw ? "<h3>Shipment incoterms:</h3>" : "") ;
                    if (productType == 2) {
                        shipmentInco = shipmentInco + (exw ? exw : "");
                    }

                    var header1 = '<div style="width=600px"> <img src="cid:header.jpg"/></div>'
                    var footer1 = '<div style="width=600px"> <img src="cid:footer.jpg"/></div>'
                   // var text = "<div>Hi,<br/>Please send your rate for below shipment:<br/><h3>" + "From:" + "</h3>" + from + "<h3>To:</h3>" + to + shipmentInco + add + "<h3>Shipment Details:</h3>" + details + "</h1><br/><h3>Remarks:</h3>" + message + "<br/> Thanks a lot</div>";
                    var text = "Please send your rate for below shipment:<br/><h3>" + "From:" + "</h3>" + from + "<h3>To:</h3>" + to + shipmentInco + add + "<br/>" + (message ? ("<h3>"+message+"</h3><br/>"):"") + "<h3>Shipment Details:</h3>" + details + "</h1><br/><br/> Thanks a lot</div>";
                    var text1 = "<div>Hello,<br/>Please send your rate for below shipment:<br/><br/><b>" + "From:" + "</b>" + from + "<br/><b>To:</b>" + to + shipmentInco + add + "<br/>" + (message ? ("<h3 style=\"color: blue\">" + message +"</h3><br/>") : "") + (shipmentType == 3 ? "<br/><b>Shipment Details:</b><br/><br/>" : "<br/><b>Shipment Details:</b><br/><br/>") + details + "<br/><br/> Thanks a lot<br/>" + (admin?.data?.user?.firstName ? admin?.data?.user?.firstName : "") + " " + (admin?.data?.user?.last_name ? admin?.data?.user?.last_name  : "")+"</div>";
                    var tEmails = []
                    var toEmails = []
                    var bccEmails = []
                    succesMailList.forEach(function (element, i) {
                        //cName
                        var tXt = header1 + ((element.cName ? ("<div>Hello " + element.cName + ",") : ("<div>Hi,")) + text) + footer1
                        console.log(tXt)
                        bccEmails.push(element.email)
                        tEmails.push({
                            email: element.email,
                            header: header,
                            footer: footer,
                            message: tXt
                        })
                    })
                    var tValidToMails = getValidateEmail();
                    if (!tValidToMails) {
                        tValidToMails = [];
                    }
                    tValidToMails.push(admin?.data?.user?.email)
                    tValidToMails.forEach(function (element, i) {
                        //cName
                        var tXt = header1 + ((element.cName ? ("<div>Hello " + element.cName + ",") : ("<div>Hi,")) + text) + footer1
                        console.log(tXt)
                        toEmails.push(element)
                        tEmails.push({
                            email: element,
                            header: header,
                            footer: footer,
                            message: tXt
                        })
                    })
                    var hd = null, ft = null;
                    getBannerEmails(dispatch, { importExportType: productType, shipmentType: shipmentType, type: 1, sessionId: admin.data.session }
                        , function (data) {
                            if (data?.data?.length) {
                                ft = productType + "" + shipmentType + "" + data.data[0].params.base64;
                                if (ft) {
                                    text1 = text1 + footer1;
                                }
                            }
                            getBannerEmails(dispatch, { importExportType: productType, shipmentType: shipmentType, type: 0, sessionId: admin.data.session }
                                , function (data) {
                                    if (data?.data?.length) {
                                        hd = productType + "" + shipmentType + "" + data.data[0].params.base64;
                                        if (hd) {
                                            text1 = header1 + text1;
                                        }
                                    }
                                    setIsLoading(true)
                                    sendmailToMultiple(dispatch, admin, toEmails, bccEmails, text1, subject, hd, ft, function (resp) {
                                        setIsLoading(false)
                                        var tAllMails = JSON.parse(JSON.stringify(allSelectedMail))
                                        for (var k = 0; k < tAllMails.length; k++) {
                                            for (var j = 0; j < resp.length; j++) {
                                                if (resp[j].email == tAllMails[k].email) {
                                                    if (resp[j].code == 1) {
                                                        tAllMails[k].message = "Mail sent succesfully to " + tAllMails[k].email;
                                                    } else {
                                                        tAllMails[k].message = "Mail failed to " + tAllMails[k].email;
                                                    }
                                                }
                                            }
                                        }
                                        setallSelectedMail(tAllMails);
                                    })
                                })
                        })
                    
                    //onHandleCancelModalMail()
                }}>
                    Send
                </Button>
            </Grid>

            <Grid container item style={{ fontWeight: "bold", fontSize: "15px !important" }}>
                <Grid container item lg={6}>
                    <Grid container item lg={6}>
                        Selected Crm
                    </Grid>
                    <Grid container item lg={6}>
                        Contacts
                    </Grid>
                </Grid>
                <Grid container item lg={6}>
                    Status
                </Grid>
                </Grid>
            {/*{succesMailList.length > 0 &&
                succesMailList.map((data, index) => (
                    <Button title={data.email} disabled={data.disabled} variant='contained' sx={{ mt: 1, height: "4.7vh", mb: 1, mr: 2 }} onClick={() => {
                        setSelectedrm(data)
                        //onHandleCancelModalMail()
                        onHandleOpenModal()
                    }}>
                        {data.name}
                    </Button>
                ))
            }*/}

            {allSelectedMail.length > 0 &&
                allSelectedMail.map((data, index) => (
                    <Grid container item>
                        <Grid container item lg={6}>
                            <Grid container item lg={6}>
                                <Button title={data.disabled ? 'Crm does not exist.' : (data.email ? data.email : "E-Mail does not exists.")} variant='outlined' sx={{ mt: 1, height: "5vh", mb: 1, mr: 2, cursor:"default",p:1}} onClick={() => {
                            }}>
                                {data.name}
                                </Button>
                            </Grid>
                            <Grid container item lg={6}>
                                <Button title={data.disabled ? 'Crm does not exist.' : (data.email ? data.email : "E-Mail does not exists.")} variant='contained' sx={{ mt: 1, height: "4.7vh", mb: 1, mr: 2, background: !data.disabled ? (data.email ? '' : '#F56C6C !important') :'#59575747' }} onClick={() => {
                                // setSelectedrm(crmsForMail.find(u => u.id === data.id))
                                console.log(allSelectedMail)
                                if (!data.disabled) {
                                    var crm = crmsForMail.find(u => u.id === data.id)
                                   
                                    var tAccounts = crm.accounts;
                                    var tCrmAcc = crm?.accounts?.find(u => u.mail === data.email);
                                    if (!tAccounts) {
                                        tAccounts = accounts
                                    }
                                    if (tAccounts) {
                                        if (productType == 2 && data.ind == 0) {
                                            tCrmAcc = { type: "Import Contact 1", name: tAccounts[0]?.name, position: tAccounts[0]?.position, mail: tAccounts[0]?.mail, mobile: tAccounts[0]?.mobile, landline: tAccounts[0]?.landline }
                                        }
                                        if (productType == 2 && data.ind == 1) {
                                            tCrmAcc = { type: "Import Contact 2", name: tAccounts[1].name, position: tAccounts[1].position, mail: tAccounts[1].mail, mobile: tAccounts[1].mobile, landline: tAccounts[1].landline }
                                        }
                                        if (productType == 1 && data.ind == 0) {
                                            tCrmAcc = { type: "Export Contact 1", name: tAccounts[2].name, position: tAccounts[2].position, mail: tAccounts[2].mail, mobile: tAccounts[2].mobile, landline: tAccounts[2].landline }
                                        }
                                        if (productType == 1 && data.ind == 1) {
                                            tCrmAcc = { type: "Export Contact 2", name: tAccounts[3].name, position: tAccounts[3].position, mail: tAccounts[3].mail, mobile: tAccounts[3].mobile, landline: tAccounts[3].landline }
                                        }
                                    }
                                        /*if (productType == 2) {
                                            tCrmAcc = accounts[0]
                                        } else {
                                            tCrmAcc = accounts[2]
                                        }
                                        if (tAccounts && productType == 2) {
                                            tCrmAcc = { type: "Import Contact 1", name: tAccounts[0]?.name, position: tAccounts[0]?.position, mail: tAccounts[0]?.mail, mobile: tAccounts[0]?.mobile, landline: tAccounts[0]?.landline }
                                        }
                                        if (tAccounts && productType == 1) {
                                            tCrmAcc = { type: "Export Contact 1", name: tAccounts[2].name, position: tAccounts[2].position, mail: tAccounts[2].mail, mobile: tAccounts[2].mobile, landline: tAccounts[2].landline }
                                        }*/
                                        var tCrm = {
                                            id: data.id,
                                            accountItem: tCrmAcc,
                                            name: data.name,
                                        }

                                        setSelectedrm(tCrm)
                                        //onHandleCancelModalMail()
                                        onHandleOpenModal()
                                    
                                }
                            }}>
                                {'Edit Contact ' + (data.ind+1)}   
                            </Button>
                        </Grid>
                        </Grid>
                        <Grid container item lg={6} alignItems="center">
                            {(data.email ? data.message : "E-Mail does not exists.") }
                        </Grid>
                    </Grid>
                ))
            }
            {/*{failedMailList.length > 0 &&
                failedMailList.map((data, index) => (
                    <Button title={"Email not found."} disabled={data.disabled} variant='contained' sx={{ mt: 1, height: "4.7vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {
                       // setSelectedrm(crmsForMail.find(u => u.id === data.id))
                        setSelectedrm(data)
                        //onHandleCancelModalMail()
                        onHandleOpenModal()
                    }}>
                        {data.name}
                    </Button>
                ))
            }*/}



           
            {openOrderModalSentMail && (
                <Dialog maxWidth='md' open={openOrderModalSentMail} title={'Mail'} onHandleCancel={onHandleCancelModalSentMail}>
                    <Grid container item style={{ minWidth: "45vw", maxHeight: "25vh", overflowY: "auto" }}>

                        {succesMailList.length > 0 &&
                            succesMailList.map(crm => (
                                <> Mail sent on {crm.email} for {crm.name}.<br /></>
                            ))
                        }
                    </Grid>
                    <Grid container item style={{ minWidth: "45vw", marginTop: "20px", maxHeight: "25vh", overflowY: "auto" }}>

                        {failedMailList.length > 0 &&
                            failedMailList.map(crm => (
                                <> Mail failed for {crm.name} as mail not found .<br /></>
                            ))
                        }
                    </Grid>
                    <Grid container item style={{ minWidth: "40vw" }} justifyContent="flex-end">
                        <Button variant='contained' sx={{ mt: 1, height: "4.7vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {
                            onHandleCancelModalMail()

                            onHandleCancelModalSentMail()
                        }}>
                            OK
                        </Button>
                    </Grid>
                </Dialog>
            )}
        </Dialog>
    );
};

export default MailTemplate;
