import { memo } from 'react';
import { useState } from 'react';
import { TableCell, TableHead, TableRow, Typography, Button } from '@mui/material';
import AddAgentCode from './AddAgentCode';
import { Dialog } from '../../style-guide';

const AgentCodeHeader = () => {
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);

    return (
        <TableHead>
            <TableRow>
                <TableCell />

                <TableCell>
                    <Typography variant='h7'>
                        Description
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h7'>Operations</Typography>
                </TableCell>
                
            </TableRow>
        </TableHead>
    );
};

export default memo(AgentCodeHeader);
