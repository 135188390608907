import { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button,Table, TableBody, TableContainer, Paper, TablePagination } from '@mui/material';
import IncotermHeader from './IncotermHeader';
import IncotermRow from './IncotermRow';
import AddIncoterm from './AddIncoterm';
import { getAdminList, getWarehouseList } from '../Admins/AdminHandler'
import { REQUEST_ACTIONS, sendRequest } from '../../utils/Communicator';
import { useSelector, useDispatch } from 'react-redux';
import { onAdminChange, onWarehouseChange } from '../../reducers/admin-reducers/AdminSlicer';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { Dialog,Snackbar } from '../../style-guide';
import { URL, LANGUAGES } from '../../constants/global-constants';

export const IncotermTable = () => {
    const dispatch = useDispatch();
    const { adminList,  warehouseList, discriptionsList} = useSelector(state => state.admin);
    const { admin} = useSelector(state => state.session);
    const { listOfIncoterms } = useSelector(state => state.parameter);
    const { snackbar } = useSelector(state => state.snackbar);
    const [isLoaded, setIsLoaded] = useState(false);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => {
        setOpenOrderModal(true);
        getAdminList(dispatch, admin);
        getWarehouseList(dispatch, admin);
    };
    const onHandleCancelModal = () => setOpenOrderModal(false);

    // pagination
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const onPageHandleChange = (_, newPage) => {
        setPage(newPage);
    };
    const onRowsPerPageHandleChange = event => {
        setSize(+event.target.value);
        setPage(0);
    };
    

    return (

        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {openOrderModal && (
                <Dialog maxWidth='md' open={openOrderModal} title={`Incoterm`} onHandleCancel={onHandleCancelModal}>
                    <AddIncoterm onHandleCancel={() => setOpenOrderModal(false)} id={null} />
                </Dialog>
            )}
            {adminList.length > 0 && <TableContainer sx={{ height: 'calc(100vh - 135px)' }}>
                <Table>
                    <IncotermHeader />
                    <TableBody>
                        {
                            listOfIncoterms.map(user => (
                                <IncotermRow key={user?.id || uuidv4()} data={user} />
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>}
            {snackbar && (
                <Snackbar
                    open={!!snackbar}
                    message={snackbar.message}
                    type={snackbar.type}
                    onClose={onSnackbarHandleClose}
                />
            )}
        </Paper>


    );
};
export default IncotermTable;
