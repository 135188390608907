import { useState, useEffect, useRef } from 'react';

import { FormGroup, TableCell, TableRow, Typography, Box, Button, Checkbox, Radio, FormControlLabel } from '@mui/material';
import { TextField } from '../../style-guide';
const Accounts = props => {
    const ref_input2 = useRef();
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                type: props.data.type,
                name: props.data.name,
                position: props.data.position,
                mail: props.data.mail,
                mobile: props.data.mobile,
                landline: props.data.landline,
            }
            : {
                id: null,
                type: null,
                name: null,
                position: null,
                mail: null,
                mobile: null,
                landline: null,
            },
    );
    const handleParams = (event) => {
        setValue(prev => ({ ...prev, pieces: event.target.value }))
        window.setTimeout(function () { ref_input2.current.focus() }, 1)
        // setValue(prev => ({ ...prev, params: t }))
    }
    const setDetails = (event) => {
        if (value.pieces && value.length && value.width && value.height && value.weight)
            props.setDetails(props.index, value)
    }
    useEffect(() => {
        //props.setDetails(props.index,value)
    }, [value]);
    return (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell style={{ width: '3.5vw' }} >
                <Typography variant='h7'>
                    <TextField style={{ width: 150 }}
                        lg={10}
                        value={value.type}
                        label=''
                        readOnly={true}
                    />
                </Typography >
            </TableCell>
            <TableCell style={{ width: '3vw' }}>
                <Typography variant='h7'>
                    <TextField style={{ width: '100%',maxWidth:'100% !important' }}
                        value={value.name}
                        lg={10}
                        label=''
                        onChange={function (event) { setValue(prev => ({ ...prev, name: event.target.value })) }}
                        onBlur={function (event) { var t = [...props.accounts]; t[props.index].name = event.target.value; props.setAccounts(t) }}
                    />
                </Typography >
            </TableCell>
            <TableCell style={{ width: '3vw' }}>
                <Typography variant='h7'>
                    <TextField style={{ width: '3vw' }}
                        value={value.position}
                        lg={10}
                        label=''
                        onChange={function (event) { setValue(prev => ({ ...prev, position: event.target.value })) }}
                        onBlur={function (event) { var t = [...props.accounts]; t[props.index].position = event.target.value; props.setAccounts(t) }}
                    />
                </Typography >
            </TableCell>
            <TableCell style={{ width: '4vw' }}>
                <Typography variant='h7'>
                    <TextField style={{ width: '3vw' }}
                        value={value.mail}
                        lg={10}
                        label=''
                        onChange={function (event) { setValue(prev => ({ ...prev, mail: event.target.value })) }}
                        onBlur={function (event) { var t = [...props.accounts]; t[props.index].mail = event.target.value; props.setAccounts(t) }}
                    />
                </Typography >
            </TableCell>
            <TableCell style={{ width: '3vw' }}>
                <Typography variant='h7'>
                    <TextField style={{ width: '3vw' }}
                        style={{ width: '3vw' }}
                        lg={10}
                        value={value.mobile}
                        label=''
                        onChange={function (event) { setValue(prev => ({ ...prev, mobile: event.target.value })) }}
                        onBlur={function (event) { var t = [...props.accounts]; t[props.index].mobile = event.target.value; props.setAccounts(t) }}
                    />
                </Typography >
            </TableCell>
            <TableCell style={{ width: '3vw' }}>
                <Typography variant='h7'>
                    <TextField style={{ width: '3vw' }}
                        value={value.landline}
                        lg={10}
                        label=''
                        onChange={function (event) { setValue(prev => ({ ...prev, landline: event.target.value })) }}
                        onBlur={function (event) { var t = [...props.accounts]; t[props.index].landline = event.target.value; props.setAccounts(t) }}
                    />
                </Typography >
            </TableCell>
        </TableRow>
    );
};

export default Accounts;
