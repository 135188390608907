import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Grid, Button, Typography, TableHead, TableCell, TableRow, Table, TableBody, TableContainer, Paper, TablePagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CostTable } from '../CostList/CostTable';
import { onAdminChange } from '../../reducers/admin-reducers/AdminSlicer';
import { setCurrentPage } from '../../reducers/session/sessionSlice';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { getAdminList, getWarehouseList } from '../Admins/AdminHandler'
import { v4 as uuidv4 } from 'uuid';
import { LoadingSpinner } from '../LoadingSpinner';


export const CompairTable = (props) => {
    const { filteredAdminList, qsListStart, getTotalAvgByUserFcl, isFiltered, getTotalAvgByUserLcl, getTotalAvgByUserAir, getTotal, getTotalAvgByUser, getStatusCountByUser, onHandleUserFilter, setSelectedUser, bLoading } = props;
    return (
        <Grid container item xs={12} justifyContent='space-between' >
            
            <TableContainer sx={{ mt: 3, mr: '2vw', width: '50vw', color: "#909399 !important" }} className="font12">
                {(bLoading) && <div style={{ height:"100%" }}><LoadingSpinner /></div>}
                {!bLoading &&<Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} size={'normal'} padding={'normal'} className="font12">
                    <TableHead className="font12">
                        <TableRow className="font12">
                            <TableCell />
                            <TableCell className="font12 ">
                                <Typography variant='h7'>Name</Typography>
                            </TableCell>
                            <TableCell className="font12">
                                <Typography variant='h7'>Total</Typography>
                            </TableCell>
                            {/* <TableCell className="font12">
                                    <Typography variant='h7'>Prepared</Typography>
                                </TableCell>*/}
                            <TableCell className="font12">
                                <Typography variant='h7'>Pending</Typography>
                            </TableCell>{/*
                                <TableCell className="font12">
                                    <Typography variant='h7'>Pending</Typography>
                                </TableCell>*/}
                            <TableCell className="font12">
                                <Typography variant='h7'>Approved</Typography>
                            </TableCell>
                            <TableCell className="font12">
                                <Typography variant='h7'>Declined</Typography>
                            </TableCell>
                            <TableCell className="font12">
                                <Typography variant='h7'>Success (%)</Typography>
                            </TableCell>
                            <TableCell className="font12">
                                <Typography variant='h7'>Margin ($)</Typography>
                            </TableCell>
                            <TableCell className="font12">
                                <Typography variant='h7'>Air Margin ($)</Typography>
                            </TableCell>
                            <TableCell className="font12">
                                <Typography variant='h7'>Lcl Margin ($)</Typography>
                            </TableCell>
                            <TableCell className="font12">
                                <Typography variant='h7'>Fcl Margin ($)</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            filteredAdminList.map(user => (

                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={user?.id || uuidv4()} id={"admin_" + user.id} onMouseOver={function () { setSelectedUser(user); }} onClick={function () { return; setSelectedUser(user); onHandleUserFilter(user.id); }} >
                                    {isFiltered(user, qsListStart) && <TableCell />}
                                    {isFiltered(user, qsListStart) && < TableCell className="td_gray">
                                        <Typography variant='h7' component='span'>
                                            {user.adminName || '-'}
                                        </Typography>
                                    </TableCell>
                                    }
                                    {isFiltered(user, qsListStart) && <TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            {getTotal(user.id, qsListStart)}
                                        </Typography>
                                    </TableCell>
                                    }
                                    {isFiltered(user, qsListStart) && <TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            {getStatusCountByUser(user.id, 1, qsListStart)}
                                        </Typography>
                                    </TableCell>
                                    }
                                    {isFiltered(user, qsListStart) && false && <TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            {getStatusCountByUser(user.id, 4, qsListStart)}
                                        </Typography>
                                    </TableCell>
                                    }
                                    {isFiltered(user, qsListStart) && <TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            {getStatusCountByUser(user.id, 2, qsListStart)}
                                        </Typography>
                                    </TableCell>
                                    }
                                    {isFiltered(user, qsListStart) && <TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            {getStatusCountByUser(user.id, 3, qsListStart)}
                                        </Typography>
                                    </TableCell>
                                    }
                                    {isFiltered(user, qsListStart) && < TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            {((getStatusCountByUser(user.id, 2, qsListStart) * 100) / getTotal(user.id, qsListStart)).toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                    }
                                    {isFiltered(user, qsListStart) && < TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            {Number(getTotalAvgByUser(user.id, qsListStart) / getTotal(user.id, qsListStart)).toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                    }
                                    {isFiltered(user, qsListStart) && < TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            {Number(getTotalAvgByUserAir(user.id, qsListStart)).toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                    }
                                    {isFiltered(user, qsListStart) && < TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            {Number(getTotalAvgByUserLcl(user.id, qsListStart)).toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                    }
                                    {isFiltered(user, qsListStart) && < TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            {Number(getTotalAvgByUserFcl(user.id, qsListStart)).toFixed(2)}
                                        </Typography>
                                    </TableCell>
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>}
            </TableContainer>
        </Grid>
    );
};

export default CompairTable;
