import { useState, useCallback, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { SelectField, TextField } from '../../style-guide';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { updateExchangeRate } from '../../reducers/requestHandler';
import { Snackbar } from '../../style-guide';

const AddExchangeRate = props => {
    const dispatch = useDispatch(); 
    const { listOfcurrency } = useSelector(state => state.parameter);
    const { admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const st = new Date(new Date().getTime());
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                currency: props.data.currency,
                oneUsd: props.data.oneUsd,
                date: new Date(props.data.date),
            }
            : {
                id: null,
                currency: null,
                oneUsd: null,
                date: st,
            },
    );

    const save = () => {
        updateExchangeRate(dispatch, snackbarToggle, value, admin);
        props.onHandleCancel();

    };

    const update = () => {
        updateExchangeRate(dispatch, snackbarToggle, value, admin);
        props.onHandleCancel();
    };
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        if (!isLoaded) {
            //getWarehouseList();
            // getPackages();
        }
    }, [dispatch, failedCallback]);

    const currencyNameOptions = listOfcurrency.map(cur => ({
        id: cur.id,
        name: cur.lable
    }));
    return (
        <div>
            <Grid container item spacing={2}>
                <Grid container item spacing={2}>
                    <SelectField
                        required
                        value={value.currency}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, currency: event.target.value }))}
                        label='Currency'
                        options={currencyNameOptions}
                    />
                    <TextField
                        required
                        lg={5.5}
                        label='Rate'
                        value={value.oneUsd}
                        onChange={event => setValue(prev => ({ ...prev, oneUsd: event.target.value }))}
                    />
                </Grid>
                <Grid container item justifyContent='flex-end' lg={11}>
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={props.data?.id ? update : save}>
                        Save
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={props.onHandleCancel}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            {snackbar && (
                <Snackbar open={!!snackbar} message={snackbar.message} type={snackbar.type} onClose={onSnackbarHandleClose} />
            )}
        </div>
    );
};

export default AddExchangeRate;
