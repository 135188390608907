import { memo } from 'react';

import { Grid, TextField } from '@mui/material';
import { MobileDateTimePicker, DatePicker } from '@mui/lab';

const DateTimePicker = ({ value, label, onChange, required, xs, sm, md, disabled }) => {
    return (
        <Grid item xs={xs} sm={sm} md={md}>
            {<DatePicker
                inputFormat="dd/MM/yyyy"
                disabled={disabled}
                helperText={value ? value : ''}
                disableMaskedInput={true}
                value={value ? value : ''}
                onChange={onChange}
                renderInput={params => <TextField sx={{ fontSize: '0.7em' }} inputProps={{ value: { value}, sx: { fontSize: '0.7em', padding: '6px' } }}
                    InputLabelProps={{ value: { value },sx: { fontSize: '0.7em' } }} size='small' fullWidth {...params} label={label} required={required} />}
            />
            }
        </Grid>
    );
};

DateTimePicker.defaultProps = {
    label: '',
    value: null,
    required: null,
    xs: 12,
    sm: 6,
    md: 4,
};

export default memo(DateTimePicker);
