import { useState, useEffect, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDraggable } from "react-use-draggable-scroll";
import { Typography, TableHead, TableRow, TableCell, Button, Table, Grid, TableBody, TableContainer, Paper, TablePagination } from '@mui/material';
import { downloadBase64File } from '../../reducers/requestHandler'
import { addBannerEmail, getBannerEmails } from '../../reducers/requestHandler';
import { getAdminList, getWarehouseList } from '../Admins/AdminHandler'
import { REQUEST_ACTIONS, sendRequest } from '../../utils/Communicator';
import { useSelector, useDispatch } from 'react-redux';
import { onAdminChange, onWarehouseChange } from '../../reducers/admin-reducers/AdminSlicer';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { Dialog, Snackbar } from '../../style-guide';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { useDropzone } from 'react-dropzone'
import { FaAngleDown, FaWindowClose, FaCloudUploadAlt } from "react-icons/fa";

export const BannerRow = (props) => {
    const dispatch = useDispatch();
    const { admin } = useSelector(state => state.session);
    const { afterFooterUpload,afterHeaderUpload, rowDataHeader, rowDataFooter, getRootHeader, getRootFooter, isUp, isUpFooter, getInputHeader, getInputFooter, heading, headerHeading, footerHeading } = props;
    return (

        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow sx={{ '& > *': { borderBottom: '1px solid ', margin: 0, padding: '5px' } }}>
                        <TableCell>
                            <Typography variant='h6'>
                                <b> {heading}</b>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='h7'>

                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='h7'>

                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='h7'>

                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                        <TableCell style={{ margin: 0, padding: '2px' }} >
                            <Typography variant='h7'>
                                <b> {headerHeading}</b>
                            </Typography>
                        </TableCell>
                        <TableCell style={{ margin: 0, padding: '2px' }} >
                            <Grid lg={5} container item justifyContent="center"   {...getRootHeader()}
                                style={{ background: isUp ? 'green' : '#1565c0', cursor: isUp ? 'pointer' : 'pointer', borderRadius: "5px", paddingBottom: "2%", marginLeft: "2%", marginTop: "1.5%" }}>
                                <FaCloudUploadAlt style={{ fill: "white", marginTop: "5%", marginRight: "3%" }} /><div style={{ color: "white", marginTop: "5%" }}> Upload {headerHeading} </div>
                                {<input {...getInputHeader()} />}
                            </Grid>
                        </TableCell>
                        <TableCell style={{ margin: 0, padding: '2px' }} >
                            <Button variant={'contained'} onClick={function () {
                                addBannerEmail(dispatch, snackbarToggle, { ...rowDataHeader, base64: "" }, admin, function (data) {
                                    //dispatch(setHeaderAirImport(data?.data[0]));
                                    afterHeaderUpload()
                                })
                            }}>
                                Clear
                            </Button>
                        </TableCell>
                        <TableCell style={{ margin: 0, padding: '2px', width: "15vw" }} >

                            <img alt="" src={isUp} onClick={() => { downloadBase64File(isUp,"Header") }} style={{ width: 'auto', height: '25px', cursor: "pointer" }} className="flag_img top_space_20 bot_space_20"  />
                            {/*<Button variant={'contained'}  onClick={function () {

                            }}>
                                Download
                            </Button>*/}
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{ '& > *': { borderBottom: '0px solid black', margin: 0, padding: '5px' } }}>
                        <TableCell style={{ margin: 0, padding: '2px' }} >
                            <Typography variant='h7'>
                               <b> { footerHeading}</b>
                            </Typography>
                        </TableCell>
                        <TableCell style={{ margin: 0, padding: '2px' }} >
                            <Grid lg={5} container item justifyContent="center"   {...getRootFooter()}
                                style={{ background: isUpFooter ? 'green' : '#1565c0', cursor: isUpFooter ? 'pointer' : 'pointer', borderRadius: "5px", paddingBottom: "2%", marginLeft: "2%", marginTop: "1.5%" }}>
                                <FaCloudUploadAlt style={{ fill: "white", marginTop: "5%", marginRight: "3%" }} /><div style={{ color: "white", marginTop: "5%" }}> Upload {footerHeading} </div>
                                {<input {...getInputFooter()} />}
                            </Grid>
                        </TableCell>
                        <TableCell style={{ margin: 0, padding: '2px' }} >
                            <Button variant={'contained'}  onClick={function () {
                                addBannerEmail(dispatch, snackbarToggle, { ...rowDataFooter, base64: "" }, admin, function (data) {
                                    //dispatch(setHeaderAirImport(data?.data[0]));
                                    afterFooterUpload()
                                })
                            }}>
                                Clear
                            </Button>
                        </TableCell>
                        <TableCell style={{ margin: 0, padding: '2px', width: "15vw" }} >
                            <img alt="" src={isUpFooter} onClick={() => { downloadBase64File(isUpFooter, "Footer") }}  style={{ width: 'auto', height: '25px', cursor:"pointer" }} className="flag_img top_space_20 bot_space_20" />
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{ '& > *': { borderBottom: '2px solid black', margin: 0, padding: '5px' } }}>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    </TableRow>
                    <TableRow sx={{ '& > *': { borderBottom: '0px solid black', margin: 0, padding: '5px' } }}>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>


    );
};
export default BannerRow;
