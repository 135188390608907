import { useState, useEffect, useCallback, useRef } from 'react';
import { useDraggable } from "react-use-draggable-scroll";
import { Link } from 'react-router-dom';
import XLSX from "xlsx";
import { v4 as uuidv4 } from 'uuid';
import { Button, Grid, Table, TableBody, TableContainer, Paper, TableCell, TableHead, TableRow, TablePagination } from '@mui/material';
import AirCostHeader from './AirCostHeader';
import AirCostRow from './AirCostRow';
import AddAirCost from './AddAirCost';
import { listOfCostLandWeightAir } from '../../../constants/global-constants';
import { useSelector, useDispatch } from 'react-redux';
import { snackbarToggle } from '../../../reducers/snackbarSlicer';
import { onPageChange, onSizeChange, onParcelCountChange } from '../../../reducers/admin-reducers/AdminSlicer';
import { onAirCostChange } from '../../../reducers/crm/crmSlice';
import { Dialog, Snackbar, MultilineField } from '../../../style-guide';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { getAirExportAf,sendMail,getCRMsForMail,getAirExportAFsPaginationFilter, getLocalChargesByShipProd, deleteAirExportAF, getCRMList } from '../../../reducers/requestHandler';
import MenuItem from '@mui/material/MenuItem';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { FaAngleLeft, FaPlus, FaPen,FaCloudUploadAlt } from "react-icons/fa";
import { addAirExportAF } from '../../../reducers/requestHandler';
import { parcelStatusList, parcelSortBy, SHIPPING_STATUS_LIST, paginationArr } from '../../../constants/global-constants';
import "../cost.css";

export const AirCostTable = props => {
    //const { crmListFilter } = useSelector(state => state.parameter);
    const ref = useRef();
    const { crmList } = useSelector(state => state.crm);
    const { countryList, listOfAirPort, listOfAirLine, listOfMeasurementUnits, listOfcurrency, qsStatusList, listOfIncoterms, listOfServiceType } = useSelector(state => state.parameter);

    const {  paginationData } = useSelector(state => state.admin);
    const { events } = useDraggable(ref);
    const dispatch = useDispatch();
    const { currentPage, admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(
        message => dispatch(snackbarToggle({ type: 'error', message })),
        [dispatch],
    );
    const setTruckingCompany = (text) => {
        setTruckingCompany1(text);
        if (text) {
            var t = airImportExportTemp1.filter(item => item.params?.TRUCKER_NAME?.includes(text));
            setAirImportExportTemp(t);
        } else
            setAirImportExportTemp(airImportExportTemp1);
    }
    const setAirImportExport = (data) => {
        setAirImportExportTemp(data)
        setAirImportExportTemp1(data)
    }
    const [totalFiles, setTotalFiles] = useState(0);
    const [numFiles, setNumFiles] = useState(0);
    const [truckingCompany, setTruckingCompany1] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorLclEl, setAnchorLclEl] = useState(null);
    const [anchorFclEl, setAnchorFclEl] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [bSettingOpen, setSettingOpen] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [airImportExportTemp1, setAirImportExportTemp1] = useState(false);
    const [airImportExport, setAirImportExportTemp] = useState(false);
    const [airImportExport1, setAirImportExport1] = useState([]);
    const open = Boolean(anchorEl);
    const openLcl = Boolean(anchorLclEl);
    const openFcl = Boolean(anchorFclEl);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => {
        setOpenOrderModal(true);
    };
    const [failedMailList, setfailedMailList] = useState("");
    const [succesMailList, setsuccesMailList] = useState("");
    const [message, setMessage] = useState("");

    const [openOrderModalSentMail, setOpenOrderModalSentMail] = useState(false);
    const onHandleOpenModalSentMail = () => {
        setOpenOrderModalSentMail(true);
    };
    const onHandleCancelModalSentMail = () => setOpenOrderModalSentMail(false);


    const [openOrderModalMail, setOpenOrderModalMail] = useState(false);
    const onHandleOpenModalMail = () => {
        setOpenOrderModalMail(true);
    };
    const onHandleCancelModalMail = () => setOpenOrderModalMail(false);

    const [loadingData, setLoadingData] = useState(false);
    const [openOrderModalDelete, setOpenOrderModalDelete] = useState(false);
    const onHandleOpenModalDelete = () => {
        setOpenOrderModalDelete(true);
    };
    const onHandleCancelModalDelete = () => setOpenOrderModalDelete(false);
    const isAnySelected = (event) => {
        for (var i = 0; i < airImportExport.length; i++) {
            if (airImportExport[i].bSelected) {

                return true
            }
        }
    };
    const isAnySelectedForCopy = (event) => {
        var numSelected = 0;
        for (var i = 0; i < airImportExport.length; i++) {
            if (airImportExport[i].bSelected) {
                numSelected++
            }
        }
        return numSelected==1;
    };

    const getSelectedforcopy = () => {
        for (var i = 0; i < airImportExport.length; i++) {
            if (airImportExport[i].bSelected) {
                return airImportExport[i];
            }
        }
    }
    const isAnySelectedForMail = (event) => {
        for (var i = 0; i < selectedCostForMail.length; i++) {
            if (selectedCostForMail[i].bSelected) {

                return true
            }
        }
    };
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const handleClick = event => {
        setSettingOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleLclClick = event => {
        setSettingOpen(true);
        setAnchorLclEl(event.currentTarget);
    };
    const handleFclClick = event => {
        setSettingOpen(true);
        setAnchorFclEl(event.currentTarget);
    };
    const handleClose = () => {
        setSettingOpen(false);
        setAnchorEl(null);
        setAnchorLclEl(null);
        setAnchorFclEl(null);
    };
    const onPageHandleChange = (_, newPage) => {
        dispatch(onPageChange(newPage))
    };
    const onRowsPerPageHandleChange = event => {
        dispatch(onSizeChange(+event.target.value))
        dispatch(onPageChange(0))
    };
    const [crmsForMail, setcrmsForMail] = useState([]);
    const [listOfCostLandWeightAir1, setListOfCostLandWeightAir] = useState(listOfCostLandWeightAir);
    const [airCostData, setAirCostData] = useState({
        fromId: "",
        toId: "",
        airLineId: "",
        min: "",
        countryId: "",
        crmId: "",
        airportId: "",
        zip: "",
        rateList: [],
        weightList: [""],
        weight: 0,
        rate: 0,
    });
    const [selectedCostForMail, setselectedCostForMail] = useState([]);
    
    const setSelectedAirCostMail = (cost) => {
        var cc = JSON.parse(JSON.stringify(selectedCostForMail))
        var bAdd = false;
        setselectedCostForMail([])
        for (var i = 0; i < cc.length; i++) {
            if (cc[i].id == cost.id) {
                bAdd = true;
                cc[i].bSelected = cost.bSelected;
                break;
            }
        }
        if (!bAdd) {
            cc.push(cost)
        }
        setselectedCostForMail(cc)
    }     
    const setSelectedAirCost = (id, bSelected) => {        
        var t = []
        for (var i = 0; i < airImportExport.length; i++) {
            var tt = { ...airImportExport[i] }
            if (tt.id == id) {
                tt.bSelected = bSelected; 
                setSelectedAirCostMail(tt)
            }
            t.push(tt)
        }
        //setAirImportExport([])
        window.setTimeout(function () {
            setAirImportExport(t)
        }, 0);
       
    }
    const deSelectAllAirCost = () => {        
        var t = []
        for (var i = 0; i < airImportExport.length; i++) {
            var tt = { ...airImportExport[i] }
            tt.bSelected = false;
            t.push(tt)
        }
        setAirImportExport([])
        window.setTimeout(function () {
            setAirImportExport(t)
        }, 0);
       
    }
    const selectAllAirCost = () => {        
        var t = []
        for (var i = 0; i < airImportExport.length; i++) {
            var tt = { ...airImportExport[i] }
            tt.bSelected = true;
            t.push(tt)
        }
        setAirImportExport([])
        window.setTimeout(function () {
            setAirImportExport(t)
        }, 0);
       
    }
    var bEdit = false;
    const getAirCostData = () => {
        var rate = [];
        airCostData.weightList = [];
        if (airCostData.weight == 0) {}
            //airCostData.weightList.push("")
        else {
            airCostData.weightList.push(airCostData.weight+"")
        }
        airCostData.rateList = [];
       
        
        if (props.data?.id == 3) {
            for (var i = 0; i < listOfCostLandWeightAir1.length; i++) {
                if (listOfCostLandWeightAir1[i].rate == 0)
                    rate.push("")
                else {
                    if (!bEdit) {
                    
                        rate.push(listOfCostLandWeightAir1[i].rate + "")
                    }
                }
            }

        } else {
            rate.push(airCostData.rate)
        }
        var data1 = {
            numItems: paginationData.size,
            pageNum: paginationData.page+1,
            importExportType: props.data?.id,
            fromId: airCostData.fromId,
            toId: airCostData.toId,
            airLineId: airCostData.airLineId,
            min: airCostData.min,
            countryId: airCostData.countryId,
            crmId: airCostData.crmId,
            airportId: airCostData.airportId,
            zip: airCostData.zip,
            rateList: rate,
            weightList: airCostData.weightList
        }
        //setLoadingData(true);
        if (props.data?.id && props.data?.id != 4 && props.data?.id != 5) {
            getAirExportAFsPaginationFilter(dispatch, admin, data1, function (d) {
                var data = [];
                for (var i = 0; i < d.length; i++) {
                    var d1 = d[i];
                    d1.TO_NAME = listOfAirPort.find(u => u.id === d1.toId)?.name || '-';
                    d1.FROM_NAME = listOfAirPort.find(u => u.id === d1.fromId)?.name || '-';
                    d1.CRM_NAME = crmList.find(u => u.id === d1.crmId)?.companyName || '-';
                    d1.CMP_NAME = listOfAirLine.find(u => u.id === d1.airLineId)?.name || '-';
                    d1.COUNTRY = countryList.find(u => u.id === d1.countryId)?.name || '-';
                    d1.AL = listOfAirPort.find(u => u.id === d1.airportId)?.name || '-';
                    data.push(d1);
                }
                setAirImportExport(data);
                setLoadingData(false);
                bEdit = false
            });
        } else if (props.data?.id && (props.data?.id == 4 || props.data?.id == 5)) {
            data1 = {
                "importExportType": props.data?.id,
                "shipmentTypeId":1
            }
            getLocalChargesByShipProd(dispatch, admin, data1, function (da) {
                var tData = [...da]
                if (props.data?.id == 4 && tData.length) {
                    var t = [{
                        name: 'Handling fee', unitOfMeasure: 2, currency: 1, rate: 1, min: 0
                    }, { name: 'Due Carrier', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Inland Freight', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Terminal fee', unitOfMeasure: 1, currency: 1, rate: 0.1, min: 0 }, { name: 'Global Gate Fee', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Courier', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Customs Clearance', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'AWB Fee', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Airline IT charge', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Bank fee', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'C.O.O', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'ATA Carnet', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Cool-Box', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Data Logger', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'EUR1', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Insurance Handling', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Packing', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Thermal Blanket', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Storage', unitOfMeasure: 1, currency: 1, rate: 0.1, min: 0 }, { name: 'Customs Inspection', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Export License', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'EXW Charges', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }]
                    /**/
                    if (tData.length != t.length) {
                        tData = []
                    }
                    for (var i = 0; i < t.length; i++) {
                        var item = da.find(u => u.product === t[i].name)
                        if (!item) {
                            var tItem = { ...da[0], id: null, product: t[i].name, rate: t[i].rate, min: t[i].min, currency: t[i].currency, unitOfMeasure: t[i].unitOfMeasure }
                            tData.push(tItem)
                        } else {
                            tData[i] = item;
                        }
                    }
                }
                if (props.data?.id == 5 && tData.length) {
                   /* var t = [{ name: 'Delivery Order', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Collection Fee', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 },{ name: 'Communication', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Customs Clearance', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'ATA Carnet', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }, { name: 'Data Logger', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 }]*/

                    var t = [{ name: 'Delivery Order', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 },
                    { name: 'IATA Fee', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 },
                    { name: 'Communication', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 },
                    { name: 'ATA Carnet', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 },
                    { name: 'Collection Fee', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 },
                    { name: 'Customs Clearance', unitOfMeasure: 2, currency: 4, rate: 1, min: 0 },
                    { name: 'Data Logger', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 },
                    { name: 'Due Carrier', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 },
                    { name: 'Handling fee', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 },
                    { name: 'IMO Handling', unitOfMeasure: 1, currency: 1, rate: 1, min: 0 },
                    { name: 'Import Handling', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 },
                    { name: 'Inland Freight', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 },
                    { name: 'It Charge', unitOfMeasure: 2, currency: 1, rate: 1, min: 0 },
                    { name: 'Storage', unitOfMeasure: 1, currency: 1, rate: 0.1, min: 0 }]

                    if (tData.length != t.length) {
                        tData = []
                    }
                    for (var i = 0; i < t.length; i++) {
                        var item = da.find(u => u.product === t[i].name)
                        if (!item) {
                            var tItem = { ...da[0], id: null, product: t[i].name, rate: t[i].rate, min: t[i].min, currency: t[i].currency, unitOfMeasure: t[i].unitOfMeasure }
                            tData.push(tItem)
                        } else {
                            tData[i] = item;
                        }
                    }
                }

                setAirImportExport(tData)
                setLoadingData(false);
            });
        }
    }

    useEffect(() => {
        if (!isLoaded && admin.data.session) {
            setIsLoaded(true);
            setLoadingData(true);
            getCRMsForMail(dispatch, admin, function (data) {
                setcrmsForMail(data)
                getAirCostData();
            });
        }
        //getAirCostData();
    }, [paginationData.page, paginationData.size]);

    useEffect(() => {
        if ( admin.data.session) {
            //setLoadingData(true);
            getAirCostData();
        }
        //getAirCostData();
    }, [paginationData.page, paginationData.size, airCostData]);
    useEffect(() => {
        if (!crmList.length) {
            getCRMList(dispatch, admin);
        }
        //getAirCostData();
    }, [crmList]);

    const jsonToExcel = function (data, name, header) {
        //{ skipHeader: true }
        var ws = XLSX.utils.json_to_sheet(data, header);

        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "All");

        /* write workbook and force a download */
        XLSX.writeFile(wb, name + ".xlsx");
    }
    const exportCsv = () => {
        var data_main = [];
        var header = ["TruckingCompany", "Country", "AL", "ZIP", "MIN", "Rate44", "Rate99", "Rate199", "Rate299", "Rate499", "Rate599", "Rate999", "Rate1999"]
        //data_main.push(header);
        for (var i = 0; i < airImportExportTemp1.length; i++) {
            var data = {
                "TruckingCompany": airImportExportTemp1[i].params?.TRUCKER_NAME,
                "Country": airImportExportTemp1[i].COUNTRY,
                "AL": airImportExportTemp1[i].AL,
                "ZIP": airImportExportTemp1[i].zip,
                "MIN": airImportExportTemp1[i].min,
                "Rate44": airImportExportTemp1[i].rateList[0],
                "Rate99": airImportExportTemp1[i].rateList[1],
                "Rate199": airImportExportTemp1[i].rateList[2],
                "Rate299": airImportExportTemp1[i].rateList[3],
                "Rate499": airImportExportTemp1[i].rateList[4],
                "Rate599": airImportExportTemp1[i].rateList[5],
                "Rate999": airImportExportTemp1[i].rateList[6],
                "Rate1999": airImportExportTemp1[i].rateList[7],
            };
            data_main.push(data);
        }
        jsonToExcel(data_main, "AIR Land Transport", header);
    }
    const make_cols = refstr => {
        let o = [],
            C = XLSX.utils.decode_range(refstr).e.c + 1;
        for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
        return o;
    };
    const changeHandler = (event) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            //console.log(rABS, wb);
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            setTotalFiles(data.length);
            var keys = data[0];
            var XL_row_object = []
            for (var i = 1; i < data.length; i++) {
                var t = {}
                keys.forEach(function (key, index) {
                    if (data[i][index])
                        t[key] = data[i][index];
                })
                if (data[i].length>0) {
                    var objToSave = {
                        importExportType: props.data.id,
                    }
                    XL_row_object.push(t)
                    if (props.data.id == 2 || props.data.id == 1) {
                        if (t['ORG']) {
                            objToSave.fromId = listOfAirPort.find(u => u.name === t['ORG'])?.id
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['DEST']) {
                            objToSave.toId = listOfAirPort.find(u => u.name === t['DEST'])?.id
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['MIN']) {
                            objToSave.min = t['MIN']
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['N']) {

                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['AL']) {
                            objToSave.airLineId = listOfAirLine.find(u => u.name === t['AL'])?.id
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (props.data.id == 2) {
                            if (t['Agent']) {
                                objToSave.agentId = crmList.find(u => u.companyName === t['Agent'])?.id
                            } else {
                                alert("Please Select apropriate file.");
                                return;
                            }

                        } else {
                            if (t['Agent']) {
                                alert("Please Select apropriate file.");
                                return;
                            } else {

                            }
                        }
                        var temp = [45, 100, 300, 500, 1000, 2000];
                        temp.forEach(function (element1) {
                            if (t[element1]) {
                                var weight = [];
                                weight.push((element1 - 1))
                                var rate = [];
                                rate.push(t[element1])
                                objToSave.weight = weight;
                                objToSave.rate = rate;
                                addAirExportAF(dispatch, snackbarToggle, objToSave, admin, function () {
                                    setNumFiles(numFiles + 1);
                                })

                            } else {
                                alert("Please Select apropriate file.");
                                return;
                            }
                        });
                    } else {
                        var temp = ["44", "99", "199", "299", "499", "599", "999", "1999"];
                        var getIndexOfWeight = function (w) {
                            var ind = 0;
                            var ind1 = temp.length - 1;
                            if (w == 499) {
                                console.log(w)
                            }
                            for (var k = 0; k < temp.length; k++) {
                                var element1 = temp[k];
                                if (t[element1]) {
                                    if (w == temp[k]) {
                                        ind1 = k;
                                        break;
                                    }
                                    ind++;
                                } else {
                                    alert("Please Select apropriate file.");
                                    return;
                                }
                            }
                            return ind1;
                        }

                        var lastAdded = 0;
                        var tempLand = []
                        listOfCostLandWeightAir1.forEach(function (element11) {
                            tempLand.push(JSON.parse(JSON.stringify(element11)))
                        });
                        for (var j = 0; j < tempLand.length; j++) {
                            var tI = getIndexOfWeight(tempLand[j].weight);
                            if (tI != -1) {
                                lastAdded = tempLand[j].rate = t[temp[tI]];
                            } else {
                                tempLand[j].rate = lastAdded;
                            }
                        }
                        var weight = [];
                        var rate = [];
                        var params = {};
                        params.TRUCKER_NAME = "";
                        if (t['TRUCKER NAME']) {
                            params.TRUCKER_NAME = t['TRUCKER NAME'];
                            objToSave.params = params;
                        }
                        for (var k = 0; k < tempLand.length; k++) {
                            weight.push(tempLand[k].weight+"");
                            rate.push(tempLand[k].rate + "");
                        }
                        setListOfCostLandWeightAir(listOfCostLandWeightAir)
                        objToSave.weight = weight
                        objToSave.rate = rate
                        if (t['AIRPORT']) {
                            objToSave.airportId = listOfAirPort.find(u => u.name === t['AIRPORT'])?.id
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['ORG']) {
                            objToSave.countryId = countryList.find(u => u.name.toLowerCase() === t['ORG'].toLowerCase())?.id
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['ZIP']) {
                            objToSave.zip = t['ZIP']
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['MIN']) {
                            objToSave.min = t['MIN']
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        addAirExportAF(dispatch, snackbarToggle, objToSave, admin, function () { })

                    }
                }
                
            }
            console.log(XL_row_object)
            window.setTimeout(function () {
                getAirCostData()
            }, 500)
            
            /* Update state */
           //var cols= make_cols(ws["!ref"])
            //this.setState({ data: data, cols: make_cols(ws["!ref"]) });
        };
        var file = event.target.files[0];
        if (file) {
            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        }
    };
    const handleSubmission = () => {
        var fileInput = document.getElementById('file');
        var element = document.getElementById("file_container");
        if (fileInput) {
            element.removeChild(fileInput);
        }
        var x = document.createElement("INPUT");
        x.setAttribute("type", "file");
        x.setAttribute("id", 'file');
        x.style.display = "none"; 
        element.appendChild(x);

        document.getElementById("file").addEventListener("change", changeHandler, true);
        document.getElementById('file').click();

    };
    const [over, setOnOver] = useState(0);
    return (
        <>{<Grid container item xs={12} justifyContent='end' id="file_container">
            
            {openOrderModal && (
                <Dialog maxWidth='md' open={openOrderModal} title={props.data.lable} onHandleCancel={() => {
                    setListOfCostLandWeightAir([])
                    setAirImportExport([])
                    getAirCostData();
                    onHandleCancelModal();
                }}>
                    <AddAirCost setAirImportExport={setAirImportExport} callBack={() => {
                        setAirImportExport([])
                        setListOfCostLandWeightAir([])
                        window.setTimeout(function () {
                            bEdit = true
                            setListOfCostLandWeightAir(listOfCostLandWeightAir)
                            getAirCostData();
                        }, 0)
                    }} typeData={props.data} data={selectedData} importExportType={props.data?.id} onHandleCancel={() => {

                        setListOfCostLandWeightAir([])
                        setAirImportExport([])
                        getAirCostData();
                        onHandleCancelModal();

                        }} id={null} airImportExport={airImportExport} />
                </Dialog>
            )}
            {openOrderModalDelete && (
                <Dialog maxWidth='md' open={openOrderModalDelete} title={props.data.lable} onHandleCancel={onHandleCancelModalDelete}>
                    <Grid container item>  Are you sure, you want to delete?</Grid>
                    <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {

                        for (var i = 0; i < airImportExport.length; i++) {
                            if (airImportExport[i].bSelected) {

                                deleteAirExportAF(dispatch, snackbarToggle, { id: airImportExport[i].id }, admin, getAirCostData)
                            }
                        }
                        onHandleCancelModalDelete()
                    }}>
                        Delete Selected
                    </Button>
                </Dialog>
            )}
            {openOrderModalMail && (
                <Dialog maxWidth='md' open={openOrderModalMail} title={props.data.lable +" Mail for selected"} onHandleCancel={onHandleCancelModalMail}>
                    <Grid container item style={{minWidth:"40vw"}}> <MultilineField
                        lg={12}
                        md={12}
                        rows={2}
                        label='Your Message'
                        value={message}
                        onChange={event => { setMessage(event.target.value); }}
                    /></Grid>
                    <Grid container item style={{ minWidth: "40vw" }} justifyContent="flex-end">
                    <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {
                        var fMailList = [];
                        var sMailList = [];
                        for (var i = 0; i < selectedCostForMail.length; i++) {
                            if (selectedCostForMail[i].bSelected) {
                                var crm = {}
                                if (props.data.id == 1) {
                                    var airName = listOfAirLine.find(u => u.id === selectedCostForMail[i].airLineId)?.name 
                                    crm=  crmsForMail.find(u => u.companyName === airName)
                                } else if (props.data.id == 2) {
                                    crm = crmsForMail.find(u => u.id === selectedCostForMail[i].crmId)
                                }
                                var accounts = crm?.accounts;
                                var email = "";
                                for (var j = 0; j < accounts?.length;j++) {
                                    if (accounts[j].mail) {
                                        email = accounts[j].mail;
                                        break;
                                    }
                                }
                                if (email) {
                                    sMailList.push({
                                        email: email,
                                        name: crm?.companyName,
                                    })
                                } else {
                                    fMailList.push({
                                        email: "",
                                        name: crm?.companyName,
                                    })
                                }
                                //MailAirExportAF(dispatch, snackbarToggle, { id: airImportExport[i].id }, admin, getAirCostData)
                            }
                            }

                            function uniqueByKey(array, key) {
                                return [...new Map(array.map((x) => [x[key], x])).values()];
                            }
                            fMailList = uniqueByKey(fMailList,'name')
                            sMailList = uniqueByKey(sMailList,'name')
                        setfailedMailList(fMailList)
                        setsuccesMailList(sMailList)
                        sMailList.forEach(function (element, i) {
                            //send mail
                            sendMail(dispatch, admin, element.email, message, "Cost Table", function () { })
                        })
                        onHandleOpenModalSentMail();
                        //onHandleCancelModalMail()
                    }}>
                        Send
                    </Button>
                    </Grid>
                    {openOrderModalSentMail && (
                        <Dialog maxWidth='md' open={openOrderModalSentMail} title={'Mail'} onHandleCancel={onHandleCancelModalSentMail}>
                            <Grid container item style={{ minWidth: "40vw", maxHeight: "25vh", overflowY: "auto" }}>

                                {succesMailList.length > 0 &&
                                    succesMailList.map(crm => (
                                        <> Mail sent on {crm.email} for {crm.name}.<br /></>
                                    ))
                                }
                            </Grid>
                            <Grid container item style={{ minWidth: "40vw", marginTop: "20px", maxHeight: "25vh", overflowY:"auto" }}>

                                {failedMailList.length > 0 &&
                                    failedMailList.map(crm => (
                                        <> Mail failed for {crm.name} as mail not found.<br/></>
                                    ))
                                }
                            </Grid>
                            <Grid container item style={{ minWidth: "40vw" }} justifyContent="flex-end">
                            <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {
                                onHandleCancelModalMail()

                                onHandleCancelModalSentMail()
                            }}>
                                OK
                                </Button>
                            </Grid>
                        </Dialog>
                    )}
                </Dialog>
            )}
            {(props.data?.id == 5 || props.data?.id == 4) && <Button variant='contained' sx={{ mt: -4, height: "4.5vh", mb: 1, mr: 2, background: "#F56C6C" }} onClick={function () {
                setSelectedData(null); onHandleOpenModal()
            }}>
                <FaPen style={{ marginRight: 5, marginTop: -2 }} /> Edit
            </Button>
            }
            {props.data?.id == 3 && <Button variant='contained' sx={{ mt: -4, height: "4.5vh", mb: 1, mr: 2, background: "#F56C6C" }} onClick={exportCsv}>
                Export CSV
            </Button>}
            {(props.data?.id == 1 || props.data?.id == 2 || props.data?.id == 3) && <Button variant='contained' sx={{ mt: -4, height: "4.5vh", mb: 1, mr: 2, background: "#F56C6C" }} onClick={function () {
                setListOfCostLandWeightAir(listOfCostLandWeightAir)
                setSelectedData(null);

                onHandleOpenModal()
            }}>
                <FaPlus style={{marginRight:5}}/>  Create
            </Button>
            }
            {(props.data?.id == 1 || props.data?.id == 2 || props.data?.id == 3) &&<Button variant='contained' sx={{ mt: -4, height: "4.5vh", mb: 1, mr: 2, background: (over == 0 ? '#85ce61 !important' : '#85ce61 !important') }} onClick={handleSubmission}>
                <FaCloudUploadAlt style={{ marginRight: 5 }}/>Upload Csv
            </Button>
            }
            {props.data?.id == 1 && <Button variant='outlined' sx={{ mt: -4,height:"4.5vh", mb: 1, mr: 4, justifyContent:'flex-end' }} onClick={function () {}}>
                <Link style={{ textDecoration: 'none', color: 'inherit', fontSize: 12 }} to="../../../../../files/air_export_af.xlsx" target="_blank" download>Template</Link>
                
            </Button>
            }
            {props.data?.id == 2 && <Button variant='outlined' sx={{ mt: -4,height:"4.5vh", mb: 1, mr: 4, justifyContent:'flex-end' }} onClick={function () {}}>
                <Link style={{ textDecoration: 'none', color: 'inherit', fontSize: 12 }} to="../../../../../files/air_import_af.xlsx" target="_blank" download>Template</Link>
                
            </Button>
            }
            {props.data?.id == 3 && <Button variant='outlined' sx={{ mt: -4,height:"4.5vh", mb: 1, mr: 4, justifyContent:'flex-end' }} onClick={function () {}}>
                <Link style={{ textDecoration: 'none', color: 'inherit', fontSize: 12 }} to="../../../../../files/air_land_transport.xlsx" target="_blank" download>Template</Link>
                
            </Button>
            }
            <Paper sx={{ width: '100%', overflow: 'auto' }} {...events} ref={ref}>
            {
                    <TableContainer sx={{ height: "calc(100vh - 170px)", width: "calc(100vw - 20px)" ,marginLeft:'10px'}}>
                        <Table>
                            {loadingData && <TableHead><TableRow><TableCell xs={12} style={{ width: "100%", padding: '8px' }}> <Grid item container justifyContent="center"><div className="spinner-container1">
                                <div className="loading-spinner1"></div>
                            </div></Grid></TableCell></TableRow></TableHead>}
                            {!loadingData && <AirCostHeader truckingCompany={truckingCompany} setTruckingCompany={setTruckingCompany} setListOfCostLandWeightAir={setListOfCostLandWeightAir} airCostData={airCostData} setAirCostData={setAirCostData} id={props.data.id} />}
                            <TableBody>
                            {airImportExport.length > 0 &&
                                airImportExport.map(crm => (                                        
                                    <AirCostRow getAirCostData={getAirCostData} setSelectedAirCost={setSelectedAirCost} airImportExport1={airImportExport1} typeData={props.data} importExportType={props.data?.id} key={crm?.id || uuidv4()} id={props.data.id} data={crm} openDialog={function () {

                                        getAirExportAf({id:crm.id}, admin, function (data) {
                                            setSelectedData(data.data); onHandleOpenModal()
                                        })

                                        
                                    }} />
                                    ))
                                }

                            </TableBody>
                        </Table>
                </TableContainer>

                }

                <Grid container item justifyContent="space-between">
                    <Grid container item justifyContent="flex-start" lg={ 7}>
                    <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => { selectAllAirCost()} }>
                       Select All
                    </Button>
                    <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => { deSelectAllAirCost()} }>
                       DeSelect All
                        </Button>
                        <Button disabled={!isAnySelected()} variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {

                            onHandleOpenModalDelete()
                    }}>
                       Delete Selected
                        </Button>
                        <Button disabled={!isAnySelectedForMail()} variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {

                            onHandleOpenModalMail()
                        }}>
                            Send Mail Selected
                        </Button>
                        <Button disabled={!isAnySelectedForCopy()} variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={function () {
                            setListOfCostLandWeightAir(listOfCostLandWeightAir);
                            var sData = JSON.parse(JSON.stringify(getSelectedforcopy()));
                            sData.id = null;
                            setSelectedData(sData);

                            onHandleOpenModal()
                        }}>
                            Copy
                        </Button>
                        </Grid>
                    {(props.data?.id && props.data?.id != 4 && props.data?.id != 5) && <TablePagination
                        rowsPerPageOptions={paginationArr}
                        component='div'
                        count={paginationData.parcelCount}
                        rowsPerPage={paginationData.size}
                        page={paginationData.page}
                        onPageChange={onPageHandleChange}
                        onRowsPerPageChange={onRowsPerPageHandleChange}
                    />}
                </Grid>
            
            {snackbar && (
                <Snackbar
                    open={!!snackbar}
                    message={snackbar.message}
                    type={snackbar.type}
                    onClose={onSnackbarHandleClose}
                />
            )}
        </Paper>
        </Grid >}</>


    );
};
export default AirCostTable;
