import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TableCell, TableRow, Typography, Box, Button } from '@mui/material';
import AddProductCharges from './AddProductCharges';
import { Dialog } from '../../style-guide';
import { useSelector, useDispatch } from 'react-redux';
import { addDesc } from '../Admins/AdminHandler'
import { deleteIncoterm, deleteProductCharges } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { FaTrash, FaPen } from "react-icons/fa";

const ProductChargesRow = ({ data }) => {
    const dispatch = useDispatch();
    const { roles, warehouseList } = useSelector(state => state.admin);
    const { admin } = useSelector(state => state.session);
    const { listOfProductCategories, listOfShipmentTypes, listOfChargesTypes, listOfcurrency, listOfMeasurementUnits } = useSelector(state => state.parameter);
    const { snackbar } = useSelector(state => state.snackbar);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const delProductCharges = () => {

        var temp = {
            id: data.id,
            descId: data.id
        }
        deleteProductCharges(dispatch, snackbarToggle, temp, admin);

    };
    const [over, setOnOver] = useState(0);
    return (
        <TableRow sx={{ '& > *': { borderBottom: '1px solid gray !important', margin: 0, padding: '5px' } }}>

            <TableCell style={{ margin: 0, padding: '8px' }}/ >
            <TableCell style={{ margin: 0, padding: '8px' }} >
                <Typography variant='h7' component='span'>
                    {data.name || '-'}
                </Typography>
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }} >

                {data.productCategories.map(id => (
                    <Button sx={{ mt: 1, mr: 1}} className={ 'productCharges'} key={id || uuidv4()}  variant='h7' component='span'>
                        {listOfProductCategories.find(u => u.id === id)?.lable || '-'}
                    </Button>
                ))}
                
            </TableCell>
            <TableCell sx={{ maxWidth: 300 }} style={{ margin: 0, padding: '8px' }}>

                {data.shipmentTypes.map(id => (
                    <Button sx={{ mt: 1, mr: 1 }} className={ 'productCharges'} key={id || uuidv4()}  variant='h7' component='span'>
                        {listOfShipmentTypes.find(u => u.id === id)?.lable || '-'}
                    </Button>
                ))}
                
            </TableCell>
            <TableCell sx={{ maxWidth: 300 }} style={{ margin: 0, padding: '8px' }}>

                {data.chargesTypes.map(id => (
                    <Button sx={{ mt: 1, mr: 1 }} className={ 'productCharges'} key={id || uuidv4()}  variant='h7' component='span'>
                        {listOfChargesTypes.find(u => u.id === id)?.lable || '-'}
                    </Button>
                ))}
                
            </TableCell>
            <TableCell sx={{ maxWidth: 200 }} style={{ margin: 0, padding: '8px' }}>

                {data.units.map(id => (
                    <Button sx={{ mt: 1, mr: 1 }} className={ 'productCharges'} key={id || uuidv4()}  variant='h7' component='span'>
                        {listOfMeasurementUnits.find(u => u.id === id)?.lable || '-'}
                    </Button>
                ))}
                
            </TableCell>
            <TableCell sx={{ maxWidth: 150 }} style={{ margin: 0, padding: '8px' }}>

                {data.currencies.map(id => (
                    <Button sx={{ mt: 1, mr: 1 }} className={ 'productCharges'} key={uuidv4()}  variant='h7' component='span'>
                        {listOfcurrency.find(u => u.id === id)?.lable || '-'}
                    </Button>
                ))}
                
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }} >
                <Typography variant='h7' component='span'>
                    {data.rate || '-'}
                </Typography>
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }} >
                <Typography variant='h7' component='span'>
                    {data.min || '-'}
                </Typography>
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }} >
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                   
                    <Button sx={{ mr: 1 }} onClick={onHandleOpenModal} variant='outlined'>
                        <FaPen />
                    </Button>
                    <Button sx={{ mr: 1, background: (over == 0 ? '#F56C6C !important' : '#e5b6b6 !important'), color: 'white' }} onMouseOver={function () { setOnOver(1); }} onMouseLeave={function () { setOnOver(0); }} onClick={delProductCharges} variant='outlined'>
                        <FaTrash />
                    </Button>
                    {openOrderModal && (
                        <Dialog  open={openOrderModal} title={'Product Charges'} onHandleCancel={onHandleCancelModal}>
                            <AddProductCharges data={data} onHandleCancel={onHandleCancelModal} id={null} />
                        </Dialog>
                    )}
                </Box>
            </TableCell>
            <TableCell />
        </TableRow>
    );
};

export default ProductChargesRow;
