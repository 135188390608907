import { memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { listOfCostLandWeightAir } from '../../../constants/global-constants';
import { FormControlLabel, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, TableCell, TableHead, TableRow, Typography, Button } from '@mui/material';


import { TextField, SelectField } from '../../../style-guide';
const AirCostHeader = (props) => {
    const [listOfCostLandWeightAir1, setListOfCostLandWeightAir] = useState(listOfCostLandWeightAir);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    
    if (props.id == 2 || props.id == 1) {
        return (
            <TableHead style={{ padding: '2px', lineHeight: '0 !important' }}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                    <TableCell style={{ width: '1vw', padding: '8px' }}>
                        <Typography variant='h7'>
                            #
                        </Typography>
                    </TableCell>
                    {(props.id == 2) && <TableCell style={{ width: '17.2vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.airCostData.crmId}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, crmId: event.target.value }))}
                                label='Agent'
                            />
                        </Typography>
                    </TableCell>
                    }
                    {(props.id == 2) && <TableCell style={{ width: '4vw', padding: '8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.airCostData.fromId}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, fromId: event.target.value }))}
                                label='From'
                            />
                        </Typography>
                    </TableCell>
                    }
                    {(props.id == 2) && <TableCell style={{ width: '4vw', padding: '8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.airCostData.toId}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, toId: event.target.value }))}
                                label='To'
                            />
                        </Typography>
                    </TableCell>
                    }
                    {(props.id == 1) && <TableCell style={{ width: '7.3vw', padding: '8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.airCostData.fromId}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, fromId: event.target.value }))}
                                label='From'
                            />
                        </Typography>
                    </TableCell>
                    }
                    {(props.id == 1) && <TableCell style={{ width: '7.3vw', padding: '8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.airCostData.toId}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, toId: event.target.value }))}
                                label='To'
                            />
                        </Typography>
                    </TableCell>
                    }
                    {( props.id == 2) && <TableCell style={{ width: '4vw',padding:'8px' }}>
                        <Typography variant='h7' style={{ width: '4vw', padding: '8px' }}>
                            <TextField
                                lg={12}
                                value={props.airCostData.airLineId}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, airLineId: event.target.value }))}
                                label='Al'
                            />
                        </Typography>
                    </TableCell>
                    }
                    {(props.id == 1) && <TableCell style={{ width: '7.3vw',padding:'8px' }}>
                        <Typography variant='h7' style={{ width: '7.3vw', padding: '8px' }}>
                            <TextField
                                lg={12}
                                value={props.airCostData.airLineId}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, airLineId: event.target.value }))}
                                label='Al'
                            />
                        </Typography>
                    </TableCell>
                    }
                    <TableCell style={{ width: '7.3vw',padding:'6px' }}>
                        <Typography variant='h7' style={{ width: '7.3vw', padding: '6px' }}>
                            <TextField
                                lg={12}
                                value={props.airCostData.weight}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, weight: event.target.value }))}
                                label='weight'
                                type="number" onwheel="return false"
                            />
                        </Typography>
                    </TableCell>
                    <TableCell style={{ width: '7.3vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.airCostData.rate}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, rate: event.target.value }))}
                                label='Rate'
                                type="number" onwheel="return false"
                            />
                        </Typography>
                    </TableCell>
                    <TableCell style={{ width: '7.3vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.airCostData.min}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, min: event.target.value }))}
                                label='Min'
                                type="number" onwheel="return false"
                            />
                        </Typography>
                    </TableCell>
                    <TableCell style={{ width: '7.3vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            Edit
                        </Typography>
                    </TableCell>


                </TableRow>
            </TableHead>
        );
    } else if (props.id == 3) {
        return (
            <TableHead style={{ padding: '2px', lineHeight: '0 !important' }}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0', margin: 0, padding: '2px' } }}>
                    <TableCell style={{ width: '1vw', padding: '8px' }}>
                        <Typography variant='h7'>
                            #
                        </Typography>
                    </TableCell>

                    <TableCell style={{ width: '8.3vw', padding: '8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.truckingCompany}
                                onChange={event => props.setTruckingCompany(event.target.value)}
                                label={"Trucking company"}
                            />
                        </Typography>
                    </TableCell>
                    {<TableCell style={{ width: '7.3vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.airCostData.countryId}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, countryId: event.target.value }))}
                                label='Country'
                            />
                        </Typography>
                    </TableCell>
                    }
                    < TableCell style={{ width: '7.3vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.airCostData.airportId}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, airportId: event.target.value }))}
                                label='Airport'
                            />
                        </Typography>
                    </TableCell>
                    <TableCell style={{ width: '7.3vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.airCostData.zip}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, zip: event.target.value }))}
                                label='Zip'
                            />
                        </Typography>
                    </TableCell>
                    {<TableCell style={{ width: '7.3vw',padding:'8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.airCostData.min}
                                onChange={event => props.setAirCostData(prev => ({ ...prev, min: event.target.value }))}
                                label='Min'
                            />
                        </Typography>
                    </TableCell>
                    }
                    {listOfCostLandWeightAir1.map((crm,index) => (
                        <TableCell key={crm?.weight || uuidv4()} >
                            <Typography variant='h7'>
                                <TextField
                                    lg={12}
                                    value={crm?.rate}
                                    onChange={function (event) {
                                        var t = [...listOfCostLandWeightAir1]; var tObj = JSON.parse(JSON.stringify(t[index])); tObj.rate = event.target.value; t[index] = tObj; setListOfCostLandWeightAir(t);

                                        props.setListOfCostLandWeightAir(t)
                                        props.setAirCostData(prev => ({ ...prev, rateList: t }))
                                    }}
                                    label={crm?.weight}
                                />
                            </Typography>
                        </TableCell>
                    ))
                    }


                </TableRow>
            </TableHead>
        );
    } else if (props.id == 4 || props.id == 5) {
        return (
            <TableHead style={{padding: '5px', lineHeight: '0' }}>
                <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', lineHeight: '1', margin: 0, padding: '10px' } }}>
                    
                    {<TableCell style={{ width: '7.3vw', padding: '15px', fontWeight: 'bold'}}>
                        <Typography variant='h7'>
                            Product
                        </Typography>
                    </TableCell>
                    }
                    <TableCell style={{ width: '7.3vw', padding: '8px', fontWeight: 'bold' }}>
                        <Typography variant='h7'>
                            Unit Of Measure
                        </Typography>
                    </TableCell>
                    <TableCell style={{ width: '7.3vw', padding: '8px', fontWeight: 'bold' }}>
                        <Typography variant='h7'>
                            Currency
                        </Typography>
                    </TableCell>
                    {<TableCell style={{ width: '7.3vw', padding: '8px', fontWeight: 'bold' }}>
                        <Typography variant='h7'>
                            Rate
                        </Typography>
                    </TableCell>
                    }
                    {<TableCell style={{ width: '7.3vw', padding: '8px', fontWeight: 'bold'}}>
                        <Typography variant='h7'>
                            Min
                        </Typography>
                    </TableCell>
                    }
                    {<TableCell style={{ width: '7.3vw', padding: '8px', fontWeight: 'bold' }}>
                        <Typography variant='h7'>
                            Remark
                        </Typography>
                    </TableCell>
                    }


                </TableRow>
            </TableHead>
        );
    } 
};

export default memo(AirCostHeader);
