import { useState, useCallback, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { TextField } from '../../style-guide';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { Snackbar } from '../../style-guide';
import { addKgFactor } from '../../reducers/requestHandler';

const AddKgFactor = props => {
    const dispatch = useDispatch();
    const { admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                shipmentType: props.data.shipmentType,
                kg: props.data.kg,
            }
            : {
                id: null,
                shipmentType: null,
                kg: null,
            },
    );

    const save = () => {
        addKgFactor(dispatch, snackbarToggle, value, admin);
        props.onHandleCancel();

    };

    const update = () => {
        addKgFactor(dispatch, snackbarToggle, value, admin);
        props.onHandleCancel();
    };
    useEffect(() => {

    }, [dispatch, failedCallback]);


    return (
        <div>
            <Grid container item spacing={2}>
                <Grid container item spacing={2}>
                    <TextField
                        required
                        lg={11}
                        value={value.shipmentType}
                        onChange={event => setValue(prev => ({ ...prev, shipmentType: event.target.value }))}
                        label='ShipmentType'
                    />
                    <TextField
                        required
                        lg={11}
                        value={value.kg}
                        onChange={event => setValue(prev => ({ ...prev, kg: event.target.value }))}
                        label='KG'
                    />
                </Grid>
                <Grid container item justifyContent='flex-end' lg={11}>
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={props.data?.id ? update : save}>
                        Save
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={props.onHandleCancel}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            {snackbar && (
                <Snackbar open={!!snackbar} message={snackbar.message} type={snackbar.type} onClose={onSnackbarHandleClose} />
            )}
        </div>
    );
};

export default AddKgFactor;
