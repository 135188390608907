import { useState } from 'react';
import { TableCell, TableRow, Typography, Box, Button } from '@mui/material';
import AddAgentCode from './AddAgentCode';
import { Dialog } from '../../style-guide';
import { useSelector } from 'react-redux';

const AgentCodeRow = ({ data }) => {
    const { roles } = useSelector(state => state.admin);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);

    return (
        <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
            <TableCell />

            <TableCell>
                <Typography variant='h7' >
                    {data.code || '-'}
                </Typography>
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button onClick={onHandleOpenModal} variant='outlined'>
                        Edit
                    </Button>
                    {openOrderModal && (
                        <Dialog open={openOrderModal} title={'AgentCode'} onHandleCancel={onHandleCancelModal}>
                            <AddAgentCode data={data} onHandleCancel={onHandleCancelModal} id={null} />
                        </Dialog>
                    )}
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default AgentCodeRow;
