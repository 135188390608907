import { configureStore } from '@reduxjs/toolkit';

import snackbarSlice from '../reducers/snackbarSlicer';
import sessionSlice from '../reducers/session/sessionSlice';
import qsSlice from '../reducers/QS/qsSlice';
import crmSlice from '../reducers/crm/crmSlice';
import parameterSlice from '../reducers/parameter/parameterSlice';
import adminSlice from '../reducers/admin-reducers/AdminSlicer';
export default configureStore({
  reducer: {
    snackbar: snackbarSlice,
        admin: adminSlice,
        session: sessionSlice,
        QS: qsSlice,
        crm: crmSlice,
        parameter: parameterSlice,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});
