import { useState, useEffect, useCallback, useRef } from 'react';
import { useDraggable } from "react-use-draggable-scroll";
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Grid, Table, TextField, TableBody, TableContainer, Paper, TablePagination, Typography, TableCell, TableHead, TableRow } from '@mui/material';
import CostHeader from './CostHeader';
import CostRow from './CostRow';
import AddCost from './AddCost';
import AirCostTable from './Air/AirCostTable';
import LclCostTable from './Lcl/LclCostTable';
import FclCostTable from './Fcl/FclCostTable';
import { useSelector, useDispatch } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { onPageChange, onSizeChange } from '../../reducers/admin-reducers/AdminSlicer';
import { onCostChange } from '../../reducers/crm/crmSlice';
import { setIsLoading } from '../../reducers/session/sessionSlice';
import { Dialog, Snackbar } from '../../style-guide';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { parcelStatusList, parcelSortBy, SHIPPING_STATUS_LIST, paginationArr } from '../../constants/global-constants';
import {  } from '../../reducers/requestHandler';

export const CostTable = () => {
    const { listOfAirImportExport, listOfLclImportExport, listOfFclImportExport } = useSelector(state => state.parameter);
    const ref = useRef();
    const {  paginationData } = useSelector(state => state.admin);
    const { events } = useDraggable(ref);
    const dispatch = useDispatch();
    const { currentPage, admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(
        message => dispatch(snackbarToggle({ type: 'error', message })),
        [dispatch],
    );
    const [bAir, setAir] = useState(false);
    const [bLcl, setLcl] = useState(false);
    const [bFcl, setFcl] = useState(false);

    
    const [sAirImport, setSairImport] = useState(null);
    if (!bAir && listOfAirImportExport.length > 0 && sAirImport==null) {
        setSairImport(listOfAirImportExport[0])
        setAir(true)
    }
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorLclEl, setAnchorLclEl] = useState(null);
    const [anchorFclEl, setAnchorFclEl] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [bSettingOpen, setSettingOpen] = useState(false);
    const [crmListFilter, setCostListFilter] = useState(false);
    const open = Boolean(anchorEl);
    const openLcl = Boolean(anchorLclEl);
    const openFcl = Boolean(anchorFclEl);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => {
        setOpenOrderModal(true);
    };
    const handleChange = (event) => {
    };
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const handleClick = event => {
        setSettingOpen(true);
       // setAir(true);
        //setSairImport(listOfAirImportExport[0])
        //setLcl(false);
        // setFcl(false);
        setAnchorEl(event.currentTarget);
    };
    const handleLclClick = event => {
        setSettingOpen(true);
        //setAir(false);
        //setLcl(true);
       // setSairImport(listOfLclImportExport[0])
        //setFcl(false);
        setAnchorLclEl(event.currentTarget);
    };
    const handleFclClick = event => {
        setSettingOpen(true);
        //setAir(false);
        //setLcl(false);
        //setFcl(true);
       // setSairImport(listOfFclImportExport[0])
        setAnchorFclEl(event.currentTarget);
    };
    const handleClose = () => {
        setAir(false);
        window.setTimeout(function () {
            setAir(true);
            setLcl(false);
            setFcl(false);
            setSettingOpen(false);
            setAnchorEl(null);
        }, 0)
    };
    const handleLclClose = () => {
        setLcl(false);
        setAir(false);
        window.setTimeout(function () {
            setAir(false);
            setLcl(true);
            setFcl(false);
            setSettingOpen(false);
            setAnchorLclEl(null);
        }, 0)
    };
    const handleFclClose = () => {
        setFcl(false);
        window.setTimeout(function () {
            setFcl(true);
            setLcl(false);
            setAir(false);
            setSettingOpen(false);
            setAnchorFclEl(null);
        }, 0)
    };
    const onPageHandleChange = (_, newPage) => {
        dispatch(onPageChange(newPage))
    };
    const onRowsPerPageHandleChange = event => {
        dispatch(onSizeChange(+event.target.value))
        dispatch(onPageChange(0))
    };
    const setSelectedAirImport = (data) => {
        setSairImport(data)
    }
    const getCostData = () => {
        var data = {
            numItems: paginationData.size,
            pageNum: paginationData.page+1,
            CompanyName: "",
            FreeText: "",
            Address: "",
            CountryId: "",
            FunctionId: [],
        }
        
        //getCostsPaginationFilter(dispatch, admin, data, setCostListFilter);
    }
    if (!isLoaded && admin.data.session) {
        getCostData();
        setIsLoaded(true);
    }
    useEffect(() => {
        getCostData();
    }, [paginationData.page, paginationData.size, dispatch, failedCallback, sAirImport?.id]);
   
    const StyledMenu = styled(props => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            minWidth: 180,
            color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                },
            },
        },
    }));
    return (
    
        <Paper sx={{ width: '100%', overflow: 'auto' }} {...events} ref={ref}>
            {openOrderModal && (
                <Dialog maxWidth='md' open={openOrderModal} title={`New Parcel`} onHandleCancel={onHandleCancelModal}>
                    <AddCost onHandleCancel={() => setOpenOrderModal(false)} id={null} />
                </Dialog>
            )}
            <Grid container item xs={12} justifyContent='start'>
                <Button onClick={handleClick} variant='outlined' sx={{ mb: 1, ml: 2, border:'none !important' }} >
                    {`Air Import / Export`} {!bSettingOpen ? <FaAngleDown /> : <FaAngleUp />}
                </Button>
                <StyledMenu
                    id='demo-customized-menu'
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClick={handleClose}>
                    {
                        listOfAirImportExport.map(item => (
                            <Link key={item?.id || uuidv4()} to='/menu/cost' onClick={event => setSelectedAirImport(item)} style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                                <MenuItem onClick={handleClose}>{item.lable }</MenuItem>
                            </Link>
                        ))
                    }
                </StyledMenu>
                <Button onClick={handleLclClick} variant='outlined' sx={{ mb: 1, ml: 2, border: 'none !important' }} >
                    {`LCL Import / Export`} {!bSettingOpen ? <FaAngleDown /> : <FaAngleUp />}
                </Button>
                <StyledMenu
                    id='demo-customized-menu'
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorLclEl}
                    open={openLcl}
                    onClick={handleLclClose}>
                    {
                        listOfLclImportExport.map(item => (
                            <Link key={item?.id || uuidv4()} to='/menu/cost' onClick={event => setSelectedAirImport(item)} style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                                <MenuItem onClick={handleLclClose}>{item.lable} </MenuItem>
                            </Link>
                        ))
                    }
                </StyledMenu>
                <Button onClick={handleFclClick} variant='outlined' sx={{ mb: 1, ml: 2, border: 'none !important' }} >
                    {`FCL Import / Export`} {!bSettingOpen ? <FaAngleDown /> : <FaAngleUp />}
                </Button>
                <StyledMenu
                    id='demo-customized-menu'
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorFclEl}
                    open={openFcl}
                    onClick={handleFclClose}>
                    {
                        listOfFclImportExport.map(item => (
                            <Link key={item?.id || uuidv4()} to='/menu/cost' onClick={event => setSelectedAirImport(item)} style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                                <MenuItem onClick={handleFclClose}>{item.lable}</MenuItem>
                            </Link>
                        ))
                    }
                    
                </StyledMenu>
                <Typography variant='h7' style={{ verticalAlign:'center',marginLeft:6,marginTop:6,fontSize:'bold'}}>
                    Current page:<Typography variant='h7' style={{ fontWeight: 'bold' }}> {currentPage} </Typography>
                </Typography>
            </Grid>
            {bAir ? <AirCostTable data={sAirImport} /> : (null)}
            {bLcl ? <LclCostTable data={sAirImport} /> : (null)}
            {bFcl ? <FclCostTable data={sAirImport} /> : (null)}
            
            {snackbar && (
                <Snackbar
                    open={!!snackbar}
                    message={snackbar.message}
                    type={snackbar.type}
                    onClose={onSnackbarHandleClose}
                />
            )}
        </Paper>


    );
};
export default CostTable;
