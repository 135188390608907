import { useState } from 'react';
import { TableCell, TableRow, Typography, Box, Button } from '@mui/material';
import AddFLCType20 from './AddFLCType20';
import { Dialog } from '../../style-guide';
import { useSelector, useDispatch } from 'react-redux';
import { addDesc } from '../Admins/AdminHandler'
import { deleteFLCType20 } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { FaTrash, FaPen } from "react-icons/fa";

const FLCType20Row = ({ data }) => {
    const dispatch = useDispatch();
    const { roles, warehouseList } = useSelector(state => state.admin);
    const { admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const delFLCType20 = () => {

        var temp = {
            id: data.id,
            flcType20Id: data.id
        }
        deleteFLCType20(dispatch, snackbarToggle, temp, admin);

    };
    const [over, setOnOver] = useState(0);
    return (
        <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
            <TableCell style={{ margin: 0, padding: '8px' }} />
            <TableCell style={{ margin: 0, padding: '8px' }}>
                <Typography variant='h7' >
                    {data.type || '-'}
                </Typography>

            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }} />
            <TableCell style={{ margin: 0, padding: '8px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    
                    <Button sx={{ mr: 1 }} onClick={onHandleOpenModal} variant='outlined'>
                        <FaPen />
                    </Button>
                    <Button sx={{ mr: 1, background: (over == 0 ? '#F56C6C !important' : '#e5b6b6 !important'), color: 'white' }} onMouseOver={function () { setOnOver(1); }} onMouseLeave={function () { setOnOver(0); }} onClick={delFLCType20} variant='outlined'>
                        <FaTrash />
                    </Button>
                    {openOrderModal && (
                        <Dialog open={openOrderModal} title={'FLCType20'} onHandleCancel={onHandleCancelModal}>
                            <AddFLCType20 data={data} onHandleCancel={onHandleCancelModal} id={null} />
                        </Dialog>
                    )}
                </Box>
            </TableCell>
            <TableCell />
        </TableRow>
    );
};

export default FLCType20Row;
