import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AdminsTable } from '../Admins/AdminsTable';
import { ExchangeRateTable } from '../ExchangeRate/ExchangeRateTable';
import { IncotermTable } from '../Incoterm/IncotermTable';
import { ProductChargesTable } from '../ProductCharges/ProductChargesTable';
import { Banners } from '../Banners/Banners';
import { ServiceTypeTable } from '../ServiceType/ServiceTypeTable';
import { FLCType20Table } from '../FLCType20/FLCType20Table';
import { FLCType40Table } from '../FLCType40/FLCType40Table';
import { PortTable } from '../Port/PortTable';
import { AirPortTable } from '../AirPort/AirPortTable';
import { AirLineTable } from '../AirLine/AirLineTable';
import { RejectReasionsTable } from '../RejectReasions/RejectReasionsTable';
import { KgFactorTable } from '../KgFactor/KgFactorTable';
import { AirTerminalTable } from '../AirTerminal/AirTerminalTable';
import { AgentCodeTable } from '../AgentCode/AgentCodeTable';
import { CountryOfImportTable } from '../CountryOfImport/CountryOfImportTable';
import { onAdminChange } from '../../reducers/admin-reducers/AdminSlicer';
import {  setCurrentPage } from '../../reducers/session/sessionSlice';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { getAdminList, getWarehouseList } from '../Admins/AdminHandler'
import AddAdmin from '../Admins/AddAdmin';
import { Dialog, Snackbar } from '../../style-guide';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import CountryAndOriginTable from '../CountryAndOrigin/CountryAndOriginTable';


export const ParameterMenu = () => {
    const dispatch = useDispatch();
    const { adminList } = useSelector(state => state.admin);
    const { admin } = useSelector(state => state.session);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => {
        setOpenOrderModal(true);
        getAdminList(dispatch, admin);
        getWarehouseList(dispatch, admin);
    };
    const onHandleCancelModal = () => setOpenOrderModal(false);


    const [openWarehouseModal, setWarehouseModal] = useState(false);
    const onHandleOpenWarehouseModal = () => {
        setWarehouseModal(true);
        getAdminList(dispatch, admin);
        getWarehouseList(dispatch, admin);
    };
    const onHandleCancelWarehouseModal = () => setWarehouseModal(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [bSettingOpen, setSettingOpen] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setSettingOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setSettingOpen(false);
        setAnchorEl(null);
    };

    const [showAdminList, setShowAdminList] = useState(true);
    if (showAdminList) {
        dispatch(setCurrentPage("User list"))
    }
    const [showWarehouseList, setShowWarehouseList] = useState(false);
    const [showIncotermList, setShowIncotermList] = useState(false);
    const [showFLCType20List, setShowFLCType20List] = useState(false);
    const [showFLCType40List, setShowFLCType40List] = useState(false);
    const [showPortList, setShowPortList] = useState(false);
    const [showAirPortList, setShowAirPortList] = useState(false);
    const [showAirLineList, setShowAirLineList] = useState(false);
    const [showRejectReasionsList, setShowRejectReasionsList] = useState(false);
    const [showCountryAndOrList, setShowCountryAndOrList] = useState(false);
    const [showServiceTypeTableList, setShowServiceTypeTableList] = useState(false);
    const [showAirTerminalList, setShowAirTerminalList] = useState(false);
    const [showAgentCodeList, setShowAgentCodeList] = useState(false);
    const [showCountryOfImportList, setShowCountryOfImportList] = useState(false);
    const [exchangeRate, setShowExchangeRate] = useState(false);
    const [KgFactor, setShowKgFactor] = useState(false);
    const [userActions, setShowUserActions] = useState(false);
    const [productCharges, setShowProductCharges] = useState(false);
    const [banners, setBanners] = useState(false);
    const onHandleHideAllItems = () => {
        setShowAdminList(false);
        setShowExchangeRate(false)
        setShowKgFactor(false)
        setShowProductCharges(false)
        setBanners(false)
        setShowUserActions(false)
        setShowServiceTypeTableList(false)
        setShowCountryAndOrList(false)
        setShowPortList(false)
        setShowAirPortList(false)
        setShowAirLineList(false)
        setShowRejectReasionsList(false)
        setShowFLCType20List(false)
        setShowFLCType40List(false)
        setShowIncotermList(false)
        setShowAirTerminalList(false)
        setShowAgentCodeList(false)
        setShowCountryOfImportList(false)
    }
    const onHandleAirLine = () => {
        onHandleHideAllItems();
        setShowAirLineList(true);
        dispatch(setCurrentPage("Air Line List"));
        //getAdminList();
    }

    const onHandleRejectReasions = () => {
        onHandleHideAllItems();
        setShowRejectReasionsList(true);
        dispatch(setCurrentPage("Reject Reasions List"));
        //getAdminList();
    }
    const onHandleAirPort = () => {
        onHandleHideAllItems();
        setShowAirPortList(true);
        dispatch(setCurrentPage("Air Port List"));
        //getAdminList();
    }
    const onHandlePort = () => {
        onHandleHideAllItems();
        setShowPortList(true);
        dispatch(setCurrentPage("Port List"));
        //getAdminList();
    }
    const onHandleServiceType = () => {
        onHandleHideAllItems();
        setShowServiceTypeTableList(true);
        dispatch(setCurrentPage("ServiceType"));
        //getAdminList();
    }
    const onHandleCountryAndOr = () => {
        onHandleHideAllItems();
        setShowCountryAndOrList(true);
        dispatch(setCurrentPage("Country And Origin"));
        //getAdminList();
    }
    const onHandleProductCharges = () => {
        onHandleHideAllItems();
        setShowProductCharges(true);
        dispatch(setCurrentPage("ProductCharges"));
        //getAdminList();
    }
    const onHandleBanners = () => {
        onHandleHideAllItems();
        setBanners(true);
        dispatch(setCurrentPage("Banners"));
        //getAdminList();
    }
    const onHandleUserActions = () => {
        onHandleHideAllItems();
        setShowUserActions(true);
        dispatch(setCurrentPage("UserActions"));
        //getAdminList();
    }
    const onHandleKgFactor = () => {
        onHandleHideAllItems();
        setShowKgFactor(true);
        dispatch(setCurrentPage("KgFactor"));
        //getAdminList();
    }
    const onHandleExchangeRate = () => {
        onHandleHideAllItems();
        setShowExchangeRate(true);
        dispatch(setCurrentPage("Exchange Rate"));
        //getAdminList();
    }
    const onHandleAdminList = () => {
        onHandleHideAllItems();
        setShowAdminList(true);
        dispatch(setCurrentPage("User list"));
        //getAdminList();
    }
    const onHandleWarehouseList = () => {
        onHandleHideAllItems();
        setShowWarehouseList(true);
        dispatch(setCurrentPage("Warehouse list"));
        //getAdminList();
    }
    const onHandleIncotermList = () => {
        onHandleHideAllItems();
        setShowIncotermList(true);
        dispatch(setCurrentPage("Incoterm list"));
        //getAdminList();
    }
    const onHandleFLCType20List = () => {
        onHandleHideAllItems();
        setShowFLCType20List(true);
        dispatch(setCurrentPage("FLCType20 list"));
        //getAdminList();showFLCType40List
    }
    const onHandleFLCType40List = () => {
        onHandleHideAllItems();
        setShowFLCType40List(true);
        dispatch(setCurrentPage("FLCType40 list"));
        //getAdminList();
    }
    const onHandleAirTerminalList = () => {
        onHandleHideAllItems();
        setShowAirTerminalList(true);
        dispatch(setCurrentPage("Air terminal list"));
        //getAdminList();
    }
    const onHandleAgentCodeList = () => {
        onHandleHideAllItems();
        setShowAgentCodeList(true);
        dispatch(setCurrentPage("Agent code list"));
        //getAdminList();
    }
    const onHandleCountryOfImportList = () => {
        onHandleHideAllItems();
        setShowCountryOfImportList(true);
        dispatch(setCurrentPage("Country of import list"));
        //getAdminList();
    }
    const getAdminList = () => {
        let loginObj = { "sessionId": admin.data.session, opcode: "getAdminList" }
        sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
            successCallback: (response) => {
                for (var i = 0; i < response.length; i++) {
                    dispatch(onAdminChange(response[i]));
                }
            },
            failedCallback: error => {

            }
        });
    }
    const StyledMenu = styled(props => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            minWidth: 180,
            color: theme.palette.mode ==='light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                },
            },
        },
    }));
    return (
        <Grid container>
            <Grid container item xs={12} justifyContent='start'>
                <Button onClick={handleClick} variant='contained' sx={{ m: 1.5, padding: '8px', lineHeight: 1}} >
                    {`List`} {!bSettingOpen ? <FaAngleDown /> : <FaAngleUp />}
                </Button>
                <Button onClick={onHandleExchangeRate} variant='contained' sx={{ m: 1.5, padding: '8px', lineHeight: 1 }}>
                    {`Exchange Rate`}
                </Button>
                <Button onClick={onHandleKgFactor} variant='contained' sx={{ m: 1.5, padding: '8px', lineHeight: 1}}>
                    {`KgFactor`}
                </Button>
                <Button onClick={onHandleOpenModal} variant='contained' sx={{ m: 1.5, padding: '8px', lineHeight: 1}} >
                    {`+ New User`}
                </Button>
                <Button onClick={onHandleAdminList} variant='contained' sx={{ m: 1.5, padding: '8px', lineHeight: 1}}>
                    {`User list`}
                </Button>
                {openOrderModal && (
                    <Dialog maxWidth='md' open={openOrderModal} title={`NEW USER`} onHandleCancel={onHandleCancelModal}>
                        <AddAdmin onHandleCancel={() => setOpenOrderModal(false)} id={null} />
                    </Dialog>
                )}
             
                <Button onClick={onHandleProductCharges} variant='contained' sx={{ m: 1.5, padding: '8px', lineHeight: 1}} >
                    {`Product Charges`}
                </Button>
             
                <Button onClick={onHandleBanners} variant='contained' sx={{ m: 1.5, padding: '8px', lineHeight: 1}} >
                    {`Banners`}
                </Button>
              
            </Grid>
            <StyledMenu
                id='demo-customized-menu'
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClick={handleClose}>
                <Link to='/menu/parameters' onClick={onHandleIncotermList} style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                    <MenuItem onClick={handleClose}>Incoterms</MenuItem>
                </Link>
                <Link to='/menu/parameters' onClick={onHandleServiceType} style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                    <MenuItem onClick={handleClose}>Service Type</MenuItem>
                </Link>
                <Link to='/menu/parameters' onClick={onHandleFLCType20List } style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                    <MenuItem onClick={handleClose}>FlcType 20</MenuItem>
                </Link>
                <Link to='/menu/parameters' onClick={onHandleFLCType40List} style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                    <MenuItem onClick={handleClose}>FlcType 40</MenuItem>
                </Link>
                <Link to='/menu/parameters' onClick={onHandleCountryAndOr} style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                    <MenuItem onClick={handleClose}>Country and origin</MenuItem>
                </Link>
                <Link to='/menu/parameters' onClick={onHandlePort} style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                    <MenuItem onClick={handleClose}>Port</MenuItem>
                </Link>
                <Link to='/menu/parameters' onClick={onHandleAirPort} style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                    <MenuItem onClick={handleClose}>Air Port</MenuItem>
                </Link>
                <Link to='/menu/parameters' onClick={onHandleAirLine} style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                    <MenuItem onClick={handleClose}>Air Line</MenuItem>
                </Link>
                <Link to='/menu/parameters' onClick={onHandleRejectReasions} style={{ textDecoration: 'none', color: 'inherit', fontSize: 17 }}>
                    <MenuItem onClick={handleClose}>Reject Reasions</MenuItem>
                </Link>
            </StyledMenu>
            <Grid container item xs={12} justifyContent='space-between' style={{ width: '100%', height: 'calc(100vh - 135px)' }}>
                {showAdminList ? <AdminsTable /> : (null)}
                {exchangeRate ? <ExchangeRateTable /> : (null)}
                {showIncotermList ? <IncotermTable /> : (null)}
                {showServiceTypeTableList ? <ServiceTypeTable /> : (null)}
                {showFLCType20List ? <FLCType20Table /> : (null)}
                {showFLCType40List ? <FLCType40Table /> : (null)}
                {showPortList ? <PortTable /> : (null)}
                {showAirPortList ? <AirPortTable /> : (null)}
                {showAirLineList ? <AirLineTable /> : (null)}
                {showRejectReasionsList ? <RejectReasionsTable /> : (null)}
                {showCountryAndOrList ? <CountryAndOriginTable /> : (null)}
                {showAirTerminalList ? <AirTerminalTable /> : (null)}
                {showAgentCodeList ? <AgentCodeTable /> : (null)}
                {showCountryOfImportList ? <CountryOfImportTable /> : (null)}
                {KgFactor ? <KgFactorTable /> : (null)}
                {productCharges ? <ProductChargesTable /> : (null)}
                {banners ? <Banners /> : (null)}
            </Grid>
        </Grid>
    );
};

export default ParameterMenu;
