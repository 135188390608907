import { useState, useEffect, useCallback, useRef } from 'react';
import { useDraggable } from "react-use-draggable-scroll";
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import XLSX from "xlsx";
import { Button, Grid, Table, TextField, TableBody, TableContainer, Paper, TablePagination, Typography, TableCell, TableHead, TableRow } from '@mui/material';
import CrmHeader from './CrmHeader';
import CrmRow from './CrmRow';
import AddCrm from './AddCrm';
import { FaAngleLeft, FaPlus, FaCloudUploadAlt, FaPen } from "react-icons/fa";
import { useSelector, useDispatch } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { onPageChange, onSizeChange } from '../../reducers/admin-reducers/AdminSlicer';
import { onCrmChange } from '../../reducers/crm/crmSlice';
import { Dialog, Snackbar } from '../../style-guide';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { parcelStatusList, parcelSortBy, SHIPPING_STATUS_LIST, paginationArr } from '../../constants/global-constants';
import { getCRMsPaginationFilter } from '../../reducers/requestHandler';
import { getProductChargesByProdShipCharge, addCrm, getCRMList } from '../../reducers/requestHandler';
export const CrmTable = () => {
    //const { crmListFilter } = useSelector(state => state.parameter);
    const ref = useRef();
    const { paginationData } = useSelector(state => state.admin);
    const { events } = useDraggable(ref);
    const dispatch = useDispatch();
    const { crmList } = useSelector(state => state.crm);
    const { countryList, listOfFunctionTypes, listOfProductCharges } = useSelector(state => state.parameter);
    const { currentPage, admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(
        message => dispatch(snackbarToggle({ type: 'error', message })),
        [dispatch],
    );

    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [bSettingOpen, setSettingOpen] = useState(false);
    const [crmListFilter, setCrmListFilter] = useState(false);
    const open = Boolean(anchorEl);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => {
        setOpenOrderModal(true);
    };
    const handleChange = (event) => {
    };
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const handleClick = event => {
        setSettingOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setSettingOpen(false);
        setAnchorEl(null);
    };
    const onPageHandleChange = (_, newPage) => {
        dispatch(onPageChange(newPage))
    };
    const onRowsPerPageHandleChange = event => {
        dispatch(onSizeChange(+event.target.value))
        dispatch(onPageChange(0))
    };
    const [crmFilter, setCrmFilter] = useState({
        CompanyName: "",
        FreeText: "",
        Address: "",
        CountryId: "",
        FunctionId: [],
        FunctionId1: -1,
    });
    const [reloadListIndex, setReloadListIndex] = useState(1);
    const reloadList = (id) => {
        setReloadListIndex(reloadListIndex + 1);
        if (!id) {
            dispatch(snackbarToggle({ type: 'success', message: 'Crm added successfully' }));
        } else {
            dispatch(snackbarToggle({ type: 'success', message: 'Crm updated successfully' }));
        }
    }
    const getCrmData = () => {
        var fId = [];
        crmFilter.FunctionId.forEach(element => {
            if (element.bSelected) {
                fId.push(element.id);
            }
        });
        var c = crmFilter.CountryId
        if (crmFilter.CountryId === -1) {
            c = "";
        }
        var data = {
            numItems: paginationData.size,
            pageNum: paginationData.page + 1,
            CompanyName: crmFilter.CompanyName,
            FreeText: crmFilter.FreeText,
            Address: crmFilter.Address,
            CountryId: c,
            FunctionId: fId,
        }

        getCRMsPaginationFilter(dispatch, admin, data, setCrmListFilter);
    }
    if (!isLoaded && admin.data.session) {
        //getCRMList(dispatch, admin);
        getCrmData();

        setIsLoaded(true);
    }
    useEffect(() => {
        getCrmData();
        getProductChargesByProdShipCharge(dispatch, admin, 1, 1, 4, handleClientCharges);
        getProductChargesByProdShipCharge(dispatch, admin, 1, 2, 4, handleClientCharges);
        getProductChargesByProdShipCharge(dispatch, admin, 1, 3, 4, handleClientCharges);
        getProductChargesByProdShipCharge(dispatch, admin, 2, 1, 4, handleClientCharges);
        getProductChargesByProdShipCharge(dispatch, admin, 2, 2, 4, handleClientCharges);
        getProductChargesByProdShipCharge(dispatch, admin, 2, 3, 4, handleClientCharges);
        getProductChargesByProdShipCharge(dispatch, admin, 2, 3, 2, handleShippingLineCharges);
        setImports();
    }, [paginationData.page, paginationData.size, dispatch, failedCallback, crmFilter, reloadListIndex]);
    const StyledMenu = styled(props => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            minWidth: 180,
            color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                },
            },
        },
    }));
    const changeHandler = (event) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            console.log(rABS, wb);
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            var keys = data[0];
            var XL_row_object = []
            for (var i = 1; i < data.length; i++) {
                var t = {}
                keys.forEach(function (key, index) {
                    //if (data[i][index])
                    t[key] = data[i][index];
                })
                if (data[i].length > 0) {

                    var objToSave = {
                    }

                    if (t['Name']) {
                        if (!crmList.find(u => u.companyName.toLowerCase().trim() === t['Name'].toLowerCase().trim())) {
                            var crmId = crmList.find(u => u.companyName.toLowerCase().trim() === t['Name'].toLowerCase().trim())?.id;
                            if (crmId) {
                                //  objToSave.id = crmId
                            }
                            var acc = [{ type: "Contact", name: "", position: "", mail: "", mobile: "", landline: t['Telephone'] }, { type: "Contact", name: "", position: "", mail: "", mobile: "", landline: "" }, { type: "Contact", name: "", position: "", mail: "", mobile: "", landline: "" }, { type: "Contact", name: "", position: "", mail: "", mobile: "", landline: "" }, { type: "Accounts", name: "", position: "", mail: "", mobile: "", landline: "" }, { type: "Manager level", name: "", position: "", mail: "", mobile: "", landline: "" }]
                            if (keys.find(u => u === 'Name')) {
                                objToSave.companyName = t['Name']
                            } else {
                                alert("Please Select apropriate file.");
                                return;
                            }
                            if (keys.find(u => u === 'Address')) {
                                objToSave.address = t['Address']
                            } else {
                                alert("Please Select apropriate file.");
                                return;
                            }

                            if (keys.find(u => u === 'Contact person')) {
                                if (t['Contact person'])
                                    acc[0].name = t['Contact person'];
                                objToSave.accounts = acc
                            } else {
                                alert("Please Select apropriate file.");
                                return;
                            }
                            if (keys.find(u => u === 'E-mail')) {
                                if (t['E-mail'])
                                    acc[0].mail = t['E-mail'];
                                objToSave.accounts = acc
                            } else {
                                alert("Please Select apropriate file.");
                                return;
                            }
                            if (keys.find(u => u === 'Telephone')) {
                                if (t['Telephone'])
                                    acc[0].landline = t['Telephone'];
                                objToSave.accounts = acc
                            } else {
                                alert("Please Select apropriate file.");
                                return;
                            }
                            if (keys.find(u => u === 'Function') && t['Function']) {

                                objToSave.functionId = listOfFunctionTypes.find(u => u.lable.toLowerCase() === t['Function'].toLowerCase())?.id
                            } else {
                                alert("Please Select apropriate file.");
                                return;
                            }
                            if (keys.find(u => u === 'Country')) {
                                if (t['Country'])
                                    objToSave.countryId = countryList.find(u => u.name.toLowerCase() === t['Country'].toLowerCase())?.id
                                if (!objToSave.countryId)
                                    objToSave.countryId = 0;
                            } else {
                                alert("Please Select apropriate file.");
                                return;
                            }
                            save(objToSave);
                        } else {
                            var x = t['Name'];

                            dispatch(snackbarToggle({ type: 'error', message: 'Some entries exists already. '}));
                          //  break;
                        }
                    }


                }
            }

            /* Update state */
            //var cols= make_cols(ws["!ref"])
            //this.setState({ data: data, cols: make_cols(ws["!ref"]) });
        };
        var file = event.target.files[0];
        if (file) {
            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        }
    };
    const procesCharges = function (callback) {
        var data = {
            data: [...listOfProductCharges]
        }

        callback(JSON.parse(JSON.stringify(data)))
    }

    const handleCustomsChargesAirImport = () => {
        var data = JSON.parse(JSON.stringify({
            data: [...listOfProductCharges]
        }));

        var t = []
        for (var i = 0; i < data.data.length; i++) {
            var itm = getCustomsChargesAirImportByName(data.data[i].name)
            if (itm) {
                data.data[i].unitOfMeasure = data.data[i]?.units?.find(u => u === itm.unitOfMeasure)
                data.data[i].currency = data.data[i]?.currencies?.find(u => u === itm.currency)//itm.currency;
            } else {
                if (data.data[i].units.length) {
                    data.data[i].unitOfMeasure = data.data[i].units[0]
                }
                if (data.data[i].currencies.length) {
                    data.data[i].currency = data.data[i].currencies[0]
                }
            }
            var bAdd = false;
            for (var j = 0; j < t.length; j++) {
                if (data.data[i].id == t[j].id) {
                    bAdd = true;
                    t[j].unitOfMeasure = data.data[i].unitOfMeasure
                    t[j].currency = data.data[i].currency;
                    break;
                }

            }
            if (!bAdd) {
                if (itm) {
                    data.data[i].rate = 0;
                    data.data[i].min = 0;
                    t.push(JSON.parse(JSON.stringify(data.data[i])));
                }
            }
        }

        t = uniqueByKey(t, "id")
        return t;
    }
    const getCustomsChargesAirImportByName = function (name) {
        var t = [
            { name: 'Customs Clearance', unitOfMeasure: 2, currency: 4, rate: 0, min: 0 },
            { name: 'Formalities', unitOfMeasure: 2, currency: 4, rate: 0, min: 0 },
            { name: 'World Gate', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
            { name: 'Insurance Handling', unitOfMeasure: 2, currency: 4, rate: 0, min: 0 },
            { name: 'Inland Freight', unitOfMeasure: 2, currency: 4, rate: 0, min: 0 },
            { name: 'Import License', unitOfMeasure: 2, currency: 4, rate: 0, min: 0 },
        ]

        return t.find(u => u.name === name);
    }
    const changeCustomeChargesHandler = (event) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            console.log(rABS, wb);
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            var keys = data[0];
            var XL_row_object = []
            for (var i = 1; i < data.length; i++) {
                var t = {}
                keys.forEach(function (key, index) {
                    //if (data[i][index])
                    t[key] = data[i][index];
                })
                if (data[i].length > 0) {

                    var objToSave = {
                    }

                    if (t['Client']) {
                        var crm = JSON.parse(JSON.stringify(crmList.find(u => u.companyName.toLowerCase().trim() === t['Client'].toLowerCase().trim())));
                        
                        if (crm.functionId == 1 || crm.functionId == 2) {
                            var cCharges = crm.charges.CUSTOM_AIR_IMPORT;
                            if (!cCharges) {
                                cCharges = handleCustomsChargesAirImport();
                            }
                           // var cCharges = crm.charges.CUSTOM_CHARGES;
                            for (var k = 0; k < cCharges.length; k++) {
                                if (keys.find(u => u === 'FORMALITIES (ILS)')) {
                                    if (cCharges[k].name == 'Formalities') {
                                        cCharges[k].rate = t['FORMALITIES (ILS)']
                                        //cCharges[k].currency = 4;
                                    }
                                }
                                if (keys.find(u => u === 'INSURANCE HANDLING FEE (ILS)')) {
                                    if (cCharges[k].name == 'Insurance Handling') {
                                        cCharges[k].rate = t['INSURANCE HANDLING FEE (ILS)']
                                        //cCharges[k].currency = 4;
                                    }
                                }
                                if (keys.find(u => u === 'WORLD GATE (USD)')) {
                                    if (cCharges[k].name == 'World Gate') {
                                        cCharges[k].rate = t['WORLD GATE (USD)']
                                        //cCharges[k].currency = 1;
                                    }
                                }
                                if (keys.find(u => u === 'CUSTOMS CLEARANCE (ILS)')) {
                                    if (cCharges[k].name == 'Customs Clearance') {
                                        cCharges[k].rate = t['CUSTOMS CLEARANCE (ILS)']
                                        //cCharges[k].currency = 4;
                                    }
                                }
                                if (keys.find(u => u === 'IMPORT LICENSE (ILS)')) {
                                    if (cCharges[k].name == 'Import License') {
                                        cCharges[k].rate = t['IMPORT LICENSE (ILS)']
                                        //cCharges[k].currency = 4;
                                    }
                                }
                                if (keys.find(u => u === 'INLAND FREIGHT (ILS)')) {
                                    if (cCharges[k].name == 'Inland Freight') {
                                        cCharges[k].rate = t['INLAND FREIGHT (ILS)']
                                        //cCharges[k].currency = 4;
                                    }
                                }
                            }
                        }
                        crm.charges.CUSTOM_AIR_IMPORT = cCharges
                        save(crm);
                    }
                    // }


                }
            }
            window.setTimeout(function () { getCrmData()},500)
            /* Update state */
            //var cols= make_cols(ws["!ref"])
            //this.setState({ data: data, cols: make_cols(ws["!ref"]) });
        };
        var file = event.target.files[0];
        if (file) {
            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        }
    };
    const save = (data) => {
        var countries = [];
        var charges = {};
        if (!data.id) {
            if (data.functionId == 2) {

                charges = {
                    "LCL_IMPORT": crmLclImports,
                    "FCL_IMPORT": crmFclImports,
                    "AIR_IMPORT": crmAirImports,
                }
            } else if (data.functionId == 1) {

                charges = {
                    "CUSTOM_CHARGES": crmClientCharges
                }
            } else if (data.functionId == 3) {

                charges = {
                    "LOCAL_CHARGES": crmShippingLineCharges
                }
            }
        } else {
            charges = data.charges
        }
        var data1 = {
            id: data.id,
            companyName: data.companyName,
            countryId: data.countryId,
            functionId: data.functionId,
            agentId: 0,
            networkId: "",
            address: data.address,
            freeText: "",
            accounts: data.accounts,
            countries: [],
            charges: charges,
        }
        addCrm(dispatch, snackbarToggle, data1, admin,getCrmData)

    };
    const handleSubmission = () => {
        var fileInput = document.getElementById('file');
        var element = document.getElementById("file_container");
        if (fileInput) {
            element.removeChild(fileInput);
        }
        var x = document.createElement("INPUT");
        x.setAttribute("type", "file");
        x.setAttribute("id", 'file');
        x.style.display = "none";
        element.appendChild(x);

        document.getElementById("file").addEventListener("change", changeHandler, true);
        document.getElementById('file').click();

    };
    const handleCustomeChargesSubmission = () => {
        var fileInput = document.getElementById('file');
        var element = document.getElementById("file_container");
        if (fileInput) {
            element.removeChild(fileInput);
        }
        var x = document.createElement("INPUT");
        x.setAttribute("type", "file");
        x.setAttribute("id", 'file');
        x.style.display = "none";
        element.appendChild(x);

        document.getElementById("file").addEventListener("change", changeCustomeChargesHandler, true);
        document.getElementById('file').click();

    };
    const [crmLclImports, setCrmLclImports] = useState([]);
    const [crmFclImports, setCrmFclImports] = useState([]);
    const [crmAirImports, setCrmAirImports] = useState([]);
    const [bLclImport, setBlclImport] = useState(false);
    const [bFclImport, setBfclImport] = useState(false);
    const [bAirImport, setBairImport] = useState(true);
    var bA = 1;
    var bL = 0;
    var bF = 0;
    const setImports = function (val) {
        getProductChargesByProdShipCharge(dispatch, admin, 2, 1, 3, function (data) {
            var t = [...crmLclImports]
            for (var i = 0; i < data.data.length; i++) {

                var bAdd = false;
                for (var j = 0; j < t.length; j++) {
                    if (data.data[i].id == t[j].id) {
                        bAdd = true;
                        break;
                    }

                }
                if (!bAdd) {
                    if (data.data[i].name == 'Expenses Abroad' || data.data[i].name == 'EXW Charges' || data.data[i].name == 'Inland Freight') {
                        if (data.data[i].units.length) {
                            data.data[i].unitOfMeasure = data.data[i].units[0]
                        }
                        if (data.data[i].currencies.length) {
                            data.data[i].currency = data.data[i].currencies[0]
                        }
                        t.push(data.data[i]);
                    }
                }
            }
            setCrmLclImports(t)
            var t = [...crmAirImports]
            for (var i = 0; i < data.data.length; i++) {

                var bAdd = false;
                for (var j = 0; j < t.length; j++) {
                    if (data.data[i].id == t[j].id) {
                        bAdd = true;
                        break;
                    }

                }
                if (!bAdd) {
                    if (data.data[i].name == 'THC' || data.data[i].name == 'Customs Clearance' || data.data[i].name == 'Screening' || data.data[i].name == 'Documents') {
                        if (data.data[i].units.length) {
                            data.data[i].unitOfMeasure = data.data[i].units[0]
                        }
                        if (data.data[i].currencies.length) {
                            data.data[i].currency = data.data[i].currencies[0]
                        }
                        t.push(data.data[i]);
                    }
                }
            }
            setCrmAirImports(t)
            var t = [...crmFclImports]
            for (var i = 0; i < data.data.length; i++) {

                var bAdd = false;
                for (var j = 0; j < t.length; j++) {
                    if (data.data[i].id == t[j].id) {
                        bAdd = true;
                        break;
                    }

                }
                if (!bAdd) {
                    if (data.data[i].name == 'OTHC' || data.data[i].name == 'Expenses Abroad') {
                        if (data.data[i].units.length) {
                            data.data[i].unitOfMeasure = data.data[i].units[0]
                        }
                        if (data.data[i].currencies.length) {
                            data.data[i].currency = data.data[i].currencies[0]
                        }
                        t.push(data.data[i]);
                    }
                }
            }
            setCrmFclImports(t)
        });
    }

    const [crmClientCharges, setCrmClientCharges] = useState([]);
    const [crmClientCharges1, setCrmClientCharges1] = useState([]);

    const handleClientCharges = (data) => {
        var t = [...crmClientCharges]
        for (var i = 0; i < data.data.length; i++) {
            var bAdd = false;
            for (var j = 0; j < t.length; j++) {
                if (data.data[i].id == t[j].id) {
                    bAdd = true;
                    break;
                }

            }
            if (!bAdd) {
                if (data.data[i].name == 'Customs Clearance' || data.data[i].name == 'Formalities' || data.data[i].name == 'World Gate' || data.data[i].name == 'Insurance Handling' || data.data[i].name == 'Trucking') {
                    if (data.data[i].units.length) {
                        data.data[i].unitOfMeasure = data.data[i].units[0]
                    }
                    if (data.data[i].currencies.length) {
                        data.data[i].currency = data.data[i].currencies[0]
                    }
                    t.push(data.data[i]);
                }
            }
        }

        t = uniqueByKey(t, "id")
        setCrmClientCharges(t)
        setCrmClientCharges1(t)
    }
    const [crmShippingLineCharges, setCrmShippingLineCharges] = useState([]);
    const handleShippingLineCharges = (data) => {
        var t = [...crmShippingLineCharges]
        for (var i = 0; i < data.data.length; i++) {

            var bAdd = false;
            for (var j = 0; j < t.length; j++) {
                if (data.data[i].id == t[j].id) {
                    bAdd = true;
                    break;
                }

            }
            if (!bAdd) {
                if (data.data[i].name == 'Handling fee' || data.data[i].name == 'Delivery Order' || data.data[i].name == 'Cleaning fee' || data.data[i].name == 'Agency fee' || data.data[i].name == 'Communication' || data.data[i].name == 'IT surcharges' || data.data[i].name == 'ISPS' || data.data[i].name == 'Transfer fee' || data.data[i].name == 'World gate fee') {
                    if (data.data[i].units.length) {
                        data.data[i].unitOfMeasure = data.data[i].units[0]
                    }
                    if (data.data[i].currencies.length) {
                        data.data[i].currency = data.data[i].currencies[0]
                    }
                    t.push(data.data[i]);
                }
            }
        }
        t = uniqueByKey(t, "id")
        setCrmShippingLineCharges(t)
    }
    function uniqueByKey(array, key) {
        return [...new Map(array.map((x) => [x[key], x])).values()];
    }
    return (

        <Paper sx={{ width: '100%', overflow: 'auto' }} {...events} ref={ref} key={reloadListIndex}>
            {openOrderModal && (
                <Dialog open={openOrderModal} title={'Crm'} onHandleCancel={onHandleCancelModal}>
                    <AddCrm setCrmFilter={setCrmFilter} callback={(id) => { } } onHandleCancel={() => setOpenOrderModal(false)} id={null} />
                </Dialog>
            )}
            <Grid container item xs={12} justifyContent='end' id="file_container">
                <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={onHandleOpenModal}>
                    <FaPlus style={{ marginRight: 5 }} />Create
                </Button>
                <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#85ce61 !important' }} onClick={handleSubmission}>
                    <FaCloudUploadAlt style={{ marginRight: 5 }} />Upload Csv
                </Button>
                <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#85ce61 !important' }} onClick={handleCustomeChargesSubmission}>
                    <FaCloudUploadAlt style={{ marginRight: 5 }} />Custom charges CSV
                </Button>
                <Button variant='outlined' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, justifyContent: 'flex-end' }} onClick={function () { }}>
                    <Link style={{ textDecoration: 'none', color: 'inherit', fontSize: 12 }} to="../../../../../files/crm_template.xlsx" target="_blank" download>Template List</Link>

                </Button>
                <Button variant='outlined' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 4, justifyContent: 'flex-end' }} onClick={function () { }}>
                    <Link style={{ textDecoration: 'none', color: 'inherit', fontSize: 12 }} to="../../../../../files/crm_custom_charges.xlsx" target="_blank" download>Template CC</Link>

                </Button>
            </Grid>
            {



                <TableContainer sx={{ height: "calc(100vh - 181px)" }}>
                    <Table>
                        <CrmHeader setCrmFilter={setCrmFilter} crmFilter={crmFilter} reset={function () { }} />
                        <TableBody>


                            {crmListFilter.length > 0 &&
                                crmListFilter.map(crm => (

                                    <CrmRow callback={reloadList} setCrmFilter={setCrmFilter} key={crm?.id || uuidv4()} data={crm} getCrmData={getCrmData} />
                                ))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>

            }
            <TablePagination
                rowsPerPageOptions={paginationArr}
                component='div'
                count={paginationData.parcelCount}
                rowsPerPage={paginationData.size}
                page={paginationData.page}
                onPageChange={onPageHandleChange}
                onRowsPerPageChange={onRowsPerPageHandleChange}
            />
            {snackbar && (
                <Snackbar
                    open={!!snackbar}
                    message={snackbar.message}
                    type={snackbar.type}
                    onClose={onSnackbarHandleClose}
                />
            )}
        </Paper>


    );
};
export default CrmTable;
