import { memo } from 'react';
import { useState } from 'react';
import { TableCell, TableHead, TableRow, Typography, Button } from '@mui/material';
import AddCountryAndOrigin from './AddCountryAndOrigin';
import { Dialog } from '../../style-guide';

const CountryAndOriginHeader = () => {
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);

    return (
        <TableHead>
            <TableRow>
                <TableCell sx={4}>
                    <Typography variant='h7'>
                        
                    </Typography>
                </TableCell>
                <TableCell sx={4}>
                    <Typography variant='h7'>
                        Name
                    </Typography>
                </TableCell>
                <TableCell sx={4}>
                    <Typography variant='h7'>

                    </Typography>
                </TableCell>
                <TableCell sx={4}>
                    <Typography variant='h7'>
                        Operations 
                    </Typography>
                </TableCell>
                <TableCell sx={4}>
                    <Typography variant='h7'>
                        <Button onClick={onHandleOpenModal} variant='contained'>
                            + New Country And Origin
                        </Button>
                        {openOrderModal && (
                            <Dialog open={openOrderModal} title={'Country And Origin'} onHandleCancel={onHandleCancelModal}>
                                <AddCountryAndOrigin onHandleCancel={onHandleCancelModal} id={null} />
                            </Dialog>
                        )}
                    </Typography>
                </TableCell>

            </TableRow>
        </TableHead>
    );
};

export default memo(CountryAndOriginHeader);
