import { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table, TableBody, TableContainer, TableCell, TableRow, Typography, Select, Grid, FormControl, InputLabel, Box, MenuItem, Button, Checkbox, FormControlLabel } from '@mui/material';
import { SelectField, Snackbar, TextField, MultilineField, DateTimePicker } from '../../../style-guide';
import { sendRequest, REQUEST_ACTIONS } from '../../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../../reducers/snackbarSlicer';
import { setAdmin, onWarehouseChange, onPageChange, onSizeChange } from '../../../reducers/admin-reducers/AdminSlicer';
import imgHide from '../hide.png'
import imgShow from '../show.png'
import { addAirExportAF, addFclExportImportAF, updateLocalCharges, getCRMList } from '../../../reducers/requestHandler';
import { truckingChargesFcl, fclCostFrieghtChargesExport, fclCostLocalChargesExport1, ofChargesFcl, fclCostLocalCharges, fclCostFrieghtCharges, ofList, pssList, conList, bafList, cnlList, gpsList, enList, isList, lrList, wrsList, ieiList, ebsList, scsList, obsList, eisList, esList, vispsList, lsaList, rateList } from '../../../constants/global-constants';
import { addDesc, getUserInfoByLockerId, getDiscriptionsList, createShippingRequest, getParcelsList } from '../../Admins/AdminHandler'
import RowRate from './RowRate';
import RowSelectOption from './RowSelectOption';

const AddFclCost = props => {
    const dispatch = useDispatch();
    const { crmList } = useSelector(state => state.crm);
    const { countryList, listOfAirPort, listOfAirLine, listOfPort, qsTypeList, qsStatusList, listOfIncoterms, listOfServiceType, listOfMeasurementUnits, listOfcurrency } = useSelector(state => state.parameter);
    const { roles, paginationData, warehouseList, senderList, discriptionsList } = useSelector(state => state.admin);
    const { admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const [userData, setUserData] = useState(null);
    const [selectFreight, setselectFreight] = useState(1);
    const [selectFreightId, setselectFreightId] = useState(1);
    const [bFrieghtCharges, setBFrieghtCharges] = useState(false);
    const [bLocalCharges, setBLocalCharges] = useState(false);
    const [bOfCharges, setBOfCharges] = useState(true);
    const [bNextPage, setNextPage] = useState(false);
    const [listOfWeight, setListOfWeight] = useState([]);
    const [truckingCharges, setTruckingCharges] = useState(props.data?.params?.TRUCKING_CAHRGES ? props.data?.params?.TRUCKING_CAHRGES : [...truckingChargesFcl]);
    const [ofCharges, setOfCharges] = useState(props.data?.params?.OF_CAHRGES ? props.data?.params?.OF_CAHRGES : [...ofChargesFcl]);
    const [frieghtCharges, setFrieghtCharges] = useState((props.data?.params?.FRIEGHT_CAHRGES /*fclCostFrieghtChargesExport.length*/) ? props.data?.params?.FRIEGHT_CAHRGES : (props.importExportType == 1 ? [...fclCostFrieghtChargesExport] : [...fclCostFrieghtCharges]));
    const [frieghtChargesTemp, setFrieghtChargesTemp] = useState([]);
    const [ofList1, setofList] = useState([...ofList]);
    const [pssList1, setpssList] = useState([...pssList]);
    const [conList1, setconList] = useState([...conList]);
    const [bafList1, setbafList] = useState([...bafList]);
    const [cnlList1, setcnlList] = useState([...cnlList]);
    const [gpsList1, setgpsList] = useState([...gpsList]);
    const [enList1, setenList] = useState([...enList]);
    const [isList1, setisList] = useState([...isList]);
    const [lrList1, setlrList] = useState([...lrList]);
    const [wrsList1, setwrsList] = useState([...wrsList]);
    const [ieiList1, setieiList] = useState([...ieiList]);
    const [ebsList1, setebsList] = useState([...ebsList]);
    const [scsList1, setscsList] = useState([...scsList]);
    const [obsList1, setobsList] = useState([...obsList]);
    const [eisList1, seteisList] = useState([...eisList]);
    const [esList1, setesList] = useState([...esList]);
    const [vispsList1, setvispsList] = useState([...vispsList]);
    const [lsaList1, setlsaList] = useState([...lsaList]);
    const [rateList1, setrateList] = useState([...rateList]);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data?.id,
                agentCrmId: props.data?.agentCrmId,
                shippingCrmId: props.data?.shippingCrmId,
                countryId: props.data?.countryId,
                portLid: props.data?.portLid,
                portAid: props.data?.portAid,
                zip: props.data?.zip,
                ofList: props.data?.ofList,
                pssList: props.data?.pssList,
                conList: props.data?.conList,
                bafList: props.data?.bafList,
                gpsList: props.data?.gpsList,
                cnlList: props.data?.cnlList,
                enList: props.data?.enList,
                isList: props.data?.isList,
                lrList: props.data?.lrList,
                wrsList: props.data?.wrsList,
                ieiList: props.data?.ieiList,
                ebsList: props.data?.ebsList,
                scsList: props.data?.scsList,
                obsList: props.data?.obsList,
                eisList: props.data?.eisList,
                esList: props.data?.esList,
                vispsList: props.data?.vispsList,
                lsaList: props.data?.lsaList,
                rateList: props.data?.rateList,
                createTime: props.data?.createTime,
                updateTime: props.data?.updateTime,
                validDate: props.data?.validDate,
                validfrom: props.data?.validfrom,
                tt: props.data?.params?.tt,
                direct: props.data?.params?.direct,
                params: props.data?.params,
                product: props?.data?.product,
                unitOfMeasure: props?.data?.unitOfMeasure,
                currency: props?.data?.currency,
                remark: props?.data?.remark,
                params: props?.data?.params,
            }
            : {
                id: null,
                agentCrmId: 0,
                shippingCrmId: 0,
                countryId: 0,
                portLid: 0,
                portAid: 0,
                zip: 0,
                min: 0,

            },
    );
    //const [charges, setCharges] = useState((props.data?.params?.LOCAL_CHARGES /*fclCostLocalChargesExport1.length*/) ? props.data?.params?.LOCAL_CHARGES : (props.data?.importExportType == 1 ? [...fclCostLocalChargesExport1] : [...fclCostLocalCharges]));
    const [charges, setCharges] = useState(props.data ? props?.data?.importExportType == 1 ? (props?.data?.params?.LOCAL_CHARGES ? props?.data?.params?.LOCAL_CHARGES : [...fclCostLocalCharges]) : (props?.data?.params?.LOCAL_CHARGES ? props?.data?.params?.LOCAL_CHARGES : [...fclCostLocalCharges]) : props.charges?.length > 0 ? props.charges : [...fclCostLocalCharges]);
    const [chargesTemp, setChargesTemp] = useState([]);
    const [selectLocalId, setselectLocalId] = useState(1);
    const isCreateShipmentDiasabled = () => {
        return (!value.token || !value.descId ||
            !value.serialNum || !value.weight || !value.length || !value.width ||
            !value.height || !value.packages);
    }
    const [checked, setChecked] = useState(false);
    if (props.typeData.id == 3 && !checked && value.id) {
        setChecked(true)

    }
    const resetTempFrieght = function (t) {
        var t1 = [];
        for (var i = 0; i < t.length; i++) {
            if (!Number(t[i].rate20) || !Number( t[i].rate40)) {
                var tObj = JSON.parse(JSON.stringify(t[i]))
                tObj.id = i + 1;
                tObj.currency = listOfcurrency.find(u => u.id === tObj.currency)?.id
                t1.push(tObj)
            }
        }
        setFrieghtChargesTemp(t1)
        setselectFreightId(t1[0].id)
    }
    const resetTempLocal = function (t) {
        var t1 = [];
        for (var i = 0; i < t.length; i++) {
            if (t[i].rate20 || t[i].rate40) {
                if (!Number(t[i].rate20) || !Number(t[i].rate40)) {
                    var tObj = JSON.parse(JSON.stringify(t[i]))
                    tObj.id = i + 1;
                    tObj.currency = listOfcurrency.find(u => u.id === tObj.currency)?.id
                    t1.push(tObj)
                }
            }
        }
        setChargesTemp(t1)
        if (t1.length)
            setselectLocalId(t1[0].id)
    }
    const hideAllCharges = function () {
        setBLocalCharges(false)
        setBFrieghtCharges(false)
        setBOfCharges(false)
    }
    const setChargesitem = function (event, key, index) {
        var t = []
        var selected = [...charges]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            if (index == i)
                tObj[key] = event.target.value

            t.push(tObj)
        }
        setCharges(t)
        resetTempLocal(t)
    }
    const setFrieghtChargesitem = function (event, key, index) {
        var t = []
        var selected = [...frieghtCharges]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            if (index == i)
                tObj[key] = event.target.value

            t.push(tObj)
        }
        setFrieghtCharges(t)
        resetTempFrieght(t)
    }
    const setOfChargesitem = function (event, key, index) {
        var t = []
        var selected = [...ofCharges]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            if (index == i)
                tObj[key] = event.target.value

            t.push(tObj)
        }
        setOfCharges(t)
    }
    const setTruckingChargesitem = function (event, key, index) {
        var t = []
        var selected = [...truckingCharges]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            if (index == i)
                tObj[key] = event.target.value

            t.push(tObj)
        }
        setTruckingCharges(t)
    }
    const save = () => {
        var tOfCharges = [...ofCharges]
        for (var i = 0; i < tOfCharges.length; i++) {
            if (!tOfCharges[i].name) {
                tOfCharges[i].name = tOfCharges[i].product
            }
        }
        var tfrieghtCharges = [...frieghtCharges]
        for (var i = 0; i < tfrieghtCharges.length; i++) {
            if (!tfrieghtCharges[i].name) {
                tfrieghtCharges[i].name = tfrieghtCharges[i].product
            }
        }
        var data = {
            id: value.id,
            importExportType: props.typeData.id,
            agentCrmId: value.agentCrmId,
            shippingCrmId: value.shippingCrmId,
            countryId: value.countryId,
            portAid: value.portAid,
            portLid: value.portLid,
            ofList: ofList1,
            pssList: pssList1,
            conList: conList1,
            bafList: bafList1,
            gpsList: gpsList1,
            cnlList: cnlList1,
            enList: enList1,
            isList: isList1,
            lrList: lrList1,
            wrsList: wrsList1,
            ieiList: ieiList1,
            ebsList: ebsList1,
            scsList: scsList1,
            obsList: obsList1,
            eisList: eisList1,
            esList: esList1,
            vispsList: vispsList1,
            lsaList: lsaList1,
            rateList: rateList1,
            zip: value.zip,
            min: value.min,
            tt: value.tt,
            validDate: value.validDate,
            validfrom: value.validfrom,
            params: {
                OF_CAHRGES: tOfCharges,
                FRIEGHT_CAHRGES: tfrieghtCharges,
                LOCAL_CHARGES: charges,
                TRUCKING_CAHRGES: truckingCharges,
                tt: value.tt,
                direct: value.direct,
                TRUCKER_NAME: crmList.find(u => u.id === value.agentCrmId)?.companyName,
            }
        }

        addFclExportImportAF(dispatch, snackbarToggle, data, admin, props.callBack)
        props.onHandleCancel();

    };
    const update = () => {
        props.onHandleCancel();
    };
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!isLoaded) {
            getDiscriptionsList(dispatch, admin);
            // getPackages();
        }
        if (!isLoaded && admin.data?.session && crmList && crmList.length == 0) {
            //getCRMList(dispatch, admin);
           
            setIsLoaded(true);
        }
    }, [dispatch, failedCallback, warehouseList]);

    const getUserInfoByToken = (e) => {
        if (value.token && value.token.length < 4) {
            dispatch(snackbarToggle({ type: 'Error', message: 'Locker ID is too short, please check again.' }));
            return;
        }
        getUserInfoByLockerId(dispatch, value.token, admin, setUserData);
        console.log(userData)
    }
    const keyPressed = (e) => {
        if (e.keyCode === 13) {
            getUserInfoByToken();

        }
    }

    const senderNameOptions = senderList.map(sender => ({
        id: sender.id,
        name: sender.senderName + ", " + sender.senderAddr + ", " + sender.senderCountry
    }));
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [uploadStarted, setUploadStarted] = useState(false);

    const changeHandler = (event) => {

    };
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const getCRMsByFunctionTypes = function (functionId) {
        var temp = []
        for (var i = 0; i < crmList.length; i++) {
            if (crmList[i].functionId == functionId) {
                temp.push(crmList[i])
            }
        }
        return temp;
    }
    const agentNameOption = getCRMsByFunctionTypes(2).map(sender => ({
        id: sender.id,
        name: sender.companyName
    })).sort(function (a, b) {
        if (a.name?.toLowerCase() < b.name?.toLowerCase()) { return -1; }
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) { return 1; }
        return 0;
    });
    const shippingAgentNameOption = getCRMsByFunctionTypes(3).map(sender => ({
        id: sender.id,
        name: sender.companyName
    })).sort(function (a, b) {
        if (a.name?.toLowerCase() < b.name?.toLowerCase()) { return -1; }
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) { return 1; }
        return 0;
    });
    const truckerNameOption = getCRMsByFunctionTypes(6).map(sender => ({
        id: sender.id,
        name: sender.companyName
    }));
    const msx = { maxHeight: '400px' }
    const onStartDateChange = data => {
        //setStartDate(data);
        setValue(prev => ({ ...prev, validDate: data?.getTime() }))
    };
    const onStartDateChangeFrom = data => {
        //setStartDate(data);
        setValue(prev => ({ ...prev, validfrom: data?.getTime() }))
    };

    const saveCharges = () => {
        var t = [];

        for (var i = 0; i < charges.length; i++) {
            var tObj = JSON.parse(JSON.stringify(charges[i]));
            charges[i].product = charges[i].product ? charges[i].product : charges[i].name;
            charges[i].importExportType = props.typeData.id;
            charges[i].shipmentId = 3;
            charges[i].remark = charges[i].remark ? charges[i].remark : "";
            charges[i].rate = charges[i].rate ? charges[i].rate : "";
            charges[i].min = charges[i].min ? charges[i].min : "";
            updateLocalCharges(dispatch, admin, charges[i], props?.callBack);
            t.push(tObj)
        }
        props?.setAirImportExport([]);
        props?.onHandleCancel();
        window.setTimeout(function () { props?.setAirImportExport(t); }, 500)
    }
    return (
        <div>
            {(props.typeData?.id == 1 || props.typeData?.id == 2 || props.typeData?.id == 3) && <Grid container item spacing={2}>
                {(props.typeData.id == 2 || props.typeData.id == 1) && <Grid container item spacing={2}>

                    {props.typeData.id == 2 && <SelectField
                        required
                        value={value.agentCrmId}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, agentCrmId: event.target.value }))}
                        label='Agent (PP)'
                        options={agentNameOption}
                    />}
                    {<SelectField
                        required
                        value={value.shippingCrmId}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, shippingCrmId: event.target.value }))}
                        label='Ship Line (CC)'
                        options={shippingAgentNameOption}
                    />}
                    <SelectField
                        value={value.countryId}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, countryId: event.target.value }))}
                        label='Country'
                        options={countryList}
                    />
                    {<SelectField
                        required
                        value={value.portLid}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, portLid: event.target.value }))}
                        label='POL'
                        options={listOfPort}
                    />}
                    {<SelectField
                        required
                        value={value.portAid}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, portAid: event.target.value }))}
                        label='POA'
                        options={listOfPort}
                    />}
                    <DateTimePicker
                        sm={null}
                        md={null}
                        xs={5.5}
                        label={<Typography fontSize="0.8rem" fontWeight='bold'>Valid Start Date</Typography>}
                        value={value.validfrom}
                        onChange={date => onStartDateChangeFrom(date)}
                        style={{ 'top': '15px', left: '35px' }}
                    />
                    <DateTimePicker
                        sm={null}
                        md={null}
                        xs={5.5}
                        label={<Typography fontSize="0.8rem" fontWeight='bold'>Valid End Date</Typography>}
                        value={value.validDate}
                        onChange={date => onStartDateChange(date)}
                        style={{ 'top': '15px', left: '35px' }}
                    />

                    {
                        props.typeData.id == 2 && <TextField
                            lg={5.5}
                            value={value.tt}
                            onChange={event => setValue(prev => ({ ...prev, tt: event.target.value }))}
                            label='Transit Time'

                        />
                    }

                    {
                        props.typeData.id == 2 &&

                        <Grid container item lg={5.5}>
                            <FormControlLabel control={<Checkbox checked={value.direct} onChange={(event) => {
                                setValue(prev => ({ ...prev, direct: event.target.checked }));
                            }} />} label={"Direct"} />
                        </Grid>
                    }

                </Grid>
                }

                {(props.typeData.id == 3) && <Grid container item spacing={2} justifyContent='flex-end'>
                    <Grid container item spacing={2} justifyContent='flex-end' style={{ marginRight: '15px' }}>

                        <SelectField
                            value={value.agentCrmId}
                            lg={6}
                            onChange={event => setValue(prev => ({ ...prev, agentCrmId: event.target.value }))}
                            label='Trucker'
                            options={truckerNameOption}
                        />
                        <SelectField
                            value={value.countryId}
                            lg={6}
                            onChange={event => setValue(prev => ({ ...prev, countryId: event.target.value }))}
                            label='Country'
                            options={countryList}
                        />
                        {<SelectField
                            required
                            value={value.portLid}
                            lg={6}
                            onChange={event => setValue(prev => ({ ...prev, portLid: event.target.value }))}
                            label='POL'
                            options={listOfPort}
                        />}
                        <TextField
                            lg={6}
                            value={value.zip}
                            onChange={event => setValue(prev => ({ ...prev, zip: event.target.value }))}
                            label='zip'
                        />
                    </Grid>
                </Grid>
                }

                {props.typeData.id == 3 && <Grid container item sx={{ ml: -0.8 }}>
                    <TableContainer>
                        <Table>

                            <TableBody>
                                {truckingCharges.length > 0 &&
                                    truckingCharges.map((chargesItem, index) => (
                                        <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.name}
                                                    disabled={true}
                                                    label='Product'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'MeasurementUnit'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'MeasurementUnit'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.unitOfMeasure}
                                                                label={'MeasurementUnit'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setTruckingChargesitem(event, 'unitOfMeasure', index)
                                                                }}
                                                               // disabled={true}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfMeasurementUnits?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'currency'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'currency'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.currency}
                                                                label={'currency'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setTruckingChargesitem(event, 'currency', index)
                                                                }}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfcurrency?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.rate20}
                                                    onChange={function (event) {
                                                        setTruckingChargesitem(event, 'rate20', index)
                                                    }}
                                                    label='Rate20'
                                                    type='number'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.rate40}
                                                    onChange={function (event) {
                                                        setTruckingChargesitem(event, 'rate40', index)
                                                    }}
                                                    label='Rate40'
                                                    type='number'
                                                />
                                            </TableCell>
                                            {/*<TableCell style={{ padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.min}
                                                onChange={function (event) {
                                                    setTruckingChargesitem(event, 'min', index)
                                                }}
                                                label='Min'
                                                type='number'
                                            />
                                        </TableCell>*/}
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.remark}
                                                    onChange={function (event) {
                                                        setTruckingChargesitem(event, 'remark', index)
                                                    }}
                                                    label='Remark'
                                                />
                                            </TableCell>
                                        </TableRow>))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>}


                {props.typeData.id != 3 && <Grid container item >
                    <Grid container item >
                        <Button variant={bOfCharges ? 'contained' : 'outlined'} sx={{ mr: 1, ml: 0, mt: 2, mb: 3 }} onClick={function () {
                            hideAllCharges();
                            setBOfCharges(true)
                        }}>
                            O/F charges
                        </Button>
                        <Button variant={bFrieghtCharges ? 'contained' : 'outlined'} sx={{ mr: 1, ml: 0, mt: 2, mb: 3 }} onClick={function () {
                            hideAllCharges();
                            setBFrieghtCharges(true)
                            var t = [];
                          //  (props.data?.importExportType == 1 ? [...fclCostFrieghtChargesExport] : [...fclCostFrieghtCharges])

                            /*for (var i = 0; i < frieghtCharges.length; i++) {
                                if (!frieghtCharges[i].rate20 && !frieghtCharges[i].rate40) {
                                    var tObj = JSON.parse(JSON.stringify(frieghtCharges[i]))
                                    tObj.id = i + 1;
                                    t.push(tObj)
                                }
                            }*/

                            var t = JSON.parse(JSON.stringify(frieghtCharges))
                            if (props?.data?.importExportType == 1) {
                                console.log(charges)
                                console.log(fclCostFrieghtChargesExport)

                                for (var i = 0; i < fclCostFrieghtChargesExport.length; i++) {
                                    var it = frieghtCharges.find(u => u.name === fclCostFrieghtChargesExport[i].name)
                                    if (!it) {
                                        console.log("no")
                                        console.log(fclCostLocalChargesExport1[i])
                                        t.push(fclCostFrieghtChargesExport[i])
                                    }
                                }

                            }
                            console.log("sss")
                            console.log(t)
                            setFrieghtCharges(t)


                            //setFrieghtChargesTemp(t)
                            //setselectFreightId(t[0].id)
                            resetTempFrieght(t)
                        }}>
                            Frieght charges
                        </Button>
                        <Button variant={bLocalCharges ? 'contained' : 'outlined'} sx={{ mr: 1, ml: 0, mt: 2, mb: 3 }} onClick={function () {
                            hideAllCharges();
                            setBLocalCharges(true)
                            var t = JSON.parse(JSON.stringify(charges))
                            console.log("Charges");
                            console.log(charges)
                            console.log(t)
                            console.log("fclCostLocalChargesExport1");
                            console.log(fclCostLocalChargesExport1)
                            if (props?.data?.importExportType == 1) {
                                
                                for (var i = 0; i < fclCostLocalChargesExport1.length; i++) {
                                    var it = charges.find(u => u.name === fclCostLocalChargesExport1[i].name)
                                    if (!it) {
                                        console.log("no")
                                        console.log(fclCostLocalChargesExport1[i])
                                        t.push(fclCostLocalChargesExport1[i])
                                    }
                                }

                            }
                            console.log("sss")
                            console.log(t)
                            setCharges(t)
                            resetTempLocal(t)
                        }}>
                            Local charges
                        </Button>

                    </Grid>
                    {bFrieghtCharges && <TableContainer>
                        <Grid container item>

                            
                        </Grid>
                        <Table>

                            <TableBody>

                                
                                {frieghtChargesTemp?.length > 0 &&
                                    <RowSelectOption setselectFreightId={setselectFreightId} frieghtChargesTemp={frieghtChargesTemp} setFrieghtChargesTemp={setFrieghtChargesTemp} selectFreightId={selectFreightId} setFrieghtCharges={setFrieghtCharges} frieghtCharges={frieghtCharges} listOfcurrency={listOfcurrency} listOfMeasurementUnits={listOfMeasurementUnits} >
                                    </RowSelectOption>
                                }
                                {frieghtCharges.length > 0 &&
                                    frieghtCharges.map((chargesItem, index) => (
                                        <> {(Number(chargesItem.rate20) || Number(chargesItem.rate40) || chargesItem.remark)&&<TableRow  sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.name}
                                                    disabled={true}
                                                    label='Product'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'MeasurementUnit'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'MeasurementUnit'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.unitOfMeasure}
                                                                label={'MeasurementUnit'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setFrieghtChargesitem(event, 'unitOfMeasure', index)
                                                                }}
                                                                //disabled={true}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfMeasurementUnits?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'currency'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'currency'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.currency}
                                                                label={'currency'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setFrieghtChargesitem(event, 'currency', index)
                                                                }}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfcurrency?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.rate20}
                                                    onChange={function (event) {
                                                        setFrieghtChargesitem(event, 'rate20', index)
                                                    }}
                                                    label='Rate20'
                                                    type='number'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.rate40}
                                                    onChange={function (event) {
                                                        setFrieghtChargesitem(event, 'rate40', index)
                                                    }}
                                                    label='Rate40'
                                                    type='number'
                                                />
                                            </TableCell>
                                            {/* <TableCell style={{ padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.min}
                                                onChange={function (event) {
                                                    setFrieghtChargesitem(event, 'min', index)
                                                }}
                                                label='Min'
                                                type='number'
                                            />
                                        </TableCell>*/}
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.remark}
                                                    onChange={function (event) {
                                                        setFrieghtChargesitem(event, 'remark', index)
                                                    }}
                                                    label='Remark'
                                                />
                                            </TableCell>
                                        </TableRow>
                                        }</>
                                    ))

                                }
                            </TableBody>
                        </Table>
                    </TableContainer>}
                    {bOfCharges && <TableContainer>
                        <Table>

                            <TableBody>
                                {ofCharges.length > 0 &&
                                    ofCharges.map((chargesItem, index) => (
                                        <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.name || chargesItem.product}
                                                    disabled={true}
                                                    label='Product'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'MeasurementUnit'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'MeasurementUnit'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.unitOfMeasure}
                                                                label={'MeasurementUnit'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setOfChargesitem(event, 'unitOfMeasure', index)
                                                                }}
                                                                //disabled={true}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfMeasurementUnits?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'currency'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'currency'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.currency}
                                                                label={'currency'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setOfChargesitem(event, 'currency', index)
                                                                }}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfcurrency?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.rate20}
                                                    onChange={function (event) {
                                                        setOfChargesitem(event, 'rate20', index)
                                                    }}
                                                    label='Rate20'
                                                    type='number'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.rate40}
                                                    onChange={function (event) {
                                                        setOfChargesitem(event, 'rate40', index)
                                                    }}
                                                    label='Rate40'
                                                    type='number'
                                                />
                                            </TableCell>
                                            {/*<TableCell style={{ padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.min}
                                                onChange={function (event) {
                                                    setOfChargesitem(event, 'min', index)
                                                }}
                                                label='Min'
                                                type='number'
                                            />
                                        </TableCell>*/}
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.remark}
                                                    onChange={function (event) {
                                                        setOfChargesitem(event, 'remark', index)
                                                    }}
                                                    label='Remark'
                                                />
                                            </TableCell>
                                        </TableRow>))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>}
                    {bLocalCharges && <TableContainer>
                        <Table>

                            <TableBody>

                                {chargesTemp?.length > 0 && <RowSelectOption setselectFreightId={setselectLocalId} frieghtChargesTemp={chargesTemp} setFrieghtChargesTemp={setChargesTemp} selectFreightId={selectLocalId} setFrieghtCharges={setCharges} frieghtCharges={charges} listOfcurrency={listOfcurrency} listOfMeasurementUnits={listOfMeasurementUnits} ></RowSelectOption>}

                                {charges.length > 0 &&
                                    charges.map((chargesItem, index) => (
                                        <> {(Number(chargesItem.rate20) > 0 || Number(chargesItem.rate40) > 0 || chargesItem.remark !== "") && <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.name}
                                                    disabled={true}
                                                    label='Product'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'MeasurementUnit'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'MeasurementUnit'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.unitOfMeasure}
                                                                label={'MeasurementUnit'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setChargesitem(event, 'unitOfMeasure', index)
                                                                }}
                                                                //disabled={true}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfMeasurementUnits?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'currency'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'currency'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.currency}
                                                                label={'currency'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setChargesitem(event, 'currency', index)
                                                                }}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfcurrency?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.rate20}
                                                    onChange={function (event) {
                                                        setChargesitem(event, 'rate20', index)
                                                    }}
                                                    label='Rate20'
                                                    type='number'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.rate40}
                                                    onChange={function (event) {
                                                        setChargesitem(event, 'rate40', index)
                                                    }}
                                                    label='Rate40'
                                                    type='number'
                                                />
                                            </TableCell>
                                            {/*<TableCell style={{ padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.min}
                                                onChange={function (event) {
                                                    setChargesitem(event, 'min', index)
                                                }}
                                                label='Min'
                                                type='number'
                                            />
                                        </TableCell>*/}
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.remark}
                                                    onChange={function (event) {
                                                        setChargesitem(event, 'remark', index)
                                                    }}
                                                    label='Remark'
                                                />
                                            </TableCell>
                                        </TableRow>}</>))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Grid>}
                {<Grid container item justifyContent='flex-end' lg={22}>
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={save}>
                        Save
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={props.onHandleCancel}>
                        Cancel
                    </Button>
                </Grid>}
            </Grid>}
            {(props?.typeData?.id == 4 || props?.typeData?.id == 5) && <Grid container justifyContent='flex-start' lg={12} >
                <TableContainer>
                    <Table>

                        <TableBody>

                            {charges.length > 0 &&
                                charges.map((chargesItem, index) => (
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                                        <TableCell style={{ padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.product ? chargesItem.product : chargesItem.name}
                                                disabled={true}
                                                label='Product'
                                            />
                                        </TableCell>
                                        <TableCell style={{ padding: '8px' }}>
                                            <Typography variant='h7'>
                                                <Grid item xs={6} sm={6} md={4} lg={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id={'MeasurementUnit'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                            {'MeasurementUnit'}
                                                        </InputLabel>
                                                        <Select
                                                            value={chargesItem.unitOfMeasure}
                                                            label={'MeasurementUnit'}
                                                            inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                            onChange={function (event) {
                                                                setChargesitem(event, 'unitOfMeasure', index)
                                                            }}
                                                            size='small'
                                                            // disabled={true}
                                                            MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                            {listOfMeasurementUnits?.map((option, index) => (
                                                                <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                    {
                                                                        (option.lable)
                                                                    }
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: '8px' }}>
                                            <Typography variant='h7'>
                                                <Grid item xs={6} sm={6} md={4} lg={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id={'currency'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                            {'currency'}
                                                        </InputLabel>
                                                        <Select
                                                            value={chargesItem.currency}
                                                            label={'currency'}
                                                            // disabled={true}
                                                            inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                            onChange={function (event) {
                                                                setChargesitem(event, 'currency', index)
                                                            }}
                                                            size='small'
                                                            MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                            {listOfcurrency?.map((option, index) => (
                                                                <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                    {
                                                                        (option.lable)
                                                                    }
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.rate}
                                                onChange={function (event) {
                                                    setChargesitem(event, 'rate', index)
                                                }}
                                                label='Rate'
                                                type='number'
                                            />
                                        </TableCell>
                                        <TableCell style={{ padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.min}
                                                onChange={function (event) {
                                                    setChargesitem(event, 'min', index)
                                                }}
                                                label='Min'
                                                type='number'
                                            />
                                        </TableCell>
                                        <TableCell style={{ padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.remark}
                                                onChange={function (event) {
                                                    setChargesitem(event, 'remark', index)
                                                }}
                                                label='Remark'
                                            />
                                        </TableCell>
                                    </TableRow>))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {<Grid container item justifyContent='flex-end' lg={22}>
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={saveCharges}>
                        Save
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={props?.onHandleCancel}>
                        Cancel
                    </Button>
                </Grid>}
            </Grid>}

            {snackbar && (
                <Snackbar open={!!snackbar} message={snackbar.message} type={snackbar.type} onClose={onSnackbarHandleClose} />
            )}
        </div>
    );
};

export default AddFclCost;
