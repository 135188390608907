import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CrmTable } from '../CrmList/CrmTable';
import { onAdminChange } from '../../reducers/admin-reducers/AdminSlicer';
import { setCurrentPage } from '../../reducers/session/sessionSlice';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { getAdminList, getWarehouseList } from '../Admins/AdminHandler'



export const CrmMenu = () => {
    const dispatch = useDispatch();
    const { admin, currentPage } = useSelector(state => state.session);
    const [showUsersList, setShowUsersList] = useState(true);
    if (showUsersList && currentPage !== 'Crm list') {
        dispatch(setCurrentPage("Crm list"));
    }
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => {
        setOpenOrderModal(true);
        getAdminList(dispatch, admin);
        getWarehouseList(dispatch, admin);
    };
    const onHandleCancelModal = () => setOpenOrderModal(false);


    const [openWarehouseModal, setWarehouseModal] = useState(false);
    const onHandleOpenWarehouseModal = () => {
        setWarehouseModal(true);
        getAdminList(dispatch, admin);
        getWarehouseList(dispatch, admin);
    };
    const onHandleCancelWarehouseModal = () => setWarehouseModal(false);


    const onHandleHideAllItems = () => {
        setShowUsersList(false);
    }
    const onHandleUsersList = () => {
        onHandleHideAllItems();
        setShowUsersList(true);
        dispatch(setCurrentPage("Client list"));
        //getAdminList();
    }
    const getAdminList = () => {
        let loginObj = { "sessionId": admin.data.session, opcode: "getAdminList" }
        sendRequest(URL.ADMIN_HANDLER, REQUEST_ACTIONS.POST, loginObj, {
            successCallback: (response) => {
                for (var i = 0; i < response.length; i++) {
                    dispatch(onAdminChange(response[i]));
                }
            },
            failedCallback: error => {

            }
        });
    }
    return (
        <Grid container item xs={12} justifyContent='space-between' >
            <CrmTable />
        </Grid>
    );
};

export default CrmMenu;
