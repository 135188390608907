import { useState, useCallback, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { SelectField, TextField, MultilineField } from '../../style-guide';
import { sendRequest, REQUEST_ACTIONS } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { setAdmin, onWarehouseChange } from '../../reducers/admin-reducers/AdminSlicer';
import { Snackbar } from '../../style-guide';
import imgHide from './hide.png'
import imgShow from './show.png'
import { URL, LANGUAGES } from '../../constants/global-constants';
import { addAgentCode } from '../Admins/AdminHandler'

const AddAgentCode = props => {
    const dispatch = useDispatch();
    const { roles, warehouseList, adminList } = useSelector(state => state.admin);
    const { admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                code: props.data.code,
                managerId: props.data.managerId,
            }
            : {
                id: null,
                name: null,
            },
    );

    const save = () => {
        addAgentCode(dispatch, snackbarToggle, value, admin);
        props.onHandleCancel();

    };

    const update = () => {
        addAgentCode(dispatch, snackbarToggle, value, admin);
        props.onHandleCancel();
    };
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        if (!isLoaded) {
            //getWarehouseList();
            // getPackages();
        }
    }, [dispatch, failedCallback, warehouseList]);


    return (
        <div>
            <Grid container item spacing={2}>
                <Grid container item spacing={2}>
                    <TextField
                        required
                        lg={11}
                        value={value.code}
                        onChange={event => setValue(prev => ({ ...prev, code: event.target.value }))}
                        label='Code'
                    />
                </Grid>
                <SelectField
                    required
                    value={value.managerId}
                    lg={5.5}
                    onChange={event => setValue(prev => ({ ...prev, managerId: event.target.value }))}
                    label='Manager'
                    options={adminList}
                />
                <Grid container item justifyContent='flex-end' lg={11}>
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={props.data?.id ? update : save}>
                        Save
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={props.onHandleCancel}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            {snackbar && (
                <Snackbar open={!!snackbar} message={snackbar.message} type={snackbar.type} onClose={onSnackbarHandleClose} />
            )}
        </div>
    );
};

export default AddAgentCode;
