import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CostTable } from '../CostList/CostTable';
import { onAdminChange } from '../../reducers/admin-reducers/AdminSlicer';
import { setCurrentPage } from '../../reducers/session/sessionSlice';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { getAdminList, getWarehouseList } from '../Admins/AdminHandler'



export const CostMenu = () => {
    
    return (
        <Grid container item xs={12} justifyContent='space-between' >
            <CostTable />
        </Grid>
    );
};

export default CostMenu;
