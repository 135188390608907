import { memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { TableCell, TableHead, TableRow, Typography, Button } from '@mui/material';
import { listOfCostLandWeightFcl} from '../../../constants/global-constants';
import { TextField, SelectField } from '../../../style-guide';
const FclCostHeader = (props) => {
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    if (props.id == 2 || props.id == 1) {
        return (
             <TableHead style={{ padding: '2px', lineHeight: '0' }}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0', margin: 0, padding: '2px' } }}>
                    <TableCell style={{ width: '2vw',padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>

                        </Typography>
                    </TableCell>
                    {props.id == 2 &&<TableCell style={{ padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.fclCostData.crmId}
                                onChange={event => props.setFclCostData(prev => ({ ...prev, crmId: event.target.value }))}
                                label='Agent'
                            />
                        </Typography>
                    </TableCell>
                    }
                    <TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            
                            <TextField
                                lg={12}
                                value={props.fclCostData.ShippingCrmId}
                                onChange={event => props.setFclCostData(prev => ({ ...prev, ShippingCrmId: event.target.value }))}
                                label='Shipping Line'
                            />
                        </Typography>
                    </TableCell>
                    {<TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            {/*Country*/}
                            <TextField
                                lg={12}
                                value={props.fclCostData.countryId}
                                onChange={event => props.setFclCostData(prev => ({ ...prev, countryId: event.target.value }))}
                                label='Country'
                            />
                        </Typography>
                    </TableCell>
                    }
                    {<TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            
                            <TextField
                                lg={12}
                                value={props.fclCostData.portLid}
                                onChange={event => props.setFclCostData(prev => ({ ...prev, portLid: event.target.value }))}
                                label='POL'
                            />
                        </Typography>
                    </TableCell>
                    }
                    {<TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.fclCostData.portAid}
                                onChange={event => props.setFclCostData(prev => ({ ...prev, portAid: event.target.value }))}
                                label='POA'
                            />
                        </Typography>
                    </TableCell>
                    }
                    {<TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.fclCostData.rate20}
                                onChange={event => props.setFclCostData(prev => ({ ...prev, rate20: event.target.value }))}
                                label='Rate20'
                            />
                        </Typography>
                    </TableCell>
                    }
                    {<TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.fclCostData.rate40}
                                onChange={event => props.setFclCostData(prev => ({ ...prev, rate40: event.target.value }))}
                                label='Rate40'
                            />
                        </Typography>
                    </TableCell>
                    }
                   
                    {<TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            Updated
                        </Typography>
                    </TableCell>
                    }
                    {<TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            Valid Date
                        </Typography>
                    </TableCell>
                    }
                    {<TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            Action
                        </Typography>
                    </TableCell>
                    }
                   
                   


                </TableRow>
            </TableHead>
        );
    } else if (props.id == 3) {
        return (
             <TableHead style={{ padding: '2px', lineHeight: '0' }}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0', margin: 0, padding: '2px' } }}>
                    <TableCell style={{ width: '2vw',padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>

                        </Typography>
                    </TableCell>
                    <TableCell style={{ width: '8.3vw', padding: '8px' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.truckingCompany}
                                onChange={event => props.setTruckingCompany(event.target.value)}
                                label={"Trucking company"}
                            />
                        </Typography>
                    </TableCell>
                    {<TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.fclCostData.countryId}
                                onChange={event => props.setFclCostData(prev => ({ ...prev, countryId: event.target.value }))}
                                label='Country'
                            />
                        </Typography>
                    </TableCell>
                    }
                    <TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.fclCostData.portLid}
                                onChange={event => props.setFclCostData(prev => ({ ...prev, portLid: event.target.value }))}
                                label='Port'
                            />
                        </Typography>
                    </TableCell>
                    <TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.fclCostData.zip}
                                onChange={event => props.setFclCostData(prev => ({ ...prev, zip: event.target.value }))}
                                label='Zip'
                            />
                        </Typography>
                    </TableCell>
                    <TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.fclCostData.rate20}
                                onChange={event => props.setFclCostData(prev => ({ ...prev, rate20: event.target.value }))}
                                label='Rate20'
                            />
                        </Typography>
                    </TableCell>
                    <TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            <TextField
                                lg={12}
                                value={props.fclCostData.rate40}
                                onChange={event => props.setFclCostData(prev => ({ ...prev, rate40: event.target.value }))}
                                label='Rate40'
                            />
                        </Typography>
                    </TableCell>


                </TableRow>
            </TableHead>
        );
    } else if (props.id == 4 || props.id == 5) {
        return (
             <TableHead style={{ padding: '2px', lineHeight: '0' }}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0', margin: 0, padding: '2px' } }}>
                    <TableCell style={{ width: '2vw',padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>

                        </Typography>
                    </TableCell>
                    {<TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7' style={{ padding: '15px' }}>
                            Product
                        </Typography>
                    </TableCell>
                    }
                    <TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            Unit Of Measure
                        </Typography>
                    </TableCell>
                    <TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            Currency
                        </Typography>
                    </TableCell>
                    {<TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            Rate20
                        </Typography>
                    </TableCell>
                    }
                    {<TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            Rate40
                        </Typography>
                    </TableCell>
                    }
                    {<TableCell style={{padding: '15px', fontWeight:'bold' }}>
                        <Typography variant='h7'>
                            Remark
                        </Typography>
                    </TableCell>
                    }


                </TableRow>
            </TableHead>
        );
    } 
};

export default memo(FclCostHeader);
