import { useState, useEffect } from 'react';
import { TableCell, Grid, TableRow, Typography, Box, Button, Checkbox, Radio, FormControlLabel } from '@mui/material';
import { Dialog } from '../../style-guide';
import { useSelector, useDispatch } from 'react-redux';
import { addDesc, recieptWithBarCode } from '../Admins/AdminHandler'
import { onQsChange, onEmptyQsChange } from '../../reducers//QS/qsSlice';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { parcelStatusList, parcelSortBy, SHIPPING_STATUS_LIST } from '../../constants/global-constants';
import { getProductChargesByProdShipCharge, getQuoatationType, getQuotationStatus, getCRMList, getFclExportImportAFs } from '../../reducers/requestHandler';
import { getFclExportImportAF, getAdminList, getQsList } from '../../reducers/requestHandler';
import AddFclCost from '../CostList/Fcl/AddFclCost';
import { setTimeOut } from '../../reducers/crm/crmSlice';
const FclQuote = props => {
    const { addRemoveSelectedMail } = props;
    const dispatch = useDispatch();
    const { adminList } = useSelector(state => state.admin);
    const { crmList, timeout } = useSelector(state => state.crm);
    const { listOfExchangeRates, listOfProductCategories, listOfShipmentTypes, listOfAirLine, listOfLclExportImportAFs, listOfAirExportAFs, countryList, qsStatusList, listOfServiceType, listOfAirPort, listOfPort, listOfIncoterms, listOfFLCTypes40, listOfFLCTypes20 } = useSelector(state => state.parameter);
    const { admin } = useSelector(state => state.session);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.detail.id,
                agentCrmId: props.detail.agentCrmId,
                shippingCrmId: props.detail.shippingCrmId,
                countryId: props.detail.countryId,
                portLid: props.detail.portLid,
                portAid: props.detail.portAid,
                zip: props.detail.zip,
                tt: props.detail.params?.tt,
                direct: props.detail.params?.direct,
                //rate20: props?.data?.params?.OF_CAHRGES?.length?props?.data?.params?.OF_CAHRGES[0]?.rate20:0,
                rate20: 0,
                //rate40: props?.data?.params?.OF_CAHRGES?.length ? props?.data?.params?.OF_CAHRGES[0]?.rate40 : 0,
                rate40: 0,
                selected: props.detail.selected,
            }
            : {
                id: null,
                agentCrmId: "",
                shippingCrmId: "",
                countryId: "",
                portLid: "",
                portAid: "",
                zip: "",
            },
    );
    function formatDate(newDate) {
        const d = newDate
        const year = d.getFullYear()
        const date = d.getDate()
        const monthName = d.getMonth() + 1
        const formatted = `${date}/${monthName}/${year}`
        return formatted.toString()
    }
    const [isLoaded, setIsLoaded] = useState(false);
    if (!isLoaded && admin.data.session && crmList && crmList.length == 0) {
        //getCRMList(dispatch, admin);
        setIsLoaded(true);
    }
    const [selectedAirQuoteData, setSelectedAirQuoteData] = useState({ ...props?.detail/*, id: null */ });
    const [updatedAirQuoteData, setUpdatedAirQuoteData] = useState({ ...props?.detail/*, id: null */ });

    const [openOrderModal, setOpenOrderModal1] = useState(false);
    const setOpenOrderModal = (data) => {
        setOpenOrderModal1(data);
    }
    const onHandleOpenModal = () => {
        getFclExportImportAF(value, admin, function (data) {
            var tObj = JSON.parse(JSON.stringify(data?.data));
            if (tObj.weightList) {
                tObj.weight = tObj.weightList[0];
            } else {
                tObj.weight = 0;
            }
            tObj.rate = tObj.rateList[0];
            tObj.quoteRate = tObj.rateList[0];
            setUpdatedAirQuoteData({ ...tObj/*, id: null */ })
            setSelectedAirQuoteData({ ...tObj/*, id: null */ });

            setValue(prev => ({ ...prev, fca: 0 }));
            setValue(prev => ({ ...prev, rate: 0 }));
            getFcaCharges(tObj)
            //getRate(tObj)
            //setOpenOrderModal(true);
        })

       setOpenOrderModal(true);
    };
    const handleChange = (event) => {
    };
    const onHandleCancelModal = () => {
        var tc = [...props.listOfQuoatationCost];
        var k = []
        for (var i = 0; i < tc.length; i++) {
            if (tc[i].id == updatedAirQuoteData?.id) {
                tc[i] = { ...props.detail, ...updatedAirQuoteData };
            }
        }
        props.setListOfQuoatationCost(tc)
        setOpenOrderModal(false)
    };


    const [openOrderModalClone, setOpenOrderModalClone] = useState(false);
    const onHandleOpenModalClone = () => {
        setOpenOrderModalClone(true);
    };
    const onHandleCancelModalClone = () => setOpenOrderModalClone(false);

    const getRate20 = (crm) => {
        var charges = crm.params;
        var FRIEGHT_CAHRGES = charges?.FRIEGHT_CAHRGES;
        var OF_CAHRGES = charges?.OF_CAHRGES
        var rate = 0;
        if (OF_CAHRGES) {
            rate = OF_CAHRGES[0]?.rate20 ? Number(OF_CAHRGES[0]?.rate20) : 0
        }

        getProductChargesByProdShipCharge(dispatch, admin, props.value.productType, props.value.shipmentType, 1, function (data) {
            if (FRIEGHT_CAHRGES) {
                for (var i = 0; i < FRIEGHT_CAHRGES.length; i++) {
                    var prCh = data?.data?.find(u => u.name === FRIEGHT_CAHRGES[i].name)
                    if (prCh) {
                        rate = rate + (FRIEGHT_CAHRGES[i]?.rate20 ? Number(FRIEGHT_CAHRGES[i]?.rate20) : 0)
                        setValue(prev => ({ ...prev, rate20: Number(rate).toFixed(2) }));
                    }
                }
            }
        });

        return rate
    }
    const getRate40 = (crm) => {
        var charges = crm.params;
        var FRIEGHT_CAHRGES = charges?.FRIEGHT_CAHRGES;
        var OF_CAHRGES = charges?.OF_CAHRGES
        var rate = 0;
        if (OF_CAHRGES) {
            rate = OF_CAHRGES[0]?.rate40 ? Number(OF_CAHRGES[0]?.rate40):0
        }
        
        getProductChargesByProdShipCharge(dispatch, admin, props.value.productType, props.value.shipmentType, 1, function (data) {
            if (FRIEGHT_CAHRGES) {
                for (var i = 0; i < FRIEGHT_CAHRGES.length; i++) {
                    var prCh = data?.data?.find(u => u.name === FRIEGHT_CAHRGES[i].name)
                    if (prCh) {
                        rate = rate + (FRIEGHT_CAHRGES[i]?.rate40 ? Number(FRIEGHT_CAHRGES[i]?.rate40) : 0)
                        setValue(prev => ({ ...prev, rate40: Number(rate).toFixed(2) }));
                    }
                }
            }
        });

        return rate
    }
    const getFcaCharges = (crm) => {
        //console.log(crm)
        var charges = crm.params;
        var FRIEGHT_CAHRGES = charges?.FRIEGHT_CAHRGES;
        var OF_CAHRGES = charges?.OF_CAHRGES
;
        var total20 = 0;
        var total40 = 0;
        var total = 0;
        if (OF_CAHRGES) {
            var exch = listOfExchangeRates.find(u => u.id === OF_CAHRGES[0].currency)
            var calc20 = Number(props.getCalculations(Number(OF_CAHRGES[0]?.rate20), Number(OF_CAHRGES[0]?.min), Number(FRIEGHT_CAHRGES[0]?.unitOfMeasure))) / exch.oneUsd;
            total20 = Number(total20) + Number(Number(calc20).toFixed(2))
            var calc40 = Number(props.getCalculations(Number(OF_CAHRGES[0]?.rate40), Number(OF_CAHRGES[0]?.min), Number(FRIEGHT_CAHRGES[0]?.unitOfMeasure))) / exch.oneUsd;
            total40 = Number(total40) + Number(Number(calc40).toFixed(2))
        }
        getProductChargesByProdShipCharge(dispatch, admin, props.value.productType, props.value.shipmentType, 1, function (data) {
        for (var i = 0; i < FRIEGHT_CAHRGES.length; i++) {
            var prCh = data?.data?.find(u => u.name === FRIEGHT_CAHRGES[i].name)
            var exch = listOfExchangeRates.find(u => u.id === FRIEGHT_CAHRGES[i].currency)
            if (exch && prCh) {
                var calc20 = Number(props.getCalculations(Number(FRIEGHT_CAHRGES[i]?.rate20), Number(FRIEGHT_CAHRGES[i]?.min), Number(FRIEGHT_CAHRGES[i]?.unitOfMeasure))) / exch.oneUsd;
                total20 = Number(total20) + Number(Number(calc20).toFixed(2))

                setValue(prev => ({ ...prev, fca20: Number(total20).toFixed(2) }));
                var calc40 = Number(props.getCalculations(Number(FRIEGHT_CAHRGES[i]?.rate40), Number(FRIEGHT_CAHRGES[i]?.min), Number(FRIEGHT_CAHRGES[i]?.unitOfMeasure))) / exch.oneUsd;
                total40 = Number(total40) + Number(Number(calc40).toFixed(2))
                setValue(prev => ({ ...prev, fca40: Number(total40).toFixed(2) }));
            }
            }
        })
        return total;
    };
    var asdf = "";
    

    useEffect(() => {
        const timer = window.setTimeout(() => {
            getFcaCharges(props?.detail)
            getRate20(props?.detail)
            getRate40(props?.detail)
        }, 1000);
        return () => window.clearTimeout(timer);
    }, []);
    return (
        <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
            <TableCell style={{ margin: 0, padding: '8px' }}>
                <Typography variant='h7' >
                    <Checkbox checked={value.selected} onChange={(event) => {
                        setValue(prev => ({ ...prev, selected: event.target.checked }));
                        addRemoveSelectedMail(props.detail, event.target.checked);
                    }} />
                </Typography>
            </TableCell>
            {props.detail?.importExportType == 2 &&value.agentCrmId != 0 && <TableCell style={{ margin: 0, padding: '8px', maxWidth: "7vw" }}>
                <Typography variant='h7' >
                    {crmList.find(u => u.id === value.agentCrmId)?.companyName || '-'}
                </Typography>
            </TableCell>
            }
            {props.detail?.importExportType == 2 &&value.agentCrmId == 0 && <TableCell style={{ margin: 0, padding: '8px', maxWidth: "7vw" }}>
                <Typography variant='h7' >
                    {'-'}
                </Typography>
            </TableCell>
            }
            
            {value.shippingCrmId != 0 && <TableCell style={{ margin: 0, padding: '8px', maxWidth: "7vw" }}>
                <Typography variant='h7' >
                    {crmList.find(u => u.id === value.shippingCrmId)?.companyName || '-'}
                </Typography>
            </TableCell>
            }
            
            {props.detail?.importExportType == 2 && <TableCell style={{ margin: 0, padding: '8px' }}>
                <p variant='h7' >
                    FCA-20:  {props?.data?.fcaCalCharges20}
                </p>
                <p variant='h7' >
                    FCA-40: {props?.data?.fcaCalCharges40}
                </p>
            </TableCell>
            }

            {props.detail?.importExportType == 1&&false && value.shippingCrmId != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                <Typography variant='h7' >
                 FCA-20:   {value.fca20}
                </Typography>
                <Typography variant='h7' >
                    FCA-40:  {value.fca40}
                </Typography>
            </TableCell>
            }
            {value.portLid == 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                <Typography variant='h7' >
                    - {/* {props?.data?.params?.OF_CAHRGES?.length?props?.data?.params?.OF_CAHRGES[0]?.rate20:0}*/}  {/*{listOfPort.find(u => u.id === value.portLid)?.name || '-'}*/}
                </Typography>
            </TableCell>
            }
            {value.portLid != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                <Typography variant='h7' >
                    {value.rate20} {/* {props?.data?.params?.OF_CAHRGES?.length?props?.data?.params?.OF_CAHRGES[0]?.rate20:0}*/}  {/*{listOfPort.find(u => u.id === value.portLid)?.name || '-'}*/}
                </Typography>
            </TableCell>
            }
            {value.portAid != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                <Typography variant='h7' >
                    { value.rate40 }   {/*{props?.data?.params?.OF_CAHRGES?.length ?props?.data?.params?.OF_CAHRGES[0]?.rate40:0} */}
                </Typography>
            </TableCell>
            }
            {value.portAid == 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                <Typography variant='h7' >
                    -
                </Typography>
            </TableCell>
            }
            {props.detail?.importExportType ==2&&<TableCell>
                <Typography variant='h7'>
                    {value.tt}
                </Typography>
            </TableCell>
            }
            {props.detail?.importExportType == 2 && <TableCell>
                <Typography variant='h7'>
                    {value.direct ? "Yes" : "No"}
                </Typography>
            </TableCell>}
            <TableCell>
                <Typography variant='h7'>
                    {formatDate(new Date(props.detail?.updateTime))}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='h7'>
                    {formatDate(new Date(props.detail?.validfrom ? props.detail?.validfrom :0))}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='h7'>
                    {formatDate(new Date(props.detail?.validDate))}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='h7'>
                    <Grid container item lg={10} justifyContent="space-evenly">

                        <Grid lg={2} item>
                            {openOrderModalClone && (
                                <Dialog maxWidth='md' open={openOrderModalClone} title={props.typeData?.lable} onHandleCancel={onHandleCancelModalClone}>
                                    <AddFclCost isAddFromQsCostTable={true} setAirImportExport={() => {

                                    }}
                                        callBack={() => {
                                            getFclExportImportAFs(dispatch, admin, function () {
                                                window.setTimeout(function () {
                                                    const elem = document.getElementById('getCostQuoat');
                                                    elem?.click();
                                                }, 10);
                                            });


                                        }} typeData={props.typeData} data={{ ...props?.detail, id: null }} importExportType={props.detail?.importExportType} onHandleCancel={() => setOpenOrderModalClone(false)} id={null} airImportExport={null} />
                                </Dialog>
                            )}

                            <Button variant='text' onClick={function () { onHandleOpenModalClone() }} >
                                Add
                            </Button>
                        </Grid>
                        <Grid lg={2} item>
                            {openOrderModal && (
                                <Dialog maxWidth='md' open={openOrderModal} title={props.typeData?.lable} onHandleCancel={onHandleCancelModal}>
                                    <AddFclCost isAddFromQsCostTable={false} setAirImportExport={() => {

                                    }}
                                        callBack={() => {
                                            getFclExportImportAFs(dispatch, admin, function () {
                                                window.setTimeout(function () {
                                                    const elem = document.getElementById('getCostQuoat');
                                                    elem?.click();
                                                }, 10);
                                            });


                                        }} typeData={props.typeData} data={selectedAirQuoteData} importExportType={props.detail?.importExportType} onHandleCancel={() => onHandleCancelModal()} id={null} airImportExport={null} />
                                </Dialog>
                            )}
                            <Button variant='text' onClick={function () { onHandleOpenModal() }} >
                                Edit
                            </Button>
                        </Grid>
                        { < Grid lg={2} item>
                            <Button variant='contained' disabled={!(new Date().getTime() > props.detail.validfrom && new Date().getTime() < props.detail.validDate)} onClick={function () {
                                props?.setbLoadingLocal(true)
                                getFclExportImportAF(value, admin, function (data) {
                                    //props?.setbLoadingLocal(false)
                                    var tObj = JSON.parse(JSON.stringify(data?.data));
                                    if (tObj.weightList) {
                                        tObj.weight = tObj.weightList[0];
                                    } else {
                                        tObj.weight = 0;
                                    }
                                    tObj.rate = tObj.rateList[0];
                                    tObj.quoteRate = tObj.rateList[0];
                                    setUpdatedAirQuoteData(tObj)
                                    setSelectedAirQuoteData({ ...tObj });

                                   
                                   // getRate(tObj)

                                    var tc = [...props.listOfQuoatationCost];
                                    var k = []
                                    for (var i = 0; i < tc.length; i++) {
                                        if (tc[i].id == tObj?.id) {
                                            tc[i] = { ...props.detail, ...tObj };
                                        }
                                    }
                                    props.setListOfQuoatationCost(tc)

                                    props.quoteByRate({ ...props.detail, ...tObj })

                                    setValue(prev => ({ ...prev, fca: 0 }));
                                    setValue(prev => ({ ...prev, rate: 0 }));
                                    getFcaCharges(tObj)
                                })

                                //props.quoteByRate(props.detail)
                            }} >
                                Quote
                            </Button>
                        </Grid>
                        }
                    </Grid>
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default FclQuote;
