import { memo } from 'react';

import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';

const SelectFieldWrapper = ({ label, value, onChange, options, disabled, required, xs, sm, md, lg, msx,propKey }) => {
    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
            <FormControl fullWidth disabled={disabled} required={required} >
                <InputLabel id={label} sx={{ top: (!value ? -7 : 0), maxWidth: 'calc(100% - 25px)', fontSize: '0.8rem' }} style={{ color: (!value && required) ? "red" : "" }}>
                    {label}
                </InputLabel>
                <Select
                    value={value || ''}
                    label={label}
                    onChange={onChange}
                    inputProps={{ sx: { fontSize: '0.7em', padding: '6px',paddingLeft:'7px' } }}
                    InputLabelProps={{ sx: { fontSize: '0.7em' } }}
                    size='small'
                    MenuProps={{ sx: msx }}>
                    {options?.map(option => (
                        <MenuItem key={option.id} disabled={(option.id == -2 || option.id == -3)} style={{ background: (option.id == -2 || option.id == -3) ? 'grey' : '' }} value={propKey ? option[propKey]:option.id} >
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
};

SelectFieldWrapper.defaultProps = {
    label: '',
    value: '',
    required: null,
    options: [],
    xs: 12,
    sm: 6,
    md: 4,
    lg: 2,
    msx: { maxHeight: '400px', fontSize: '0.8rem' }
};

export default memo(SelectFieldWrapper);
