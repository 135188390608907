import { memo } from 'react';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { FormControlLabel, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, TableCell, TableHead, TableRow, Typography, Button } from '@mui/material';
import { Dialog } from '../../style-guide';
import { TextField, SelectField } from '../../style-guide';
const CrmHeader = (props) => {
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const { commonFilterData, countryList, listOfFunctionTypes, listOfcurrency, listOfAirPort, listOfPort, qsTypeList, qsStatusList, listOfIncoterms, listOfServiceType } = useSelector(state => state.parameter);

    const countryList1 = [{
        id: -1,
        name: 'All'
    }].concat(countryList)

    const crmFunctionListOpt = listOfFunctionTypes.map(sender => ({
        id: sender.id,
        bSelected: sender.bSelected,
        name: sender.lable
    }));

    const crmFunctionListOpt1 = [{
        id: -1,
        name: 'Function'
    }].concat(crmFunctionListOpt)
    const [crmFunctionListOpt2, setcrmFunctionListOpt2] = useState(crmFunctionListOpt1);
    return (
        <TableHead>
            <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                <TableCell >
                    <Typography variant='h6'>
                        #
                    </Typography>
                </TableCell>
                <TableCell >
                    <Typography variant='h6'>
                        <TextField
                            lg={12}
                            value={props.crmFilter.CompanyName}
                            onChange={event => props.setCrmFilter(prev => ({ ...prev, CompanyName: event.target.value }))}
                            label='Name'
                        />
                    </Typography>
                </TableCell>
                <TableCell >
                    <Typography variant='h6'>
                        <SelectField
                            required
                            value={props.crmFilter.CountryId}
                            lg={12}
                            onChange={event => props.setCrmFilter(prev => ({ ...prev, CountryId: event.target.value }))}
                            label='Country'
                            options={countryList1}
                        />
                    </Typography>
                </TableCell>
                <TableCell >
                    <Typography variant='h6'>
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                            <FormControl fullWidth>
                                <InputLabel id={'Type'} sx={{ top: 4, maxWidth: 'calc(100% - 45px)', fontSize: '0.88em' }} >
                                    {''}
                                </InputLabel>
                                <Select
                                    value={props.crmFilter.FunctionId1 || 'Function'}
                                    label={''}
                                    inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}

                                    size='small'
                                    MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                    {crmFunctionListOpt2?.map((option, index) => (
                                        <MenuItem key={option.id} disabled={(option.id == -2 || option.id == -3)} style={{ background: (option.id == -2 || option.id == -3) ? 'grey' : '' }} value={option.id} inputProps={{ sx: { fontSize: '0.7em' } }}>
                                            {option.id != -1 &&
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <FormControlLabel key={uuidv4()} control={<Checkbox checked={option.bSelected} />} label={option.name} onClickCapture={(e) => {
                                                        var t = []
                                                        var selected = [...crmFunctionListOpt2]
                                                        for (var i = 0; i < selected.length; i++) {
                                                            var tObj = JSON.parse(JSON.stringify(selected[i]));
                                                            if (tObj.id == option.id) {
                                                                tObj.bSelected = e.target.checked
                                                            }
                                                            t.push(tObj)
                                                        }
                                                        setcrmFunctionListOpt2(t)
                                                        props.setCrmFilter(prev => ({ ...prev, FunctionId: t }))
                                                        e.stopPropagation();
                                                    }} />
                                                </Grid>
                                            }
                                            {
                                                (option.id == -1 ? (option.name) : '')
                                            }
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Typography>
                </TableCell>
                <TableCell >
                    <Typography variant='h6'>
                        <TextField
                            lg={12}
                            value={props.crmFilter.Address}
                            onChange={event => props.setCrmFilter(prev => ({ ...prev, Address: event.target.value }))}
                            label='Address'
                        />
                    </Typography>
                </TableCell>
                <TableCell >
                    <Typography variant='h6'>
                        <TextField
                            lg={12}
                            value={props.crmFilter.FreeText}
                            onChange={event => props.setCrmFilter(prev => ({ ...prev, FreeText: event.target.value }))}
                            label='Free Text'
                        />
                    </Typography>
                </TableCell>
                <TableCell >
                    <Typography variant='h6'>
                        Operation
                    </Typography>
                </TableCell>


            </TableRow>
        </TableHead>
    );
};

export default memo(CrmHeader);
