import { memo } from 'react';
import { useState } from 'react';
import { TableCell, TableHead, TableRow, Typography, Button } from '@mui/material';
import AddFLCType20 from './AddFLCType20';
import { Dialog } from '../../style-guide';
import { FaTrash, FaPen } from "react-icons/fa";

const FLCType20Header = () => {
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);

    const [over, setOnOver] = useState(0);
    return (
        <TableHead>
            <TableRow>
                <TableCell sx={4}>
                    <Typography variant='h7'>
                        
                    </Typography>
                </TableCell>
                <TableCell sx={4}>
                    <Typography variant='h7'>
                        Name
                    </Typography>
                </TableCell>
                <TableCell sx={4}>
                    <Typography variant='h7'>

                    </Typography>
                </TableCell>
                <TableCell sx={4}>
                    <Typography variant='h7'>
                        Operations 
                    </Typography>
                </TableCell>
                <TableCell sx={4}>
                    <Typography variant='h7'>
                        <Button onClick={onHandleOpenModal} variant='contained'>
                            + New FLCType20
                        </Button>
                        {openOrderModal && (
                            <Dialog open={openOrderModal} title={'FLCType20'} onHandleCancel={onHandleCancelModal}>
                                <AddFLCType20 onHandleCancel={onHandleCancelModal} id={null} />
                            </Dialog>
                        )}
                    </Typography>
                </TableCell>

            </TableRow>
        </TableHead>
    );
};

export default memo(FLCType20Header);
