import { memo } from 'react';

import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const WarehouseHeader = () => {
  return (
      <TableHead>
          <TableRow>
              <TableCell />
              <TableCell>
                  <Typography variant='h7'>Name</Typography>
              </TableCell>
              <TableCell>
                  <Typography variant='h7'>1 USD</Typography>
              </TableCell>
              <TableCell>
                  <Typography variant='h7'>Date</Typography>
              </TableCell>
              <TableCell>
                  <Typography variant='h7'>Operations</Typography>
              </TableCell>
          </TableRow>
      </TableHead>
  );
};

export default memo(WarehouseHeader);
