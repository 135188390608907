import { memo } from 'react';
import { useState } from 'react';
import { TableCell, TableHead, TableRow, Typography, Button } from '@mui/material';
import AddProductCharges from './AddProductCharges';
import { Dialog } from '../../style-guide';

const ProductChargesHeader = () => {
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);

    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <Typography variant='h7'>
                        
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h7'>
                        Product Name
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h7'>
                        Product Category
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h7'>
                        Shipment Types
                    </Typography>
                </TableCell>
                <TableCell >
                    <Typography variant='h7'>
                        Charges Types
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h7'>
                        Usage Units
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h7'>
                        Currency
                    </Typography>
                </TableCell>
                <TableCell >
                    <Typography variant='h7'>
                        Rate
                    </Typography>
                </TableCell>
                <TableCell >
                    <Typography variant='h7'>
                        Min
                    </Typography>
                </TableCell>
                <TableCell >
                    <Typography variant='h7'>
                        Operations 
                    </Typography>
                </TableCell>
                {false && <TableCell >
                    <Typography variant='h7'>
                        <Button onClick={onHandleOpenModal} variant='contained'>
                            + New Incoterm
                        </Button>
                        {openOrderModal && (
                            <Dialog open={openOrderModal} title={'Product Charges'} onHandleCancel={onHandleCancelModal}>
                                <AddProductCharges onHandleCancel={onHandleCancelModal} id={null} />
                            </Dialog>
                        )}
                    </Typography>
                </TableCell>
                }
            </TableRow>
        </TableHead>
    );
};

export default memo(ProductChargesHeader);
