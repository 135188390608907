import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Table, TableBody, TableContainer, Box, FormControlLabel, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, TableCell, TableHead, TableRow, Typography, Button, ListSubheader, InputAdornment } from '@mui/material';
import { Autocomplete, TextField, SelectField, DateTimePicker, SearchSelectField } from '../../style-guide';
import { useDispatch, useSelector } from 'react-redux';
import { CostTable } from '../CostList/CostTable';
import { onAdminChange } from '../../reducers/admin-reducers/AdminSlicer';
import { setCurrentPage } from '../../reducers/session/sessionSlice';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { URL, LANGUAGES, qsReportStatusList } from '../../constants/global-constants';

import { v4 as uuidv4 } from 'uuid';
import { LoadingSpinner } from '../LoadingSpinner';
import { getQsByQuoteTypeAndStatus, getQsList, getQSListBeforeDate, getCRMList } from '../../reducers/requestHandler';



export const Report = () => {
    const dispatch = useDispatch();
    const { currentPage, admin } = useSelector(state => state.session);
    const { qsTypeList, listOfRejectReasions } = useSelector(state => state.parameter);
    const { adminList } = useSelector(state => state.admin);

    const [qsListStart, setQsListStart] = new useState([]);
    //const [bLoading, setBLoading] = new useState([]);
    const getQsData = function () {
        var quotes = [];
        var quoteType = [];
        setbLoading(true)
        qsTypeListOpt2.forEach(element => {
            if (element.bSelected) {
                quotes.push(element.id);
            }
        });
        if (status1 == -1) {
            quotes = [1,2,3,4,5,6,7,8]
        }
        var statuses = [3];
        getQsByQuoteTypeAndStatus(dispatch, admin, quotes, statuses, function (data) {
            setQsListStart(data)
            setbLoading(false)
            //setStartDateLoader(false)
        })
    }
    

    const [filteredAdminList, setFilteredAdminList] = useState(adminList);
    const [status, setStatus] = useState(qsReportStatusList[0].id);
    const [status1, setStatus1] = useState(qsReportStatusList[1].id);
    const qsTypeListOpt = qsTypeList.map(sender => ({
        id: sender.id,
        name: sender.lable,
        bSelected:true
    }));
    const [statuses, setStatuses] = useState(qsReportStatusList);
    const qsTypeListOpt1 = [{
        id: -1,
        name: 'Quotation Type'
    },{
        id: 1000,
        name: 'All',
         bSelected: true
    }].concat(qsTypeListOpt)
    const [qsTypeListOpt2, setQsTypeListOpt2] = useState(qsTypeListOpt1);
    const [bLoading, setbLoading] = useState(false);
    const getDeclinedPer = function (user, status, qsListStart) {
        var r = getStatusCountByUser(user.id, 3, qsListStart)/(getStatusCountByUser(user.id, 3, qsListStart) + getStatusCountByUser(user.id, 4, qsListStart))
        if (!r) {
            return 0
        }
        return Number(r).toFixed(2)
    }
    const getCanceledPer = function (user, status, qsListStart) {
        var r = getStatusCountByUser(user.id, 4, qsListStart)/(getStatusCountByUser(user.id, 3, qsListStart) + getStatusCountByUser(user.id, 4, qsListStart))
        if (!r) {
            return 0
        }
        return Number(r).toFixed(2)
    }
    const getStatusCountByUser = function (usetId, status, qsList) {
        var count = 0;
        qsList?.forEach(element => {
            if (element.userId == usetId && element.status == status) {
                count++;
                //temp.push(element)
            }
        });


        return count;
    }

    const getReasionPer = function (user, user1, qsListStart) {
        var r = getStatusCountByUserReasion(user.id, 3, user1.id, qsListStart) / getTotalStatusCountByUserReasion(user.id, 3, qsListStart) * 100;
        if (!r) {
            r = 0;
        }
        return Number(r).toFixed(2)
    }

    const getStatusCountByUserReasion = function (usetId, status,reasion, qsList) {
        var count = 0;
        qsList?.forEach(element => {
            if (element.userId == usetId && element.status == status && element.statusDesc == reasion) {
                count++;
                //temp.push(element)
            }
        });


        return count;
    }
    const getTotalStatusCountByUserReasion = function (usetId, status, qsList) {
        var count = 0;
        qsList?.forEach(element => {
            for (var i = 0; i < listOfRejectReasions?.length; i++) {
                var reasion = listOfRejectReasions[i].id
                if (element.userId == usetId && element.status == status && element.statusDesc == reasion) {
                    count++;
                    //temp.push(element)
                }
            }
           
        });


        return count;
    }
    const getTotal = (userId, list) => {

        var n = getStatusCountByUser(userId, 1, list) + getStatusCountByUser(userId, 2, list) + getStatusCountByUser(userId, 3, list);// + getStatusCountByUser(userId, 4)
        return n;
    }
    const isFiltered = (user, qsList) => {
        for (var i = 0; i < qsList.length; i++) {
            var qs = qsList[i];
            if (qsList[i].userId === user.id) {
                return true;
            }
        }
        return false;
    }
    useEffect(() => {
        //setStartDateLoader(true)
        getQsData()
    }, [qsTypeListOpt2]);
    return (
        <Grid container item xs={12} justifyContent='center' sx={{mt:2}} >
            <Grid container item xs={8} justifyContent='center' >
                <Grid item  lg={2}>
                    <FormControl fullWidth>
                        <InputLabel id={'Type'} sx={{ top: 4, maxWidth: 'calc(100% - 45px)', fontSize: '0.88em' }} >
                            {'Status'}
                        </InputLabel>
                        <Select
                            value={status || "Status"}
                            label={'Status'}
                            inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}

                            size='small'
                            MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                            {qsTypeListOpt2?.map((option, index) => (
                                <MenuItem key={option.id} disabled={(option.id == -2 || option.id == -3)} style={{ background: (option.id == -2 || option.id == -3) ? 'grey' : '' }} value={option.id} inputProps={{ sx: { fontSize: '0.7em' } }}>
                                    {option.id != -1 &&
                                        <Grid item xs={12} sm={6} md={4} lg={12}>
                                            <FormControlLabel key={uuidv4()} control={<Checkbox checked={option.bSelected} />} label={option.name} onClickCapture={(e) => {
                                                var t = []
                                                var selected = [...qsTypeListOpt2]
                                                for (var i = 0; i < selected.length; i++) {
                                                    var tObj = JSON.parse(JSON.stringify(selected[i]));
                                                    if (tObj.id == option.id) {
                                                        tObj.bSelected = e.target.checked
                                                    }
                                                    if (option.id == 1000) {
                                                        tObj.bSelected = e.target.checked
                                                    }
                                                    t.push(tObj)
                                                }
                                                setQsTypeListOpt2(t)
                                                setStatus1(option.id)
                                                e.stopPropagation();
                                            }} />
                                        </Grid>
                                    }
                                    {
                                        (option.id == -1 ? (option.name) : '')
                                    }
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <TableContainer sx={{ mt: 3, mr: '2vw', width: '50vw', color: "#909399 !important" }} className="font12">
                {(bLoading) && <div style={{ height: "100%" }}><LoadingSpinner /></div>}
                
                {!bLoading && <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} size={'normal'} padding={'normal'} className="font12">
                    <TableHead className="font12">
                        <TableRow className="font12">
                            <TableCell className="font12 ">
                                <Typography variant='h7'>Name</Typography>
                            </TableCell>
                            <TableCell className="font12">
                                <Typography variant='h7'>Reasons</Typography>
                            </TableCell>
                            <TableCell className="font12">
                                <Typography variant='h7'>Number</Typography>
                            </TableCell>
                            <TableCell className="font12">
                                <Typography variant='h7'>Percentage</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        
                        {


                            
                            filteredAdminList.map(user => (

                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={user?.id || uuidv4()} id={"admin_" + user.id} onMouseOver={function () {  }} onClick={function () { return;  }} >
                                    
                                    {isFiltered(user, qsListStart) && < TableCell className="td_gray">
                                        <Typography variant='h7' component='span'>
                                            {user.adminName || '-'}
                                        </Typography>
                                    </TableCell>
                                    }
                                    
                                    {isFiltered(user, qsListStart) && <TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            <Table style={{ "borderWidth": "0px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} size={'normal'} padding={'normal'} className="font12">
                                                
                                                <TableBody>
                                                    {listOfRejectReasions?.map(user1 => (<TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={user?.id || uuidv4()} id={"admin_" + user.id} onMouseOver={function () { }} onClick={function () { return; }} >

                                                        < TableCell className="td_gray" style={{ border: "none" }}>
                                                            <Typography variant='h7' component='span'>
                                                                {user1.reasion || '-'} 
                                                            </Typography>
                                                        </TableCell>
                                                        
                                                    </TableRow>))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </Typography>
                                    </TableCell>
                                    }
                                    {isFiltered(user, qsListStart) && <TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            <Table style={{ "borderWidth": "0px", 'borderColor': "#aaaaaa", 'borderStyle': 'none' }} size={'normal'} padding={'normal'} className="font12">

                                                <TableBody>
                                                    {listOfRejectReasions?.map(user1 => (<TableRow  key={user?.id || uuidv4()} id={"admin_" + user.id} onMouseOver={function () { }} onClick={function () { return; }} >

                                                        < TableCell className="td_gray" style={{border:"none"}}>
                                                            <Typography variant='h7' component='span'>
                                                                {getStatusCountByUserReasion(user.id, 3, user1.id, qsListStart)}
                                                            </Typography>
                                                        </TableCell>

                                                    </TableRow>))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </Typography>
                                    </TableCell>
                                    }
                                    {isFiltered(user, qsListStart) && <TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            <Table style={{ "borderWidth": "0px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} size={'normal'} padding={'normal'} className="font12">

                                                <TableBody>
                                                    {listOfRejectReasions?.map(user1 => (<TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={user?.id || uuidv4()} id={"admin_" + user.id} onMouseOver={function () { }} onClick={function () { return; }} >

                                                        < TableCell className="td_gray" style={{ border: "none" }}>
                                                            <Typography variant='h7' component='span'>
                                                                {getReasionPer(user, user1, qsListStart)}
                                                            </Typography>
                                                        </TableCell>

                                                    </TableRow>))
                                                    }
                                                </TableBody>
                                            </Table>
                                            
                                        </Typography>
                                    </TableCell>
                                    }
                                    {isFiltered(user, qsListStart)&&false && <TableCell className="font12 td_gray">
                                        <Typography variant='h7' component='span'>
                                            {getTotalStatusCountByUserReasion(user.id, 3, qsListStart)}
                                        </Typography>
                                    </TableCell>
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>}
            </TableContainer>
        </Grid>
    );
};

export default Report;
