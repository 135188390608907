import { createSlice } from '@reduxjs/toolkit';

export const qsSlice = createSlice({
    name: 'crm',
    initialState: {
        crmList: [],
        selectedCrm: {},
        timeout: null,
    },
    reducers: {  
        setSelectedCrm: (state, { payload }) => {
            state.selectedCrm = payload
        },
        setCrmListItems: (state, { payload }) => {
            //state.crmList = [];
            state.crmList = payload;
        },
        onEmptyCrm: (state, { payload }) => {
            state.crmList = [];
        },
        setTimeOut: (state, { payload }) => {
            state.timeout = payload;
        },
        onCrmChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.crmList.length; i++) {
                if (payload.id === state.crmList[i].id) {
                    bUpdated = true;
                    state.crmList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.crmList.push(payload);
            }
        },

    },
});
// Action creators are generated for each case reducer function
export const { setSelectedCrm, onCrmChange, onEmptyCrm, setCrmListItems, setTimeOut } = qsSlice.actions;

export default qsSlice.reducer;
