import { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table, TableBody, TableContainer, TableCell, TableRow, Typography, Select, Grid, FormControl, InputLabel, Box, MenuItem, Button, Checkbox, FormControlLabel } from '@mui/material';
import { SelectField, Snackbar, TextField, MultilineField } from '../../../style-guide';
import { sendRequest, REQUEST_ACTIONS } from '../../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../../reducers/snackbarSlicer';
import { setAdmin, onWarehouseChange, onPageChange, onSizeChange } from '../../../reducers/admin-reducers/AdminSlicer';
import imgHide from '../hide.png'
import imgShow from '../show.png'
import { addAirExportAF, updateLocalCharges, getCRMList } from '../../../reducers/requestHandler';
import { airExportFrieghtCharges, airImportFrieghtCharges, listOfCostWeight, listOfCostLandWeightAir } from '../../../constants/global-constants';
import { addDesc, getUserInfoByLockerId, getDiscriptionsList, createShippingRequest, getParcelsList } from '../../Admins/AdminHandler'

const AddAirCost = props => {
    const dispatch = useDispatch();
    const { crmList } = useSelector(state => state.crm);
    const { countryList, listOfAirPort, listOfMeasurementUnits, listOfAirLine, listOfcurrency, listOfPort, qsTypeList, qsStatusList, listOfIncoterms, listOfServiceType } = useSelector(state => state.parameter);
    const { roles, paginationData, warehouseList, senderList, discriptionsList } = useSelector(state => state.admin);
    const { admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const [userData, setUserData] = useState(null);
    const [bNextPage, setNextPage] = useState(false);
    const [bFrieghtImport, setBFrieghtImport] = useState(true);
    const [bFrieghtExport, setBFrieghtExport] = useState(false);
    const hideAllFright = () => {
        setBFrieghtImport(false)
        setBFrieghtExport(false)
    }
    const [listOfWeight, setListOfWeight] = useState(JSON.parse(JSON.stringify(listOfCostLandWeightAir)));
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                importExportType: props.data.importExportType,
                fromId: props.data.fromId,
                toId: props.data.toId,
                airLineId: props.data.airLineId,
                rateList: props.data.rateList,
                min: props?.isAddFromQsCostTable ? null : props.data.min,
                crmId: props?.isAddFromQsCostTable ? null : props.data.crmId,
                airportId: props.data.airportId,
                zip: props.data.zip,
                fcaCharges: props.data.fcaCharges,
                weightList: props.data.weightList,
                weight: props.data.weightList ? props.data.weightList[0] : (props.data.weight ? props.data.weight : 0),
                rate: props?.isAddFromQsCostTable?null:props.data.rateList[0],
                countryId: props.data.countryId,
                createTime: props.data.createTime,
                updateTime: props.data.updateTime,
                params: props.data.params,
                airImportFrieghtCharges: props.data.params?.AIR_IMP_FRIEGHT ? props.data.params?.AIR_IMP_FRIEGHT : JSON.parse(JSON.stringify(airImportFrieghtCharges)) ,
                airExportFrieghtCharges: props.data.params?.AIR_EXP_FRIEGHT ? props.data.params?.AIR_EXP_FRIEGHT : JSON.parse(JSON.stringify(airExportFrieghtCharges)),
            }
            : {
                id: null,
                importExportType: null,
                fromId: null,
                toId: null,
                airLineId: null,
                rateList: null,
                min: null,
                crmId: null,
                airportId: null,
                zip: null,
                fcaCharges: null,
                weightList: null,
                countryId: null,
                createTime: null,
                updateTime: null,
            },
    );
    const [charges, setCharges] = useState(
        props.airImportExport
            ? [...props.airImportExport]
            : [],
    );

    const isCreateShipmentDiasabled = () => {
        return (!value.token || !value.descId ||
            !value.serialNum || !value.weight || !value.length || !value.width ||
            !value.height || !value.packages);
    }
    const [checked, setChecked] = useState(false);
    
    if (props.typeData.id == 3 && !checked && value.id) {
        setChecked(true)
        var t = [...listOfWeight]
        for (var i = 0; i < value.weightList.length; i++) {
            t[i].rate = value.rateList[i];
        }
        setListOfWeight(t)
    }
    const saveCharges = () => {
        var t = [];

        for (var i = 0; i < charges.length; i++) {
            var tObj = JSON.parse(JSON.stringify(charges[i]));
            updateLocalCharges(dispatch, admin, charges[i], props.callBack);
            t.push(tObj)
        }
        props.setAirImportExport([]);
        props.onHandleCancel();
        window.setTimeout(function () { props.setAirImportExport(t);},500)
    }
    const saveDefault = () => {
        for (var k = 0;k < listOfWeight.length;k++) {
            if (props.typeData.id == 2 || props.typeData.id == 1) {
                var data = {}
                var weight = [];
                weight.push(listOfWeight[k])
                var rate = [];
                rate.push(0)
                var data = {
                    id: value.id,
                    importExportType: props.typeData.id,
                    fromId: value.fromId,
                    toId: value.toId,
                    airLineId: value.airLineId,
                    weight: weight,
                    fcaCharges: value.fcaCharges,
                    rate: rate,
                    min: 0
                }
                if (props.typeData.id == 2) {
                    data.agentId = value.crmId;
                }
            } else {
                var weight = [];
                var rate = [];
                for (var i = 0; i < listOfWeight.length; i++) {
                    weight.push(listOfWeight[i].weight);
                    rate.push(listOfWeight[i].rate);
                }
                data = {
                    id: value.id,
                    importExportType: props.typeData.id,
                    countryId: value.countryId,
                    airportId: value.airportId,
                    rate: rate,
                    weight: weight,
                    zip: value.zip,
                    min: value.min
                }
            }
            addAirExportAF(dispatch, snackbarToggle, data, admin, props.callBack)
            props.onHandleCancel();
        }

    };
    const isValid = () => {
        var bAdd = true;
        if (props.typeData.id == 2 || props.typeData.id == 1) {
            if (!value.fromId && bAdd) {
               // dispatch(snackbarToggle({ type: 'error', message: 'Please add all mandatory field and try again.' }));
                // return;
                bAdd = false;
            }
            if (!value.toId && bAdd) {
              //  dispatch(snackbarToggle({ type: 'error', message: 'Please add all mandatory field and try again.' }));
                bAdd = false;
            }
            if (!value.airLineId && bAdd) {
               // dispatch(snackbarToggle({ type: 'error', message: 'Please add all mandatory field and try again.' }));
                bAdd = false;
            }
            if (!value.weight && bAdd) {
               // dispatch(snackbarToggle({ type: 'error', message: 'Please add all mandatory field and try again.' }));
                bAdd = false;
            }
        }
        return bAdd;
    }
    const [isEnabled, setIsEnabled] = useState(isValid());
    const save = () => {
        var bAdd = true;
        setIsEnabled(bAdd)
        if (props.typeData.id == 2 || props.typeData.id == 1) {
            if (!value.fromId && bAdd) {
                dispatch(snackbarToggle({ type: 'error', message: 'Please add all mandatory field and try again.' }));
               // return;
                bAdd = false;
            }
            if (!value.toId && bAdd) {
                dispatch(snackbarToggle({ type: 'error', message: 'Please add all mandatory field and try again.' }));
                bAdd = false;
            }
            if (!value.airLineId && bAdd) {
                dispatch(snackbarToggle({ type: 'error', message: 'Please add all mandatory field and try again.' }));
                bAdd = false;
            }
            if (!value.weight && bAdd) {
                dispatch(snackbarToggle({ type: 'error', message: 'Please add all mandatory field and try again.' }));
                bAdd = false;
            }

            var data = {}
            var weight = [];
            weight.push(value.weight)
            var rate = [];
            rate.push(value.rate)
            var data = {
                id: value.id,
                importExportType: props.typeData.id,
                fromId: value.fromId,
                toId: value.toId,
                airLineId: value.airLineId,
                weight: weight,
                fcaCharges: value.fcaCharges,
                rate: rate,
                min: value.min,
                params: {
                    ...value.params,
                    AIR_IMP_FRIEGHT: value.airImportFrieghtCharges,
                    AIR_EXP_FRIEGHT: value.airExportFrieghtCharges,
                }
            }
            if (props.typeData.id == 2) {
                data.agentId = value.crmId;
            }
        } else {
            var weight = [];
            var rate = [];
            for (var i = 0; i < listOfWeight.length; i++) {
                weight.push(listOfWeight[i].weight);
                rate.push(listOfWeight[i].rate);
            }
            data = {
                id: value.id,
                importExportType: props.typeData.id,
                countryId: value.countryId,
                airportId: value.airportId,
                agentId: value.crmId,
                rate: rate,
                weight: weight,
                zip: value.zip,
                min: value.min,
                params: {
                    ...value.params,
                    TRUCKER_NAME: crmList.find(u => u.id === value.crmId)?.companyName,
                    AIR_IMP_FRIEGHT: value.airImportFrieghtCharges,
                    AIR_EXP_FRIEGHT: value.airExportFrieghtCharges,
                }
            }
        }
        setIsEnabled(bAdd)
        if (bAdd) {
            addAirExportAF(dispatch, snackbarToggle, data, admin, props.callBack)
            props.onHandleCancel();
        }
       
    };
    const setValueParamsRateCurrency = (index) => {
        var val = JSON.parse(JSON.stringify(value))
        if (!val.params)
            val.params = {};
        val.params.rateCurrency = index;
        setValue(val);
    }
    const update = () => {
        props.onHandleCancel();
    };
    const [isLoaded, setIsLoaded] = useState(false);
    if (!isLoaded && admin.data.session && crmList && crmList.length == 0) {
        //getCRMList(dispatch, admin);
        setIsLoaded(true);
    }
    useEffect(() => {
        if (!isLoaded) {
            getDiscriptionsList(dispatch, admin);
            if (!props?.data?.id) {
                resetCharges();
            }
            // getPackages();
        }
        /*if (props?.isAddFromQsCostTable) {
            setValue(prev => ({ ...prev, crmId: null }))
            setValue(prev => ({ ...prev, rate: null }))
        }*/
    }, [failedCallback, warehouseList]);
    //}, [dispatch, failedCallback, warehouseList]);

    const getUserInfoByToken = (e) => {
        if (value.token && value.token.length < 4) {
            dispatch(snackbarToggle({ type: 'Error', message: 'Locker ID is too short, please check again.' }));
            return;
        }
        getUserInfoByLockerId(dispatch, value.token, admin, setUserData);
        //console.log(userData)
    }
    const keyPressed = (e) => {
        if (e.keyCode === 13) {
            getUserInfoByToken();

        }
    }

    const senderNameOptions = senderList.map(sender => ({
        id: sender.id,
        name: sender.senderName + ", " + sender.senderAddr + ", " + sender.senderCountry
    }));
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [uploadStarted, setUploadStarted] = useState(false);

    const changeHandler = (event) => {

    };
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const getCRMsByFunctionTypes = function (functionId) {
        var temp = []
        for (var i = 0; i < crmList.length; i++) {
            if (crmList[i].functionId == functionId) {
                temp.push(crmList[i])
            }
        }
        return temp;
    }
    const agentNameOption = getCRMsByFunctionTypes(2).map(sender => ({
        id: sender.id,
        name: sender.companyName
    }));
    const truckerNameOption = getCRMsByFunctionTypes(6).map(sender => ({
        id: sender.id,
        name: sender.companyName
    }));
    const msx = { maxHeight: '400px' }
    const setChargesitem = function (event,key,index) {
        var t = []
        var selected = [...charges]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            if (index == i)
                tObj[key] = event.target.value

            t.push(tObj)
        }
        setCharges(t)
    }
    const resetCharges = function (event, key, index) {
        setValue(prev => ({ ...prev, airExportFrieghtCharges: JSON.parse(JSON.stringify(airExportFrieghtCharges)) }))
        setValue(prev => ({ ...prev, airImportFrieghtCharges: JSON.parse(JSON.stringify(airImportFrieghtCharges)) }))
    }
    const setFrieghtChargesitemExport = function (event, key, index) {
        var t = []
        var selected = [...value.airExportFrieghtCharges]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            if (index == i)
                tObj[key] = event.target.value

            t.push(tObj)
        }
        //setFrieghtCharges(t)
        setValue(prev => ({ ...prev, airExportFrieghtCharges: t }))
    }
    const setFrieghtChargesitemImport = function (event, key, index) {
        var t = []
        var selected = [...value.airImportFrieghtCharges]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            if (index == i)
                tObj[key] = event.target.value

            t.push(tObj)
        }
        //setFrieghtCharges(t)
        setValue(prev => ({ ...prev, airImportFrieghtCharges: t }))
    }
    return (
        <div>
            {(props.typeData?.id == 1 || props.typeData?.id == 2 || props.typeData?.id == 3) && <Grid container item spacing={2}>
                {(props.typeData.id == 2 || props.typeData.id == 1) && <Grid container item spacing={2}>

                    {props.importExportType == 2 && <SelectField
                        required
                        value={value.crmId}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, crmId: event.target.value }))}
                        label='Agent'
                        options={agentNameOption}
                    />}
                    <SelectField
                        required
                        value={value.fromId}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, fromId: event.target.value }))}
                        label='From'
                        options={listOfAirPort}
                    />
                    <SelectField
                        required
                        value={value.toId}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, toId: event.target.value }))}
                        label='To'
                        options={listOfAirPort}
                    />

                    <SelectField
                        required
                        value={value.airLineId}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, airLineId: event.target.value }))}
                        label='Al'
                        options={listOfAirLine}
                    />
                    {/*<TextField
                        lg={5.5}
                        value={value.fcaCharges}
                        onChange={event => setValue(prev => ({ ...prev, fcaCharges: event.target.value }))}
                        label='Fca Charges'
                    />*/}



                    <Grid item lg={5.5} sm={6} md={4}>
                        <FormControl fullWidth required={true}>
                            <InputLabel sx={{ top: "-0.5vw", maxWidth: 'calc(100% - 40px)' }} style={{ color: (!value.weight) ? "red" : "" }}>
                                {'Weight'}
                            </InputLabel>
                            <Select
                                value={value.weight || ''}
                                label={'Weight'}
                                required
                                onChange={event => setValue(prev => ({ ...prev, weight: event.target.value }))}
                                style={{ height: "2.3vw" }}
                                MenuProps={{ sx: { maxHeight: '400px' } }}
                            >
                                {[...listOfCostWeight, 4999, 9999]?.map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button style={{ maxWidth: '20px' }} variant='contained' sx={{ mr: 2, ml: 2, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, rate: Number(value.rate) - 1 })) }}>
                            -
                        </Button>
                        <Grid style={{ marginTop: '15px' }}>
                            <TextField
                                lg={12}
                                value={value.rate}
                                onChange={event => setValue(prev => ({ ...prev, rate: event.target.value }))}
                                label='Rate'
                                type='number'
                            />
                        </Grid>
                        <Button style={{ maxWidth: '20px !important' }} variant='contained' sx={{ mr: 2, ml: 2, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, rate: Number(value.rate) + 1 })) }}>
                            + 
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button style={{ maxWidth: '20px' }} variant='contained' sx={{ mr: 2, ml: 2, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, min: Number(value.min) - 1 })) }}>
                            -
                        </Button>
                        <Grid style={{ marginTop: '15px' }}>
                            <TextField
                                lg={12}
                                value={value.min}
                                onChange={event => setValue(prev => ({ ...prev, min: event.target.value }))}
                                label='Min'
                                type='number'
                            />
                        </Grid>
                        <Button style={{ maxWidth: '20px !important' }} variant='contained' sx={{ mr: 2, ml: 2, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, min: Number(value.min) + 1 })) }}>
                            +
                        </Button>
                    </Box>
                    <Grid item xs={6} sm={6} md={4} lg={12}>
                        <InputLabel id={'currency'} sx={{ top: 4, maxWidth: '300px', fontSize: '0.88em' }} >
                            {'currency'}
                        </InputLabel>
                        <Select
                            value={value.params?.rateCurrency ? value.params.rateCurrency:0}
                            label={'currency'}
                            inputProps={{ sx: { fontSize: '0.7em', padding: '6px', minWidth:"200px" } }}
                            onChange={function (event) {
                                setValueParamsRateCurrency(event.target.value)
                            }}
                            size='small'
                            MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                            {listOfcurrency?.map((option, index) => (
                                <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                    {
                                        (option.lable)
                                    }
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid container item justifyContent="space-between">
                        {<Button sx={{ mt: 0, mb: 2, ml:0 }} variant='outlined' style={{ background: 'white', color: "black", fontWeight: "bold", textTransform: "none" }}>
                            Fright Charges 
                        </Button>}
                        <Button sx={{ mt: 0, mb: 2, ml: 2 }} onClick={() => {
                            resetCharges();
                        }} variant='contained' >
                            Reset
                        </Button>
                        {/*{<Button sx={{ mt: 0, mb: 2, ml: 2 }} onClick={() => {
                            resetCharges();
                            setBFrieghtImport(true)
                        }} variant='contained' style={{ background: bFrieghtImport ? 'green' : '#1565c0' }}>
                            Import
                        </Button>}
                        {<Button sx={{ mt: 0, mb: 2, ml: 2 }} onClick={() => {
                            hideAllFright();
                            setBFrieghtExport(true)
                        }} variant='contained' style={{ background: bFrieghtExport ? 'green' : '#1565c0' }}>
                            Export
                        </Button>}*/}

                    

                        {props.importExportType==1 && <TableContainer style={{marginLeft:"-5px"}}>
                        <Table>

                            <TableBody>
                                {value.airExportFrieghtCharges?.length > 0 &&
                                    value.airExportFrieghtCharges?.map((chargesItem, index) => (
                                        <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.name}
                                                    disabled={true}
                                                    label='Product'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'MeasurementUnit'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'MeasurementUnit'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.unitOfMeasure}
                                                                label={'MeasurementUnit'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setFrieghtChargesitemExport(event, 'unitOfMeasure', index)
                                                                }}
                                                                //disabled={true}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfMeasurementUnits?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'currency'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'currency'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.currency}
                                                                label={'currency'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setFrieghtChargesitemExport(event, 'currency', index)
                                                                }}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfcurrency?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.rate}
                                                    onChange={function (event) {
                                                        setFrieghtChargesitemExport(event, 'rate', index)
                                                    }}
                                                    label='Rate'
                                                    type='number'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.min}
                                                    onChange={function (event) {
                                                        setFrieghtChargesitemExport(event, 'min', index)
                                                    }}
                                                    label='Min'
                                                    type='number'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.remark}
                                                    onChange={function (event) {
                                                        setFrieghtChargesitemExport(event, 'remark', index)
                                                    }}
                                                    label='Remark'
                                                />
                                            </TableCell>
                                        </TableRow>))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    }

                        {props.importExportType==2 && <TableContainer style={{ marginLeft: "-5px" }}>
                        <Table>

                            <TableBody>
                                {value.airImportFrieghtCharges?.length > 0 &&
                                    value.airImportFrieghtCharges?.map((chargesItem, index) => (
                                        <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.name}
                                                    disabled={true}
                                                    label='Product'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'MeasurementUnit'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'MeasurementUnit'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.unitOfMeasure}
                                                                label={'MeasurementUnit'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setFrieghtChargesitemImport(event, 'unitOfMeasure', index)
                                                                }}
                                                                //disabled={true}
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfMeasurementUnits?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <Typography variant='h7'>
                                                    <Grid item xs={6} sm={6} md={4} lg={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={'currency'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                                {'currency'}
                                                            </InputLabel>
                                                            <Select
                                                                value={chargesItem.currency}
                                                                label={'currency'}
                                                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                                onChange={function (event) {
                                                                    setFrieghtChargesitemImport(event, 'currency', index)
                                                                }}
                                                                
                                                                size='small'
                                                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                                {listOfcurrency?.map((option, index) => (
                                                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                                                        {
                                                                            (option.lable)
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.rate}
                                                    onChange={function (event) {
                                                        setFrieghtChargesitemImport(event, 'rate', index)
                                                    }}
                                                    label='Rate'
                                                    type='number'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.min}
                                                    onChange={function (event) {
                                                        setFrieghtChargesitemImport(event, 'min', index)
                                                    }}
                                                    label='Min'
                                                    type='number'
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '8px' }}>
                                                <TextField
                                                    lg={12}
                                                    value={chargesItem.remark}
                                                    onChange={function (event) {
                                                        setFrieghtChargesitemImport(event, 'remark', index)
                                                    }}
                                                    label='Remark'
                                                />
                                            </TableCell>
                                        </TableRow>))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Grid>
                </Grid>
                }
                {(props.typeData.id == 3) && <Grid container item spacing={2} justifyContent='flex-end'>
                    <Grid container item spacing={2} justifyContent='flex-end' style={{ marginRight: '15px' }}>

                        <SelectField
                            value={value.crmId}
                            lg={6}
                            onChange={event => setValue(prev => ({ ...prev, crmId: event.target.value }))}
                            label='Trucker'
                            options={truckerNameOption}
                        />
                        <SelectField
                            value={value.countryId}
                            lg={6}
                            onChange={event => setValue(prev => ({ ...prev, countryId: event.target.value }))}
                            label='Country'
                            options={countryList}
                        />

                        <SelectField
                            value={value.airportId}
                            lg={6}
                            onChange={event => setValue(prev => ({ ...prev, airportId: event.target.value }))}
                            label='Airport'
                            options={listOfAirPort}
                        />
                        <TextField
                            lg={6}
                            value={value.zip}
                            onChange={event => setValue(prev => ({ ...prev, zip: event.target.value }))}
                            label='zip'
                        />
                       
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }} lg={12}>
                        <Button style={{ maxWidth: '20px' }} variant='contained' sx={{ mr: 2, ml: 20, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, min: Number(value.min) - 1 })) }}>
                            -
                        </Button>
                        <Grid style={{ marginTop: '15px' }}>
                            <TextField
                                lg={12}
                                value={value.min}
                                onChange={event => setValue(prev => ({ ...prev, min: event.target.value }))}
                                label='Min'
                                type='number'
                            />
                        </Grid>
                        <Button style={{ maxWidth: '20px !important' }} variant='contained' sx={{ mr: 2, ml: 2, mt: 2 }} color='secondary' onClick={function () { setValue(prev => ({ ...prev, min: Number(value.min) + 1 })) }}>
                            +
                        </Button>
                    </Box>

                    <Grid container item justifyContent='flex-end' lg={22}>

                        {
                            listOfWeight.map((rateWeight, index) => (


                                <Box  sx={{ display: 'flex', justifyContent: 'flex-start' }}>

                                    <Button style={{ maxWidth: '20px' }} variant='contained' sx={{ mr: 10, ml: 0, mt: 2 }} color='secondary' onClick={function () { }}>
                                        {rateWeight.weight}
                                    </Button>
                                    <Button style={{ maxWidth: '20px' }} variant='contained' sx={{ mr: 2, ml: 0, mt: 2 }} color='secondary' onClick={function () {
                                        var t = [...listOfWeight]
                                        t[index].rate = Number(t[index].rate) - 1
                                        setListOfWeight(t)

                                    }}>
                                        -
                                    </Button>
                                    <Grid style={{ marginTop: '15px' }}>
                                        <TextField
                                            lg={12}
                                            value={rateWeight.rate}
                                            onChange={function (event) {
                                                var t = [...listOfWeight]
                                                t[index].rate = event.target.value
                                                setListOfWeight(t)
                                            }}
                                            label='Rate'
                                            type='number'

                                        />
                                    </Grid>
                                    <Button style={{ maxWidth: '20px !important' }} variant='contained' sx={{ mr: 2, ml: 2, mt: 2 }} color='secondary' onClick={function () {
                                        var t = [...listOfWeight]
                                        t[index].rate = Number(t[index].rate) + 1
                                        setListOfWeight(t)

                                    }}>
                                        +
                                    </Button>
                                </Box>
                            ))
                        }
                    </Grid>

                </Grid>
                }
                {<Grid container item justifyContent='flex-end' lg={22}>
                    {/*<Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={saveDefault}>
                        Save Default
                    </Button>*/}
                    <Button disabled={!isValid()} variant='contained' sx={{ mt: 2, mr: 2 }} onClick={save}>
                        Save
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={props.onHandleCancel}>
                        Cancel
                    </Button>
                </Grid>}
            </Grid>}
            {(props.typeData?.id == 4 || props.typeData?.id == 5) && <Grid container justifyContent='flex-start' lg={12} >
                <TableContainer>
                    <Table>

                        <TableBody>
                            {charges.length > 0 &&
                                charges.map((chargesItem,index) => (
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                                        <TableCell style={{  padding: '8px' }}>
                                        <TextField
                                            lg={12}
                                            value={chargesItem.product}
                                            disabled={ true}
                                            label='Product'
                                        />
                                        </TableCell>
                                        <TableCell style={{  padding: '8px' }}>
                                            <Typography variant='h7'>
                                                <Grid item xs={6} sm={6} md={4} lg={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id={'MeasurementUnit'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                            {'MeasurementUnit'}
                                                        </InputLabel>
                                                        <Select
                                                            value={chargesItem.unitOfMeasure}
                                                            label={'MeasurementUnit'}
                                                            inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                            onChange={function (event) {
                                                                setChargesitem(event, 'unitOfMeasure',index)
                                                            }}
                                                            size='small'
                                                            MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                            {listOfMeasurementUnits?.map((option, index) => (
                                                                <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>
                                                                    
                                                                    {
                                                                        (option.lable)
                                                                    }
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{  padding: '8px' }}>
                                            <Typography variant='h7'>
                                                <Grid item xs={6} sm={6} md={4} lg={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id={'currency'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                                            {'currency'}
                                                        </InputLabel>
                                                        <Select
                                                            value={chargesItem.currency}
                                                            label={'currency'}
                                                            inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                                            onChange={function (event) {
                                                                setChargesitem(event, 'currency',index)
                                                            }}
                                                            size='small'
                                                            MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                                            {listOfcurrency?.map((option, index) => (
                                                                <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>
                                                                    
                                                                    {
                                                                        (option.lable)
                                                                    }
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{  padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.rate}
                                                onChange={function (event) {
                                                    setChargesitem(event, 'rate', index)
                                                }}
                                                label='Rate'
                                                type='number'
                                            />
                                        </TableCell>
                                        <TableCell style={{  padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.min}
                                                onChange={function (event) {
                                                    setChargesitem(event, 'min', index)
                                                }}
                                                label='Min'
                                                type='number'
                                            />
                                        </TableCell>
                                        <TableCell style={{  padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={chargesItem.remark}
                                                onChange={function (event) {
                                                    setChargesitem(event, 'remark', index)
                                                }}
                                                label='Remark'
                                            />
                                        </TableCell>
                                    </TableRow>))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {<Grid container item justifyContent='flex-end' lg={22}>
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={saveCharges}>
                        Save
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={props.onHandleCancel}>
                        Cancel
                    </Button>
                </Grid>}
            </Grid>}
            {snackbar && (
                <Snackbar open={!!snackbar} message={snackbar.message} type={snackbar.type} onClose={onSnackbarHandleClose} />
            )}
        </div>
    );
};

export default AddAirCost;
