import { useState, useEffect} from 'react';
import { Grid, TableCell, TableRow, Typography, Box, Button, Checkbox, Radio, FormControlLabel } from '@mui/material';
import { Dialog } from '../../style-guide';
import TextButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { addDesc, recieptWithBarCode } from '../Admins/AdminHandler'
import { onQsChange, onEmptyQsChange } from '../../reducers//QS/qsSlice';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { parcelStatusList, parcelSortBy, SHIPPING_STATUS_LIST } from '../../constants/global-constants';
import { getQuoatationType, getQuotationStatus } from '../../reducers/requestHandler';
import { getAdminList, getQsList, getCRMList } from '../../reducers/requestHandler';
import { FaPlus, FaMinus, FaInfoCircle } from "react-icons/fa";
import { setListOfKgFactor } from '../../reducers/parameter/parameterSlice';
const QSRow = props => {
    const dispatch = useDispatch();
    const { crmList } = useSelector(state => state.crm);
    const { countryList, listOfAirPort, listOfPort, listOfKgFactor, qsTypeList, qsStatusList, listOfIncoterms, listOfServiceType, listOfRejectReasions } = useSelector(state => state.parameter);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                quoteType: props.data.quoteType,
                cutomerCrmId: props.data.cutomerCrmId,
                shipmentType: props.data.shipmentType,
                total: props.data.total,
                flc20: props.data.flc20,
                flc40: props.data.flc40,
                total20: props.data.total20,
                total40: props.data.total40,
                countryId: props.data.countryId,
                from: props.data.from,
                to: props.data.to,
                statusDesc: props.data.statusDesc,
                status: props.data.status,
                incoId: props.data.incoId,
                userId: props.data.userId,
                serviceType: props.data.serviceType,
                createdDate: props.data.createdDate,
                checked: props.data.checked||false,
                params: props.data.params,
            }
            : {
                id: null,
                quoteType: null,
                cutomerCrmId: null,
                shipmentType: null,
                total20: null,
                total40: null,
                flc20: null,
                flc40: null,
                countryId: null,
                total: null,
                from: null,
                to: null,
                statusDesc: null,
                status: null,
                incoId: null,
                userId: null,
                serviceType: null,
                createdDate: null,
                checked: null,
            },
    );
    const { roles, adminList, users } = useSelector(state => state.admin);
    const { admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const getDesc = (r) => {
        if (value.status == 3) {
            return listOfRejectReasions?.find(u => u.id == Number(value.statusDesc))?.reasion;
        }
        return value.statusDesc
    }
    const deleteDescription = () => {
        var temp = {
            id: value.id,
            descId: value.id
        }
        addDesc(dispatch, snackbarToggle, temp, admin);

    };
   
    const downLoadReciept = (data) => {
        if (data) {
            recieptWithBarCode(dispatch, data, admin);
        }
    };
    function getClassName(status) {
        switch (status) {
            case 1:
                return "dashBoardPrepared_qs ";
                
            case 2:
                return "dashBoardApproved_qs";
            case 3:
                return "dashBoardDeclined_qs";
            case 4:
                return "dashBoardPending_qs";
            case 5:
                return "dashBoardCancled_qs";
        }
    }
    const getTotalCostChargeable = function (value) {
        var kfF = listOfKgFactor.find(u => u.shipmentType === 'Air')?.kg

        var totCharg = value.params.sum_total_volume * kfF;
        if (totCharg < value.params.sum_total_weight) {
            totCharg = value.params.sum_total_weight;
        }
        return Number(Number(totCharg).toFixed(2));
    }
    function getTotal(value) {
        var ret = ""
        if (value.shipmentType == 1) {
            ret = getTotalCostChargeable(value) + " Kg";
        }
        if (value.shipmentType == 2) {
            ret = value.params.totalVolume + " m3";
        }
        if (value.shipmentType == 3) {
            return <> <span>T20: {value.flc20}</span><br /><span>T40: {value.flc40}</span></>
            //ret = value.flc20 + ", " + value.flc40
        }
        return ret;
    }
    function getMargin(value) {
        if (value.shipmentType != 3) {
            return value.params?.margin
        } else {
            
            return <> <span>T20: {value.params?.margin20}</span><br /><span>T40: {value.params?.margin40}</span></>
        }

        return 0
    }     
    function formatDate(newDate) {        
        const d = newDate
        const year = d.getFullYear()
        const date = d.getDate()
        const monthName = d.getMonth()+1
        const formatted =  `${date}/${monthName}/${year}`
        return formatted.toString()
    }
    const [isLoaded, setIsLoaded] = useState(false);
    if (!isLoaded && admin.data.session && crmList && crmList.length == 0) {
        //getCRMList(dispatch, admin);
        setIsLoaded(true);
    }
    return (
        <TableRow className="qs_row" sx={{ '& > *': { border: '1px solid #EBEEF5', padding: 0, paddingLeft: 2, paddingRight: 2, borderLeft: '1.5px solid #d0d7e1', borderRight: '1.5px solid #d0d7e1' } }}>
            <TableCell style={{ fontSize: '0.8rem', width: '2vw',padding:0,paddingLeft:2,paddingRight:2 }}>
                <Typography variant='body1' component='span' variant='h7'>
                    <FormControlLabel style={{ fontSize: '0.8rem', width: '2vw', padding: '5px', margin: 0 }} value={value.id} control={<Radio color={'info'} style={{margin:0}}/>} label="" />

                    {/*<Grid container item spacing={2} sx={{ mt: -4 }}>

                        {<Button variant='contained' sx={{ mt: -2, ml: 10 }} onClick={() => {
                            props.openQsPdf()
                            props.onHandleCancelModalfourAction()
                        }}>
                            Open Pdf
                        </Button>
                        }
                        {<Button variant='contained' sx={{ mt: -2, ml: 2 }} onClick={() => {
                            props.checkAndOpen();
                            props.onHandleCancelModalfourAction()
                        }}>
                            Edit
                        </Button>
                        }
                        {<Button variant='contained' sx={{ mt: -2, ml: 2 }} onClick={() => {
                            props.checkAndDelete();
                            props.onHandleCancelModalfourAction()
                        }}>
                            Delete
                        </Button>
                        }
                        {<Button variant='contained' sx={{ mt: -2, ml: 2 }} onClick={() => {
                            props.checkAndCopy();
                            props.onHandleCancelModalfourAction()
                        }}>
                            Copy
                        </Button>
                        }
                    </Grid>*/}
                </Typography>
            </TableCell>

            < TableCell style={{ fontSize: '12px', width: '4.3vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}>

                {value.id}
            </TableCell>
            <TableCell onClick={() => {
                props.openOnLable()
            }} style={{cursor:"pointer", fontSize: '12px', width: '4vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center', color:"#00aeef" }}>
                {/*<Typography variant='body1' component='span' variant='h7'>*/}
                    {qsTypeList.find(u => u.id === value.quoteType)?.lable || '-'} 
                {/*</Typography>*/}
            </TableCell>
            <TableCell style={{ fontSize: '12px', width: '10vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }} className="Cutomer_header" >
               
                    {crmList.find(u => u.id === value.cutomerCrmId)?.companyName || '-'}
            </TableCell>
            {/*<TableCell style={{ fontSize: '12px', width: '7.3vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}>
                {value.shipmentType == 3 &&<> <span>T20: {Number(value.total20).toFixed(0)}</span><br/></>}
                {value.shipmentType == 3 && <span>T40: {Number(value.total40).toFixed(0)}</span>}
                {value.shipmentType != 3 && <span>{Number(value.total).toFixed(0)}</span>}
            </TableCell>*/}
             {/*<TableCell title={("Revenue: " + value.total) + (", Price: " + (value.params?.originalPrice ? Number(value.params?.originalPrice) : value.total))} style={{ fontSize: '12px', width: '7.3vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}>
                {value.shipmentType == 3 && <><span>T20: {(value.params?.originalPrice20 ? Number(value.params?.originalPrice20).toFixed(0) : Number(value.total20).toFixed(0))}</span><br/></>}
                {value.shipmentType == 3 && <span>T40: {(value.params?.originalPrice40 ? Number(value.params?.originalPrice40).toFixed(0) : Number(value.total40).toFixed(0))}</span>}
                {value.shipmentType != 3 && <span>{value.params?.originalPrice ? Number(value.params?.originalPrice) : Number(value.total).toFixed(0)}</span>}
                
            </TableCell>*/}
            <TableCell title={("Revenue: " + value.total) + (", Price: " + (value.params?.originalPrice ? Number(value.params?.originalPrice) : value.total))} style={{ fontSize: '12px', width: '7.3vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}>

                {getTotal(value)}

            </TableCell>
            <TableCell title={("Revenue: " + value.total) + (", Price: " + (value.params?.originalPrice ? Number(value.params?.originalPrice) : value.total))} style={{ fontSize: '12px', width: '7.3vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}>

                {getMargin(value)}

            </TableCell>
           
            <TableCell style={{ fontSize: '12px', width: '7.3vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}>
                
                {value.params?.finalCountry?.id ? countryList.find(u => u.id === value.params?.finalCountry?.id)?.name : countryList.find(u => u.id === value.countryId)?.name || '-'}
                
            </TableCell>
            <TableCell style={{ fontSize: '12px', width: '4.3vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}>
                
                    {value.shipmentType == 1 ? (listOfAirPort.find(u => u.id === value.from)?.name || '-') : (listOfPort.find(u => u.id === value.from)?.name || '-')}
                
            </TableCell>
            <TableCell style={{ fontSize: '12px', width: '4.3vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}>
                
                    {value.shipmentType == 1 ? (listOfAirPort.find(u => u.id === value.to)?.name || '-') : (listOfPort.find(u => u.id === value.to)?.name || '-')}
              
            </TableCell>

            < TableCell style={{ fontSize: '12px', width: '7.3vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}>

                {adminList.find(u => u.id === value.userId)?.adminName || '-'}
            </TableCell>
            <TableCell style={{ fontSize: '14px', width: '7.3vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'start',textAlign:'' }}>
                
                <TextButton title={`${value.params.agentText} \n ${value.statusDesc ? value.statusDesc : "-"}`} variant='contained' style={{ fontSize: '14px !important', height: '3.5vh', zIndex: 1, width: '7vw', textAlign: 'center', marginLeft: 1, marginTop: -1 }} sx={{ mr: 2, ml: 2, mt: 2, justifyContent: 'flex-start' }} className={getClassName(value.status)} onClick={props.openDialog}>
                    {qsStatusList.find(u => u.id === value.status)?.lable || '-'} {value.statusDesc && <FaInfoCircle title={getDesc()}style={{ zIndex: 4, height: '1.2vh', width: '1.2vw', textAlign: 'center', marginLeft: 5, marginTop: -1, color: 'black' }} sx={{ mr: 2, ml: 2, mt: 2 }} />}
                    </TextButton> 
                
            </TableCell>
           < TableCell style={{ fontSize: '12px', width: '5vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}>
                
                    {listOfIncoterms.find(u => u.id === value.incoId)?.incoterm || '-'}
                
            </TableCell>
            
            {/*< TableCell style={{ fontSize: '12px', width: '4.3vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}>
                
                  USD
            </TableCell>*/}
            < TableCell style={{ fontSize: '12px', width: '7.3vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}>
                
                    {listOfServiceType.find(u => u.id === value.serviceType)?.serviceType || '-'}
               
            </TableCell>
            < TableCell style={{ fontSize: '12px', width: '7.3vw', padding: 0, paddingLeft: 2, paddingRight: 2, textAlign: 'center' }}>
                
                    {formatDate(new Date(value.createdDate), 'dd/mm/yyyy')}
            </TableCell>
            
        </TableRow>
    );
};

export default QSRow;
