import { memo } from 'react';

import { Grid, TextField } from '@mui/material';

const TextFieldWrapper = ({
  label,
  value,
    className,
    onBlur,
  onChange,
  onKeyDown,
  readOnly,
  required,
  placeholder,
  type,
  xs,
  sm,
  md,
  lg,
  id,
  endAdornment,
}) => {
    let inputProps = { sx: { fontSize: '0.8rem', padding: '0px' } }

  if (type =='number') {
      inputProps = {min:0, sx: { fontSize: '0.8rem', padding: '0px' } }
  }

    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} className={className}>
      <TextField
        InputProps={{
          readOnly,
          ...inputProps,
          endAdornment,
              }}
              InputLabelProps={{ sx: { fontSize: '0.8rem'} }}
        error={required && (value ===null || value ==='')}
        required={required}
        placeholder={placeholder}
              fullWidth
        size='small'
        type={type}
        label={label}
        value={value || ''}
        onChange={onChange}
              onBlur={onBlur}
        onKeyDown={onKeyDown}
        id={id}
      />
    </Grid>
  );
};

TextFieldWrapper.defaultProps = {
  label: '',
  value: '',
    className: '',
  placeholder: '',
  readOnly: false,
  required: null,
  endAdornment: null,
  xs: 12,
  sm: 6,
  md: 4,
  lg: 2,
};

export default memo(TextFieldWrapper);
