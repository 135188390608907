import { memo } from 'react';
import { useState } from 'react';
import { TableCell, TableHead, TableRow, Typography, Button } from '@mui/material';
import AddAirTerminal from './AddAirTerminal';
import { Dialog } from '../../style-guide';

const AirTerminalHeader = () => {
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);

  return (
      <TableHead>
          <TableRow>
              <TableCell />
              
              <TableCell>
                  <Typography variant='h7'>
                      Description
                  </Typography>
              </TableCell>
              <TableCell>
                  <Typography variant='h7'>Operations</Typography>
              </TableCell>
              <TableCell>
                  <Typography variant='h7'>
                      <Button onClick={onHandleOpenModal} variant='contained'>
                          + New AirTerminal
                      </Button>
                      {openOrderModal && (
                          <Dialog open={openOrderModal} title={'AirTerminal'} onHandleCancel={onHandleCancelModal}>
                              <AddAirTerminal onHandleCancel={onHandleCancelModal} id={null} />
                          </Dialog>
                      )}
                  </Typography>
              </TableCell>
          </TableRow>
      </TableHead>
  );
};

export default memo(AirTerminalHeader);
