import { useState } from 'react';
import { TableCell, TableRow, Typography, Box, Button } from '@mui/material';
import AddExchangeRate from './AddExchangeRate';
import { Dialog } from '../../style-guide';
import { useSelector } from 'react-redux';
import { FaTrash, FaPen } from "react-icons/fa";

const ExchangeRateRow = ({ data }) => {
    const { roles } = useSelector(state => state.admin);
    const { listOfcurrency } = useSelector(state => state.parameter);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const onHandleOpenModal = () => setOpenOrderModal(true);
  const onHandleCancelModal = () => setOpenOrderModal(false);
    function formatDate(newDate) {
        const d = newDate
        const year = d.getFullYear()
        const date = d.getDate()
        const monthName = d.getMonth() + 1
        const formatted = `${date}/${monthName}/${year}`
        return formatted.toString()
    }
  return (
    <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
          <TableCell style={{ margin: 0, padding: '8px' }} />
          <TableCell style={{ margin: 0, padding: '8px' }} >
        <Typography variant='h7' >
                  {listOfcurrency.find(u => u.id === data.id)?.lable || '-'}
        </Typography>
      </TableCell>
          <TableCell style={{ margin: 0, padding: '8px' }} >
        <Typography variant='h7' >
                  {Number(data.oneUsd).toFixed(2) || '-'}
        </Typography>
      </TableCell>
          <TableCell style={{ margin: 0, padding: '8px' }} >
        <Typography variant='h7' >
                  {formatDate(new Date(data.date), 'dd/mm/yyyy')} <br />
        </Typography>
      </TableCell>
          <TableCell style={{ margin: 0, padding: '8px' }} >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Button sx={{ mr: 1 }} onClick={onHandleOpenModal} variant='outlined'>
                      <FaPen />
                  </Button>
          {openOrderModal && (
                      <Dialog open={openOrderModal} title={'ExchangeRate'} onHandleCancel={onHandleCancelModal}>
                          <AddExchangeRate data={data} onHandleCancel={onHandleCancelModal} id={null} />
            </Dialog>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ExchangeRateRow;
