import { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Grid, Checkbox, FormControlLabel, Button } from '@mui/material';
import { SelectField, TextField, MultilineField } from '../../style-guide';
import { sendRequest, REQUEST_ACTIONS } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { setAdmin, onWarehouseChange } from '../../reducers/admin-reducers/AdminSlicer';
import { Snackbar } from '../../style-guide';
import imgHide from './hide.png'
import imgShow from './show.png'
import { URL, LANGUAGES } from '../../constants/global-constants';
import { addDesc } from '../Admins/AdminHandler'
import { addUpdateProductCharges } from '../../reducers/requestHandler';

const AddProductCharges = props => {
    const dispatch = useDispatch();
    const { roles, warehouseList } = useSelector(state => state.admin);
    const { admin } = useSelector(state => state.session);
    const { listOfProductCategories, listOfShipmentTypes, listOfChargesTypes, listOfcurrency, listOfMeasurementUnits } = useSelector(state => state.parameter);
    const { snackbar } = useSelector(state => state.snackbar);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                name: props.data.name,
                rate: props.data.rate,
                min: props.data.min,
                productCategories: props.data.productCategories,
                shipmentTypes: props.data.shipmentTypes,
                chargesTypes: props.data.chargesTypes,
                units: props.data.units,
                currencies: props.data.currencies,
            }
            : {
                id: null,
                name: null,
                min: null,
                rate: null,
                productCategories: [],
                shipmentTypes: [],
                chargesTypes: [],
                units: [],
                currencies: [],
            },
    );

    const [isLoaded, setIsLoaded] = useState(false);
    const [productCategoriesOpt, setProductCategoriesOpt] = useState([]);
    const [shipmentTypesOpt, setShipmentTypesOpt] = useState([]);
    const [chargesTypesOpt, setChargesTypesOpt] = useState([]);
    const [unitsOpt, setUnitsOpt] = useState([]);
    const [currencyOpt, setCurrencyOpt] = useState([]);


    useEffect(() => {
        if (!isLoaded && listOfProductCategories.length > 0 && listOfShipmentTypes.length > 0 && listOfChargesTypes.length > 0 && listOfcurrency.length > 0) {
            setIsLoaded(true)
            if (value.productCategories.length > 0 && listOfProductCategories.length > 0 && productCategoriesOpt.length == 0) {
                var t = []
                t.push(...listOfProductCategories);
                for (var j = 0; j < t.length; j++) {
                    var tObj = JSON.parse(JSON.stringify(t[j]));
                    tObj.bSelected = false;
                    t[j] = tObj;
                }
                for (var i = 0; i < value.productCategories.length; i++) {
                    for (var j = 0; j < t.length; j++) {
                        var tObj = JSON.parse(JSON.stringify(t[j]));
                        if (tObj.id === value.productCategories[i]) {
                            tObj.bSelected = true;
                            t[j] = tObj;
                            break;
                        }

                    }
                }
                setProductCategoriesOpt(t)
            } else if (value.productCategories.length == 0) {
                var t = []
                for (var i = 0; i < listOfProductCategories.length; i++) {
                    var tObj = JSON.parse(JSON.stringify(listOfProductCategories[i]));
                    tObj.bSelected = false;
                    t.push(tObj)
                }
                setProductCategoriesOpt(t)
            }
            if (value.shipmentTypes.length > 0 && listOfShipmentTypes.length > 0 && shipmentTypesOpt.length == 0) {
                var t = []
                t.push(...listOfShipmentTypes);
                for (var j = 0; j < t.length; j++) {
                    var tObj = JSON.parse(JSON.stringify(t[j]));
                    tObj.bSelected = false;
                    t[j] = tObj;
                }
                for (var i = 0; i < value.shipmentTypes.length; i++) {
                    for (var j = 0; j < t.length; j++) {
                        var tObj = JSON.parse(JSON.stringify(t[j]));
                        if (tObj.id === value.shipmentTypes[i]) {
                            tObj.bSelected = true;
                            t[j] = tObj;
                            break;
                        }

                    }
                }
                setShipmentTypesOpt(t)
            } else if (value.shipmentTypes.length == 0) {
                var t = []
                for (var i = 0; i < listOfShipmentTypes.length; i++) {
                    var tObj = JSON.parse(JSON.stringify(listOfShipmentTypes[i]));
                    tObj.bSelected = false;
                    t.push(tObj)
                }
                setShipmentTypesOpt(t)
            }
            if (value.chargesTypes.length > 0 && listOfChargesTypes.length > 0 && chargesTypesOpt.length == 0) {
                var t = []
                t.push(...listOfChargesTypes);
                for (var j = 0; j < t.length; j++) {
                    var tObj = JSON.parse(JSON.stringify(t[j]));
                    tObj.bSelected = false;
                    t[j] = tObj;
                }
                for (var i = 0; i < value.chargesTypes.length; i++) {
                    for (var j = 0; j < t.length; j++) {
                        var tObj = JSON.parse(JSON.stringify(t[j]));
                        if (tObj.id === value.chargesTypes[i]) {
                            tObj.bSelected = true;
                            t[j] = tObj;
                            break;
                        }

                    }
                }
                setChargesTypesOpt(t)
            } else if (value.chargesTypes.length == 0) {
                var t = []
                for (var i = 0; i < listOfChargesTypes.length; i++) {
                    var tObj = JSON.parse(JSON.stringify(listOfChargesTypes[i]));
                    tObj.bSelected = false;
                    t.push(tObj)
                }
                setChargesTypesOpt(t);
            }
            if (value.currencies.length > 0 && listOfcurrency.length > 0 && currencyOpt.length == 0) {
                var t = []
                t.push(...listOfcurrency);
                for (var j = 0; j < t.length; j++) {
                    var tObj = JSON.parse(JSON.stringify(t[j]));
                    tObj.bSelected = false;
                    t[j] = tObj;
                }
                for (var i = 0; i < value.currencies.length; i++) {
                    for (var j = 0; j < t.length; j++) {
                        var tObj = JSON.parse(JSON.stringify(t[j]));
                        if (tObj.id === value.currencies[i]) {
                            tObj.bSelected = true;
                            t[j] = tObj;
                            break;
                        }

                    }
                }
                setCurrencyOpt(t)
            } else if (value.currencies.length == 0) {
                var t = []
                for (var i = 0; i < listOfcurrency.length; i++) {
                    var tObj = JSON.parse(JSON.stringify(listOfcurrency[i]));
                    tObj.bSelected = false;
                    t.push(tObj)
                }
                setCurrencyOpt(t);
            }
            if (value.units.length > 0 && listOfMeasurementUnits.length > 0 && unitsOpt.length == 0) {
                var t = []
                t.push(...listOfMeasurementUnits);
                for (var j = 0; j < t.length; j++) {
                    var tObj = JSON.parse(JSON.stringify(t[j]));
                    tObj.bSelected = false;
                    t[j] = tObj;
                }
                for (var i = 0; i < value.units.length; i++) {
                    for (var j = 0; j < t.length; j++) {
                        var tObj = JSON.parse(JSON.stringify(t[j]));
                        if (tObj.id === value.units[i]) {
                            tObj.bSelected = true;
                            t[j] = tObj;
                            break;
                        }

                    }
                }
                setUnitsOpt(t)
            } else if (value.units.length == 0) {
                var t = []
                for (var i = 0; i < listOfMeasurementUnits.length; i++) {
                    var tObj = JSON.parse(JSON.stringify(listOfMeasurementUnits[i]));
                    tObj.bSelected = false;
                    t.push(tObj)
                }
                setUnitsOpt(t);
            }
        }
    }, []);

    
    const save = () => {
        var chargesTypes = [];
        var currencies = [];
        var productCategories = [];
        var shipmentTypes = [];
        var units = [];

        productCategories = productCategoriesOpt.filter(function (obj) {
            return obj.bSelected;
        }).map(function (obj) { return obj.id; });

        shipmentTypes = shipmentTypesOpt.filter(function (obj) {
            return obj.bSelected;
        }).map(function (obj) { return obj.id; });

        chargesTypes = chargesTypesOpt.filter(function (obj) {
            return obj.bSelected;
        }).map(function (obj) { return obj.id; });

        currencies = currencyOpt.filter(function (obj) {
            return obj.bSelected;
        }).map(function (obj) { return obj.id; });
        
        units = unitsOpt.filter(function (obj) {
            return obj.bSelected;
        }).map(function (obj) { return obj.id; });

        var data = {
            id: value.id,
            name: value.name,
            rate: value.rate,
            min: value.min,
            chargesTypes: chargesTypes,
            currencies: currencies,
            productCategories: productCategories,
            shipmentTypes: shipmentTypes,
            units: units,
        }
        addUpdateProductCharges(dispatch, snackbarToggle, data, admin);
        props.onHandleCancel();

    };

    const update = () => {
        addUpdateProductCharges(dispatch, snackbarToggle, value, admin);
        props.onHandleCancel();
    };
    const handleProductCategoriesOpt = (event, data) => {
        var t = []
        for (var i = 0; i < productCategoriesOpt.length; i++) {
            if (productCategoriesOpt[i].id == data.id) {
                productCategoriesOpt[i].bSelected = event.target.checked
            }
            t.push(productCategoriesOpt[i])
        }
        setProductCategoriesOpt(t);
    }
    const handleShipmentTypesOpt = (event, data) => {
        var t = []
        for (var i = 0; i < shipmentTypesOpt.length; i++) {
            if (shipmentTypesOpt[i].id == data.id) {
                shipmentTypesOpt[i].bSelected = event.target.checked
            }
            t.push(shipmentTypesOpt[i])
        }
        setShipmentTypesOpt(t);
    }
    const handleChargesTypesOpt = (event, data) => {
        var t = []
        for (var i = 0; i < chargesTypesOpt.length; i++) {
            if (chargesTypesOpt[i].id == data.id) {
                chargesTypesOpt[i].bSelected = event.target.checked
            }
            t.push(chargesTypesOpt[i])

        }
        setChargesTypesOpt(t);
    }
    const handleUnitsOpt = (event, data) => {
        var t = []
       // setUnitsOpt(t);

        var opt = unitsOpt.find(u => u.id === data.id)
        opt.bSelected = event.target.checked
        t.push(opt)
        for (var i = 0; i < unitsOpt.length; i++) {
            if (unitsOpt[i].id == data.id) {
                unitsOpt[i].bSelected = event.target.checked
            } else {
                t.push(unitsOpt[i])
            }
        }
        setUnitsOpt(t);
    }
    const handleCurrencyOpt = (event, data) => {
        var t = []
        //setUnitsOpt(t);
        for (var i = 0; i < currencyOpt.length; i++) {
            if (currencyOpt[i].id == data.id) {
                currencyOpt[i].bSelected = event.target.checked
            }
            t.push(currencyOpt[i])
        }
        setCurrencyOpt(t);
    }


    return (
        <div>
            <Grid container item spacing={2}>
                <Grid container item spacing={2}>
                    <TextField
                        lg={3.666}
                        value={value.name}
                        onChange={event => setValue(prev => ({ ...prev, name: event.target.value }))}
                        label='Name'
                    />
                    <TextField
                        lg={3.666}
                        value={value.min}
                        onChange={event => setValue(prev => ({ ...prev, min: event.target.value }))}
                        label='Min'
                    />
                    <TextField
                        lg={3.666}
                        value={value.rate}
                        onChange={event => setValue(prev => ({ ...prev, rate: event.target.value }))}
                        label='Rate'
                    />
                    <Grid container item justifyContent='flex-start' lg={10.8} style={{ border: "2px solid #1976d2", marginTop: 20, marginLeft: 16, borderRadius: 5 }}>
                        <Button variant='text' sx={{ mt: 2, mb: 2, ml: 1 }} style={{ marginLeft: -10, marginTop: -35, background: "white" }}>
                            Product Categories
                        </Button>
                        <Grid container item justifyContent='flex-start' lg={10.8}>
                            {
                                productCategoriesOpt.map(pr => (
                                    <Grid style={{ marginTop: -20 }} key={uuidv4()} item xs={3} > <FormControlLabel control={<Checkbox checked={pr.bSelected} onChange={event => handleProductCategoriesOpt(event, pr)} />} label={pr.lable} /></Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent='flex-start' lg={10.8} style={{ border: "2px solid #1976d2", marginTop: 20, marginLeft: 16, borderRadius: 5 }}>
                        <Button variant='text' sx={{ mt: 2, mb: 2, ml: 1 }} style={{ marginLeft: -10, marginTop: -35, background: "white" }}>
                            Product Categories
                        </Button>
                        <Grid container item justifyContent='flex-start' lg={10.8}>

                            {
                                shipmentTypesOpt.map(pr => (
                                    <Grid style={{ marginTop: -20 }} key={ uuidv4()} item xs={3} > <FormControlLabel control={<Checkbox checked={pr.bSelected} onChange={event => handleShipmentTypesOpt(event, pr)} />} label={pr.lable} /></Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent='flex-start' lg={10.8} style={{ border: "2px solid #1976d2", marginTop: 20, marginLeft: 16, borderRadius: 5 }}>
                        <Button variant='text' sx={{ mt: 2, mb: 2, ml: 1 }} style={{ marginLeft: -10, marginTop: -35, background: "white" }}>
                            Charges Types
                        </Button>
                        <Grid container item justifyContent='flex-start' lg={10.8}>

                            {
                                chargesTypesOpt.map(pr => (
                                    <Grid style={{ marginTop: -20 }} key={uuidv4()} item xs={3} > <FormControlLabel control={<Checkbox checked={pr.bSelected} onChange={event => handleChargesTypesOpt(event, pr)} />} label={pr.lable} /></Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent='flex-start' lg={10.8} style={{ border: "2px solid #1976d2", marginTop: 20, marginLeft: 16, borderRadius: 5 }}>
                        <Button variant='text' sx={{ mt: 2, mb: 2, ml: 1 }} style={{ marginLeft: -10, marginTop: -35, background: "white" }}>
                            Units Types
                        </Button>
                        <Grid container item justifyContent='flex-start' lg={12}>

                            {
                                unitsOpt.map(pr => (
                                    <Grid style={{ marginTop: -20 }} key={uuidv4()} item xs={2.4} > <FormControlLabel control={<Checkbox checked={pr.bSelected} onChange={event => handleUnitsOpt(event, pr)} />} label={pr.lable} /></Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent='flex-start' lg={10.8} style={{ border: "2px solid #1976d2", marginTop: 20, marginLeft: 16, borderRadius: 5 }}>
                        <Button variant='text' sx={{ mt: 2, mb: 2, ml: 1 }} style={{ marginLeft: -10, marginTop: -35, background: "white" }}>
                            Currency Types
                        </Button>
                        <Grid container item justifyContent='flex-start' lg={10.8}>
                            {
                                currencyOpt.map(pr => (
                                    <Grid style={{ marginTop: -20 }} key={uuidv4()} item xs={3} > <FormControlLabel control={<Checkbox checked={pr.bSelected} onChange={event => handleCurrencyOpt(event, pr)} />} label={pr.lable} /></Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item justifyContent='flex-end' lg={11}>
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={save}>
                        Save
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={props.onHandleCancel}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            {snackbar && (
                <Snackbar open={!!snackbar} message={snackbar.message} type={snackbar.type} onClose={onSnackbarHandleClose} />
            )}
        </div>
    );
};

export default AddProductCharges;
