import { memo } from 'react';

import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const UserHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell>
            <Typography variant='h7'>Name</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='h7'>Email</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='h7'>Type</Typography>
        </TableCell>
        <TableCell>
            <Typography variant='h7'>Phone</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='h7'>Operations</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default memo(UserHeader);
