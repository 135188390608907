import { useState, useEffect, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDraggable } from "react-use-draggable-scroll";
import { Button, Table, Grid, TableBody, TableContainer, Paper, TablePagination } from '@mui/material';
import ProductChargesHeader from './ProductChargesHeader';
import ProductChargesRow from './ProductChargesRow';
import AddProductCharges from './AddProductCharges';
import { getAdminList, getWarehouseList } from '../Admins/AdminHandler'
import { REQUEST_ACTIONS, sendRequest } from '../../utils/Communicator';
import { useSelector, useDispatch } from 'react-redux';
import { onAdminChange, onWarehouseChange } from '../../reducers/admin-reducers/AdminSlicer';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { Dialog,Snackbar } from '../../style-guide';
import { URL, LANGUAGES } from '../../constants/global-constants';

export const ProductChargesTable = () => {
    const ref = useRef();
    const { events } = useDraggable(ref);
    const dispatch = useDispatch();
    const { adminList,  warehouseList, discriptionsList} = useSelector(state => state.admin);
    const { admin} = useSelector(state => state.session);
    const { listOfProductCharges } = useSelector(state => state.parameter);
    const { snackbar } = useSelector(state => state.snackbar);
    const [isLoaded, setIsLoaded] = useState(false);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => {
        setOpenOrderModal(true);
        getAdminList(dispatch, admin);
        getWarehouseList(dispatch, admin);
    };
    const onHandleCancelModal = () => setOpenOrderModal(false);

    // pagination
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const onPageHandleChange = (_, newPage) => {
        setPage(newPage);
    };
    const onRowsPerPageHandleChange = event => {
        setSize(+event.target.value);
        setPage(0);
    };
    

    return (

        <Paper sx={{ width: '100%' }} >
        <Paper sx={{ width: '100%', overflow: 'auto', height: 'calc(100vh - 180px)' }}  {...events} ref={ref}>
            {openOrderModal && (
                <Dialog maxWidth='md' open={openOrderModal} title={`ProductCharges`} onHandleCancel={onHandleCancelModal}>
                    <AddProductCharges onHandleCancel={() => setOpenOrderModal(false)} id={null} />
                </Dialog>
            )}
            {listOfProductCharges.length > 0 && <TableContainer sx={{  minWidth: 2000 }}>
                <Table>
                    <ProductChargesHeader />
                    <TableBody>
                        {
                            listOfProductCharges.map(user => (
                                <ProductChargesRow key={user?.id || uuidv4()} data={user} />
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>}
            
            {snackbar && (
                <Snackbar
                    open={!!snackbar}
                    message={snackbar.message}
                    type={snackbar.type}
                    onClose={onSnackbarHandleClose}
                />
            )}
            </Paper>
            {<Button variant='contained' sx={{ mt: 0.5, ml: 2 }} onClick={onHandleOpenModal}>
                Add new Product
            </Button>
            }
        </Paper>



    );
};
export default ProductChargesTable;
