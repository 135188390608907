import { useState, useEffect } from 'react';
import { TableCell, Grid, TableRow, Typography, Box, Button, Checkbox, Radio, FormControlLabel } from '@mui/material';
import { Dialog } from '../../style-guide';
import { useSelector, useDispatch } from 'react-redux';
import { addDesc, recieptWithBarCode } from '../Admins/AdminHandler'
import { onQsChange, onEmptyQsChange } from '../../reducers//QS/qsSlice';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { parcelStatusList, parcelSortBy, SHIPPING_STATUS_LIST } from '../../constants/global-constants';
import { getProductChargesByProdShipCharge, getQuoatationType, getQuotationStatus, getCRMList, getLclExportImportAFs } from '../../reducers/requestHandler';
import { getLclExportImportAF, getAdminList, getQsList } from '../../reducers/requestHandler';
import AddLclCost from '../CostList/Lcl/AddLclCost';
const LclQuote = props => {
    const { addRemoveSelectedMail } = props;
    const dispatch = useDispatch();
    const { adminList } = useSelector(state => state.admin);
    const { crmList } = useSelector(state => state.crm);
    const { listOfProductCharges, listOfExchangeRates, listOfProductCategories, listOfShipmentTypes, listOfAirLine, listOfLclExportImportAFs, listOfAirExportAFs, countryList, qsStatusList, listOfServiceType, listOfAirPort, listOfPort, listOfIncoterms, listOfFLCTypes40, listOfFLCTypes20 } = useSelector(state => state.parameter);
    const { admin } = useSelector(state => state.session);
    const [value, setValue] = useState(
        props.detail ? {
            id: props.detail.id,
            importExportType: props.detail.importExportType,
            rate: props.detail.rate,
            selected: props.detail.selected,
            fca: 0,
        }
            : {
                id: null,
                importExportType: null,
                fromId: null,
                toId: null,
                airLineId: null,
                rateList: null,
                min: null,
                crmId: null,
                airportId: null,
                zip: null,
                weightList: null,
                countryId: null,
                createTime: null,
                updateTime: null,
                isShow: false,
            },
    );
    function formatDate(newDate) {
        const d = newDate
        const year = d.getFullYear()
        const date = d.getDate()
        const monthName = d.getMonth() + 1
        const formatted = `${date}/${monthName}/${year}`
        return formatted.toString()
    }
    const [isLoaded, setIsLoaded] = useState(false);
    if (!isLoaded && admin.data.session && crmList && crmList.length == 0) {
        //getCRMList(dispatch, admin);
        setIsLoaded(true);
    }
    useEffect(() => {
        getFcaCharges(selectedAirQuoteData)
        getRate(selectedAirQuoteData)
    }, []);
    const [selectedAirQuoteData, setSelectedAirQuoteData] = useState({ ...props?.detail/*, id: null */ });
    const [updatedAirQuoteData, setUpdatedAirQuoteData] = useState({ ...props?.detail/*, id: null */ });

    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => {

        // change needed
        getLclExportImportAF(value, admin, function (data) {
            var tObj = JSON.parse(JSON.stringify(data?.data));
            if (tObj.weightList) {
                tObj.weight = tObj.weightList[0];
            } else {
                tObj.weight = 0;
            }
            tObj.rate = tObj.rateList[0];
            tObj.quoteRate = tObj.rateList[0];
            setUpdatedAirQuoteData(tObj)
            setSelectedAirQuoteData({ ...tObj/*, id: null */ });
            
            setValue(prev => ({ ...prev, fca: 0 }));
            setValue(prev => ({ ...prev, rate: 0 }));
            getFcaCharges(tObj)
            getRate(tObj)
            setOpenOrderModal(true);

        })

       // setOpenOrderModal(true);
    };
    const handleChange = (event) => {
    };
    //const onHandleCancelModal = () => { setOpenOrderModal(false) };
    const onHandleCancelModal = () => {
       /* window.setTimeout(function () {
            const elem = document.getElementById('getCostQuoat');
            elem?.click();
        }, 10);*/
        var tc = [...props.listOfQuoatationCost];
        var k = []
        for (var i = 0; i < tc.length; i++) {
            if (tc[i]?.id == updatedAirQuoteData?.id) {
                tc[i] = updatedAirQuoteData;
            }
        }
        props.setListOfQuoatationCost(tc)
        setOpenOrderModal(false)
    };

    const [openOrderModalClone, setOpenOrderModalClone] = useState(false);
    const onHandleOpenModalClone = () => {
        setOpenOrderModalClone(true);
    };
    const onHandleCancelModalClone = () => setOpenOrderModalClone(false);
    const getRate = (crm) => {
        var charges = crm.params;
        var FRIEGHT_CAHRGES = charges?.FRIEGHT_CAHRGES;
        var total = value.rate?Number(value.rate):0;
        getProductChargesByProdShipCharge(dispatch, admin, props.value.productType, props.value.shipmentType, 1, function (data) {
            if (FRIEGHT_CAHRGES) {
                for (var i = 0; i < FRIEGHT_CAHRGES.length; i++) {
                    var prCh = data?.data?.find(u => u.name === FRIEGHT_CAHRGES[i].name)
                    if (prCh) {
                        total = total + Number(FRIEGHT_CAHRGES[i]?.rate ? Number(FRIEGHT_CAHRGES[i]?.rate) : 0)
                        setValue(prev => ({ ...prev, rate: total }));
                    }
                    }
            }
        })
       
        return total
    }// props.calculation(value.selectedItem?.rate, value.selectedItem?.min, value.selectedUnit?.id)
    const getFcaCharges = (crm) => {// props.calculation(value.selectedItem?.rate, value.selectedItem?.min, value.selectedUnit?.id)
        console.log(crm)
        var charges = crm.params;
        var FRIEGHT_CAHRGES = charges?.FRIEGHT_CAHRGES;
        var total = 0;
        if (FRIEGHT_CAHRGES) {
            getProductChargesByProdShipCharge(dispatch, admin, props.value.productType, props.value.shipmentType, 1, function (data) {
                for (var i = 0; i < FRIEGHT_CAHRGES.length; i++) {
                    var prCh = data?.data?.find(u => u.name === FRIEGHT_CAHRGES[i].name)
                    var exch = listOfExchangeRates.find(u => u.id === FRIEGHT_CAHRGES[i].currency)
                    if (exch && prCh) {
                        var calc = Number(props.getFreightCalculations(Number(FRIEGHT_CAHRGES[i]?.rate), Number(FRIEGHT_CAHRGES[i]?.min), Number(FRIEGHT_CAHRGES[i]?.unitOfMeasure))) / exch.oneUsd;
                        total = Number(total) + Number(Number(calc).toFixed(2))
                    }
                }
                setValue(prev => ({ ...prev, fca: total }));
                getProductChargesByProdShipCharge(dispatch, admin, props.value.productType, props.value.shipmentType, 2, function (data) {
                    var LOCAL_CHARGES = charges?.LOCAL_CHARGES

                   // total = value.fca
                    for (var i = 0; i < LOCAL_CHARGES.length; i++) {
                        var prCh = data?.data?.find(u => u.name === LOCAL_CHARGES[i].name)
                        var exch = listOfExchangeRates.find(u => u.id === LOCAL_CHARGES[i].currency)
                        if (exch && prCh) {
                            var calc = Number(props.getFreightCalculations(Number(LOCAL_CHARGES[i]?.rate), Number(LOCAL_CHARGES[i]?.min), Number(LOCAL_CHARGES[i]?.unitOfMeasure))) / exch.oneUsd;
                            total = Number(total) + Number(Number(calc).toFixed(2))
                        }
                    }
                    setValue(prev => ({ ...prev, fca: Number(total).toFixed(2) }));
                })
            })
           
        }
        return total;
    };

    return (
        /*<TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{ background: (props.detail.validDate < new Date().getTime()) ?"gray":""}}>*/
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
                <Typography variant='h7'>
                    {/*<Checkbox disabled={!(new Date().getTime() > props.detail.validfrom && new Date().getTime() < props.detail.validDate)} checked={value.selected} onChange={(event) => {*/}
                    <Checkbox checked={value.selected} onChange={(event) => {
                        setValue(prev => ({ ...prev, selected: event.target.checked }));
                        addRemoveSelectedMail(props.detail, event.target.checked);
                    }} />
                </Typography>
            </TableCell>
            {<TableCell>
                <Typography variant='h7'>
                    {crmList.find(u => u.id === props?.detail?.crmId)?.companyName || '-'}
                </Typography>
            </TableCell>}
            <TableCell>
                <Typography variant='h7'>
                    {Number(value.rate)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='h7'>
                    {props.detail?.clAgent}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='h7'>
                    {props.detail?.tt} 
                </Typography>
            </TableCell>
            {props.detail?.importExportType == 1 && <TableCell>
                <Typography variant='h7'>
                    {value.fca}
                </Typography>
            </TableCell>}
            <TableCell>
                <Typography variant='h7'>
                    {formatDate(new Date(props.detail?.updateTime))}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='h7'>
                    {formatDate(new Date(props.detail?.validfrom))}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='h7'>
                    {formatDate(new Date(props.detail?.validDate))}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='h7'>
                    <Grid container item lg={10} justifyContent="space-evenly">

                        <Grid lg={2} item>
                            {openOrderModalClone && (
                                <Dialog maxWidth='md' open={openOrderModalClone} title={props.typeData?.lable} onHandleCancel={onHandleCancelModalClone}>
                                    <AddLclCost isAddFromQsCostTable={true} setAirImportExport={() => {

                                    }}
                                        callBack={() => {
                                            getLclExportImportAFs(dispatch, admin, function () {
                                                window.setTimeout(function () {
                                                    const elem = document.getElementById('getCostQuoat');
                                                    elem?.click();
                                                }, 10);
                                            });


                                        }} typeData={props.typeData} data={{ ...props?.detail, id: null }} importExportType={props.detail?.importExportType} onHandleCancel={() => setOpenOrderModalClone(false)} id={null} airImportExport={null} />
                                </Dialog>
                            )}

                            <Button variant='text' onClick={function () { onHandleOpenModalClone() }} >
                                Add
                            </Button>
                        </Grid>
                        <Grid lg={2} item>
                            {openOrderModal && (
                                <Dialog maxWidth='md' open={openOrderModal} title={props.typeData?.lable} onHandleCancel={onHandleCancelModal}>
                                    <AddLclCost isAddFromQsCostTable={false} setAirImportExport={() => {

                                    }}
                                        callBack={() => {
                                            getLclExportImportAFs(dispatch, admin, function () {
                                                window.setTimeout(function () {
                                                    const elem = document.getElementById('getCostQuoat');
                                                    elem?.click();
                                                }, 10);
                                            });


                                        }} typeData={props.typeData} data={selectedAirQuoteData} importExportType={props.detail?.importExportType} onHandleCancel={() => onHandleCancelModal()} id={null} airImportExport={null} />
                                </Dialog>
                            )}
                            <Button variant='text' onClick={function () { onHandleOpenModal() }} >
                                Edit
                            </Button>
                        </Grid>
                        { < Grid lg={2} item>
                            <Button variant='contained' disabled={!(new Date().getTime() > props.detail?.validfrom && new Date().getTime() < props.detail?.validDate)} onClick={function () {
                                props?.setbLoadingLocal(true)
                                getLclExportImportAF(value, admin, function (data) {
                                    //props?.setbLoadingLocal(false)
                                    var tObj = JSON.parse(JSON.stringify(data?.data));
                                    if (tObj.weightList) {
                                        tObj.weight = tObj.weightList[0];
                                    } else {
                                        tObj.weight = 0;
                                    }
                                    tObj.rate = tObj.rateList[0];
                                    tObj.quoteRate = tObj.rateList[0];
                                    setUpdatedAirQuoteData(tObj)
                                    setSelectedAirQuoteData({ ...tObj});

                                    setValue(prev => ({ ...prev, fca: 0 }));
                                    setValue(prev => ({ ...prev, rate: 0 }));
                                    getFcaCharges(tObj)
                                    getRate(tObj)

                                    var tc = [...props.listOfQuoatationCost];
                                    var k = []
                                    for (var i = 0; i < tc.length; i++) {
                                        if (tc[i].id == tObj?.id) {
                                            tc[i] = { ...props.detail, ...tObj };;
                                        }
                                    }
                                    props.setListOfQuoatationCost(tc)

                                    props.quoteByRate({ ...props.detail, ...tObj })

                                })
                               // props.quoteByRate(props.detail)
                              props.setProductFreightChargesItem1([])
                                
                            }} >
                                Quote
                            </Button>
                        </Grid>
                        }
                    </Grid>
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default LclQuote;
