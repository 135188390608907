import { useState, useEffect} from 'react';
import { TableHead, TableCell, TableRow, Typography, Box, Button, Checkbox, Radio, FormControlLabel } from '@mui/material';
import { Dialog } from '../../style-guide';
import { useSelector, useDispatch } from 'react-redux';
import { addDesc, recieptWithBarCode } from '../Admins/AdminHandler'
import { onQsChange, onEmptyQsChange } from '../../reducers//QS/qsSlice';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { parcelStatusList, parcelSortBy, SHIPPING_STATUS_LIST } from '../../constants/global-constants';
import { getQuoatationType, getQuotationStatus } from '../../reducers/requestHandler';
import { getAdminList, getQsList } from '../../reducers/requestHandler';
const AirLclQuoteHeader = props => {
    const { adminList } = useSelector(state => state.admin);
    const { crmList } = useSelector(state => state.crm);
    const { listOfProductCategories, listOfShipmentTypes, listOfAirLine, listOfLclExportImportAFs, listOfAirExportAFs, countryList, qsStatusList, listOfServiceType, listOfAirPort, listOfPort, listOfIncoterms, listOfFLCTypes40, listOfFLCTypes20 } = useSelector(state => state.parameter);
    const { admin } = useSelector(state => state.session);
    function formatDate(newDate) {
        const d = newDate
        const year = d.getFullYear()
        const date = d.getDate()
        const monthName = d.getMonth() + 1
        const formatted = `${date}/${monthName}/${year}`
        return formatted.toString()
    }
    return (
        <TableHead>
            {props.value.shipmentType == 1 && <TableRow>
                <TableCell>
                    <Typography variant='h7'>
                        <Checkbox checked={props.selectAllMail} onChange={(event) => {
                            //setValue(prev => ({ ...prev, selected: event.target.checked }));
                            props.addRemoveSelectedMailAll(props.detail, event.target.checked);
                        }} />
                    </Typography>
                </TableCell>
                {(props.value.productType == 2) && <TableCell>
                    <Typography variant='h7'>
                        Agent
                    </Typography>
                </TableCell>}
                
                <TableCell>
                    <Typography variant='h7'>
                        Airline
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h7'>
                        Rate
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h7'>
                        Min
                    </Typography>
                </TableCell>
                {(props.value.productType == 2) && <TableCell>
                    <Typography variant='h7'>
                        FCA
                    </Typography>
                </TableCell>}
                {/*{(props.value.productType == 2) && <TableCell>
                    <Typography variant='h7'>
                        Agent Charges
                    </Typography>
                </TableCell>}*/}
                <TableCell>
                    <Typography variant='h7'>
                        Updated
                    </Typography>
                </TableCell>
                <TableCell style={{textAlign:"center"}}>
                    <Typography variant='h7'>
                        Action
                    </Typography>
                </TableCell>

            </TableRow>}

            {props.value.shipmentType == 2 && <TableRow>
                <TableCell>
                    <Typography variant='h7'>
                        <Checkbox checked={props.selectAllMail} onChange={(event) => {
                            //setValue(prev => ({ ...prev, selected: event.target.checked }));
                            props.addRemoveSelectedMailAll(props.detail, event.target.checked);
                        }} />
                    </Typography>
                </TableCell>
                {<TableCell>
                    <Typography variant='h7'>
                        Co-loader
                    </Typography>
                </TableCell>}
                <TableCell>
                    <Typography variant='h7'>
                        Cost
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h7'>
                        CL-Agent
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h7'>
                        TT
                    </Typography>
                </TableCell>
                {props.value.productType == 1 && <TableCell>
                    <Typography variant='h7'>
                        FCA
                    </Typography>
                </TableCell>}
                <TableCell>
                    <Typography variant='h7'>
                        Updated
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h7'>
                        Valid From
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h7'>
                        Valid End
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant='h7'>
                        Action
                    </Typography>
                </TableCell>

            </TableRow>}
        </TableHead>
    );
};

export default AirLclQuoteHeader;
