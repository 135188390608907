import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table, TableBody, TableContainer, TableCell, TableRow, Typography, Select, Grid, FormControl, InputLabel, Box, MenuItem, Button, Checkbox, FormControlLabel } from '@mui/material';
import { SelectField, Snackbar, TextField, MultilineField, DateTimePicker } from '../../../style-guide';
import { Dialog } from '../../../style-guide';
import { useSelector, useDispatch } from 'react-redux';
import { addDesc, recieptWithBarCode } from '../../Admins/AdminHandler'
import { onFclCostChange, onEmptyFclCost } from '../../../reducers/crm/crmSlice';
import { snackbarToggle } from '../../../reducers/snackbarSlicer';
import { listOfCostLandWeightFcl, parcelSortBy, SHIPPING_STATUS_LIST } from '../../../constants/global-constants';
import { getQuoatationType, getQuotationStatus, getCRMList } from '../../../reducers/requestHandler';
import { getAdminList, getQsList } from '../../../reducers/requestHandler';
import { setCurrentPage } from '../../../reducers/session/sessionSlice';
import { FaTrash, FaPen } from "react-icons/fa";
const RowSelectOption = props => {
    const { setselectFreightId, frieghtChargesTemp, setFrieghtChargesTemp, selectFreightId, setFrieghtCharges, frieghtCharges, listOfcurrency, listOfMeasurementUnits } = props;

    useEffect(() => {
        var t = [];
        //  (props.data?.importExportType == 1 ? [...fclCostFrieghtChargesExport] : [...fclCostFrieghtCharges])


    }, []);
   
        return (
            <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '0 !important', margin: 0, padding: '2px' } }}>
                <TableCell style={{ padding: '8px' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl fullWidth>
                            <InputLabel id={'ItemP'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                {'Product'}
                            </InputLabel>
                            <Select
                                value={selectFreightId}
                                label={'Item'}
                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                onChange={function (event) {
                                    var t = [];
                                    for (var i = 0; i < frieghtCharges.length; i++) {
                                        if (!Number(frieghtCharges[i].rate20) || !Number(frieghtCharges[i].rate40)) {
                                            var tObj = JSON.parse(JSON.stringify(frieghtCharges[i]))
                                            tObj.id = i + 1;
                                            tObj.currency = listOfcurrency.find(u => u.id === tObj.currency)?.id
                                            t.push(tObj)
                                        }
                                    }
                                    setFrieghtChargesTemp(t)
                                    window.setTimeout(function () {
                                        setselectFreightId(event.target.value)

                                    }, 100)
                                    //setFrieghtChargesitem(event, 'unitOfMeasure', index)
                                }}
                                size='small'
                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                {frieghtChargesTemp?.map((option, index) => (
                                    <MenuItem key={option.name} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                        {
                                            (option.name || option.product)
                                        }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </TableCell>
                <TableCell style={{ padding: '8px' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl fullWidth>
                            <InputLabel id={'MeasurementUnit'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                {'MeasurementUnit'}
                            </InputLabel>
                            <Select
                                value={frieghtChargesTemp.find(u => u.id === selectFreightId)?.unitOfMeasure}
                                label={'MeasurementUnit'}
                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                onChange={function (event) {
                                    /*var obj = JSON.parse(JSON.stringify(selectFreightId))
                                    obj.unitOfMeasure = event.target.value;
                                    setselectFreightId(obj)*/
                                    // setFrieghtChargesitem(event, 'unitOfMeasure', index)
                                }}
                                //disabled={true}
                                size='small'
                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                {listOfMeasurementUnits?.map((option, index) => (
                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                        {
                                            (option.lable)
                                        }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </TableCell>
                <TableCell style={{ padding: '8px' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl fullWidth>
                            <InputLabel id={'currency'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                {'currency'}
                            </InputLabel>
                            <Select
                                value={frieghtChargesTemp.find(u => u.id === selectFreightId)?.currency}
                                label={'currency'}
                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                onChange={function (event) {
                                    var t = JSON.parse(JSON.stringify(frieghtChargesTemp))
                                    for (var i = 0; i < t.length; i++) {
                                        if (t[i].id == selectFreightId) {
                                            t[i].currency = event.target.value;
                                        }
                                    }
                                    setFrieghtChargesTemp(t)
                                    // setselectFreightId(prev => ({ ...frieghtCharges.find(u => u.name === selectFreightId.name), currency: event.target.value }))
                                    // setFrieghtChargesitem(event, 'currency', index)
                                }}
                                size='small'
                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                {listOfcurrency?.map((option, index) => (
                                    <MenuItem key={option.id} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>

                                        {
                                            (option.lable)
                                        }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </TableCell>
                <TableCell style={{ padding: '8px' }}>
                    <TextField
                        lg={12}
                        value={frieghtChargesTemp.find(u => u.id === selectFreightId)?.rate20}
                        onChange={function (event) {
                            var t = JSON.parse(JSON.stringify(frieghtChargesTemp))
                            for (var i = 0; i < t.length; i++) {
                                if (t[i].id == selectFreightId) {
                                    t[i].rate20 = event.target.value;
                                }
                            }
                            setFrieghtChargesTemp(t)
                            //setFrieghtChargesitem(event, 'rate20', index)
                        }}
                        label='Rate20'
                        type='number'
                    />
                </TableCell>
                <TableCell style={{ padding: '8px' }}>
                    <TextField
                        lg={12}
                        value={frieghtChargesTemp.find(u => u.id === selectFreightId)?.rate40}
                        onChange={function (event) {
                            var t = JSON.parse(JSON.stringify(frieghtChargesTemp))
                            for (var i = 0; i < t.length; i++) {
                                if (t[i].id == selectFreightId) {
                                    t[i].rate40 = event.target.value;
                                }
                            }
                            setFrieghtChargesTemp(t)
                            //setFrieghtChargesitem(event, 'rate40', index)
                        }}
                        label='Rate40'
                        type='number'
                    />
                </TableCell>
                {/* <TableCell style={{ padding: '8px' }}>
                                            <TextField
                                                lg={12}
                                                value={selectFreightId.min}
                                                onChange={function (event) {
                                                    setFrieghtChargesitem(event, 'min', index)
                                                }}
                                                label='Min'
                                                type='number'
                                            />
                                        </TableCell>*/}
                <TableCell style={{ padding: '8px' }}>
                    <TextField
                        lg={12}
                        value={frieghtChargesTemp.find(u => u.id === selectFreightId)?.remark}
                        onChange={function (event) {
                            var t = JSON.parse(JSON.stringify(frieghtChargesTemp))
                            for (var i = 0; i < t.length; i++) {
                                if (t[i].id == selectFreightId) {
                                    t[i].remark = event.target.value;
                                }
                            }
                            setFrieghtChargesTemp(t)
                            //setFrieghtChargesitem(event, 'remark', index)
                        }}
                        label='Remark'
                    />
                </TableCell>
                <TableCell style={{ padding: '8px' }}>
                    <Button variant={'contained'} sx={{ mr: 1, ml: 0, mt: 2, mb: 3 }} onClick={function () {
                        var t = [...frieghtCharges]
                        for (var i = 0; i < t.length; i++) {

                            if (t[i].name == frieghtChargesTemp.find(u => u.id === selectFreightId).name) {
                                t[i] = frieghtChargesTemp.find(u => u.id === selectFreightId);
                            }
                        }
                        setFrieghtCharges(t)


                        var t1 = [];
                        for (var i = 0; i < t.length; i++) {
                            if (!t[i].rate20 && !t[i].rate40) {
                                var tObj = JSON.parse(JSON.stringify(t[i]))
                                tObj.id = i + 1;
                                tObj.currency = listOfcurrency.find(u => u.id === tObj.currency)?.id
                                t1.push(tObj)
                            }
                        }
                        setFrieghtChargesTemp(t1)
                        setselectFreightId(t1[0].id)
                    }}>
                        Add
                    </Button>
                </TableCell>
            </TableRow>

        );
};

export default RowSelectOption;
