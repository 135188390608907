import { createSlice } from '@reduxjs/toolkit';

import {paginationArr } from '../../constants/global-constants';
export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        admin: {
            data: {
                session: "",
                user: {
                    adminName: "",
                    email: "",
                    firstName: "",
                    id: 0,
                    lastName: "",
                    nickName: "",
                    password: "",
                    permissions: {},
                    phone: "",
                    status: 1,
                    type: 1
                }
            }
        },
        roles: [{ id: 1, label: 'Admin', name: 'Admin', }, { id: 2, label: 'Manager', name: 'Manager' }],
        adminList: [],
        users: [],
        warehouseList: [],
        discriptionsList: [],
        airTerminalList: [],
        senderList: [],
        carrierPrefixList: [],
        portOfLodingList: [],
        agentCodeList: [],
        countryOfImportList: [],
        parcelList: [],
        manifestList: [],
        currentPage:"",
        bMenu:false,
        paginationData: {
            page: 0,
            size: paginationArr[1],
            parcelCount: 0,
        },
        imageScanData: {
            output: {
                scan_output: {
                    data: {
                        non_members_found: []
                    },
                    address: {
                        sender_address: {
                        },
                        receiver_address: {
                        },
                    },
                    courier_info: {
                    }
                }
            }
        }
    },
    reducers: {
        resetAdminState: state => {
            state.admin.data.user = {
                adminName: "",
                email: "",
                firstName: "",
                id: 0,
                lastName: "",
                nickName: "",
                password: "",
                permissions: {},
                phone: "",
                status: 1,
                type: 1
            };
            state.admin.data.session = "";
        },
        onPageChange: (state, { payload }) => {
            state.paginationData.page = payload;
        },
        onSizeChange: (state, { payload }) => {
            state.paginationData.size = payload;
        },
        onParcelCountChange: (state, { payload }) => {
            state.paginationData.parcelCount = payload;
        },
        setMenu: (state, { payload }) => {
            state.bMenu = payload;
        },
        setSenderList: (state, { payload }) => {
            state.senderList = payload;
        },
        setImageScanData: (state, { payload }) => {
            state.imageScanData.output.scan_output.data.non_members_found = payload.output.scan_output.data.non_members_found;

            if (payload.output.scan_output.address.sender_address)
                state.imageScanData.output.scan_output.address.sender_address = payload.output.scan_output.address.sender_address;

            if (payload.output.scan_output.address.receiver_address)
                state.imageScanData.output.scan_output.address.receiver_address = payload.output.scan_output.address.receiver_address;

            state.imageScanData.output.scan_output.courier_info = payload.output.scan_output.courier_info;
        },
        onUsersChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.users.length; i++) {
                if (payload.id ===state.users[i].id) {
                    bUpdated = true;
                    state.users[i] = payload;
                }
            }
            if (!bUpdated) {
                state.users.push(payload);
            }
        },
        onAdminChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.adminList.length; i++) {
                if (payload.id ===state.adminList[i].id) {
                    bUpdated = true;
                    state.adminList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.adminList.push(payload);
            }
        },
        onWarehouseChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.warehouseList.length; i++) {
                if (payload.id ===state.warehouseList[i].id) {
                    bUpdated = true;
                    state.warehouseList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.warehouseList.push(payload);
            }
        },
        deleteManifest: (state, { payload }) => {
            for (var i = 0; i < state.manifestList.length; i++) {
                if (payload.id === state.manifestList[i].id) {
                    state.manifestList.splice(i, 1);
                }
            }
        },
        deleteDiscription: (state, { payload }) => {
            for (var i = 0; i < state.discriptionsList.length; i++) {
                if (payload.id ===state.discriptionsList[i].id) {
                    state.discriptionsList.splice(i, 1);
                }
            }
        },
        deleteSender: (state, { payload }) => {
            for (var i = 0; i < state.senderList.length; i++) {
                if (payload.id ===state.senderList[i].id) {
                    state.senderList.splice(i, 1);
                }
            }
        },
        deleteCountryOfImport: (state, { payload }) => {
            for (var i = 0; i < state.countryOfImportList.length; i++) {
                if (payload.id ===state.countryOfImportList[i].id) {
                    state.countryOfImportList.splice(i, 1);
                }
            }
        },
        deleteCarrierPrefix: (state, { payload }) => {
            for (var i = 0; i < state.carrierPrefixList.length; i++) {
                if (payload.id ===state.carrierPrefixList[i].id) {
                    state.carrierPrefixList.splice(i, 1);
                }
            }
        },
        deletePortOfLoding: (state, { payload }) => {
            for (var i = 0; i < state.portOfLodingList.length; i++) {
                if (payload.id ===state.portOfLodingList[i].id) {
                    state.portOfLodingList.splice(i, 1);
                }
            }
        },
        onCarrierPrefixChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.carrierPrefixList.length; i++) {
                if (payload.id ===state.carrierPrefixList[i].id) {
                    bUpdated = true;
                    state.carrierPrefixList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.carrierPrefixList.push(payload);
            }
        },
        onManifestChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.manifestList.length; i++) {
                if (payload.id ===state.manifestList[i].id) {
                    bUpdated = true;
                    state.manifestList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.manifestList.push(payload);
            }
        },
        onPortOfLodingChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.portOfLodingList.length; i++) {
                if (payload.id ===state.portOfLodingList[i].id) {
                    bUpdated = true;
                    state.portOfLodingList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.portOfLodingList.push(payload);
            }
        },
        onDiscriptionsChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.discriptionsList.length; i++) {
                if (payload.id ===state.discriptionsList[i].id) {
                    bUpdated = true;
                    state.discriptionsList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.discriptionsList.push(payload);
            }
        },
        onAgentCodeChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.agentCodeList.length; i++) {
                if (payload.id ===state.agentCodeList[i].id) {
                    bUpdated = true;
                    state.agentCodeList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.agentCodeList.push(payload);
            }
        },
        emptyParcelList: (state, { payload }) => {
            state.parcelList = [];
        },
        onParcelListChange: (state, { payload }) => {
            
            var bUpdated = false;
            for (var i = 0; i < state.parcelList.length; i++) {
                if (payload.id ===state.parcelList[i].id) {
                    bUpdated = true;
                    state.parcelList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.parcelList.push(payload);
            }
        },
        onAirTerminalChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.airTerminalList.length; i++) {
                if (payload.id ===state.airTerminalList[i].id) {
                    bUpdated = true;
                    state.airTerminalList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.airTerminalList.push(payload);
            }
        },
        onCountryOfImportChange: (state, { payload }) => {
            var bUpdated = false;
            for (var i = 0; i < state.countryOfImportList.length; i++) {
                if (payload.id ===state.countryOfImportList[i].id) {
                    bUpdated = true;
                    state.countryOfImportList[i] = payload;
                }
            }
            if (!bUpdated) {
                state.countryOfImportList.push(payload);
            }
        }
    },
});
// Action creators are generated for each case reducer function
export const { setMenu, setSenderList, setImageScanData, resetAdminState, onPageChange, onParcelCountChange, onSizeChange, emptyParcelList, onParcelListChange, onAdminChange, onUsersChange, onWarehouseChange, onCarrierPrefixChange, onDiscriptionsChange, onPortOfLodingChange, onCountryOfImportChange, onManifestChange, onAirTerminalChange, onAgentCodeChange, deleteDiscription, deleteManifest, deleteCountryOfImport, deletePortOfLoding, deleteCarrierPrefix, deleteSender } =
    adminSlice.actions;

export default adminSlice.reducer;
