import { useState, useCallback, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table, TableHead, TableContainer, TableBody, TableCell, TableRow, Typography, Grid, Button, Checkbox, FormControlLabel } from '@mui/material';
import { SelectField, Snackbar, TextField, MultilineField } from '../../style-guide';
import { sendRequest, REQUEST_ACTIONS } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { FaAngleLeft, FaPlus } from "react-icons/fa";

import { ShipmentDetailsRow } from './ShipmentDetailsRow';
export const ShipmentDetails = props => {
    const dispatch = useDispatch();
    const { listOfKgFactor } = useSelector(state => state.parameter);
    const { roles, paginationData, warehouseList, senderList, discriptionsList } = useSelector(state => state.admin);
    const { admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const [costShipmentDetails, setCostShipmentDetails] = useState(props.shipmentDetails ? props.shipmentDetails : []);
    const [costShipmentDetails1, setCostShipmentDetails1] = useState(props.shipmentDetails ? props.shipmentDetails : []);
    const [bLoad, setBLoad] = useState(false);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const addShipmentRec = function () {
        if (costShipmentDetails.length > 0) {
            if (costShipmentDetails[costShipmentDetails.length - 1].length == null ||
                costShipmentDetails[costShipmentDetails.length - 1].width == null ||
                costShipmentDetails[costShipmentDetails.length - 1].height == null ||
                costShipmentDetails[costShipmentDetails.length - 1].weight == null)
                return;
        }

        var tObj = {
            pieces: 0,
            length: null,
            width: null,
            height: null,
            weight: null,
        }
        var t = []
        for (var i = 0; i < costShipmentDetails.length; i++) {
            var tObj2 = JSON.parse(JSON.stringify(costShipmentDetails[i]));
            t.push(tObj2);
        }
        t.push(tObj);
        setCostShipmentDetails(t)
        setCostShipmentDetails1(t)
        props.setCostShipmentDetails(t)
    }

    const removeShipmentRec = function (index) {
        var t = []
        setCostShipmentDetails(t)
        props.setCostShipmentDetails(t)
        window.setTimeout(function () {
            var t = []
            for (var i = 0; i < costShipmentDetails1.length; i++) {
                var tObj = JSON.parse(JSON.stringify(costShipmentDetails1[i]));
                t.push(tObj);
            }
            t.splice(index, 1);
            setCostShipmentDetails(t)
            setCostShipmentDetails1(t)
            props.setCostShipmentDetails(t)
        }, 100)
    }
    const setDetails = function (index, val) {
        var t = JSON.parse(JSON.stringify([...costShipmentDetails]))
        /*for (var i = 0; i < costShipmentDetails.length; i++) {
            var tObj = JSON.parse(JSON.stringify(costShipmentDetails[i]));
            t.push(tObj);
        }*/
        t[index] = val;
        setCostShipmentDetails(t)
        setCostShipmentDetails1(t)
        props.setCostShipmentDetails(t)

        //props.onHandleOpenModalDimention(val);
    }
    useEffect(() => {
        if (!bLoad) {
            setBLoad(true);
            //addShipmentRec();
        }
    }, [dispatch, failedCallback]);
    const getTotalCostSum = function () {
        var tot = 0;
        for (var i = 0; i < costShipmentDetails.length; i++) {
            tot = tot + costShipmentDetails[i].weight * costShipmentDetails[i].pieces;
        }
        return Number(Number(props.valueData?.manualWeight ? props.valueData?.manualWeight : tot).toFixed(2))
        //return tot;
    }
    const getTotalCostVolume = function () {
        var tot = 0;
        for (var i = 0; i < costShipmentDetails.length; i++) {
            tot = tot + (costShipmentDetails[i].pieces * costShipmentDetails[i].length * costShipmentDetails[i].width * costShipmentDetails[i].height) / 1000000;
        }
        tot = Number(Number(props.valueData?.manualVolume ? props.valueData?.manualVolume : tot).toFixed(2))
        if (props?.shipmentType == 2) {
            if (tot) {
                tot = tot < 1 ? 1 : tot;
            }
        }
        return tot;
    }
    const getTotalCostChargeable = function () {
        var kfF = listOfKgFactor.find(u => u.shipmentType === 'Air')?.kg

        var totCharg = getTotalCostVolume() * kfF;
        if (totCharg < getTotalCostSum()) {
            totCharg = getTotalCostSum();
        }
        return Number(Number(totCharg).toFixed(2));
    }
    const getTotalCostWM = function () {
        var t_w_m = 0;
        for (var i = 0; i < costShipmentDetails.length; i++) {
            //tot = tot + costShipmentDetails[i].weight * costShipmentDetails[i].pieces;
            var t_v_m3 = costShipmentDetails[i].pieces * (costShipmentDetails[i].length * costShipmentDetails[i].width * costShipmentDetails[i].height) / 1000000;
            var t_w_kg = costShipmentDetails[i].weight * costShipmentDetails[i].pieces;
            var kfF = listOfKgFactor.find(u => u.shipmentType === 'Ocean')?.kg
            if (t_w_kg / kfF > t_v_m3) {
                t_w_m += t_w_kg / kfF
            } else {
                t_w_m += t_v_m3
            }

        }

        if (props?.shipmentType == 2) {
            if (t_w_m) {
                t_w_m = t_w_m < 1 ? 1 : t_w_m;
            }
        }
        return Number(Number(t_w_m).toFixed(2));
    }
    return (
        <Grid container item>
            <Grid container item justifyContent='flex-start' lg={3}>
                <Button variant='contained' onClick={addShipmentRec} style={{ margin: '9px', padding: '5px', minWidth: '28px !important', height: '28px', marginLeft: 12 }} className="charges_details_btn plus_btn">
                    <FaPlus />
                </Button>
                <Button variant='text' sx={{ mt: 0, mb: 2, ml: -1 }} style={{ marginLeft: -8, marginTop: 10, background: "white", fontWeight: "700" }} className="charges_label font_16">
                    Shipment Details
                </Button>
            </Grid>
            <Grid container item justifyContent='flex-start' lg={12} style={{ marginTop: -32, marginLeft: 0, borderRadius: 5 }}>

                <Grid container item justifyContent='flex-start' lg={10} sx={{ mt: 1 }}>

                    {<TableContainer>
                        <Table>
                            <TableHead sx={{ '& > *': { border: 'unset', padding: '4px' } }}>
                                <TableRow >
                                    <TableCell>
                                        <Typography variant='h7'>

                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>

                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Pieces
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            L(cm)
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            W(cm)
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            H(cm)
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Weight per piece (kg)
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Total Weight (kg)
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Total Volume (m3)
                                        </Typography>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    costShipmentDetails.map((detail, index) => (
                                        <ShipmentDetailsRow manualWt={props?.valueData?.manualWeight || props?.valueData?.manualVolume ? true : false} onHandleOpenModalDimention={props.onHandleOpenModalDimention} costShipmentDetails={costShipmentDetails} data={detail} removeShipmentRec={removeShipmentRec} index={index} setDetails={setDetails} />
                                    ))

                                }
                                <TableRow sx={{ '& > *': { borderBottom: 'unset', padding: '4px' } }}>
                                    <TableCell style={{ border: 'none' }}>
                                        <Typography variant='h1'>

                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ border: 'none' }}>
                                        <Typography variant='h7'>

                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ border: 'none' }}>

                                    </TableCell>
                                    <TableCell style={{ border: 'none' }}>

                                    </TableCell>
                                    <TableCell style={{ border: 'none' }}>

                                    </TableCell>
                                    <TableCell style={{ border: 'none' }}>

                                    </TableCell>
                                    <TableCell style={{ width: 150, }}>

                                        <Typography variant='h7'>
                                            Sum - Total Weight
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: 120, }}>

                                        <Typography variant='h7'>
                                            Sum - Total W/M
                                        </Typography>
                                    </TableCell>
                                    {props.shipmentType == 1 && <TableCell style={{ width: 120, border: 'none' }}>

                                        <Typography variant='h7'>
                                            Chargeable Weight
                                        </Typography>
                                    </TableCell>
                                    }
                                    {props.shipmentType == 2 && <TableCell style={{ width: 120, border: 'none' }}>

                                        <Typography variant='h7'>
                                            Total W/M
                                        </Typography>
                                    </TableCell>
                                    }

                                </TableRow>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <TableCell style={{ border: 'none' }}>
                                        <Typography variant='h7'>

                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ border: 'none' }}>
                                        <Typography variant='h7'>

                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ border: 'none' }}>

                                    </TableCell>
                                    <TableCell style={{ border: 'none' }}>

                                    </TableCell>
                                    <TableCell style={{ border: 'none' }}>

                                    </TableCell>
                                    <TableCell style={{ border: 'none' }}>

                                    </TableCell>
                                    <TableCell >
                                        <TextField
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            value={getTotalCostSum()}
                                            disabled={true}
                                            label='Sum - Total Weight'
                                        />
                                    </TableCell>
                                    <TableCell >
                                        <TextField
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            value={getTotalCostVolume()}
                                            disabled={true}
                                            label='Sum - Total W/M'
                                        />
                                    </TableCell>

                                    {props.shipmentType == 1 && <TableCell >
                                        <TextField
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            value={getTotalCostChargeable()}
                                            disabled={true}
                                            label='Chargeable Weight'
                                        />
                                    </TableCell>
                                    }

                                    {props.shipmentType == 2 && <TableCell >
                                        <TextField
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            value={getTotalCostWM()}
                                            disabled={true}
                                            label='Total W/M'
                                        />
                                    </TableCell>
                                    }

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>}

                </Grid>
                <Grid container item justifyContent='center' lg={2} sx={{ mt: 5 }}>
                    <TableContainer style={{ justifyContent: 'center' }}>
                        <Table style={{ justifyContent: 'center', border: '1px solid', margin: '15px', width: '85%' }} lg={12}>

                            <TableBody style={{ justifyContent: 'center' }}>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <TableCell style={{ border: 'none' }}>
                                        <TextField
                                            lg={10}
                                            value={props?.valueData?.manualWeight}
                                            onChange={event => {
                                                props?.setValueData(prev => ({ ...prev, manualWeight: event.target.value}))
                                            }}
                                            label='Manual Weight'
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <TableCell style={{ border: 'none' }}>
                                        <TextField
                                            lg={10}
                                            value={props?.valueData?.manualVolume}
                                            onChange={event => {

                                                props?.setValueData(prev => ({ ...prev, manualVolume: event.target.value }))
                                            }}
                                            label='Manual Volume'
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {snackbar && (
                <Snackbar open={!!snackbar} message={snackbar.message} type={snackbar.type} onClose={onSnackbarHandleClose} />
            )}
        </Grid>

    );
};
export default ShipmentDetails;
