import { useState, useEffect, useCallback, useRef } from 'react';
import { useDraggable } from "react-use-draggable-scroll";
import { Link } from 'react-router-dom';
import XLSX from "xlsx";
import { v4 as uuidv4 } from 'uuid';
import { Button, Grid, Table, TextField, TableBody, TableContainer, Paper, TablePagination, Typography, TableCell, TableHead, TableRow } from '@mui/material';
import LclCostHeader from './LclCostHeader';
import LclCostRow from './LclCostRow';
import AddLclCost from './AddLclCost';
import { useSelector, useDispatch } from 'react-redux';
import { snackbarToggle } from '../../../reducers/snackbarSlicer';
import { onPageChange, onSizeChange } from '../../../reducers/admin-reducers/AdminSlicer';
import { onLclCostChange } from '../../../reducers/crm/crmSlice';
import { Dialog, Snackbar } from '../../../style-guide';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import {getCRMList, getLclExportImportAF,addLclExportImportAF } from '../../../reducers/requestHandler';

import { getLclExportImportAFsPaginationFilter, getLocalChargesByShipProd, deleteLclExportImportAF } from '../../../reducers/requestHandler';
import MenuItem from '@mui/material/MenuItem';
import { FaAngleLeft, FaPlus, FaCloudUploadAlt, FaPen} from "react-icons/fa";
import { listOfCostLandWeightLcl } from '../../../constants/global-constants';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { parcelStatusList, parcelSortBy, SHIPPING_STATUS_LIST, paginationArr } from '../../../constants/global-constants';
import "../cost.css";

export const LclCostTable = props => {
    //const { crmListFilter } = useSelector(state => state.parameter);
    const { crmList } = useSelector(state => state.crm);
    const { countryList, listOfPort, listOfAirPort, listOfMeasurementUnits, listOfcurrency, qsStatusList, listOfIncoterms, listOfServiceType } = useSelector(state => state.parameter);

    const ref = useRef();
    const { paginationData } = useSelector(state => state.admin);
    const { events } = useDraggable(ref);
    const dispatch = useDispatch();
    const { currentPage, admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(
        message => dispatch(snackbarToggle({ type: 'error', message })),
        [dispatch],
    );
    const setTruckingCompany = (text) => {
        setTruckingCompany1(text);
        if (text) {
            var t = airImportExportTemp1.filter(item => item.params?.TRUCKER_NAME?.includes(text));
            setLclImportExportTemp(t);
        } else
            setLclImportExportTemp(airImportExportTemp1);
    }
    const setLclImportExport = (data) => {
        setLclImportExportTemp(data)
        setLclImportExportTemp1(data)
    }
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorLclEl, setAnchorLclEl] = useState(null);
    const [anchorFclEl, setAnchorFclEl] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [bSettingOpen, setSettingOpen] = useState(false);
    const [truckingCompany, setTruckingCompany1] = useState(0);
    const [airImportExportTemp1, setLclImportExportTemp1] = useState(false);
    const [airImportExport, setLclImportExportTemp] = useState(false);
    const [costChanrges, setCostCharges] = useState([]);
    const open = Boolean(anchorEl);
    const openLcl = Boolean(anchorLclEl);
    const openFcl = Boolean(anchorFclEl);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => {
        setOpenOrderModal(true);
    };
    const handleChange = (event) => {
    };
    const onHandleCancelModal = () => {
        setLclImportExport([])
        getLclCostData()
        setOpenOrderModal(false)
    };
    const isAnySelectedForCopy = (event) => {
        var numSelected = 0;
        for (var i = 0; i < airImportExport.length; i++) {
            if (airImportExport[i].bSelected) {
                numSelected++
            }
        }
        return numSelected == 1;
    };

    const getSelectedforcopy = () => {
        for (var i = 0; i < airImportExport.length; i++) {
            if (airImportExport[i].bSelected) {
                return airImportExport[i];
            }
        }
    }
    const [crmsForMail, setcrmsForMail] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const [openOrderModalDelete, setOpenOrderModalDelete] = useState(false);
    const onHandleOpenModalDelete = () => {
        setOpenOrderModalDelete(true);
    };
    const onHandleCancelModalDelete = () => setOpenOrderModalDelete(false);
    const handleClick = event => {
        setSettingOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleLclClick = event => {
        setSettingOpen(true);
        setAnchorLclEl(event.currentTarget);
    };
    const handleFclClick = event => {
        setSettingOpen(true);
        setAnchorFclEl(event.currentTarget);
    };
    const handleClose = () => {
        setSettingOpen(false);
        setAnchorEl(null);
        setAnchorLclEl(null);
        setAnchorFclEl(null);
    };

    const setSelectedLclCost = (id, bSelected) => {
        var t = []
        for (var i = 0; i < airImportExport.length; i++) {
            var tt = { ...airImportExport[i] }
            if (tt.id == id) {
                tt.bSelected = bSelected;
            }
            t.push(tt)
        }
        //setLclImportExport([])
        window.setTimeout(function () {
            setLclImportExport(t)
        }, 0);

    }

    const deSelectAllLclCost = () => {
        var t = []
        for (var i = 0; i < airImportExport.length; i++) {
            var tt = { ...airImportExport[i] }
            tt.bSelected = false;
            t.push(tt)
        }
        setLclImportExport([])
        window.setTimeout(function () {
            setLclImportExport(t)
        }, 0);

    }
    const selectAllLclCost = () => {
        var t = []
        for (var i = 0; i < airImportExport.length; i++) {
            var tt = { ...airImportExport[i] }
            tt.bSelected = true;
            t.push(tt)
        }
        setLclImportExport([])
        window.setTimeout(function () {
            setLclImportExport(t)
        }, 0);

    }
    const onPageHandleChange = (_, newPage) => {
        dispatch(onPageChange(newPage))
    };
    const onRowsPerPageHandleChange = event => {
        dispatch(onSizeChange(+event.target.value))
        dispatch(onPageChange(0))
    };
    const [listOfCostLandWeightLcl1, setListOfCostLandWeightLcl] = useState(listOfCostLandWeightLcl);
    const [lclCostData, setLclCostData] = useState({
        crmId: "",
        countryId: "",
        min: "",
        zip: "",
        portAid: "",
        portLid: "",
        tt: "",
        clAgent: "",
        rateList: [""],
        weightList: [""],
        weight: 0,
        rate: 0,
    });
    const getLclCostData = () => {
        var rate = [];
        lclCostData.weightList = [];
        if (lclCostData.weight == 0)
            lclCostData.weightList.push("")
        else 
            lclCostData.weightList.push(lclCostData.weight+"")

        lclCostData.rateList = [];

        /*if (lclCostData.countryId!="") {
            if (lclCostData.countryId.length < 3) {
                return;
            }
        }*/
        if (props.data?.id == 3) {
            for (var i = 0; i < listOfCostLandWeightLcl1.length; i++) {
                if (listOfCostLandWeightLcl1[i].rate == 0)
                    rate.push("")
                else {
                    rate.push(listOfCostLandWeightLcl1[i].rate+"")
                }
            }

        } else {
            rate.push(lclCostData.rate)
        }
        var data1 = {
            numItems: paginationData.size,
            pageNum: paginationData.page + 1,
            importExportType: props.data?.id,
            crmId: lclCostData.crmId,
            countryId: lclCostData.countryId,
            min: lclCostData.min,
            zip: lclCostData.zip,
            portAid: lclCostData.portAid,
            portLid: lclCostData.portLid,
            tt: lclCostData.tt,
            clAgent: lclCostData.clAgent,
            rateList: rate,
            weightList: lclCostData.weightList
        }
        setLclImportExport([])
        if (props.data?.id && props.data?.id != 4 && props.data?.id != 5) {
            getLclExportImportAFsPaginationFilter(dispatch, admin, data1, function (itms) {
                var data = [];
                for (var i = 0; i < itms.length; i++) {
                    var d1 = itms[i];
                    d1.COLOADER = crmList.find(u => u.id === d1.crmId)?.companyName || '-';
                    d1.COUNTRY = countryList.find(u => u.id === d1.countryId)?.name || '-';
                    d1.PORTL = listOfPort.find(u => u.id === d1.portLid)?.name || '-';
                    d1.PORTA = listOfPort.find(u => u.id === d1.portAid)?.name || '-';
                    data.push(d1);
                }
                setLclImportExport(data)
                setLoadingData(false);
           // getLocalChargesByShipProd(dispatch, admin, data1, function (da) {
                var tData = []

                if (props.data?.id == 1) {
                    var t = [{ name: 'Due Carrier', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'B/L Fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Handling fee', unitOfMeasure: 2, currency: 0, rate: 0, min: 0 },
                    { name: 'Port fees', unitOfMeasure: 3, currency: 1, rate: 0, min: 0 },
                    { name: 'Global Gate fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'AMS', unitOfMeasure: 2, currency: 4, rate: 0, min: 0 },
                    { name: 'OTHC', unitOfMeasure: 3, currency: 4, rate: 0, min: 0 },
                    { name: 'INLAND FREIGHT', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'ISPS', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'VGM', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'PSS', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Stuffing', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 },
                    { name: 'Warehouse Handeling Fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Export Customs', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Collection Fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Exw Charges', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'COO', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Communication Fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Congestion', unitOfMeasure: 3, currency: 1, rate: 0, min: 0 },
                    { name: 'Delivery Order', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Eur1', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Imo Handeling', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }
                    ];
                    setCostCharges(t)
                }
                if (props.data?.id == 2) {
                    var t = [{ name: 'Due Carrier', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'B/L Fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Handling fee', unitOfMeasure: 2, currency: 0, rate: 0, min: 0 },
                    { name: 'Port fees', unitOfMeasure: 3, currency: 1, rate: 0, min: 0 },
                    { name: 'Global Gate fee', unitOfMeasure: 3, currency: 1, rate: 0, min: 0 },
                    { name: 'AMS', unitOfMeasure: 2, currency: 4, rate: 0, min: 0 },
                    { name: 'OTHC', unitOfMeasure: 3, currency: 4, rate: 0, min: 0 },
                    { name: 'INLAND FREIGHT', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'ISPS', unitOfMeasure: 3, currency: 1, rate: 0, min: 0 },
                    { name: 'VGM', unitOfMeasure: 3, currency: 1, rate: 0, min: 0 },
                    { name: 'PSS', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Stuffing', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 },
                    { name: 'PORT FEES', unitOfMeasure: 3, currency: 1, rate: 0, min: 0 },
                    { name: 'Warehouse Handeling Fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Export Customs', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Collection Fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Exw Charges', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 },
                    { name: 'COO', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Communication Fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Congestion', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Delivery Order', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
                    { name: 'Eur1', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 },
                    { name: 'Imo Handeling', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }
                    ];
                    setCostCharges(t)
                }
                //setCostCharges(tData)
            //});
            });


            


        } else if (props.data?.id && props.data?.id == 4 || props.data?.id == 5) {
            data1 = {
                "importExportType": props.data?.id,
                "shipmentTypeId": 2
            }
            getLocalChargesByShipProd(dispatch, admin, data1, function (da) {
                var tData = [...da]

                if (props.data?.id == 5 && tData.length) {
                    var t = [{ name: 'Due Carrier', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'B/L Fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'Handling fee', unitOfMeasure: 2, currency: 0, rate: 0, min: 0 }, { name: 'Port fees', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'Global Gate fee', unitOfMeasure: 3, currency: 1, rate: 0, min: 0 }, { name: 'AMS', unitOfMeasure: 2, currency: 4, rate: 0, min: 0 }, { name: 'OTHC', unitOfMeasure: 3, currency: 4, rate: 0, min: 0 }, { name: 'INLAND FREIGHT', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'ISPS', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'VGM', unitOfMeasure: 1, currency: 1, rate: 0, min: 0 }, { name: 'PSS', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'Stuffing', unitOfMeasure: 3, currency: 1, rate: 0, min: 0 }, { name: 'PORT FEES', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'Warehouse Handeling Fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'Export Customs', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'Collection Fee', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'Exw Charges', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'COO', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'Communication Fee', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'Congestion', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'Delivery Order', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'Eur1', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'Imo Handeling', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }]
                    for (var i = 0; i < t.length; i++) {
                        var item = da.find(u => u.product === t[i].name)
                        if (!item) {
                            var tItem = { ...da[0], id: null, product: t[i].name, rate: t[i].rate, min: t[i].min, currency: t[i].currency, unitOfMeasure: t[i].unitOfMeasure }
                            tData.push(tItem)
                        } else {
                            tData[i] = item;
                        }
                    }
                }
                if (props.data?.id == 4 && tData.length) {
                    var t = [{ name: 'Due Carrier', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'B/L Fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'Handling fee', unitOfMeasure: 2, currency: 0, rate: 0, min: 0 }, { name: 'Port fees', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'Global Gate fee', unitOfMeasure: 3, currency: 1, rate: 0, min: 0 }, { name: 'AMS', unitOfMeasure: 2, currency: 4, rate: 0, min: 0 }, { name: 'OTHC', unitOfMeasure: 3, currency: 4, rate: 0, min: 0 }, { name: 'INLAND FREIGHT', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'ISPS', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'VGM', unitOfMeasure: 1, currency: 1, rate: 0, min: 0 }, { name: 'PSS', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'Stuffing', unitOfMeasure: 3, currency: 1, rate: 0, min: 0 }, { name: 'PORT FEES', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'Warehouse Handeling Fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'Export Customs', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 }, { name: 'Collection Fee', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'Exw Charges', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'COO', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'Communication Fee', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'Congestion', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'Delivery Order', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'Eur1', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }, { name: 'Imo Handeling', unitOfMeasure: 5, currency: 1, rate: 0, min: 0 }]
                    if (tData.length != t.length) {
                        tData = []
                    }
                    for (var i = 0; i < t.length; i++) {
                        var item = da.find(u => u.product === t[i].name)
                        if (!item) {
                            var tItem = { ...da[0], id: null, product: t[i].name, rate: t[i].rate, min: t[i].min, currency: t[i].currency, unitOfMeasure: t[i].unitOfMeasure }
                            tData.push(tItem)
                        } else {
                            tData[i] = item;
                        }
                    }
                }
                var data = [];
                for (var i = 0; i < tData.length; i++) {
                    var d1 = tData[i];
                    d1.COUNTRY = countryList.find(u => u.id === d1.countryId)?.name || '-';
                    d1.PORTA = listOfPort.find(u => u.id === d1.portAid)?.name || '-';
                    data.push(d1);
                }
                setLoadingData(false);
                setLclImportExport(data)
                //setLclImportExport(tData)
            });
        }
    }
    /*if (!isLoaded && admin.data.session) {
        getLclCostData();
        setIsLoaded(true);
    }*/

    useEffect(() => {
        if (admin.data.session) {
            //setLoadingData(true);
            getLclCostData();
        }
        //getAirCostData();
    }, [paginationData.page, paginationData.size, lclCostData]);
    useEffect(() => {
        if (!crmList.length) {
            getCRMList(dispatch, admin);
        }
        //getAirCostData();
    }, [crmList]);
    /*useEffect(() => {
        getLclCostData();
    }, [paginationData.page, paginationData.size, dispatch, failedCallback, lclCostData]);*/


    const [selectedData, setSelectedData] = useState({});
    const changeHandler = (event) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            console.log(rABS, wb);
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            var keys = data[0];
            var XL_row_object = []
            for (var i = 1; i < data.length; i++) {
                var t = {}
                keys.forEach(function (key, index) {
                    if (data[i][index])
                        t[key] = data[i][index];
                })
                if (data[i].length > 0) {
                    var objToSave = {
                        importExportType: props.data.id,
                    }
                    XL_row_object.push(t)
                    if (props.data.id == 2 || props.data.id == 1) {
                        var rate = [];
                        if (t['Co Loader']) {
                            objToSave.crmId = crmList.find(u => u.companyName.toLowerCase() === t['Co Loader'].toLowerCase())?.id
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (props.data.id == 2)
                            if (t['Pol']) {
                                objToSave.portLid = listOfPort.find(u => u.name.toLowerCase() === t['Pol'].toLowerCase())?.id
                            } else {
                                alert("Please Select apropriate file.");
                                return;
                            }

                        if (t['Poa']) {
                            objToSave.portAid = listOfPort.find(u => u.name.toLowerCase() === t['Poa'].toLowerCase())?.id
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['Country']) {
                            objToSave.countryId = countryList.find(u => u.name.toLowerCase() === t['Country'].toLowerCase())?.id
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['Rate']) {
                            rate.push(Number(t['Rate']));
                            
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['Agent']) {
                            objToSave.clAgent = t['Agent']                            
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['T/T']) {
                            objToSave.tt = t['T/T']
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['Valid Date']) {
                            var date = new Date(Math.round((t['Valid Date'] - (25567 + 1)) * 86400 * 1000));
                            var converted_date = date.toISOString().split('T')[0];

                            objToSave.validDate = date.getTime()
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        objToSave.rate = rate;
                        addLclExportImportAF(dispatch, snackbarToggle, objToSave, admin, function () { })
                    } else {
                        var temp = ["44", "99", "199", "299", "399", "499", "599", "999", "1999"];
                        var getIndexOfWeight = function (w) {
                            var ind = 0;
                            var ind1 = temp.length - 1;
                            for (var k = 0; k < temp.length; k++) {
                                var element1 = temp[k];
                                if (t[element1]) {
                                    if (w == temp[k]) {
                                        ind1 = k;
                                        break;
                                    }
                                    ind++;
                                } else {
                                    alert("Please Select apropriate file.");
                                    return;
                                }
                            }
                            return ind1;
                        }

                        var lastAdded = 0;
                        var tempLand = []
                        listOfCostLandWeightLcl1.forEach(function (element11) {
                            tempLand.push(JSON.parse(JSON.stringify(element11)))
                        });
                        for (var j = 0; j < tempLand.length; j++) {
                            var tI = getIndexOfWeight(tempLand[j].weight);
                            if (tI != -1) {
                                lastAdded = tempLand[j].rate = t[temp[tI]];
                            } else {
                                tempLand[j].rate = lastAdded;
                            }
                        }
                        var weight = [];
                        var rate = [];
                        for (var a = 0; a < tempLand.length; a++) {
                            weight.push(tempLand[a].weight + "");
                            rate.push(tempLand[a].rate + "");
                        }
                        objToSave.weight = weight
                        objToSave.rate = rate
                        var params = {};
                        params.TRUCKER_NAME = "";
                        if (t['TRUCKER NAME']) {
                            params.TRUCKER_NAME = t['TRUCKER NAME'];
                            objToSave.params = params;
                        }
                        if (t['PORT OF DEP']) {
                            objToSave.portAid = listOfPort.find(u => u.name === t['PORT OF DEP'])?.id
                            objToSave.portLid = listOfPort.find(u => u.name === t['PORT OF DEP'])?.id
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['ORG']) {
                            objToSave.countryId = countryList.find(u => u.name.toLowerCase() === t['ORG'].toLowerCase())?.id
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['ZIP']) {
                            objToSave.zip = t['ZIP']
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        if (t['MIN']) {
                            objToSave.min = t['MIN']
                        } else {
                            alert("Please Select apropriate file.");
                            return;
                        }
                        addLclExportImportAF(dispatch, snackbarToggle, objToSave, admin, function () { })

                    }
                }

            }
            console.log(XL_row_object)
            window.setTimeout(function () {
                getLclCostData()
            }, 1500)

            /* Update state */
            //var cols= make_cols(ws["!ref"])
            //this.setState({ data: data, cols: make_cols(ws["!ref"]) });
        };
        var file = event.target.files[0];
        if (file) {
            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        }
    };
    const jsonToExcel = function (data, name, header) {
        //{ skipHeader: true }
        var ws = XLSX.utils.json_to_sheet(data, header);

        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "All");

        /* write workbook and force a download */
        XLSX.writeFile(wb, name + ".xlsx");
    }
    const exportCsv = () => {
        var data_main = [];
        var header = ["TruckingCompany", "Country", "Port", "ZIP", "MIN", "Rate44", "Rate99", "Rate199", "Rate299", "Rate399", "Rate499", "Rate599", "Rate999", "Rate1999"]
        //data_main.push(header);
        for (var i = 0; i < airImportExportTemp1.length; i++) {
            var data = {
                "TruckingCompany": airImportExportTemp1[i].params?.TRUCKER_NAME,
                "Country": airImportExportTemp1[i].COUNTRY,
                "Port": airImportExportTemp1[i].PORTA,
                "ZIP": airImportExportTemp1[i].zip,
                "MIN": airImportExportTemp1[i].min,
                "Rate44": airImportExportTemp1[i].rateList[0],
                "Rate99": airImportExportTemp1[i].rateList[1],
                "Rate199": airImportExportTemp1[i].rateList[2],
                "Rate299": airImportExportTemp1[i].rateList[3],
                "Rate399": airImportExportTemp1[i].rateList[4],
                "Rate499": airImportExportTemp1[i].rateList[5],
                "Rate599": airImportExportTemp1[i].rateList[6],
                "Rate999": airImportExportTemp1[i].rateList[7],
                "Rate1999": airImportExportTemp1[i].rateList[8],
            };
            data_main.push(data);
        }
        jsonToExcel(data_main, "LCL Land Transport", header);
    }
    const handleSubmission = () => {
        var fileInput = document.getElementById('file');
        var element = document.getElementById("file_container");
        if (fileInput) {
            element.removeChild(fileInput);
        }
        var x = document.createElement("INPUT");
        x.setAttribute("type", "file");
        x.setAttribute("id", 'file');
        x.style.display = "none";
        element.appendChild(x);

        document.getElementById("file").addEventListener("change", changeHandler, true);
        document.getElementById('file').click();

    };
    const [over, setOnOver] = useState(0);
    const isAnySelected = (event) => {
        for (var i = 0; i < airImportExport.length; i++) {
            if (airImportExport[i].bSelected) {

                return true
            }
        }
    };
    return (
        <>{<Grid container item xs={12} justifyContent='end' id="file_container">
            
            {openOrderModal && (
                <Dialog maxWidth='md' open={openOrderModal} title={props.data.lable} onHandleCancel={onHandleCancelModal}>
                    <AddLclCost callBack={getLclCostData} typeData={props.data} data={selectedData} importExportType={props.data?.id} onHandleCancel={() => onHandleCancelModal()} id={null} charges={props.data?.id != 4 && props.data?.id != 5 ? costChanrges : airImportExport} setAirImportExport={props.data?.id != 4 && props.data?.id != 5 ? setCostCharges:  setLclImportExport} />
                </Dialog>
            )}

            {openOrderModalDelete && (
                <Dialog maxWidth='md' open={openOrderModalDelete} title={props.data.lable} onHandleCancel={onHandleCancelModalDelete}>
                    <Grid container item>  Are you sure, you want to delete?</Grid>
                    <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {

                        for (var i = 0; i < airImportExport.length; i++) {
                            if (airImportExport[i].bSelected) {
                               // alert(airImportExport[i].id)
                                deleteLclExportImportAF(dispatch, snackbarToggle, { id: airImportExport[i].id }, admin, getLclCostData)
                            }
                        }
                        onHandleCancelModalDelete()
                    }}>
                        Delete Selected
                    </Button>
                </Dialog>
            )}
            {(props.data?.id == 4 || props.data?.id == 5) && <Button variant='contained' sx={{ mt: -4, height: "4.5vh", mb: 1, mr: 2, background: "#F56C6C" }} onClick={function () { setSelectedData(null); onHandleOpenModal() }}>
                <FaPen style={{ marginRight: 5 ,marginTop:-2 }} /> Edit
            </Button>
            }
            {props.data?.id == 3 && <Button variant='contained' sx={{ mt: -4, height: "4.5vh", mb: 1, mr: 2, background: "#F56C6C" }} onClick={exportCsv}>
                Export CSV
            </Button>}
            {(props.data?.id == 1 || props.data?.id == 2 || props.data?.id == 3) &&<Button variant='contained' sx={{ mt: -4, height: "4.5vh", mb: 1, mr: 2, background: "#F56C6C" }} onClick={function () { setSelectedData(null); onHandleOpenModal() }}>
                <FaPlus style={{ marginRight: 5 }} />  Create
            </Button>
            }
            {(props.data?.id == 1 || props.data?.id == 2 || props.data?.id == 3) &&<Button variant='contained' sx={{ mt: -4, height: "4.5vh", mb: 1, mr: 2, background: (over == 0 ? '#85ce61 !important' : '#85ce61 !important') }} onClick={handleSubmission}>
                <FaCloudUploadAlt style={{ marginRight: 5 }} />Upload Csv
            </Button>
            }
            {props.data?.id == 1 && <Button variant='outlined' sx={{ mt: -4, height: "4.5vh", mb: 1, mr: 4, justifyContent: 'flex-end' }} onClick={function () { }}>
                <Link style={{ textDecoration: 'none', color: 'inherit', fontSize: 12 }} to="../../../../../files/lcl_export_of.xlsx" target="_blank" download>Template</Link>

            </Button>
            }
            {props.data?.id == 2 && <Button variant='outlined' sx={{ mt: -4, height: "4.5vh", mb: 1, mr: 4, justifyContent: 'flex-end' }} onClick={function () { }}>
                <Link style={{ textDecoration: 'none', color: 'inherit', fontSize: 12 }} to="../../../../../files/lcl_import_of.xlsx" target="_blank" download>Template</Link>

            </Button>
            }
            {props.data?.id == 3 && <Button variant='outlined' sx={{ mt: -4, height: "4.5vh", mb: 1, mr: 4, justifyContent: 'flex-end' }} onClick={function () { }}>
                <Link style={{ textDecoration: 'none', color: 'inherit', fontSize: 12 }} to="../../../../../files/lcl_land_transport.xlsx" target="_blank" download>Template</Link>

            </Button>
            }
            <Paper sx={{ width: '100%', overflow: 'auto' }} {...events} ref={ref}>
            {
                    <TableContainer sx={{ height: "calc(100vh - 170px)" }}>
                        <Table>
                            {loadingData && <TableHead><TableRow><TableCell xs={12} style={{ width: "100%", padding: '8px' }}> <Grid item container justifyContent="center"><div className="spinner-container1">
                                <div className="loading-spinner1"></div>
                            </div></Grid></TableCell></TableRow></TableHead>}
                            {!loadingData && <LclCostHeader truckingCompany={truckingCompany} setTruckingCompany={setTruckingCompany} lclCostData={lclCostData} setLclCostData={setLclCostData} setListOfCostLandWeightLcl={setListOfCostLandWeightLcl} id={props.data.id} />}

                            <TableBody>

                                
                            {airImportExport.length > 0 &&
                                airImportExport.map(crm => (
                                    <LclCostRow getLclCostData={getLclCostData} setSelectedLclCost={setSelectedLclCost} typeData={props.data} importExportType={props.data?.id} key={crm?.id || uuidv4()} id={props.data.id} data={crm} openDialog={function () {
                                        getLclExportImportAF({ id: crm.id}, admin, function (data) {

                                            setSelectedData(data?.data);
                                            onHandleOpenModal()
                                        })
                                        //onHandleOpenModal()
                                        
                                    }} />
                                ))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>

                }

                <Grid container item justifyContent="space-between">
                    <Grid container item justifyContent="flex-start" lg={8}>
                    <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => { selectAllLclCost() }}>
                        Select All
                    </Button>
                    <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => { deSelectAllLclCost() }}>
                        DeSelect All
                    </Button>
                    <Button disabled={!isAnySelected()} variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {

                        onHandleOpenModalDelete()
                    }}>
                        Delete Selected
                        </Button>
                        <Button disabled={!isAnySelectedForCopy()} variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={function () {
                            //setListOfCostLandWeightAir(listOfCostLandWeightAir);
                            var sData = JSON.parse(JSON.stringify(getSelectedforcopy()));
                            sData.id = null;
                            setSelectedData(sData);

                            onHandleOpenModal()
                        }}>
                            Copy
                        </Button>
                </Grid>
            {(props.data?.id && props.data?.id != 4 && props.data?.id != 5) && <TablePagination
                rowsPerPageOptions={paginationArr}
                component='div'
                count={paginationData.parcelCount}
                rowsPerPage={paginationData.size}
                page={paginationData.page}
                onPageChange={onPageHandleChange}
                onRowsPerPageChange={onRowsPerPageHandleChange}
                    />}
                </Grid>
            {snackbar && (
                <Snackbar
                    open={!!snackbar}
                    message={snackbar.message}
                    type={snackbar.type}
                    onClose={onSnackbarHandleClose}
                />
            )}
        </Paper>
        </Grid>}</>


    );
};
export default LclCostTable;
