import { useState, useEffect} from 'react';
import { TableCell, TableRow, Typography, Box, Button, Checkbox, Radio, FormControlLabel } from '@mui/material';
import { Dialog } from '../../style-guide';
import { useSelector, useDispatch } from 'react-redux';
import { addDesc, recieptWithBarCode } from '../Admins/AdminHandler'
import { onCrmChange, onEmptyCrm } from '../../reducers/crm/crmSlice';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import AddCrm from './AddCrm';
import { FaTrash, FaPen } from "react-icons/fa";
import { parcelStatusList, parcelSortBy, SHIPPING_STATUS_LIST } from '../../constants/global-constants';
import { getQuoatationType, deleteCRM } from '../../reducers/requestHandler';
import { getAdminList, getQsList, getCRMList } from '../../reducers/requestHandler';
const CrmRow = props => {
    const dispatch = useDispatch();
    const { crmList } = useSelector(state => state.crm);
    const { countryList, listOfFunctionTypes} = useSelector(state => state.parameter);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                companyName: props.data.companyName,
                countryId: props.data.countryId,
                functionId: props.data.functionId,
                address: props.data.address,
                freeText: props.data.freeText,
            }
            : {
                id: null,
                companyName: null,
                countryId: null,
                functionId: null,
                address: null,
                freeText: null,
            },
    );
    const { roles, adminList, users } = useSelector(state => state.admin);
    const { admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const [over, setOnOver] = useState(0);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const deleteCRMItem = () => {
        var temp = {
            id: value.id,
        }
        deleteCRM(dispatch, snackbarToggle, temp, admin);
        window.setTimeout(function () { props.getCrmData() }, 500)

    };
    const [isLoaded, setIsLoaded] = useState(false);
    function formatDate(newDate) {        
        const d = newDate
        const year = d.getFullYear()
        const date = d.getDate()
        const monthName = d.getMonth()+1
        const formatted =  `${date}/${monthName}/${year}`
        return formatted.toString()
    }
    useEffect(() => {
        if (!isLoaded && admin.data.session && crmList && crmList.length == 0) {
            //getCRMList(dispatch, admin);
            setIsLoaded(true);
        }
    }, [dispatch, value]);
    var t2 = [];
    const handleParams = (event) => {
    }
    return (
        <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
            <TableCell style={{ margin: 0, padding: '8px' }}>
                <Typography variant='h7' >
                    {value.id}
                </Typography>
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }}>
                <Typography variant='h7' >
                    {value.companyName}
                </Typography>
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }}>
                <Typography variant='h7' >
                    {countryList.find(u => u.id === value.countryId)?.name || '-'}
                </Typography>
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }}>
                <Typography variant='h7' >
                    {listOfFunctionTypes.find(u => u.id === value.functionId)?.lable || '-'}
                </Typography>
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }}>
                <Typography variant='h7' >
                    {value.address}
                </Typography>
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }}>
                <Typography variant='h7' >
                    {value.freeText}
                </Typography>
            </TableCell>
            <TableCell style={{ margin: 0, padding: '8px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button sx={{ mr: 1 }} onClick={onHandleOpenModal} variant='outlined'>
                        <FaPen/>
                    </Button>
                    <Button sx={{ mr: 1, background: (over == 0 ? '#F56C6C !important' : '#e5b6b6 !important'), color: 'white' }} onMouseOver={function () { setOnOver(1); }} onMouseLeave={function () { setOnOver(0); }} onClick={deleteCRMItem} variant='outlined'>
                        <FaTrash/>
                    </Button>
                    {openOrderModal && (
                        <Dialog open={openOrderModal} title={'Crm'} onHandleCancel={onHandleCancelModal}>
                            <AddCrm callback={props.callback}  setCrmFilter={props.setCrmFilter} data={props.data} onHandleCancel={onHandleCancelModal} id={null} />
                        </Dialog>
                    )}
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default CrmRow;
