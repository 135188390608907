import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Grid, Button, Typography, TableHead, TableCell, TableRow, Table, TableBody, TableContainer, Paper, TablePagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { setSelectedAdmin } from '../../reducers/QS/qsSlice';
import { setCommonFilterData, setQsStatusList } from '../../reducers/parameter/parameterSlice';
import { onAdminChange } from '../../reducers/admin-reducers/AdminSlicer';
import { Dialog, Snackbar, DateTimePicker, SelectField } from '../../style-guide';

import { snackbar1Toggle } from '../../reducers/snackbar1Slice';
import { setCurrentPage } from '../../reducers/session/sessionSlice';
import { getAdminList, getQsList, getCRMList} from '../../reducers/requestHandler';
import DonutChart from "react-donut-chart";
export const DashBoard = () => {
    const dispatch = useDispatch();
    const { crmList } = useSelector(state => state.crm);
    const { qsList } = useSelector(state => state.QS);
    const { commonFilterData, countryList, listOfcurrency, listOfAirPort, listOfPort, qsTypeList, qsStatusList, listOfIncoterms, listOfServiceType } = useSelector(state => state.parameter);

    const [reactDonutChartdata, setDonutChartdata] = new useState([]);
    const { adminList } = useSelector(state => state.admin);
    const [filteredAdminList, setFilteredAdminList] = useState(adminList);
    const { currentPage, admin } = useSelector(state => state.session);
    const { selectedAdmin } = useSelector(state => state.QS);
    function getMargin(value) {
        if (value.shipmentType != 3) {
            var total = Number(value.total) ? Number(value.total) : 0;
            var price = value.params?.originalPrice ? Number(value.params?.originalPrice) : total;
            /*var profit = total - price;
            var gMargin = profit / total;*/
            var gMargin = ((total / price) - 1) * 100
            if (gMargin) {
                return Number(gMargin.toFixed(2))
            }
        } 

        return 0
    } 
    function getMargin20(value) {
        if (value.shipmentType != 3) {
           
        } else {
            var total20 = Number(value.total20) ? Number(value.total20) : 0;
            var price20 = value.params?.originalPrice20 ? Number(value.params?.originalPrice20) : total20;
            var gMargin20 = ((total20 / price20) - 1) * 100
            if (gMargin20) {
                return Number(gMargin20.toFixed(2))
            }
        }

        return 0
    } 
    function getMargin40(value) {
        if (value.shipmentType != 3) {           
        } else {
            var total40 = Number(value.total40) ? Number(value.total40) : 0;
            var price40 = value.params?.originalPrice40 ? Number(value.params?.originalPrice40) : total40;
            var gMargin40 = ((total40 / price40) - 1) * 100
            if (gMargin40) {
                return Number(gMargin40.toFixed(2))
            }
        }

        return 0
    } 
    const getTotalAvgByUserLcl = function (usetId) {
        var margin = 0;
        var count = 0;
        qsList.forEach(element => {
            if (element.userId == usetId) {
                //console.log(element.shipmentType)                
                if (element.shipmentType == 2) {
                    var m = Number(element.params.margin ? element.params.margin : 0);
                    margin = margin + (m ? m : 0);
                    count = count + 1;
                }
            }
        });
        var avg = margin / count;
        if (!avg) {
            avg = 0;
        }
        return avg;
    }
    const getTotalAvgByUserAir = function (usetId) {
        var margin = 0;
        var count = 0;
        qsList.forEach(element => {
            if (element.userId == usetId) {
                //console.log(element.shipmentType)                
                if (element.shipmentType == 1) {
                    var m = Number(element.params.margin ? element.params.margin : 0);
                    margin = margin + (m ? m : 0);
                    count = count + 1;
                }
            }
        });
        var avg = margin / count;
        if (!avg) {
            avg = 0;
        }
        return avg;
    }
    const getTotalAvgByUserFcl = function (usetId) {
        var margin = 0;
        var count = 0;
        qsList.forEach(element => {
            if (element.userId == usetId) {
                //console.log(element.shipmentType)                
                if (element.shipmentType == 3) {
                    var m1 = Number((element.params.margin20 && element.params.margin20 != "NaN") ? element.params.margin20 : 0)
                    var m2 = Number((element.params.margin40 && element.params.margin40 != "NaN") ? element.params.margin40 : 0)
                    margin = margin + (((m1 ? m1 : 0) + (m2 ? m2 : 0)) / 2)
                    count = count + 1;
                }
            }
        });
        var avg = margin / count;
        if (!avg) {
            avg = 0;
        }
        return avg;
    }
    const getTotalAvgByUser = function (usetId) {
        var margin = 0;
        qsList.forEach(element => {
            if (element.userId == usetId) {
                //console.log(element.shipmentType)                
                if (element.shipmentType == 3) {
                    
                    var m1 = Number((element.params.margin20 && element.params.margin20 != "NaN") ? element.params.margin20 : 0)
                    var m2 = Number((element.params.margin40 && element.params.margin40 != "NaN") ? element.params.margin40 : 0)
                    margin = margin + (((m1 ? m1 : 0) + (m2 ? m2 : 0)) / 2)
                } else {
                    var m = Number(element.params.margin ? element.params.margin : 0);
                    margin = margin + (m ? m : 0);
                }
            }
        });
        return margin;
    }
    const getStatusCountByUser = function (usetId, status) {
        var count = 0;
        qsList.forEach(element => {
            if (element.userId == usetId && element.status == status) {
                count++;
                //temp.push(element)
            }
        });


        return count;
    }
    if (currentPage !== 'Dashboard') {

        dispatch(setCurrentPage("Dashboard"));
    }
    const onHandleHideAllItems = () => {

    }

    const navigate = useNavigate();

    const onHandleUserFilter = (userId, data) => {
        var t = {}
        if (!data)
            t = { ...commonFilterData }
        else
            t = { ...data }
        t.userId = [];
        var t1 = { ...adminList.find(u => u.id === userId) }
        t1.bSelected = true;
        t.userId.push(t1)
        dispatch(setCommonFilterData(t));

        var p = []
        var selected = [...adminList]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            if (tObj.id == userId) {
                tObj.bSelected = true
            }
            dispatch(onAdminChange(tObj));
        }
        navigate("/menu/qs")

    }
    const onHandleStatusFilter = (status) => {
        var t = { ...commonFilterData }
        t.status = [];
        var t1 = { ...qsStatusList.find(u => u.id === status) }
        t1.bSelected = true;
        t.status.push(t1)

        dispatch(setCommonFilterData(t));
        var p = []
        var selected = [...qsStatusList]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            if (tObj.id == status) {
                tObj.bSelected = true
            }
            p.push(tObj)
        }
        dispatch(setQsStatusList(p));
        //navigate("/menu/qs")
        onHandleUserFilter(selectedAdmin.id, t)

    }
    const setSelectedUser = (user) => {
        //console.log(user);
        dispatch(setSelectedAdmin(user));

        var data = []

        data.push({ label: "Prepared", value: getStatusCountByUser(selectedAdmin.id, 1), color: "#eaae39" });
        data.push({ label: "Pending", value: getStatusCountByUser(selectedAdmin.id, 4), color: "#2d9cdb" });
        data.push({ label: "Approved", value: getStatusCountByUser(selectedAdmin.id, 2), color: "#6fcf97" });
        data.push({ label: "Declined", value: getStatusCountByUser(selectedAdmin.id, 3), color: "#f45858" });
        setDonutChartdata(data);
    }
    const reactDonutChartBackgroundColor = [
        "#eaae39",
        "#2d9cdb",
        "#6fcf97",
        "#f45858",
    ];
    const [isLoaded, setIsLoaded] = useState(false);
    if (!isLoaded) {
        if (admin.data.session) {
            getCRMList(dispatch, admin);
            getAdminList(dispatch, admin);
            getQsList(dispatch, admin);
            setSelectedUser(admin.data.user)
            window.setTimeout(function () {
                setSelectedUser(admin.data.user)
                if (document.getElementById('admin_' + admin.data.user.id))
                    document.getElementById('admin_' + admin.data.user.id).onmouseover = function () { setSelectedUser(admin.data.user) };
            }, 1000);

            setIsLoaded(true);
        }
    }
    const getTotal = (userId) => {

        var n = getStatusCountByUser(userId, 1) + getStatusCountByUser(userId, 2) + getStatusCountByUser(userId, 3);// + getStatusCountByUser(userId, 4)
        return n;
    }


    const reactDonutChartInnerRadius = 0.5;
    const reactDonutChartSelectedOffset = 0.04;
    const reactDonutChartHandleClick = (item, toggled) => {
        if (toggled) {
            //console.log(item);
        }
    };
    let reactDonutChartStrokeColor = "#FFFFFF";
    const reactDonutChartOnMouseEnter = (item) => {
        let color = reactDonutChartdata.find((q) => q.label === item.label).color;
        reactDonutChartStrokeColor = color;
    };
    const st = new Date(new Date().getTime());
    const st1 = new Date(new Date().getTime());
    const twoDayBefor = st.setDate(st.getDate() - 1);
    const twoDayafter = st1.setDate(st1.getDate() + 2);

    const [startDate, setStartDate] = useState((st));

    const isFiltered = user => {
        for (var i = 0; i < qsList.length; i++) {
            var qs = qsList[i];
            if (qsList[i].userId === user.id) {
                var bDate = true;
                if (qs.createdDate >= startDate.getTime() && qs.createdDate < endDate.getTime()) {
                    bDate = true;
                }
                var bCustomer = false;
                if (qs.cutomerCrmId === crmId) {
                    bCustomer = true;
                }
                if (crmId == 0 || crmId == -1) {
                    bCustomer = true;
                }
                var bCountry = false;
                if (qs.countryId === countryId) {
                    bCountry = true;
                }
                if (countryId == 0 || countryId == -1) {
                    bCountry = true;
                }
                var p = ""
                if (qs.shipmentType == 1) {
                    p = listOfAirPort.find(u => u.id === qs.from)?.name
                } else {
                    p = listOfPort.find(u => u.id === qs.from)?.name
                }
                var bPort = false;
                if (portOfOrigin === p) {
                    bPort = true;
                }
                if (portOfOrigin == '' || portOfOrigin == 'All') {
                    bPort = true;
                }
                var bInco = false;
                if (qs.incoId === incoId) {
                    bInco = true;
                }
                if (incoId == 0 || incoId == -1) {
                    bInco = true;
                }
                var bService = false;
                if (qs.serviceType === serviceId) {
                    bService = true;
                }
                if (serviceId == 0 || serviceId == -1) {
                    bService = true;
                }
                var bQuouteType = false;
                if (qs.quoteType === quouteTypeId) {
                    bQuouteType = true;
                }
                if (quouteTypeId == 0 || quouteTypeId == -1) {
                    bQuouteType = true;
                }
                if (bQuouteType && bService && bInco && bPort && bCountry && bCustomer && bDate) {
                    return true;
                }
            }
        }
        return false;
    }
    const onStartDateChange = data => {
        setStartDate(data);
    };

    const [endDate, setEndDate] = useState((st1));
    const onEndDateChange = data => {
        setEndDate(data);
    };
    const crmListOpt = crmList.map(sender => ({
        id: sender.id,
        name: sender.companyName
    }));
    const crmListOpt1 = [{
        id: -1,
        name: 'All'
    }].concat(crmListOpt)
    const [crmId, setCrmId] = useState(0);
    const countryList1 = [{
        id: -1,
        name: 'All'
    }].concat(countryList)
    const [countryId, setCountryId] = useState(0);
    var t = [{
        id: -2,
        name: 'Air Port'
    }, {
        id: -1,
        name: 'All'
    }].concat(listOfAirPort)
    const [portOfOrigin, setPortOfOrigin] = useState("");
    const listOfPortOpt = listOfPort.map(sender => ({
        id: t.length + 1 + sender.id,
        name: sender.name
    }));
    var t1 = [{
        id: -3,
        name: 'Port'
    }].concat(listOfPortOpt)
    const airPortAndPorts = t.concat(t1)

    const listOfIncotermsOpt = listOfIncoterms.map(sender => ({
        id: sender.id,
        name: sender.incoterm
    }));
    const listOfIncotermsOpt1 = [{
        id: -1,
        name: 'All'
    }].concat(listOfIncotermsOpt)


    const [listOfIncotermsOpt2, setListOfIncotermsOpt2] = useState(listOfIncotermsOpt1);
    const [incoId, setIncoId] = useState(0);
    const listOfServiceTypeOpt = listOfServiceType.map(sender => ({
        id: sender.id,
        name: sender.serviceType
    }));
    const listOfServiceTypeOpt1 = [{
        id: -1,
        name: 'All'
    }].concat(listOfServiceTypeOpt)


    const [listOfServiceTypeOpt2, setListOfServiceTypeOpt2] = useState(listOfServiceTypeOpt1);

    const [serviceId, setServiceId] = useState(0);
    const qsTypeListOpt = qsTypeList.map(sender => ({
        id: sender.id,
        name: sender.lable
    }));
    const qsTypeListOpt1 = [{
        id: -1,
        name: 'All'
    }].concat(qsTypeListOpt)

    const [qsTypeListOpt2, setQsTypeListOpt2] = useState(qsTypeListOpt1);
    const [quouteTypeId, setQuouteTypeId] = useState(0);


    return (
        <Grid container>
            <Grid container item xs={12} justifyContent='flex-start' sx={{ mt: 3}}>
                <Button onClick={onHandleHideAllItems} className={'dashBoardTotal'} variant='contained' sx={{ mt: 1, mr: 2, ml: 2, }} style={{
                    fontSize: '12px', height:"32px"
                }}>
                    {`Total`}: {getTotal(selectedAdmin.id)}
                </Button>
                {/*<Button onClick={function () { onHandleStatusFilter(1) }} className={'dashBoardPrepared'} variant='contained' sx={{ mt: 1, mr: 2 }} style={{
                    fontSize: '12px', height: "32px"
                }}>
                    {`Prepared`}: {getStatusCountByUser(selectedAdmin.id, 1)}
                </Button>*/}
                <Button onClick={function () { onHandleStatusFilter(1) }} className={'dashBoardPrepared'} variant='contained' sx={{ mt: 1, mr: 2 }} style={{
                    fontSize: '12px', height: "32px"
                }}>
                    {`Pending`}: {getStatusCountByUser(selectedAdmin.id, 1)}
                </Button>
                {/*<Button onClick={function () { onHandleStatusFilter(4) }} className={'dashBoardPending'} variant='contained' sx={{ mt: 1, mr: 2 }} style={{
                    fontSize: '12px', height: "32px"
                }}>
                    {`Pending`}:  {getStatusCountByUser(selectedAdmin.id, 4)}
                </Button>*/}
                <Button onClick={function () { onHandleStatusFilter(2) }} className={'dashBoardApproved'} variant='contained' sx={{ mt: 1, mr: 2 }} style={{
                    fontSize: '12px', height: "32px"
                }}>
                    {`Approved`}:  {getStatusCountByUser(selectedAdmin.id, 2)}
                </Button>
                <Button onClick={function () { onHandleStatusFilter(3) }} className={'dashBoardDeclined'} variant='contained' sx={{ mt: 1, mr: 2 }} style={{
                    fontSize: '12px', height: "32px"
                }}>
                    {`Declined`}:  {getStatusCountByUser(selectedAdmin.id, 3)}
                </Button>
                <Button onClick={onHandleHideAllItems} className={'dashBoardSuccess'} variant='contained' sx={{ mt: 1, mr: 2 }} style={{
                    fontSize: '12px', height: "32px"
                }}>
                    {`Success`}: {((getStatusCountByUser(selectedAdmin.id, 2) * 100) / getTotal(selectedAdmin.id)).toFixed(2)}
                </Button>
                <br />
            </Grid>
            <Grid container item xs={12} justifyContent='start'>
                <TableContainer sx={{ mt: 3, mr: '2vw', width: '65vw', height: 'calc(100vh - 159px)', color:"#909399 !important" }} className="font12">
                    <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }} size={'normal'} padding={'normal'} className="font12">
                        <TableHead className="font12">
                            <TableRow className="font12">
                                <TableCell />
                                <TableCell className="font12 ">
                                    <Typography variant='h7'>Name</Typography>
                                </TableCell>
                                <TableCell className="font12">
                                    <Typography variant='h7'>Total</Typography>
                                </TableCell>
                               {/* <TableCell className="font12">
                                    <Typography variant='h7'>Prepared</Typography>
                                </TableCell>*/}
                                <TableCell className="font12">
                                    <Typography variant='h7'>Pending</Typography>
                                </TableCell>{/*
                                <TableCell className="font12">
                                    <Typography variant='h7'>Pending</Typography>
                                </TableCell>*/}
                                <TableCell className="font12">
                                    <Typography variant='h7'>Approved</Typography>
                                </TableCell>
                                <TableCell className="font12">
                                    <Typography variant='h7'>Declined</Typography>
                                </TableCell>
                                <TableCell className="font12">
                                    <Typography variant='h7'>Success (%)</Typography>
                                </TableCell>
                                <TableCell className="font12">
                                    <Typography variant='h7'>Margin ($)</Typography>
                                </TableCell>
                                <TableCell className="font12">
                                    <Typography variant='h7'>Air Margin ($)</Typography>
                                </TableCell>
                                <TableCell className="font12">
                                    <Typography variant='h7'>Lcl Margin ($)</Typography>
                                </TableCell>
                                <TableCell className="font12">
                                    <Typography variant='h7'>Fcl Margin ($)</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                filteredAdminList.map(user => (

                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={user?.id || uuidv4()} id={"admin_" + user.id} onMouseOver={function () { setSelectedUser(user); }} onClick={function () { setSelectedUser(user); onHandleUserFilter(user.id); }} >
                                        {isFiltered(user) && <TableCell />}
                                        {isFiltered(user) && < TableCell className="td_gray">
                                            <Typography variant='h7' component='span'>
                                                {user.adminName || '-'}
                                            </Typography>
                                        </TableCell>
                                        }
                                        {isFiltered(user) && <TableCell className="font12 td_gray">
                                            <Typography variant='h7' component='span'>
                                                {getTotal(user.id)}
                                            </Typography>
                                        </TableCell>
                                        }
                                        {isFiltered(user) && <TableCell className="font12 td_gray">
                                            <Typography variant='h7' component='span'>
                                                {getStatusCountByUser(user.id, 1)}
                                            </Typography>
                                        </TableCell>
                                        }
                                        {isFiltered(user)&&false && <TableCell className="font12 td_gray">
                                            <Typography variant='h7' component='span'>
                                                {getStatusCountByUser(user.id, 4)}
                                            </Typography>
                                        </TableCell>
                                        }
                                        {isFiltered(user) && <TableCell className="font12 td_gray">
                                            <Typography variant='h7' component='span'>
                                                {getStatusCountByUser(user.id, 2)}
                                            </Typography>
                                        </TableCell>
                                        }
                                        {isFiltered(user) && <TableCell className="font12 td_gray">
                                            <Typography variant='h7' component='span'>
                                                {getStatusCountByUser(user.id, 3)}
                                            </Typography>
                                        </TableCell>
                                        }
                                        {isFiltered(user) && < TableCell className="font12 td_gray">
                                            <Typography variant='h7' component='span'>
                                                {((getStatusCountByUser(user.id, 2) * 100) / getTotal(user.id)).toFixed(2)}
                                            </Typography>
                                        </TableCell>
                                        }
                                        {isFiltered(user) && < TableCell className="font12 td_gray">
                                            <Typography variant='h7' component='span'>
                                                {Number(getTotalAvgByUser(user.id) / getTotal(user.id)).toFixed(2)}
                                            </Typography>
                                        </TableCell>
                                        }
                                        {isFiltered(user) && < TableCell className="font12 td_gray">
                                            <Typography variant='h7' component='span'>
                                                {Number(getTotalAvgByUserAir(user.id)).toFixed(2)}
                                            </Typography>
                                        </TableCell>
                                        }
                                        {isFiltered(user) && < TableCell className="font12 td_gray">
                                            <Typography variant='h7' component='span'>
                                                {Number(getTotalAvgByUserLcl(user.id) ).toFixed(2)}
                                            </Typography>
                                        </TableCell>
                                        }
                                        {isFiltered(user) && < TableCell className="font12 td_gray">
                                            <Typography variant='h7' component='span'>
                                                {Number(getTotalAvgByUserFcl(user.id)).toFixed(2)}
                                            </Typography>
                                        </TableCell>
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container xs={3.7} justifyContent='start'>
                    {/*<TableContainer sx={{ height: 305, width: '30vw',minWidth:305, textAlign: 'center' }}>
                        {reactDonutChartdata.length > 0 &&
                            <DonutChart
                                width={300}
                                height={300}
                                onMouseEnter={(item) => reactDonutChartOnMouseEnter(item)}
                                strokeColor={reactDonutChartStrokeColor}
                                data={reactDonutChartdata}
                                colors={reactDonutChartBackgroundColor}
                                innerRadius={reactDonutChartInnerRadius}
                                selectedOffset={reactDonutChartSelectedOffset}
                                formatValues={(values, total) => `${(values).toFixed(2)}`}
                                legend={false}
                                clickToggle={false}
                                onClick={(item, toggled) => reactDonutChartHandleClick(item, toggled)}
                            />}

                    </TableContainer>*/}
                    <Grid container item xs={12} justifyContent='start' alignContent='flex-start' rowSpacing={1} style={{marginTop:"17px",height:'calc(100vh - 420px)'}}>
                        <Grid container item xs={12} justifyContent='space-evenly' alignContent='center' rowSpacing={1}>
                            <Button onClick={() => {
                                navigate("/compareQs")
                            }} variant='contained' sx={{ mt: 1, mr: 2 }} style={{
                            fontSize: '12px', height: "32px"
                        }}>
                            Compare
                        </Button>
                            <Button onClick={() => {
                                navigate("/report")
                            }} variant='contained' sx={{ mt: 1, mr: 2 }} style={{
                            fontSize: '12px', height: "32px"
                        }}>
                            Report
                            </Button>
                            </Grid>
                        <DateTimePicker
                            sm={null}
                            md={null}
                            xs={6}
                            label={<Typography fontSize="12px" fontWeight='bold'>Start</Typography>}
                            value={startDate}
                            onChange={date => onStartDateChange(date)}
                            style={{ 'top': '15px', left: '35px' }}
                        />
                        <DateTimePicker
                            sm={null}
                            md={null}
                            xs={6}
                            label={<Typography fontSize="12px" fontWeight='bold'>End</Typography>}
                            value={endDate}
                            onChange={date => onEndDateChange(date)}
                            style={{ 'top': '15px', left: '35px', fontSize: '0.7em' }}

                        />
                        <SelectField
                            required
                            value={crmId}
                            lg={12}
                            onChange={event => setCrmId(event.target.value)}
                            label='Customer'
                            options={crmListOpt1}
                        />
                        <SelectField
                            required
                            value={countryId}
                            lg={12}
                            onChange={event => setCountryId(event.target.value)}
                            label='Country'
                            options={countryList1}
                        />
                        <SelectField
                            required
                            value={portOfOrigin}
                            lg={12}
                            propKey={'name'}
                            onChange={event => setPortOfOrigin(event.target.value)}
                            label='PortOfOrigin'
                            options={airPortAndPorts}
                        />
                        <SelectField
                            required
                            value={incoId}
                            lg={12}
                            onChange={event => setIncoId(event.target.value)}
                            label='Incoterms'
                            options={listOfIncotermsOpt2}
                        />
                        <SelectField
                            required
                            value={serviceId}
                            lg={12}
                            onChange={event => setServiceId(event.target.value)}
                            label='Service Type'
                            options={listOfServiceTypeOpt2}
                        />
                        <SelectField
                            required
                            value={quouteTypeId}
                            lg={12}
                            onChange={event => setQuouteTypeId(event.target.value)}
                            label='Quotation Type'
                            options={qsTypeListOpt1}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DashBoard;
