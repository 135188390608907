import { memo } from 'react';
import { useState } from 'react';
import { TableCell, TableHead, TableRow, Typography, Button } from '@mui/material';
import AddPort from './AddPort';
import { Dialog } from '../../style-guide';

const PortHeader = () => {
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);

    return (
        <TableHead>
            <TableRow>
                <TableCell sx={4}>
                    <Typography variant='h7'>
                        
                    </Typography>
                </TableCell>
                <TableCell sx={4}>
                    <Typography variant='h7'>
                        Name
                    </Typography>
                </TableCell>
                <TableCell sx={4}>
                    <Typography variant='h7'>
                        Country
                    </Typography>
                </TableCell>
                <TableCell sx={4}>
                    <Typography variant='h7'>
                        Operations 
                    </Typography>
                </TableCell>
                <TableCell sx={4}>
                    <Typography variant='h7'>
                        <Button onClick={onHandleOpenModal} variant='contained'>
                            + New Port
                        </Button>
                        {openOrderModal && (
                            <Dialog open={openOrderModal} title={'Port'} onHandleCancel={onHandleCancelModal}>
                                <AddPort onHandleCancel={onHandleCancelModal} id={null} />
                            </Dialog>
                        )}
                    </Typography>
                </TableCell>

            </TableRow>
        </TableHead>
    );
};

export default memo(PortHeader);
