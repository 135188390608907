import { createSlice } from '@reduxjs/toolkit';

export const snackbar1Slice = createSlice({
    name: 'snackbar1',
    initialState: {
        snackbar1: false,
    },
    reducers: {
        snackbar1Toggle: (state, { payload }) => {
            state.snackbar1 = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { snackbar1Toggle } = snackbar1Slice.actions;

export default snackbar1Slice.reducer;
