import { memo } from 'react';
import { useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, FormControlLabel, ListSubheader, InputAdornment } from '@mui/material';
import { TextField } from '../index';
const SelectFieldWrapper = ({ label, value, onChange, options, disabled, required, xs, sm, md, lg, msx, propKey }) => {
    let inputProps = { sx: { width: "100%", fontSize: '0.8rem', padding: '0px' } }
    const [searchText, setSearchText] = useState("");
    const [optionsItems, setOptItems] = useState(options);
    return (

        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
            <FormControl fullWidth disabled={disabled} required={required} sx={{ marginTop: 0, width: '100%' }}>
                <InputLabel id={label} sx={{ top: (!value ? -7 : 4), maxWidth: 'calc(100% - 25px)', fontSize: '0.8rem' }} >
                    {label}
                </InputLabel>
                <Select
                    value={value || ''}
                    label={label}
                    onChange={onChange}
                    sx={{ marginTop: 0 }}
                    inputProps={{ sx: { fontSize: '0.7em', padding: '6px', paddingLeft: '7px' } }}
                    InputLabelProps={{ sx: { fontSize: '0.7em' } }}
                    size='small'
                    MenuProps={{ sx: msx }}
                    onClose={function () { setOptItems(options); setSearchText("") }}
                >
                    <ListSubheader sx={{ width: '100%', padding: '4px', paddingBottom: 1}}>
                        <TextField
                            value={searchText || ''}
                            lg={ 12}
                            autoFocus
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start"></InputAdornment>
                            }}
                            onChange={function (e) {
                                setSearchText(e.target.value)
                                var t = []
                                for (var i = 0; i < options.length; i++) {
                                    var tObj = JSON.parse(JSON.stringify(options[i]));
                                    if (tObj.name.toLowerCase().includes(e.target.value.toLowerCase()) ) {
                                        t.push(tObj)
                                    }
                                   
                                }
                                if (t.length > 0) {
                                    setOptItems(t)
                                } else {
                                    setOptItems(options)
                                }

                            }}
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                    // Prevents autoselecting item while typing (default Select behaviour)
                                    e.stopPropagation();
                                }
                            }}
                        />
                    </ListSubheader>
                    {optionsItems?.map((option,index) => (
                        
                        <MenuItem lg={12} key={index}  disabled={(option.id == -2 || option.id == -3)} style={{ background: (option.id == -2 || option.id == -3) ? 'grey' : '' }} value={propKey ? option[propKey] : option.id} >
                            {option.name}
                            </MenuItem>
                        

                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
};

SelectFieldWrapper.defaultProps = {
    label: '',
    value: '',
    required: null,
    options: [],
    xs: 12,
    sm: 6,
    md: 4,
    lg: 2,
    msx: { maxHeight: '400px', fontSize: '0.8rem' }
};

export default memo(SelectFieldWrapper);
