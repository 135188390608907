import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TableCell, Grid, TableRow, Typography, Box, Button, Checkbox, Radio, FormControlLabel } from '@mui/material';
import { Dialog } from '../../../style-guide';
import { useSelector, useDispatch } from 'react-redux';
import { addDesc, recieptWithBarCode } from '../../Admins/AdminHandler'
import { onLclCostChange, onEmptyLclCost } from '../../../reducers/crm/crmSlice';
import { snackbarToggle } from '../../../reducers/snackbarSlicer';
import { listOfCostLandWeightLcl, parcelSortBy, SHIPPING_STATUS_LIST } from '../../../constants/global-constants';
import { getQuoatationType, getQuotationStatus, getCRMList, deleteLclExportImportAF } from '../../../reducers/requestHandler';
import { getAdminList, getQsList } from '../../../reducers/requestHandler';
import { setCurrentPage } from '../../../reducers/session/sessionSlice';
import { FaTrash, FaPen } from "react-icons/fa";
const LclCostRow = props => {
    const dispatch = useDispatch();
    const { crmList } = useSelector(state => state.crm);
    const { countryList, listOfAirPort, listOfPort, listOfMeasurementUnits, listOfcurrency, qsStatusList, listOfIncoterms, listOfServiceType } = useSelector(state => state.parameter);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                clAgent: props.data.clAgent,
                countryId: props.data.countryId,
                createTime: props.data.createTime,
                crmId: props.data.crmId,
                importExportType: props.data.importExportType,
                min: props.data.min,
                portAid: props.data.portAid,
                portLid: props.data.portLid,
                rateList: props.data.rateList,
                tt: props.data.tt,
                updateTime: props.data.updateTime,
                validDate: props.data.validDate,
                weightList: props.data.weightList,
                rate: props.data.rate,
                zip: props.data.zip,
                product: props.data.product,
                unitOfMeasure: props.data.unitOfMeasure,
                currency: props.data.currency,
                remark: props.data.remark,
                bSelected: props.data.bSelected,
                COLOADER: props.data.COLOADER,
                COUNTRY: props.data.COUNTRY,
                PORTL: props.data.PORTL,
                PORTA: props.data.PORTA,
            }
            : {
                id: null,
                clAgent: "",
                countryId: "",
                createTime: "",
                crmId: "",
                importExportType: "",
                min: "",
                rate: "",
                portAid: "",
                portLid: "",
                rateList: [],
                tt: "",
                updateTime: "",
                validDate: "",
                weightList: [],
                zip: "",
                product: null,
                unitOfMeasure: null,
                currency: null,
                rate: null,
                remark: null,
                bSelected: false,
            },
    );
    const { roles, adminList, users } = useSelector(state => state.admin);
    const { currentPage,admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const deleteDescription = () => {
        var temp = {
            id: value.id,
            descId: value.id
        }
        addDesc(dispatch, snackbarToggle, temp, admin);

    };
    const [isLoaded, setIsLoaded] = useState(false);
    function formatDate(newDate) {        
        const d = newDate
        const year = d.getFullYear()
        const date = d.getDate()
        const monthName = d.getMonth()+1
        const formatted =  `${date}/${monthName}/${year}`
        return formatted.toString()
    }
    useEffect(() => {
        if (!isLoaded && admin.data.session && crmList && crmList.length == 0) {
            //getCRMList(dispatch, admin);
            setIsLoaded(true);
        }
    }, [dispatch, value]);
    var t2 = [];
    const handleParams = (event) => {
    }
    const [openOrderModalDelete, setOpenOrderModalDelete] = useState(false);
    const onHandleOpenModalDelete = () => {
        setOpenOrderModalDelete(true);
    };
    const onHandleCancelModalDelete = () => setOpenOrderModalDelete(false);
    if (props.id == 2 || props.id == 1) {
        if (props.id == 1) {
            if (currentPage != 'Lcl Export A/F') {
                dispatch(setCurrentPage("Lcl Export A/F"));
            }
        } else {
            if (currentPage != 'Lcl Import A/F') {
                dispatch(setCurrentPage("Lcl Import A/F"));
            }
        }

        return (
            <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        <FormControlLabel control={<Checkbox
                            checked={value.bSelected} onChange={(event) => {
                                setValue(prev => ({ ...prev, bSelected: event.target.checked }))
                                props.setSelectedLclCost(value.id, event.target.checked)
                            }} />} label="" />
                    </Typography>
                </TableCell>
                { value.crmId != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.COLOADER}
                        {/*{crmList.find(u => u.id === value.crmId)?.companyName || '-'}*/}
                    </Typography>
                </TableCell>
                }
                {value.countryId != 0 ? <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.COUNTRY}
                        {/*{countryList.find(u => u.id === value.countryId)?.name || '-'}*/}
                    </Typography>
                </TableCell> : <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        -
                        {/*{countryList.find(u => u.id === value.countryId)?.name || '-'}*/}
                    </Typography>
                </TableCell>
                }
                {props.id == 2 &&value.portLid != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.PORTL}
                        {/*{listOfPort.find(u => u.id === value.portLid)?.name || '-'}*/}
                    </Typography>
                </TableCell>
                }
                {value.portAid != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.PORTA}
                        {/*{listOfPort.find(u => u.id === value.portAid)?.name || '-'}*/}
                    </Typography>
                </TableCell>
                }
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    {value.rateList!=null &&value.rateList.map(r => (
                        <Button sx={{ mt: 0, mr: 0, lineHeight: '0.75 !important' }} className={'dashBoardApproved'} key={r || uuidv4()} variant='h7' >
                            {r}
                        </Button>
                    ))}
                </TableCell>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.clAgent}
                    </Typography>
                </TableCell>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.tt}
                    </Typography>
                </TableCell>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {formatDate(new Date(value.validDate))}
                    </Typography>
                </TableCell>

                <TableCell style={{ margin: 0, padding: '8px',minWidth: "8vw" }}>
                    <Button variant='contained' sx={{ mt: 1, ml: 2, lineHeight: '0.75 !important', background: "#66b1ff" }} onClick={props.openDialog}>
                        <FaPen/>
                    </Button>
                    <Button variant='contained' sx={{ mt: 1, ml: 2, lineHeight: '0.75 !important', background: "#F56C6C" }} onClick={onHandleOpenModalDelete}>
                        <FaTrash />
                    </Button>

                    {openOrderModalDelete && (
                        <Dialog maxWidth='md' open={openOrderModalDelete} title={currentPage} onHandleCancel={onHandleCancelModalDelete}>
                            <Grid container item>  Are you sure, you want to delete?</Grid>
                            <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {

                                deleteLclExportImportAF(dispatch, snackbarToggle, { id: value.id }, admin, props.getLclCostData)
                                onHandleCancelModalDelete()
                            }}>
                                Delete 
                            </Button>
                        </Dialog>
                    )}
                </TableCell>
            </TableRow >

        );
    } else if (props.id == 3) {
        if (currentPage != 'Lcl Land Transport') {
            dispatch(setCurrentPage("Lcl Land Transport"));
        }
        return (
            <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        <FormControlLabel control={<Checkbox
                            checked={value.bSelected} onChange={(event) => {
                                setValue(prev => ({ ...prev, bSelected: event.target.checked }))
                                props.setSelectedLclCost(value.id, event.target.checked)
                            }} />} label="" />
                    </Typography>
                </TableCell>
                {<TableCell >
                    <Typography variant='h7' >
                        {/*{value.countryId} */}
                        {props.data?.params?.TRUCKER_NAME}
                    </Typography>
                </TableCell>
                }
                {value.countryId != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.COUNTRY}
                        {/*{countryList.find(u => u.id === value.countryId)?.name || '-'}*/}
                    </Typography>
                </TableCell>
                }
                {value.portAid != 0 ? <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.PORTA}
                        {/*{listOfPort.find(u => u.id === value.portAid)?.name || '-'}*/}
                    </Typography>
                </TableCell> : <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                       
                    </Typography>
                </TableCell>
                }
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.zip}
                    </Typography>
                </TableCell>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.min}
                    </Typography>
                </TableCell>
                {value.rateList != null && value.rateList.map(r => (
                    <TableCell key={uuidv4()}>

                        <Button sx={{ mt: 0, mr: 1, lineHeight: '0.75 !important' }} className={'dashBoardApproved'} variant='h7' >
                            {r}
                        </Button>

                    </TableCell>
                ))}
                <TableCell style={{ margin: 0, padding: '8px', minWidth: "10vw" }}>
                    <Button variant='contained' sx={{ mt: 1, ml: 2, lineHeight: '0.75 !important', background: "#66b1ff" }} onClick={props.openDialog}>
                        <FaPen />
                    </Button>
                    <Button variant='contained' sx={{ mt: 1, ml: 2, lineHeight: '0.75 !important', background: "#F56C6C" }} onClick={onHandleOpenModalDelete}>
                        <FaTrash />
                    </Button>

                    {openOrderModalDelete && (
                        <Dialog maxWidth='md' open={openOrderModalDelete} title={currentPage} onHandleCancel={onHandleCancelModalDelete}>
                            <Grid container item>  Are you sure, you want to delete?</Grid>
                            <Button variant='contained' sx={{ mt: 1, height: "4.5vh", mb: 1, mr: 2, background: '#F56C6C !important' }} onClick={() => {

                                deleteLclExportImportAF(dispatch, snackbarToggle, { id: value.id }, admin, props.getLclCostData)
                                onHandleCancelModalDelete()
                            }}>
                                Delete
                            </Button>
                        </Dialog>
                    )}
                </TableCell>
            </TableRow >

        );
    } else if (props.id == 4 || props.id == 5) {
        if (props.id == 4) {
            if (currentPage != 'Lcl Export - Locals') {
                dispatch(setCurrentPage("Lcl Export - Locals"));
            }
        }
       
        if (props.id == 5) {
            if (currentPage != 'Lcl Import - Locals') {
                dispatch(setCurrentPage("Lcl Import - Locals"));
            }
        }
        return (
            <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.product }
                    </Typography>
                </TableCell>
                {value.unitOfMeasure != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {listOfMeasurementUnits.find(u => u.id === value.unitOfMeasure)?.lable || '-'}
                    </Typography>
                </TableCell>
                }
                {value.currency != 0 && <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {listOfcurrency.find(u => u.id === value.currency)?.lable || '-'}
                    </Typography>
                </TableCell>
                }
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.rate}
                    </Typography>
                </TableCell>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.min}
                    </Typography>
                </TableCell>
                <TableCell style={{ margin: 0, padding: '8px' }}>
                    <Typography variant='h7' >
                        {value.remark}
                    </Typography>
                </TableCell>
            </TableRow >

        );
    }
};

export default LclCostRow;
