import { useState, useEffect} from 'react';
import { TableCell,Grid, TableRow, Typography, Box, Button, Checkbox, Radio, FormControlLabel } from '@mui/material';
import { Dialog, TextField } from '../../style-guide';
import { useSelector, useDispatch } from 'react-redux';
import { addDesc, recieptWithBarCode, } from '../Admins/AdminHandler'
import { onQsChange, onEmptyQsChange } from '../../reducers//QS/qsSlice';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { parcelStatusList, parcelSortBy, SHIPPING_STATUS_LIST } from '../../constants/global-constants';
import { getQuoatationType, getQuotationStatus, getCRMList, getAirExportAFs } from '../../reducers/requestHandler';
import {getCRMList1,getAirExportAf,getAdminList, getQsList, updateAirExportAFRate } from '../../reducers/requestHandler';
import { FaAngleDown, FaAngleUp, FaRegEdit, FaCheck } from "react-icons/fa";
import AddAirCost from '../CostList/Air/AddAirCost';
import { onCrmChange, onEmptyCrm, setCrmListItems } from '../../reducers/crm/crmSlice';
const AirQuote = props => {
    const { addRemoveSelectedMail } = props;
    const dispatch = useDispatch();
    const { adminList } = useSelector(state => state.admin);
    const { crmList } = useSelector(state => state.crm);
    const { listOfProductCategories, listOfShipmentTypes, listOfAirLine, listOfLclExportImportAFs, listOfAirExportAFs, countryList, qsStatusList, listOfServiceType, listOfAirPort, listOfPort, listOfIncoterms, listOfFLCTypes40, listOfFLCTypes20, listOfcurrency } = useSelector(state => state.parameter);
    const { admin } = useSelector(state => state.session);

    const [value, setValue] = useState(
        props.detail? {
                id: props.detail.id,
                importExportType: props.detail.importExportType,
                rate: props.detail.rate,
                selected: props.detail.selected,
            }
            : {
                id: null,
                importExportType: null,
                fromId: null,
                toId: null,
                airLineId: null,
                rateList: null,
                min: null,
                crmId: null,
                airportId: null,
                zip: null,
                weightList: null,
                countryId: null,
                createTime: null,
                updateTime: null,
                isShow: false,
            },
    );

    function formatDate(newDate) {
        const d = newDate
        const year = d.getFullYear()
        const date = d.getDate()
        const monthName = d.getMonth() + 1
        const formatted = `${date}/${monthName}/${year}`
        return formatted.toString()
    }
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedAirQuoteData, setSelectedAirQuoteData] = useState({ ...props?.detail/*, id: null */});
    const [updatedAirQuoteData, setUpdatedAirQuoteData] = useState({ ...props?.detail/*, id: null */});
    if (!isLoaded && admin.data.session && crmList && crmList.length == 0) {
        //getCRMList(dispatch, admin);
        setIsLoaded(true);
    }
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => {
        //
       
        getAirExportAf(value, admin, function (data) {
            
            var tObj = JSON.parse(JSON.stringify(data?.data));
            if (tObj.weightList) {
                tObj.weight = tObj.weightList[0];
            } else {
                tObj.weight = 0;
            }
            tObj.rate = tObj.rateList[0];
            tObj.quoteRate = tObj.rateList[0];
            setUpdatedAirQuoteData(tObj)
            setSelectedAirQuoteData({ ...tObj/*, id: null */});
            setOpenOrderModal(true);

        })
        
    };
    const handleChange = (event) => {
    };
    const getAgentCharges = (crm) => {
       // props.calculation(value.selectedItem?.rate, value.selectedItem?.min, value.selectedUnit?.id)
        console.log(crm)
        var charges = crm.charges;
        var AIR_IMPORT = charges?.AIR_IMPORT;
        var total = 0;
        if (AIR_IMPORT) {
            for (var i = 0; i < AIR_IMPORT.length; i++) {
                total = Number(total) + Number(props.calculation(Number(AIR_IMPORT[i]?.rate), Number(AIR_IMPORT[i]?.min), Number(AIR_IMPORT[i]?.currency)))
            }
        }
        return total;
    };
    const onHandleCancelModal = () => {
        var tc = [...props.listOfQuoatationCost];
        var k = []
        for (var i = 0; i < tc.length; i++) {
            if (tc[i].id == updatedAirQuoteData?.id) {
                tc[i] = updatedAirQuoteData;
            }
        }
        props.setListOfQuoatationCost(tc)
        setOpenOrderModal(false)
    };


    const [openOrderModalClone, setOpenOrderModalClone] = useState(false);
    const onHandleOpenModalClone = () => {
        setOpenOrderModalClone(true);
    };
    const onHandleCancelModalClone = () => setOpenOrderModalClone(false);
    //crmList.find(u => u.companyName === listOfAirLine.find(u => u.id === props.detail.airLineId)?.name) &&
    return (
        <>{ <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
                <Typography variant='h7'>
                    <Checkbox checked={value.selected} onChange={(event) => {
                        setValue(prev => ({ ...prev, selected: event.target.checked }));
                        addRemoveSelectedMail(props.detail, event.target.checked);
                    }} />
                </Typography>
            </TableCell>
            {(props.value.productType == 2) && <TableCell style={{ minWidth: "7vw" }}>
                <Typography variant='h7'>
                    {crmList.find(u => u.id === props.detail.crmId)?.companyName || '-'}
                </Typography>
            </TableCell>}
            {<TableCell>
                <Typography variant='h7'>
                    {listOfAirLine.find(u => u.id === props.detail.airLineId)?.name || props.detail.airLineId}
                </Typography>
            </TableCell>
            }
            <TableCell>
                <Typography variant='h7'>

                    {value.isShow ? <Grid container item lg={6}>
                        <TextField
                            lg={12}
                            value={value.rate}
                            onChange={event => setValue(prev => ({ ...prev, rate: event.target.value }))}
                            label='Rate1'
                            type='number'
                        />
                    </Grid> : <Grid container item lg={12}><Grid lg={12} item>{props.detail.rate ? (props.detail.params?.rateCurrency ? listOfcurrency.find(cur => cur.id == props.detail.params.rateCurrency).lable : "USD") : ""} {props.detail.rate ? props.detail.rate : ""}</Grid></Grid>}
                </Typography>
            </TableCell>
            <TableCell >
                <Typography variant='h7'>
                    {props.detail.params?.rateCurrency ? listOfcurrency.find(cur => cur.id == props.detail.params.rateCurrency).lable : "USD"} {props.detail.min}
                </Typography>
            </TableCell>
            {(props.value.productType == 2) && <TableCell >
                <Typography variant='h7'>
                    USD {props.detail.fcaCalCharges}
                </Typography>
            </TableCell>
            }
            {/*{(props.value.productType == 2) && <TableCell >
                <Typography variant='h7'>
                    {getAgentCharges(crmList.find(u => u.id === props.detail.crmId))}
                </Typography>
            </TableCell>
            }*/}
            <TableCell>
                <Typography variant='h7'>
                    {formatDate(new Date(props.detail.updateTime))}
                </Typography>
            </TableCell>
            <TableCell style={{ minWidth: "14vw" }}>
                <Typography variant='h7'>

                    <Grid container item lg={10} justifyContent="space-evenly">


                        {!value.isShow && <Grid lg={2} item>
                            {openOrderModalClone && (
                                <Dialog maxWidth='md' open={openOrderModalClone} title={props.typeData?.lable} onHandleCancel={onHandleCancelModalClone}>
                                    <AddAirCost isAddFromQsCostTable={true} setAirImportExport={() => {

                                    }}
                                        callBack={() => {
                                            getAirExportAFs(dispatch, admin, function () {
                                                const elem = document.getElementById('getCostQuoat');
                                                elem?.click();
                                            });


                                        }} typeData={props.typeData} data={{ ...props?.detail/*, id: null */}} importExportType={props.detail?.importExportType} onHandleCancel={() => setOpenOrderModalClone(false)} id={null} airImportExport={null} />
                                </Dialog>
                            )}

                            <Button variant='text' onClick={function () { onHandleOpenModalClone() }} >
                                Add
                            </Button>
                        </Grid>}


                        {!value.isShow && <Grid lg={2} item>
                            {openOrderModal && (
                                <Dialog maxWidth='md' open={openOrderModal} title={props.typeData?.lable} onHandleCancel={onHandleCancelModal}>
                                    <AddAirCost setAirImportExport={() => {

                                    }}
                                        callBack={() => {
                                            getAirExportAFs(dispatch, admin, function () {
                                                const elem = document.getElementById('getCostQuoat');
                                                elem?.click();
                                            });


                                        }} typeData={props.typeData} data={selectedAirQuoteData} importExportType={props.detail?.importExportType} onHandleCancel={() => onHandleCancelModal()} id={null} airImportExport={null} />
                                </Dialog>
                            )}

                            <Button variant='text' onClick={function () {
                                
                                onHandleOpenModal()
                            }} >
                                Edit
                            </Button>
                        </Grid>}
                        {/*{!value.isShow && <Grid lg={3.2} item>
                            <Button variant='text' onClick={function () { setValue(prev => ({ ...prev, isShow: true })) }}>
                                Edit
                            </Button>
                        </Grid>}*/}


                        {value.isShow && <Grid lg={12} item>
                            <Button onClick={() => {
                                var l = [];
                                l.push(value.rate)
                                var data = {
                                    rate: l,
                                    id: value.id
                                }
                                updateAirExportAFRate(dispatch, snackbarToggle, data, admin, function (res) {
                                    /* console.log(res)
                                     const elem = document.getElementById('getCostQuoat');
                                     elem?.click();*/
                                    setValue(prev => ({ ...prev, isShow: false }))
                                    var tc = [...props.listOfQuoatationCost];
                                    var k = []
                                    for (var i = 0; i < tc.length; i++) {
                                        if (tc[i].id == value.id) {
                                            var l = [];
                                            l.push(value.rate)
                                            tc[i].rate = value.rate;
                                            tc[i].rateList = l;
                                        }
                                    }
                                    props.setListOfQuoatationCost(tc)
                                })
                            }}> Rate Save</Button>
                        </Grid>
                        }
                        {!value.isShow && <Grid lg={3.5} item>
                            <Button variant='text' onClick={function () {   
                                props?.setbLoadingLocal(true)
                                getAirExportAf(value, admin, function (data) {
                                   props?.setbLoadingLocal(true)
                                    var tObj = JSON.parse(JSON.stringify(data?.data));
                                    if (tObj.weightList) {
                                        tObj.weight = tObj.weightList[0];
                                    } else {
                                        tObj.weight = 0;
                                    }
                                    tObj.rate = tObj.rateList[0];
                                    tObj.quoteRate = tObj.rateList[0];
                                    setUpdatedAirQuoteData(tObj)
                                    setSelectedAirQuoteData({ ...props.detail, ...tObj });

                                    var tc = [...props.listOfQuoatationCost];
                                    var k = []
                                    for (var i = 0; i < tc.length; i++) {
                                        if (tc[i].id == tObj?.id) {
                                            tc[i] = { ...props.detail, ...tObj };
                                        }
                                    }
                                    props.setListOfQuoatationCost(tc)
                                    // dispatch(onEmptyCrm());

                                    props.quoteByRate({ ...props.detail, ...tObj })
                                    // const elem = document.getElementById('getAirExportAf');
                                    //  elem?.click();
                                })
                                //props.setDefCharges()
                                //props.quoteByRate(props.detail)
                            }} >
                                Quote
                            </Button>
                            {/*<span style={{ width: "2px !important", height: "2px  !important" }} id="getAirExportAf" variant='text' onClick={function () {

                                getAirExportAf(value, admin, function (data) {
                                    var tObj = JSON.parse(JSON.stringify(data?.data));
                                    if (tObj.weightList) {
                                        tObj.weight = tObj.weightList[0];
                                    } else {
                                        tObj.weight = 0;
                                    }
                                    tObj.rate = tObj.rateList[0];
                                    tObj.quoteRate = tObj.rateList[0];
                                    setUpdatedAirQuoteData(tObj)
                                    setSelectedAirQuoteData({ ...props.detail, ...tObj });                                 

                                    var tc = [...props.listOfQuoatationCost];
                                    var k = []
                                    for (var i = 0; i < tc.length; i++) {
                                        if (tc[i].id == tObj?.id) {
                                            tc[i] = { ...props.detail, ...tObj };
                                        }
                                    }
                                    props.setListOfQuoatationCost(tc)
                                   // dispatch(onEmptyCrm());
                                  
                                    //getCRMList(dispatch, admin)
                                    props.quoteByRate({ ...props.detail, ...tObj })

                                })

                                //props.quoteByRate(props.detail)
                            }} >
                                
                            </span>*/}
                        </Grid>}

                    </Grid>



                </Typography>
            </TableCell>
        </TableRow>}</>
    );
};

export default AirQuote;
